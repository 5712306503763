<template>
  <!-- German version -->
  <div
    v-if="$i18n.locale==='de'"
    class="px-2"
  >
    <p>
      <span class="text-h6">Datenschutzinformationen für die Website, externe Seiten und andere Verarbeitungsvorgänge der aedifion GmbH</span><br>

      <span class="text-h6">I. Allgemeines</span><br>

      <span class="text-h6">1. Verantwortlicher</span>
    </p><p>Wir, die aedifion GmbH, nehmen den Schutz Ihrer personenbezogenen Daten und die diesem Schutz dienenden gesetzlichen Verpflichtungen sehr ernst. Die gesetzlichen Vorgaben verlangen umfassende Transparenz über die Verarbeitung personenbezogener Daten. Nur wenn Sie ausreichend über Zweck, Art und Umfang der Verarbeitung informiert sind, ist die Verarbeitung für Sie als betroffene Person nachvollziehbar.</p>

    <p>Unsere Datenschutzinformationen erklären Ihnen deshalb ausführlich, welche personenbezogenen Daten von uns bei der Nutzung unserer Website (https://www.aedifion.com/ ), allen hierauf verweisenden anderen Internetseiten und in den anderen, hier ggf. erläuterten Fällen, verarbeitet werden.</p>

    <p>Verantwortlicher im Sinne der Datenschutz-Grundverordnung (DSGVO), des Bundesdatenschutzgesetzes (BDSG) sowie sonstiger datenschutzrechtlicher Vorgaben ist die</p>

    <p>
      aedifion GmbH<br>
      Hohenzollernring 72<br>
      50672 Köln<br>
      Deutschland
    </p>

    <p>
      +49 221 - 98650770<br>
      info@aedifion.com
    </p>

    <p>Im Folgenden "Verantwortlicher" oder „wir“ genannt.</p>

    <p>
      Sie erreichen den Datenschutzbeauftragten unter:<br>
      PROLIANCE GmbH
    </p>

    <p>www.datenschutzexperte.de</p>

    <p>Leopoldstr. 21</p>

    <p>80802 München</p>

    <p>datenschutzbeauftragter(at)http://datenschutzexperte.de</p>

    <p>Bitte beachten Sie, dass Sie über Links auf unserer Website zu anderen Websites gelangen können, die nicht von uns, sondern von Dritten betrieben werden. Solche Links werden von uns entweder eindeutig gekennzeichnet oder sind durch einen Wechsel in der Adresszeile Ihres Browsers erkennbar. Für die Einhaltung der datenschutzrechtlichen Bestimmungen und einen sicheren Umgang mit Ihren personenbezogenen Daten auf diesen von Dritten betriebenen Websites sind wir nicht verantwortlich.</p>

    <span class="text-h6">2. Definitionen</span>

    <p>Aus der DSGVO</p>

    <p>Diese Datenschutzerklärung verwendet die Begriffe des Gesetzestextes der DSGVO. Die Begriffsbestimmungen (Art. 4 DSGVO) können Sie z.B. unter https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX:32016R0679 einsehen.</p>

    <p>Zusätzliche Begriffsbestimmungen:</p>

    <p>Cookies und ähnliche Technologien</p>

    <p>Cookies sind Textdateien, die von einer Website auf Ihrem Endgerät abgelegt bzw. dort ausgelesen werden. Sie enthalten Buchstaben- und Zahlenkombinationen, um z.B. bei einer erneuten Verbindung zur Cookie-setzenden Website den Nutzer und seine Einstellungen wiederzuerkennen, das Eingeloggt-Bleiben in einem Kundenkonto zu ermöglichen oder ein bestimmtes Nutzungsverhalten statistisch zu analysieren.</p>

    <p>Die WebStorage Technik ermöglicht es, Variablen und Werte lokal im Browser-Cache des Nutzers zu speichern. Die Technik umfasst sowohl den sog. "sessionStorage", der bis zum Beenden des Browser-Tabs gespeichert bleibt, wie auch den "localStorage", der so lange im Cache des Browsers gespeichert wird, bis der Cache vom Nutzer geleert wird. Die localStorage Technik ermöglicht es u.a., den Nutzer und seine Einstellungen bei dem Aufruf unserer Website wiederzuerkennen.</p>

    <p>Datenkategorien</p>

    <p>Wenn wir die verarbeiteten Datenkategorien angeben, sind darunter insbesondere die folgenden Daten gemeint: Stammdaten (z.B. Namen, Anschrift, Geburtsdaten), Kontaktdaten (z.B. E-Mail-Adressen, Telefonnummer, Messengerdienste), Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos, Inhalte von Dokumenten/Dateien), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeiten, Kundenkategorie), Zahlungsdaten (z.B. Bankverbindungen, Zahlungshistorie, Verwendung sonstiger Zahlungsdienstleister), Nutzungsdaten (z.B. Verlauf auf unserer Website, Nutzung bestimmter Inhalte, Zugriffszeiten, Kontakthistorie), Verbindungsdaten (z.B. Geräte-Informationen, IP-Adressen, URL-Referrer), Standortdaten (z.B. GPS-Daten, IP-Geolokalisierung, Zugriffspunkte); Diagnosedaten (z.B. Crash-Logs, Performance Daten der Website/App, andere technische Daten zur Analyse von Störungen und Fehlern).</p>

    <span class="text-h6">3. Informationen zur Datenverarbeitung</span>

    <p>Wir verarbeiten personenbezogene Daten nur, soweit dies gesetzlich zulässig ist. Eine Weitergabe personenbezogener Daten findet ausschließlich in den unten beschriebenen Fällen statt. Die personenbezogenen Daten werden durch angemessene technische und organisatorische Maßnahmen (z.B. Pseudonymisierung, Verschlüsselung) geschützt.</p>

    <p>Sofern wir nicht gesetzlich zu einer Speicherung oder einer Weitergabe an Dritte (insbesondere Strafverfolgungsbehörden) verpflichtet sind, hängt die Entscheidung, welche personenbezogenen Daten von uns wie lange verarbeitet werden und in welchem Umfang wir sie ggf. offenlegen, davon ab, welche Funktionen der Website Sie im Einzelfall nutzen.</p>

    <span class="text-h6">4. Speicherdauer</span>

    <p>Die personenbezogenen Daten werden gelöscht, sobald der Zweck der Verarbeitung entfällt oder eine vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der personenbezogenen Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht. Soweit wir über die Speicherdauer von Cookies und ähnlichen Technologien informieren müssen, finden Sie die Angaben hierzu in unserem Consent Tool.</p>

    <span class="text-h6">5. Automatisierte Entscheidungen im Einzelfall einschließlich Profiling</span>

    <p>Automatisierte Entscheidungen im Einzelfall einschließlich Profiling erfolgen nicht.</p>

    <span class="text-h6">6. Rechte betroffener Personen</span>

    <p>Sie haben als betroffene Person das Recht auf Auskunft nach Art. 15 DSGVO, das Recht auf Berichtigung nach Art. 16 DSGVO, das Recht auf Löschung nach Art. 17 DSGVO, das Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO sowie das Recht auf Datenübertragbarkeit nach Art. 20 DSGVO. Beim Auskunftsrecht und beim Löschungsrecht gelten die Beschränkungen aus §§ 34, 35 BDSG. Sie haben das Recht, sich bei einer Datenschutzaufsichtsbehörde zu beschweren (Art. 77 DSGVO in Verbindung mit § 19 BDSG).</p>

    <p>Die für uns/ für unsere Hauptniederlassung zuständige Datenschutzaufsichtsbehörde ist:</p>

    <p>
      Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen<br>
      Kavalleriestraße 2-4<br>
      40213 Düsseldorf
    </p>

    <p>Ihnen steht es aber frei, sich bei einer anderen Datenschutzaufsichtsbehörde zu beschweren.</p>

    <p>Eine Liste der Aufsichtsbehörden finden Sie unter: https://www.bfdi.bund.de/ (unter Infothek/Anschriften und Links)</p>

    <span class="text-h6">7. Mitteilungspflichten des Verantwortlichen</span>

    <p>Wir teilen allen Empfängern, denen Ihre personenbezogenen Daten offengelegt wurden, jede Berichtigung oder Löschung Ihrer personenbezogenen Daten oder eine Einschränkung der Verarbeitung nach Art. 16, Art. 17 Abs. 1 und Art. 18 DSGVO mit, es sei denn, die Mitteilung ist unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden. Wir unterrichten Sie über die Empfänger, wenn Sie dies verlangen.</p>

    <span class="text-h6">8. Pflicht zur Bereitstellung</span>

    <p>Soweit nachfolgend bei den Angaben zu den Rechtsgrundlagen nichts anderes erläutert wird, sind Sie zur Bereitstellung personenbezogener Daten nicht verpflichtet. In den Fällen des Art. 6 Abs. 1 Buchst. b DSGVO sind die personenbezogenen Daten jedoch zu einer Vertragserfüllung oder zu einem Vertragsabschluss erforderlich. Stellen Sie die betroffenen personenbezogenen Daten nicht zur Verfügung, ist die Vertragserfüllung oder der Vertragsabschluss nicht möglich. Stellen Sie die Daten in den Fällen des Art. 6 Abs. 1 Buchst. a, f DSGVO nicht zur Verfügung, ist eine Nutzung der betroffenen Teile unserer Website nicht möglich.</p>

    <p>Hinsichtlich unserer Veranstaltungen sind Sie zur Bereitstellung personenbezogener Daten nicht verpflichtet, eine Teilnahme an den Veranstaltungen ist ohne diese Daten jedoch nicht möglich.</p>

    <span class="text-h6">9. Widerspruchsrecht</span>

    <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 Buchst. f DSGVO erfolgt, Widerspruch einzulegen. Werden personenbezogene Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen.</p>

    <p>Der Widerspruch kann formfrei erfolgen und sollte gerichtet werden an die oben genannten Kontaktdaten.</p>

    <span class="text-h6">10. Widerruf der Einwilligung</span>

    <p>Sie haben gemäß Art. 7 Abs. 3 S. 1 DSGVO das Recht, Ihre Einwilligung jederzeit mit Wirkung für die Zukunft formlos per Post oder E-Mail zu widerrufen. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird hiervon nicht berührt. Auf Ihren Widerruf werden wir die auf Grundlage der Einwilligung verarbeiteten personenbezogenen Daten löschen, wenn es keine andere Rechtsgrundlage für deren Verarbeitung gibt.</p>

    <p>Der Widerruf kann formfrei erfolgen und sollte gerichtet werden an die oben genannten Kontaktdaten.</p>

    <p>Sie können Ihre Einwilligung(en) auch durch die Deaktivierung der jeweiligen Datenverarbeitungsdienste direkt in unserem Consent-Tool widerrufen. Bitte beachten Sie, dass Sie dies auf jedem Endgerät, auf dem Sie unsere Website besucht und in die Datenverarbeitung eingewilligt haben, vornehmen müssen.</p>

    <span class="text-h6">II. Datenverarbeitung im Zusammenhang mit der Nutzung unserer Produkte</span>

    <p>Die Nutzung der Website und ihrer Funktionen erfordert regelmäßig die Verarbeitung personenbezogener Daten.</p>

    <span class="text-h6">Nutzerkontos</span>

    <p>
      Zweck der Verarbeitung: Nutzung eines Nutzerkontos (als Voraussetzung um Zugriff auf das Frontend & Mobile App zu gewährleisten); Gewährleistung der Daten- und Informationssicherheit; Einschränkung von Inhalten von Funktionen durch den Auftraggeber; Überführung der Daten in unser CRM-System; Gewährleistung der Sicherheit unserer Systeme.<br>
      Rechtsgrundlage: Art. 6 Abs. 1 Buchst. b, f DSGVO, Art. 49 Abs. 1 Buchst. a DSGVO.<br>
      Datenkategorien: Stammdaten, Kontaktdaten und Verbindungsdaten.<br>
      Empfänger der Daten: Hetzner Online GmbH, Industriestr. 25 91710 Gunzenhausen Germany. | Amazon Web Services EMEA S.à r.l.
      avenue John F. Kennedy, L-1855 Luxembourg | Microsoft Ireland Operations Limited, The Atrium Building
      Block B, Carmanhall Road, Dublin 18<br>
      Beabsichtigte Drittlandübermittlung: Keine.<br>
      Speichern wir auf Ihrem Endgerät aufgrund Ihrer Einwilligung personenbezogene Daten oder lesen solche aus? Nein.
    </p>

    <span class="text-h6">Sentry</span>
    <p>
      Zweck der Verarbeitung: Analyse der Verwendung unserer Apps und der verwendeten Endgeräte zur Behebung von (System-)Abstürzen und anderen technischen Problemen im Zusammenhang mit unseren Apps.<br>
      Rechtsgrundlage: Art. 6 Abs. 1 Buchst. a DSGVO, Art. 49 Abs. 1 Buchst. a DSGVO.<br>
      Datenkategorien: Verbindungsdaten, Nutzungsdaten, Inhaltsdaten (je nach Einzelfall).<br>
      Empfänger der Daten: Functional Software Inc. Sentry 132 Hawthorne Street San Francisco, California 94107, USA.<br>
      Beabsichtigte Drittlandübermittlung: Im Einzelfall USA auf Basis einer Einwilligung.<br>
      Speichern wir auf Ihrem Endgerät aufgrund Ihrer Einwilligung personenbezogene Daten oder lesen solche aus? Nein.
    </p>

    <span class="text-h6">Confluent</span>
    <p>
      Name und Rechtsform: Confluent, Inc.<br>
      Adresse: 899 West Evelyn Avenue, Mountain View, CA 94041, US<br>
      Vertragslaufzeit: seit 2018<br>
      Vertragsinhalt: Event Stream Processing<br>
      Kontaktinformationen: contact@confluent.io<br>

      <span class="text-h6">T-Systems</span>
    </p><p>
      Name und Rechtsform: T-Systems International GmbH<br>
      Adresse: Hahnstraße 43d, 60528 Frankfurt a.M., Deutschland<br>
      Vertragslaufzeit: seit 2020<br>
      Vertragsinhalt: Dokumentendatenbank<br>
      Kontaktinformationen: https://www.t-systems.com/de/de/kontakt<br>
    </p>
  </div>

  <!-- English version -->
  <div
    v-else
    class="px-2"
  >
    <span class="text-h6">Data protection information for the website, external pages and other processing operations of aedifion GmbH</span><br>
    <span class="text-h6">I. General</span><br>
    <span class="text-h6">1. Responsible</span>
    <p>We, aedifion GmbH, take the protection of your personal data and the legal obligations serving this protection very seriously. The legal requirements demand comprehensive transparency regarding the processing of personal data. Only if you are sufficiently informed about the purpose, nature and scope of the processing, the processing is comprehensible for you as a data subject.</p>

    <p>Our data protection information therefore explains in detail what personal data is processed by us when you use our website (aedifion GmbH | Gebäude einfach nachhaltig & effizient gemacht ), all other websites that refer to it and in the other cases that may be explained here.</p>

    <p>The responsible party within the meaning of the General Data Protection Regulation (DSGVO), the Federal Data Protection Act (BDSG) and other legal data protection requirements is the</p>

    <p>
      aedifion GmbH<br>
      Hohenzollernring 72<br>
      50672 Cologne<br>
      Germany
    </p>

    <p>
      +49 221 - 98650770<br>
      info@aedifion.com
    </p>

    <p>Hereinafter referred to as the "responsible party" or "we".</p>

    <p>
      You can reach the data protection officer at:<br>
      PROLIANCE GmbH
    </p>

    <p>datenschutzexperte.de | Digitale Datenschutzlösungen</p>

    <p>Leopoldstr. 21</p>

    <p>80802 Munich</p>

    <p>data protection officer(at)datenschutzexperte.de | Digitale Datenschutzlösungen</p>

    <p>Please note that links on our website may take you to other websites that are not operated by us but by third parties. Such links are either clearly marked by us or are recognizable by a change in the address line of your browser. We are not responsible for compliance with data protection regulations and secure handling of your personal data on these websites operated by third parties.</p>

    <span class="text-h6">2. Definitions</span>
    <p>From the DSGVO</p>

    <p>This privacy policy uses the terms of the legal text of the DSGVO. You can view the definitions (Art. 4 DSGVO), for example, at https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX:32016R0679.</p>

    <p>Additional Definitions:</p>

    <p>Cookies and similar technologies</p>

    <p>Cookies are text files that are placed on or read from your terminal device by a website. They contain combinations of letters and numbers in order, for example, to recognize the user and his settings when he reconnects to the cookie-setting website, to enable him to remain logged in to a customer account or to statistically analyze specific usage behavior.</p>

    <p>The WebStorage technology makes it possible to store variables and values locally in the user's browser cache. The technique includes both so-called "sessionStorage", which remains stored until the browser tab is closed, and "localStorage", which is stored in the browser cache until the cache is cleared by the user. The localStorage technique makes it possible, among other things, to recognize the user and his or her settings when our website is called up.</p>

    <p>Data categories</p>

    <p>When we specify the categories of data processed, we are referring in particular to the following data: master data (e.g. names, addresses, dates of birth), contact data (e.g. e-mail addresses, telephone numbers, messenger services), content data (e.g. text entries, photographs, videos, contents of documents/files), contract data (e.g. subject of contract, terms, customer category), payment data (e.g. bank details, payment history, use of other payment service providers), usage data (e.g. history on our website, use of certain content, access times, contact history), connection data (e.g. device information, IP addresses, URL referrers), location data (e.g. GPS data, IP geolocation, access points); diagnostic data (e.g. crash logs, performance data of the website/app, other technical data for the analysis of faults and errors).</p>

    <span class="text-h6">3. Information on data processing</span>
    <p>We process personal data only to the extent permitted by law. A transfer of personal data takes place exclusively in the cases described below. Personal data is protected by appropriate technical and organizational measures (e.g. pseudonymization, encryption).</p>

    <p>Unless we are required by law to store or disclose personal data to third parties (in particular law enforcement agencies), the decision as to which personal data we process and for how long, and the extent to which we disclose it, depends on which website functions you use in each individual case.</p>

    <span class="text-h6">4. Storage period</span>
    <p>The personal data will be deleted as soon as the purpose of processing ceases to apply or a prescribed storage period expires, unless there is a necessity for the continued storage of the personal data for the conclusion or performance of a contract. Insofar as we have to inform about the storage period of cookies and similar technologies, you will find the details in our Consent Tool.</p>

    <span class="text-h6">5. Automated decisions in individual cases including profiling</span>
    <p>Automated decisions in individual cases including profiling do not take place.</p>

    <span class="text-h6">6. Rights of data subjects</span>
    <p>As a data subject, you have the right to information under Article 15 of the GDPR, the right to rectification under Article 16 of the GDPR, the right to erasure under Article 17 of the GDPR, the right to restriction of processing under Article 18 of the GDPR and the right to data portability under Article 20 of the GDPR. With regard to the right to information and the right to erasure, the restrictions from §§ 34, 35 BDSG apply. You have the right to complain to a data protection supervisory authority (Art. 77 DSGVO in conjunction with § 19 BDSG).</p>

    <p>The data protection supervisory authority responsible for us/ for our head office is:</p>

    <p>
      State Commissioner for Data Protection and Freedom of Information of North Rhine-Westphalia.<br>
      Kavalleriestrasse 2-4<br>
      40213 Düsseldorf
    </p>

    <p>However, you are free to complain to another data protection supervisory authority.</p>

    <p>You can find a list of supervisory authorities at: Startseite (under Infothek/Addresses and Links).</p>

    <span class="text-h6">7. Notification obligations of the data controller</span>
    <p>We will notify all recipients to whom your personal data has been disclosed of any rectification or erasure of your personal data or restriction of processing pursuant to Art. 16, Art. 17(1) and Art. 18 of the GDPR, unless such notification is impossible or involves a disproportionate effort. We will inform you of the recipients if you request this.</p>

    <span class="text-h6">8. Obligation to provide</span>
    <p>Unless otherwise explained below in the information on the legal basis, you are not obliged to provide personal data. However, in the cases of Art. 6 (1) (b) DSGVO, the personal data is necessary for the performance of a contract or for the conclusion of a contract. If you do not provide the personal data concerned, the performance or conclusion of the contract is not possible. If you do not provide the data in the cases of Art. 6 (1) a, f DSGVO, the use of the affected parts of our website is not possible.</p>

    <p>With regard to our events, you are not obliged to provide personal data, but participation in the events is not possible without this data.</p>

    <span class="text-h6">9. Right of objection</span>
    <p>You have the right to object at any time, on grounds relating to your particular situation, to the processing of personal data concerning you which is carried out on the basis of Article 6(1)(f) DSGVO. If personal data are processed for the purpose of direct marketing, you have the right to object at any time to the processing of personal data concerning you for the purpose of such marketing.</p>

    <p>The objection can be made form-free and should be directed to the contact details above.</p>

    <span class="text-h6">10. Revocation of consent</span>
    <p>In accordance with Art. 7 (3) p. 1 DSGVO, you have the right to revoke your consent at any time with effect for the future informally by mail or e-mail. The lawfulness of the processing carried out on the basis of the consent until the revocation is not affected by this. Upon your revocation, we will delete the personal data processed on the basis of the consent if there is no other legal basis for its processing.</p>

    <p>The revocation can be made form-free and should be directed to the contact details above.</p>

    <p>You can also revoke your consent(s) by deactivating the respective data processing services directly in our Consent Tool. Please note that you must do this on each terminal device on which you have visited our website and consented to data processing.</p>

    <span class="text-h6">II. Data processing in connection with the use of our products.</span>
    <p>The use of the website and its functions regularly requires the processing of personal data.</p>

    <span class="text-h6">User account</span>
    <p>
      Purpose of processing: use of a user account (as a prerequisite to ensure access to the frontend & mobile app); ensuring data and information security; restriction of content of functions by the client; transfer of data to our CRM system; ensuring the security of our systems.<br>
      Legal basis: Art. 6 para. 1 letter b, f DSGVO, Art. 49 para. 1 letter a DSGVO.<br>
      Data categories: Master data, contact data and connection data.<br>
      Recipients of the data: Hetzner Online GmbH, Industriestr. 25 91710 Gunzenhausen Germany. | Amazon Web Services EMEA S.à r.l.
      avenue John F. Kennedy, L-1855 Luxembourg | Microsoft Ireland Operations Limited, The Atrium Building
      Block B, Carmanhall Road, Dublin 18<br>
      Intended third country transfer: None.<br>
      Do we store personal data on your end device based on your consent or read out such data? No.
    </p>

    <span class="text-h6">Sentry</span>
    <p>
      Purpose of processing: analysis of the use of our apps and the end devices used to fix (system) crashes and other technical problems related to our apps.<br>
      Legal basis: Art. 6(1)(a) DSGVO, Art. 49(1)(a) DSGVO.<br>
      Data categories: Connection data, usage data, content data (depending on the individual case).<br>
      Recipients of the data: Functional Software Inc Sentry 132 Hawthorne Street San Francisco, California 94107, USA.<br>
      Intended third country transfer: On a case-by-case basis USA on the basis of consent.<br>
      Do we store or read personal data on your end device based on your consent? No.
    </p>

    <span class="text-h6">Confluent</span>
    <p>
      Legal name: Confluent, Inc.<br>
      Address: 899 West Evelyn Avenue, Mountain View, CA 94041, US<br>
      Contract period: seit 2018<br>
      Contract: Event Stream Processing<br>
      Contact: contact@confluent.io<br>

      <span class="text-h6">T-Systems</span>
    </p><p>
      Legal name: T-Systems International GmbH<br>
      Address: Hahnstraße 43d, 60528 Frankfurt a.M., Deutschland<br>
      Contract period: seit 2020<br>
      Contract: Document database<br>
      Contact: https://www.t-systems.com/de/de/kontakt<br>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DataPrivacy',
})
</script>
