import 'vanilla-cookieconsent/src/cookieconsent.js'

// @ts-ignore
export const cookieconsent = window.initCookieConsent()

export function startCookieConsent (): void {
  cookieconsent.run({
    autorun: false,
    languages: {
      en: {
        consent_modal: {
          description: 'empty',
          primary_btn: {
            role: 'accept_all',
            text: 'empty',
          },
          secondary_btn: {
            role: 'settings',
            text: 'empty',
          },
          title: 'empty',
        },
        settings_modal: {
          accept_all_btn: 'empty',
          blocks: [
            {
              description: 'empty',
              title: 'empty',
            },
            {
              description: 'empty',
              title: 'empty',
              toggle: {
                enabled: true,
                readonly: true,
                value: 'authentication',
              },
            },
            {
              description: 'empty',
              title: 'empty',
              toggle: {
                enabled: false,
                readonly: false,
                value: 'analytics_ux',
              },
            },
          ],
          save_settings_btn: 'empty',
          title: 'empty',
        },
      },
    },
    mode: 'opt-in',
    page_scripts: true,
  })
}
