/* eslint-disable no-console */
import { register } from 'register-service-worker'

register(import.meta.env.PROD ? '/service-worker.js' : '/dev-sw.js?dev-sw', {
  cached () {
    console.log('Content has been cached for offline use.')
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  },
  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },
  ready () {
    console.log(
      'App is being served from cache by a service worker.\n' +
      'For more details, visit https://goo.gl/AFskqB',
    )
  },
  registered () {
    console.log('Service worker has been registered.')
  },
  registrationOptions: {
    type: import.meta.env.PROD ? undefined : 'module',
  },
  updated () {
    console.log('New content is available; please refresh.')
  },
  updatefound () {
    console.log('New content is downloading.')
  },
})
