import { AnalyticsResultsForComponent, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { MutationTree } from 'vuex'
import { OptimizationState } from './types'
import { Samplerate } from '@/vuex/data_points_view/types'
import { PlotViewDateRange } from '@/vuex/optimization/types'

export default {
  SET_FOLLOW_ANALYSIS_PERIOD: (state: OptimizationState, match: boolean) => (state.followAnalysisPeriod = match),

  SET_LOADING_COMPONENT_IN_PROJECT: (state: OptimizationState, loading: boolean) => (state.loadingComponentInProject = loading),

  SET_SAMPLERATE: (state: OptimizationState, samplerate: Samplerate) => (state.samplerate = samplerate),

  SET_SEARCH: (state: OptimizationState, search: string) => (state.search = search),

  SET_SELECTED_COMPONENT_IN_PROJECT: (state: OptimizationState, componentInProject: ComponentInProjectWithContext|null) => (state.selectedComponentInProject = componentInProject),

  SET_SELECTED_COMPONENTS_ANALYSIS_RESULTS: (state: OptimizationState, componentResults: AnalyticsResultsForComponent|null) => (state.selectedComponentInProjectResults = componentResults),

  SET_SELECTED_INSTANCE_ID: (state: OptimizationState, instanceId: number|null) => (state.selectedInstanceId = instanceId),

  SET_SELECTED_RESULT_ID: (state: OptimizationState, resultId: string|null) => (state.selectedResultId = resultId),

  SET_DATE_RANGE: (state: OptimizationState, dateRange: PlotViewDateRange) => (state.dateRange = dateRange),

  SET_HIDDEN_PINS_HASH_IDS: (state: OptimizationState, hiddenPinsHashIds: string[]) => (state.hiddenPinsHashIds = hiddenPinsHashIds),
} as MutationTree<OptimizationState>
