import { RouteLocation } from 'vue-router'

/**
 * Clears all status and alerts values that are displayed in the route query from the store.
 * @param store vuex store.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function clearStatusAndAlertsStore (): RouteLocation|void {}

/**
 * Checks the store and the query for status and alerts values that should be displayed in the query and adds all that are missing or different.
 * @param store vuex store.
 * @param to Target route with the new query.
 * @returns a new location with updated query, if the query was changed.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function updateStatusAndAlertsQuery (): RouteLocation|void {}

/**
 * Checks the query of the target route and if it contains values that differ from the stored values it updates the store.
 * @param store vuex store.
 * @param to Target route with values to be stored.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function updateStatusAndAlertsStore (): RouteLocation|void {}
