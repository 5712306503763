<script setup lang="ts">
import { computed, onBeforeUpdate, onUpdated, ref } from 'vue'
import { createDoubleChipItem, getTagSelectionForListDisplay } from '@/utils/helpers/tags'
import { DIKebabItemDatapointPayload, DoubleChipItem } from '@/utils/types'
import { Node, Tag } from '@aedifion.io/aedifion-api'
import { type ActionMenuItem } from '@/components/ActionMenu.vue'
import { copyStringToClipboard } from '@/utils/helpers/clipboard'
import { DatapointOverviewItem } from '@/vuex/data_points_view/types'
import DoubleChip from '@/components/DoubleChip.vue'
import { formatNumber } from '@/filters/formatting'
import KebabMenu from '@/components/KebabMenu.vue'
import { useI18n } from 'vue-i18n'

const noUnit = ['No Unit', 'Keine Einheit']
const SORTING_ICON_MAP = {
  asc: 'fa:fas fa-sort-up',
  desc: 'fa:fas fa-sort-down',
}
type Props = {
  datapointTableItems: DatapointOverviewItem[],
  deselectionEnabled?: boolean,
  itemsHaveNoDescription?: boolean,
  labelGetter: (rootNodeId: string, childNodeId?: string) => Node|null,
  loading?: boolean,
  menuEnabled?: boolean,
  noDataText?: string,
  readOnly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  deselectionEnabled: true,
  itemsHaveNoDescription: false,
  loading: false,
  menuEnabled: true,
  readOnly: false,
})

const emit = defineEmits<{
  (e: 'datapoints-table:toggleSeries', datapointId: string): void,
  (e: 'datapoints-table:setAllSeriesVisibility', visibility: boolean): void,
  (e: 'datapoints-table:deselectAllDatapoints'): void,
  (e: 'datapoints-table:deselectDatapoint', datapoint: DatapointOverviewItem): void,
  (e: 'datapoints-table:edit-tags', datapointPayload: DIKebabItemDatapointPayload): void,
  (e: 'datapoints-table:edit-description', datapointPayload: DIKebabItemDatapointPayload): void
  (e: 'datapoints-table:before-update'): void
  (e: 'datapoints-table:updated'): void
}>()

onBeforeUpdate(() => {
  emit('datapoints-table:before-update')
})

onUpdated(() => {
  emit('datapoints-table:updated')
})

const { t } = useI18n()

const isUnitSortDirectionDesc = ref<boolean | undefined>(false)

type SortItem = {
  key: string,
  order: 'asc' | 'desc'
}
const sortBy = ref<SortItem[]>([{ key: 'title', order: 'asc' }])

const allItemsVisible = computed<boolean>(() => {
  return props.datapointTableItems.every((item) => {
    return item.visible
  })
})

const someItemsVisible = computed<boolean>(() => {
  return props.datapointTableItems.some((item) => {
    return item.visible
  })
})

const dataPointTableItemsWithSelectedTags = computed<Array<DatapointOverviewItem & { virtualTags: Tag[] }>>(() => {
  return props.datapointTableItems.map((item) => {
    const virtualTags: Tag[] = getTagSelectionForListDisplay(item)
    return {
      ...item,
      virtualTags,
    }
  })
})

function tagsArraySorter (a: DatapointOverviewItem['tags'], b: DatapointOverviewItem['tags']) {
  if (!a?.length || !b?.length) {
    if (!a?.length && !b?.length) return 0

    return !a?.length ? 1 : -1
  }

  if (a[0].key === b[0].key) {
    return (a[0].value ?? '').localeCompare(b[0].value ?? '')
  }

  return a[0].key.localeCompare(b[0].key)
}

function numberSorter (a: number|null, b: number|null) {
  if (a === null || b === null) {
    if (a === b) return 0

    return a === null ? 1 : -1
  }

  return a - b
}

type Header = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sort?: (a: any, b: any) => number,
  sortable?: boolean,
  title: string,
  value: string
}

const headers = computed(() => {
  const result: Header[] = [
    {
      sortable: true,
      title: '',
      value: 'visible',
    },
    {
      sort: (a: DatapointOverviewItem['title'], b: DatapointOverviewItem['title']) => (a ?? '').localeCompare(b ?? ''),
      sortable: true,
      title: t('table_header.datapoint'),
      value: 'title',
    }, {
      sort: (a: DatapointOverviewItem['tags'], b: DatapointOverviewItem['tags']) => tagsArraySorter(a, b),
      sortable: true,
      title: t('table_header.tag'),
      value: 'virtualTags',
    }, {
      sort: (a: DatapointOverviewItem['unit'], b: DatapointOverviewItem['unit']) => {
        if (isUnitSortDirectionDesc.value) {
          if (noUnit.includes(a ?? '')) return -1
          if (noUnit.includes(b ?? '')) return 1
        }

        if (isUnitSortDirectionDesc.value === false) {
          if (noUnit.includes(a ?? '')) return 1
          if (noUnit.includes(b ?? '')) return -1
        }
        return (a ?? '').localeCompare(b ?? '')
      },
      sortable: true,
      title: t('table_header.unit'),
      value: 'unit',
    },
    {
      sort: (a: DatapointOverviewItem['min'], b: DatapointOverviewItem['min']) => numberSorter(a, b),
      sortable: true,
      title: 'Min',
      value: 'min',
    },
    {
      sort: (a: DatapointOverviewItem['mean'], b: DatapointOverviewItem['mean']) => numberSorter(a, b),
      sortable: true,
      title: 'Mean',
      value: 'mean',
    },
    {
      sort: (a: DatapointOverviewItem['max'], b: DatapointOverviewItem['max']) => numberSorter(a, b),
      sortable: true,
      title: 'Max',
      value: 'max',
    },
  ]

  if (props.deselectionEnabled && !props.readOnly) {
    result.push({
      sortable: false,
      title: t('table_header.actions'),
      value: 'actions',
    })
  }
  return result
})

const itemsPerPage = computed<number>(() => {
  return props.datapointTableItems.length
})

const kebabMenuItems = computed<ActionMenuItem[]>(() => {
  return [{
    id: 'edit-tags',
    label: t('edit_tags'),
  }, {
    id: 'edit-description',
    label: t('edit_description'),
  }]
})

function copyDataPointId (item: DatapointOverviewItem): void {
  copyStringToClipboard(item.dataPointID)
}

function createTagItem (tagKey: string, tagValue?: string): DoubleChipItem {
  const label = props.labelGetter(tagKey, tagValue)
  return createDoubleChipItem(label, tagKey, tagValue)
}

function getKebabEventData (item: DatapointOverviewItem): DIKebabItemDatapointPayload {
  return {
    description: item.description,
    id: item.dataPointID,
    tags: item.tags,
    title: item.dataPointID,
  }
}

function updateSortDirection (sortedItems: SortItem[]): void {
  const titleSortAsc: SortItem = { key: 'title', order: 'asc' }
  const visibleSortDesc: SortItem = { key: 'visible', order: 'desc' }

  const visibleSort = sortedItems.find(sort => sort.key === 'visible')
  const unitSort = sortedItems.find(sort => sort.key === 'unit')

  // If 'visible' is the primary sort or included in the sort
  if (visibleSort !== undefined) {
    if (visibleSort.order === 'asc') {
      // If 'visible' is sorted ascending, change it to descending and ensure 'title' is sorted ascending
      sortBy.value = [visibleSortDesc, titleSortAsc, ...sortedItems.filter(sort => sort.key !== 'title' && sort.key !== 'visible')]
    } else {
      sortBy.value = sortedItems
    }
  } else {
    sortBy.value = sortedItems
  }

  isUnitSortDirectionDesc.value = unitSort?.order === 'desc'
}
</script>

<template>
  <v-data-table
    class="mt-2"
    data-testid="datapoints-table"
    :headers="headers"
    :items="dataPointTableItemsWithSelectedTags"
    :no-data-text="props.noDataText"
    :items-per-page="itemsPerPage"
    multi-sort
    :row-props="{ class: 'table-row' }"
    :sort-asc-icon="`${SORTING_ICON_MAP.asc}`"
    :sort-desc-icon="`${SORTING_ICON_MAP.desc}`"
    :loading="props.loading"
    :sort-by="sortBy"
    @update:sort-by="updateSortDirection"
  >
    <template #item.visible="{ item }">
      <v-checkbox-btn
        color="primary"
        data-testid="datapoints-table-hide-datapoint"
        :ripple="false"
        :model-value="item.visible"
        @update:model-value="emit('datapoints-table:toggleSeries', item.dataPointID)"
      />
    </template>

    <template #header.visible>
      <div class="d-flex align-center">
        <v-tooltip
          v-if="props.datapointTableItems.length > 0"
          location="left"
        >
          <template #activator="{ props: hideTimeseriesTooltipProps }">
            <v-checkbox-btn
              class="flex-grow-0"
              color="primary"
              data-testid="set-all-datapoints-visibility"
              :indeterminate="someItemsVisible && !allItemsVisible"
              indeterminate-icon="fa:fas fa-check-square"
              :ripple="false"
              :model-value="allItemsVisible"
              v-bind="hideTimeseriesTooltipProps"
              @click.stop
              @update:model-value="emit('datapoints-table:setAllSeriesVisibility', $event)"
            />
          </template>
          <span data-testid="checkbox-tooltip">{{ allItemsVisible ? t('hide_all_datapoints') : t('show_all_datapoints') }}</span>
        </v-tooltip>
        <v-checkbox-btn
          v-else
          class="flex-grow-0"
          color="primary"
          :ripple="false"
          :value="false"
          disabled
        />
        <v-icon
          class="notranslate v-theme--light v-icon--size-default v-data-table-header__sort-icon"
          data-testid="datapoints-table-checkbox-sort-icon"
        >
          {{ SORTING_ICON_MAP.desc }}
        </v-icon>
      </div>
    </template>

    <template #item.title="{ item }">
      <div
        class="selection__wrapper d-flex justify-start align-center flex-row"
        data-cy="datapoint-overview-id"
      >
        <div
          class="selection mr-2"
          :style="`background-color: ${item.visible ? item.seriesColor : 'transparent'}; border-color: ${item.seriesColor}`"
        />
        <div
          class="d-flex flex-column"
        >
          <span
            :class="[
              {
                'tw-italic': !item.visible,
                'font-weight-medium': !props.itemsHaveNoDescription
              }
            ]"
          >{{
            item.title
          }}</span>
          <span
            v-if="item.description"
            :class="[{ 'tw-italic': !item.visible }, 'clamp-text-1', 'text-subtitle-1', 'text--secondary']"
            data-cy="datapoint-overview-desciption"
          >{{ item.description }}</span>
        </div>
        <v-tooltip
          :disabled="item.title === item.dataPointID"
          location="top"
        >
          <template #activator="{ props: copyTooltipProps }">
            <v-btn
              class="copy-icon ml-auto"
              data-testid="datapoints-table-copy-datapoint-id"
              icon
              size="x-small"
              v-bind="copyTooltipProps"
              @click="copyDataPointId(item)"
            >
              <v-icon
                size="18"
              >
                fa:far fa-copy
              </v-icon>
            </v-btn>
          </template>
          {{ item.dataPointID }}
        </v-tooltip>
      </div>
    </template>

    <template #item.virtualTags="{ item }">
      <div class="d-flex justify-start align-center flex-row flex-wrap mt-1">
        <DoubleChip
          v-for="(tag, index) in item.virtualTags"
          :key="`tag_${index}`"
          class="mb-1 mr-1"
          color="neutral-darken3"
          dark
          :left-item="createTagItem(tag.key)"
          :right-item="createTagItem(tag.key, tag.value)"
        />
        <span v-if="item.virtualTags.length === 0">
          {{ t('no_tags_available') }}
        </span>
      </div>
    </template>

    <template #item.unit="{ item }">
      <div class="d-flex justify-start align-center flex-row flex-wrap mt-1">
        <span v-if="!props.loading">{{ item.unit }}</span>
        <v-skeleton-loader
          v-else
          height="20"
          width="50"
          type="text"
          class="mb-n2"
        />
      </div>
    </template>

    <template #item.min="{ item }">
      <div class="d-flex justify-start align-center flex-row flex-wrap mt-1">
        <span v-if="!props.loading">{{ formatNumber(item.min, { fallbackValue: '-' }) }}</span>
        <v-skeleton-loader
          v-else
          height="20"
          width="40"
          type="text"
          class="mb-n2"
        />
      </div>
    </template>

    <template #item.mean="{ item }">
      <div class="d-flex justify-start align-center flex-row flex-wrap mt-1">
        <span v-if="!props.loading">{{ formatNumber(item.mean, { fallbackValue: '-' }) }}</span>
        <v-skeleton-loader
          v-else
          height="20"
          width="40"
          type="text"
          class="mb-n2"
        />
      </div>
    </template>

    <template #item.max="{ item }">
      <div class="d-flex justify-start align-center flex-row flex-wrap mt-1">
        <span v-if="!props.loading">{{ formatNumber(item.max, { fallbackValue: '-' }) }}</span>
        <v-skeleton-loader
          v-else
          height="20"
          width="40"
          type="text"
          class="mb-n2"
        />
      </div>
    </template>

    <template
      v-if="!props.readOnly"
      #header.actions
    >
      <v-tooltip
        v-if="props.deselectionEnabled && props.datapointTableItems.length > 0"
        location="left"
      >
        <template #activator="{ props: deselectAllDatapointsTooltipProps }">
          <v-btn
            icon
            variant="plain"
            size="small"
            v-bind="deselectAllDatapointsTooltipProps"
            @click="emit('datapoints-table:deselectAllDatapoints')"
          >
            <v-icon size="small">
              fa:far fa-circle-xmark
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t('remove_all_datapoints') }}</span>
      </v-tooltip>
    </template>

    <template
      #item.actions="{ item }"
    >
      <div class="d-flex flex-row flex-nowrap">
        <v-tooltip
          v-if="props.deselectionEnabled"
          location="left"
        >
          <template #activator="{ props: deselectDatapointTooltipProps }">
            <v-btn
              data-testid="datapoints-table-remove-datapoint"
              icon
              variant="plain"
              size="small"
              v-bind="deselectDatapointTooltipProps"
              @click="emit('datapoints-table:deselectDatapoint', item)"
            >
              <v-icon size="small">
                fa:far fa-circle-xmark
              </v-icon>
            </v-btn>
          </template>
          <span>{{ t('remove_datapoint') }}</span>
        </v-tooltip>
        <KebabMenu
          v-if="props.menuEnabled"
          color="neutral-darken2"
          :custom-event-data="getKebabEventData(item)"
          :items="kebabMenuItems"
          offset-direction="y"
          plain
          small
          @edit-tags-option-click="emit('datapoints-table:edit-tags', $event)"
          @edit-description-option-click="emit('datapoints-table:edit-description', $event)"
        />
      </div>
    </template>
    <template #bottom />
  </v-data-table>
</template>

<style lang="sass" scoped>
  .clamp-text-1
    display: -webkit-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    white-space: normal !important
    overflow: hidden

  .selection__wrapper
    position: relative

    .selection
      height: 35px
      width: 7px
      border-radius: 5px
      border-width: 2px
      border-style: solid
      transition: background-color 0.3s ease

  .copy-icon
    visibility: hidden
    opacity: 0
    transition: opacity 0.3s linear

  .table-row:hover .copy-icon
    visibility: visible
    opacity: 1

  :deep(.v-data-table-header__sort-badge)
    display: none !important
</style>

<i18n lang="json" locale="de">
  {
    "hide_all_datapoints": "Alle Datenpunkte ausblenden",
    "show_all_datapoints": "Alle Datenpunkte anzeigen",
    "no_tags_available": "Keine Tags vorhanden",
    "remove_all_datapoints": "Alle Datenpunkte abwählen",
    "remove_datapoint": "Datenpunkt abwählen",
    "edit_tags": "Tags bearbeiten",
    "edit_description": "Beschreibung bearbeiten",
    "table_header": {
      "datapoint": "Datenpunkt",
      "tag": "Tag",
      "unit": "Einheit",
      "actions": "Aktionen"
    }
  }
</i18n>

<i18n lang="json" locale="en">
  {
    "hide_all_datapoints": "Hide all datapoints",
    "show_all_datapoints": "Show all datapoints",
    "no_tags_available": "No tags available",
    "remove_all_datapoints": "Deselect all datapoint",
    "remove_datapoint": "Deselect datapoint",
    "edit_tags": "Edit tags",
    "edit_description": "Edit description",
    "table_header": {
      "datapoint": "Datapoint",
      "tag": "Tag",
      "unit": "Unit",
      "actions": "Actions"
    }
  }
</i18n>
