<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  buttonText: string,
  icon?: string,
  isLoading: boolean,
  modelValue: boolean,
  text: string,
  title: string
}

const props = defineProps<Props>()

const emits = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void
}>()

const snackbarState = computed({
  get: () => props.modelValue,
  set: (modelValue: boolean) => {
    emits('update:modelValue', modelValue)
  },
})

</script>

<template>
  <v-snackbar
    v-if="!isLoading && modelValue"
    v-model="snackbarState"
    color="white"
    :timeout="-1"
    class="elevation-0"
    content-class="aedifion-box-shadow ma-0"
    data-testid="consent-dialog"
  >
    <div
      class="d-flex"
    >
      <v-icon
        v-if="props.icon"
        :size="40"
        data-testid="consent-icon"
        class="text-neutral-darken3 mr-6"
      >
        {{ props.icon }}
      </v-icon>
      <div class="text--h6 text-neutral-darken3">
        <v-card-title
          data-testid="consent-title"
          class="text-h5 pa-0 mb-2"
        >
          {{ props.title }}
        </v-card-title>
        <v-card-text
          data-testid="consent-text"
          class="pa-0"
        >
          {{ props.text }}
        </v-card-text>
      </div>

      <v-card-actions>
        <v-spacer />
        <v-btn
          data-testid="consent-button"
          color="primary-darken2"
          variant="text"
          @click="snackbarState = false"
        >
          {{ props.buttonText }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-snackbar>
</template>

<style lang="sass" scoped>
  :deep(.v-snack__content)
    padding: 24px
    border: 1px solid rgb(var(--v-theme-neutral-lighten1))
    border-radius: 4px !important

  :deep(.v-snack__wrapper)
    margin: 0 0 8px 0
    display: block
    border: 0 !important
</style>
