<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { TIMESERIES_COLORS, VUETIFY_COLORS } from '@theme/colors'
import { Chart } from 'highcharts-vue'
import { ChartData } from './types'
import { formatSubAndSuper } from '@/filters/formatting'
import Highcharts from 'highcharts'
import { merge } from 'lodash'
import { TYPOGRAPHY } from '@/utils/designConstants'
import { useI18n } from 'vue-i18n'

interface Props {
  categories?: string[]
  customChartOptions?: Highcharts.ChartOptions
  data: ChartData[]
  loading?: boolean
  title?: string|null
  unit?: string|null
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  customChartOptions: () => ({}),
  loading: false,
  title: null,
  unit: null,
})

const { t } = useI18n()

const chartOptions = computed(() => {
  const seriesData = props.data.map((item: ChartData) => {
    return {
      data: item.values,
      name: item.name,
    }
  })
  const result: Highcharts.ChartOptions = {
    // @ts-ignore
    accessibility: {
      enabled: false,
    },
    chart: {
      plotBackgroundColor: undefined,
      plotBorderWidth: 0,
      plotShadow: false,
      resetZoomButton: {
        position: {
          verticalAlign: props.data.length > 1 ? 'bottom' : 'top',
          x: 0,
          y: props.data.length > 1 ? 25 : -35,
        },
      },
      spacingBottom: 0,
      spacingTop: 20,
      type: 'column',
      // @ts-ignore
      zoomType: 'y',
    },
    colors: TIMESERIES_COLORS,
    credits: {
      enabled: false,
    },
    legend: {
      align: 'left',
      enabled: props.data.length > 1,
      itemStyle: {
        color: VUETIFY_COLORS.neutral.darken3,
        cursor: 'auto',
        fontSize: TYPOGRAPHY.headings.legend.size,
        fontWeight: TYPOGRAPHY.headings.legend.weight,
        textOverflow: 'ellipsis',
      },
    },
    loading: {
      // @ts-ignore
      useHTML: true,
    },
    noData: {
      useHTML: true,
    },
    plotOptions: {
      column: {
        colorByPoint: true,
        pointWidth: 30,
      },
    },
    // @ts-ignore
    series: seriesData,
    title: {
      // @ts-ignore
      align: 'Left',
      margin: props.data.length > 1 ? 10 : 20,
      style: {
        color: VUETIFY_COLORS.neutral.darken3,
        fontSize: TYPOGRAPHY.headings.h6.size,
        fontWeight: TYPOGRAPHY.headings.h6.weight,
      },
      text: props.title ?? '',
      useHTML: true,
      verticalAlign: 'top',
      widthAdjust: 0,
      x: -10,
      y: 0,
    },
    tooltip: {
      pointFormat: props.unit ? `<b>{point.y}</b> ${formatSubAndSuper(props.unit)}` : '<b>{point.y}</b>',
      useHTML: true,
      valueDecimals: 2,
    },
    xAxis: {
      categories: props.categories,
      labels: {
        rotation: 0,
        style: {
          color: VUETIFY_COLORS.neutral.darken3,
          cursor: 'auto',
          fontSize: TYPOGRAPHY.headings.legend.size,
          fontWeight: TYPOGRAPHY.headings.legend.weight,
          textOverflow: 'ellipsis',
        },
      },
      lineColor: VUETIFY_COLORS.neutral.darken3,
      type: 'category',
    },
    // @ts-ignore
    yAxis: {
      endOnTick: false,
      gridLineColor: VUETIFY_COLORS.neutral.lighten2,
      labels: {
        format: props.unit ? `{text} ${formatSubAndSuper(props.unit)}` : '{text}',
        style: {
          color: VUETIFY_COLORS.neutral.darken3,
          cursor: 'auto',
          fontSize: TYPOGRAPHY.headings.legend.size,
          fontWeight: TYPOGRAPHY.headings.legend.weight,
          textOverflow: 'ellipsis',
        },
        useHTML: true,
      },
      min: 0,
      tickAmount: 5,
      title: false,
    },
  }
  merge(result, props.customChartOptions ?? {})
  return result
})

const chart = ref<typeof Chart|null>()

watch(() => props.loading, (newVal: boolean) => {
  if (newVal === true) {
    chart.value?.chart.showLoading()
  } else {
    chart.value?.chart.hideLoading()
  }
})

// --- execution ---

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: "'Inter', sans-serif",
    },
  },
  lang: {
    loading: t('highcharts.loading'),
  },
})
</script>

<template>
  <div class="d-flex flex-column fill-height">
    <Chart
      ref="chart"
      :options="chartOptions"
      :update-args="[true, true, true]"
    />
  </div>
</template>

<style lang="sass" scoped>
@use '@/scss/settings' as vuetifySettings

:deep(.highcharts-tooltip)
  &, & span
    font-size: map-get(vuetifySettings.$typography, 'legend', 'size') !important
:deep(.highcharts-tooltip b)
  font-weight: 500 !important
</style>
