/* eslint-disable @typescript-eslint/no-explicit-any */
import { LabelsState } from './types'
import { LabelsSystems } from '@aedifion.io/aedifion-api'
import { MutationTree } from 'vuex'

export default {
  SET_DEFINITIONS: (state: LabelsState, labelDefinitions: null|any) => {
    state.definitions = labelDefinitions
    state.labelDefinitionLookupMap.clear()
  },

  SET_SYSTEMS: (state: LabelsState, labelSystems: null|LabelsSystems) => (state.systems = labelSystems),
} as MutationTree<LabelsState>
