<script setup lang="ts">
import { Alignment, ColumnHeader, RowData, Severity, SortDirection, Width } from '@/components/SummarizedContent/types'
import { computed, provide, ref, watch } from 'vue'
import { type Keys, type KPIS, SavingsPotentialMetaData, useSavingsPotentialStore } from '@/stores/views/Optimization/savingsPotential'
import { NavigationGuardNext, onBeforeRouteUpdate, RouteLocationNormalized } from 'vue-router'
import { ComponentSearchItem } from './Search.vue'
import EditSavingsDialog from '@/views/Optimization/SavingsPotential/EditSavingsDialog.vue'
import FilterBar from './FilterBar.vue'
import { getUnit } from '@/stores/views/Reporting/helpers'
import HorizontalStack from '@/components/HorizontalStack.vue'
import PageHeader from '@/components/PageHeader.vue'
import { storeToRefs } from 'pinia'
import SummarizedTable from '@/components/SummarizedTable.vue'
import { useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { useI18n } from 'vue-i18n'
import useIsReadOnly from '@/composables/useIsReadOnly'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const analyticsApiStore = useAnalyticsApiStore()
const savingsPotentialStore = useSavingsPotentialStore()
const { t } = useI18n()
const { isReadOnly } = useIsReadOnly()

const headers = computed<ColumnHeader<Keys>[]>(() => [{
  key: 'result',
  text: t('header.result_text'),
  width: Width.MaxContent,
}, {
  key: 'component',
  sortOptions: [SortDirection.ASC],
  text: t('header.component_text'),
}, {
  align: Alignment.Right,
  key: 'cost',
  sortOptions: [SortDirection.DESC],
  text: t('header.costs_text'),
  tooltip: t('header.costs_tooltip'),
  unit: getUnit('euro') as string,
  width: Width.MaxContent,
}, {
  align: Alignment.Right,
  key: 'co2',
  sortOptions: [SortDirection.DESC],
  text: t('header.co2_text'),
  tooltip: t('header.co2_tooltip'),
  unit: getUnit('kilograms') as string,
  width: Width.MaxContent,
}, {
  align: Alignment.Right,
  key: 'energy',
  sortOptions: [SortDirection.DESC],
  text: t('header.energy_text'),
  tooltip: t('header.energy_tooltip'),
  unit: getUnit('kilowatt-hours') as string,
  width: Width.MaxContent,
}])

if (savingsPotentialStore.isSavingsPotentialDataStale) {
  savingsPotentialStore.fetchSavingsPotential()
}
if (analyticsApiStore.functions === null) {
  analyticsApiStore.getFunctions({}, { storeResult: true })
}

// #region Permission for Editing Savings Potential
const authorizedCompaniesToEditSavingsPotential = ['aedifion GmbH', 'Phoenix Contact']

const isUserAuthorizedToEditSavingsPotential = computed<boolean>(() => {
  return authorizedCompaniesToEditSavingsPotential.includes(userStore.companyName ?? '') && !isReadOnly.value
})
// #endregion

// #region Sets up an intersection observer to load render more rows once the intersectionTarget is visible in the viewport.

const { savingsPotentialTable } = storeToRefs(savingsPotentialStore)

const displayAllRows = ref<boolean>(false)

const itemsToDisplay = computed<RowData<Keys>[]>(() => {
  return savingsPotentialTable.value.slice(0, numberOfItemsToDisplay.value)
})

const numberOfItemsToDisplay = ref(15)

function onLoadMore (): void {
  if (numberOfItemsToDisplay.value >= savingsPotentialTable.value.length) {
    return
  }
  numberOfItemsToDisplay.value += 15
}

watch(displayAllRows, (newValue: boolean) => {
  if (newValue) {
    numberOfItemsToDisplay.value = savingsPotentialTable.value.length
  }
})

// #endregion

const loadingSearchRelevantItems = ref(false)

const comoponentSearchItems = computed<ComponentSearchItem[]>(() => {
  return []
})

provide('componentSearchItems', comoponentSearchItems)
provide('loadingSearchRelevantItems', loadingSearchRelevantItems)

// #region SearchBar methods
function onResetSearch () {
  savingsPotentialStore.resetAllFilters()
}

function onSearch (search: string) {
  savingsPotentialStore.filterBySearch(search)
}

function onHighPotentialsSearch () {
  savingsPotentialStore.filterBySeverities([Severity.Error])
}

function onHighAndMediumPotentialsSearch () {
  savingsPotentialStore.filterBySeverities([Severity.Error, Severity.Warning])
}

function onWithCostsSavingsPotentialSearch () {
  savingsPotentialStore.filterByHasCost(true)
}

function onAnalysisFunctionSearch (id: number) {
  savingsPotentialStore.filterByAnalysisFunction(id)
}

function onComponentSearch (id: number) {
  savingsPotentialStore.filterByComponent(id)
}

const isDialogOpen = ref(false)

const currentlyEditedRow = ref<RowData | null>(null)

function handleEditAnalysis (row: RowData) {
  currentlyEditedRow.value = row
  isDialogOpen.value = true
}
// #endregion

async function handleUpdateSavings (payload: { metaData: SavingsPotentialMetaData, row: RowData<Keys>, updatedSavings: KPIS[] }) {
  await savingsPotentialStore.postKPI(payload.row, payload.updatedSavings, payload.metaData)
}

onBeforeRouteUpdate ((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.params.project && from.params.project && to.params.project !== from.params.project) {
    savingsPotentialStore.fetchSavingsPotential()
    numberOfItemsToDisplay.value = 15
  }
  next()
})

</script>

<template>
  <div class="layout-wrapper">
    <PageHeader
      sticky
      title-key="links.meta.title.optimization_potential"
    >
      <FilterBar
        class="ml-auto"
        @search:reset="onResetSearch"
        @search:search="onSearch"
        @search:potentials:high="onHighPotentialsSearch"
        @search:potentials:high-and-medium="onHighAndMediumPotentialsSearch"
        @search:potentials:with-costs-savings="onWithCostsSavingsPotentialSearch"
        @search:filter:component="onComponentSearch"
        @search:filter:analysis-function="onAnalysisFunctionSearch"
      />
    </PageHeader>
    <SummarizedTable
      v-if="!savingsPotentialStore.loading && savingsPotentialTable.length > 0"
      :headers="headers"
      :load-more-callback="onLoadMore"
      :rows="itemsToDisplay"
      :summary="savingsPotentialStore.savingsPotentialSummary"
      :editable-cell-values="isUserAuthorizedToEditSavingsPotential"
      kpi-to-expand-its-highest-value-row="cost"
      :default-sorting="{
        key: 'cost',
        direction: SortDirection.DESC
      }"
      @edit-savings-potential="handleEditAnalysis"
      @sort-triggered="displayAllRows = true"
    >
      <template #summary.result>
        <div class="d-flex fill-height align-center">
          <HorizontalStack
            class="severity-summary"
            :is-loading="savingsPotentialStore.loading"
            :items="savingsPotentialStore.severitySummary"
            :gap-size="4.35"
            :hoverable="false"
          />
        </div>
      </template>
    </SummarizedTable>
    <EditSavingsDialog
      v-if="isDialogOpen && currentlyEditedRow"
      v-model="isDialogOpen"
      :data="currentlyEditedRow"
      :edit-button-text="t('edit_savings_potential')"
      @update:savings-potential="handleUpdateSavings"
    />
    <v-sheet
      v-if="savingsPotentialStore.loading"
      class="text-center py-16"
    >
      <v-progress-circular
        color="primary-darken2"
        indeterminate
      />
    </v-sheet>
    <v-sheet
      v-else-if="savingsPotentialTable.length < 1"
      class="text-center py-16"
    >
      <h6
        v-if="savingsPotentialStore.isEmptyBecauseOfFiltering"
        class="text-h6"
      >
        {{ t('no_search_result') }}
      </h6>
      <h6
        v-else
        class="text-h6"
      >
        {{ t('no_data') }}
      </h6>
    </v-sheet>
  </div>
</template>

<style lang="sass" scoped>
.severity-summary
  width: 320px
</style>

<!-- the usage of the title key by PageHeader is not detected -->
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n locale="de">
{
  "header": {
    "co2_text": "CO<sub>2</sub>",
    "co2_tooltip": "Einsparpotenzial für CO<sub>2</sub>-Emissionen (jährlich)",
    "component_text": "Komponente",
    "costs_text": "Kosten",
    "costs_tooltip": "Kosteneinsparpotenzial (jährlich)",
    "energy_text": "Energie",
    "energy_tooltip": "Energieeinsparpotenzial (jährlich)",
    "result_text": "Analyseresultat",
  },
  "no_data": "Dieses Projekt hat kein Einsparpotential.",
  "no_search_result": "Dieser Suche entspricht kein Einsparpotential.",
  "title": "Einsparpotentiale",
  "edit_savings_potential": "Einsparpotenzial bearbeiten"
}
</i18n>
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n locale="en">
{
  "header": {
    "co2_text": "CO<sub>2</sub>",
    "co2_tooltip": "CO<sub>2</sub> emissions savings potential (yearly)",
    "component_text": "Component",
    "costs_text": "Costs",
    "costs_tooltip": "Cost savings potential (yearly)",
    "energy_text": "Energy",
    "energy_tooltip": "Energy savings potential (yearly)",
    "result_text": "Analysis result",
  },
  "no_data": "This project has no saving potential.",
  "no_search_result": "No saving potential corresponds to this search.",
  "title": "Savings potential",
  "edit_savings_potential": "Edit savings potential"
}
</i18n>
