import { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAdministrationStore } from '@/stores/views/Administration'

export const validUserGuardMiddleware: () => NavigationGuard = () => async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!to.meta || !to.meta.requiresValidUser) {
    return next()
  }

  const userId: number|null = to.params.user ? parseInt(to.params.user as string) : null
  if (userId === null) {
    return next({ name: 'page-not-found' })
  }

  const administrationStore = useAdministrationStore()
  if (administrationStore.company === null) {
    await administrationStore.fetchCompanyWithContext()
  }

  const wasUserFound = administrationStore.users.some((user) => user.id === userId)

  if (!wasUserFound) {
    return next({ name: 'page-not-found' })
  }

  next()
}
