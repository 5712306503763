<script setup lang="ts">
import { computed, PropType } from 'vue'
import type { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import { getFallbackName } from '@/utils/helpers/locale'
import ItemSelectorWithFilter from '@/components/ItemSelectorWithFilter.vue'
import { storeToRefs } from 'pinia'
import { useComponentsInProjectStore } from '@/stores/views/Optimization/Checklist/useComponentsInProject'
import { whitelabelColor } from '@/filters/colors'

interface Props {
  isEditMode: boolean,
  isReadOnly?: boolean,
}

withDefaults(defineProps<Props>(), {
  isReadOnly: false,
})

const selectedComponent = defineModel({
  type: Object as PropType<ComponentInProjectListItemData|number|null>,
})

const componentsInProjectStore = useComponentsInProjectStore()
const { fetchComponentsInProjectWithResults } = componentsInProjectStore
const { componentsInProject, loading, search } = storeToRefs(componentsInProjectStore)

const componentData = computed<ComponentInProjectListItemData|null>(() => {
  if (selectedComponent.value == null) {
    return null
  }

  if (typeof selectedComponent.value === 'number') {
    const component = componentsInProject.value.find(component => component.id === selectedComponent.value)

    return component === undefined
      ? null
      : {
        description: component?.description,
        id: component?.id,
        nameDE: component?.nameDE,
        nameEN: component?.nameEN,
        signalColor: component?.signalColor,
        title: getFallbackName(component?.nameDE, component?.nameEN),
      }
  }

  return {
    ...selectedComponent.value,
    title: getFallbackName(selectedComponent.value.nameDE, selectedComponent.value.nameEN),
  }
})

function onSelectComponent (component: ComponentInProjectListItemData|null) {
  if (component === null) {
    selectedComponent.value = null
    return
  }

  selectedComponent.value = {
    description: component.description,
    id: component.id,
    nameDE: component.nameDE,
    nameEN: component.nameEN,
    signalColor: component.signalColor,
    title: component.title,
  }
}
</script>

<template>
  <ItemSelectorWithFilter
    v-model="search"
    data-test-id="componentInProject"
    item-locale-key="notifications.resources.component"
    :filter-fields="['nameEN', 'nameDE', 'description']"
    :item-pool="componentsInProject"
    :is-edit-mode="isEditMode"
    :load-more-callback="fetchComponentsInProjectWithResults"
    :is-read-only="isReadOnly"
    :loading="loading"
    :selected-item="componentData"
    @select-item="onSelectComponent"
    @deselect-item="onSelectComponent(null)"
  >
    <template #item-option="{item: component}">
      <div
        class="d-flex flex-row tw-flex-1 align-center"
      >
        <v-avatar
          class="mr-4"
          :color="whitelabelColor(component?.signalColor as string)"
          rounded-circle
          size="12"
        />
        <span :class="`d-block tw-flex-grow text-${whitelabelColor(component?.signalColor as string)}-darken2 font-weight-medium text-body-1`">{{ getFallbackName(component.nameDE, component.nameEN) }}</span>
        <span class="d-block tw-text-right text-body-1 text-neutral-darken1">{{ component?.description }}</span>
      </div>
    </template>
  </ItemSelectorWithFilter>
</template>
