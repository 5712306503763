<template>
  <div class="tabbed-container mb-4 d-flex flex-wrap">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'TabbedContainer',

  props: {
    value: {
      required: false,
      type: [Number, String, null] as PropType<number|null|string>,
    },
  },
})
</script>

<style lang="sass" scoped>
.tabbed-container
  width: auto
</style>
