<script setup lang="ts">
import { type ActionMenuItem } from '@/components/ActionMenu.vue'
import { docsLink } from '@/utils/helpers/locale'
import KebabMenu from '@/components/KebabMenu.vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

withDefaults(defineProps<{
  disabled?: boolean,
  userId: number
}>(), {
  disabled: false,
})

const emit = defineEmits<{
  (e: 'delete-option-click'): void
  (e: 'edit-option-click'): void
}>()

const { t } = useI18n()

const documentationLink = docsLink('home')!

const items = ref<ActionMenuItem[]>([{
  icon: 'fa:far fa-pen',
  id: 'edit',
  label: t('edit_users'),
}, {
  icon: 'fa:far fa-trash',
  id: 'delete',
  label: t('delete_users'),
}, {
  divider: true,
  href: documentationLink,
  icon: 'fa:far fa-arrow-up-right',
  id: 'open-api-documentation',
  label: t('documentation'),
  target: '_blank',
}])
</script>

<template>
  <KebabMenu
    :disabled="disabled"
    :items="items"
    offset-direction="y"
    @delete-option-click="emit('delete-option-click')"
    @edit-option-click="emit('edit-option-click')"
  />
</template>

<i18n lang="json" locale="de">
{
  "delete_users": "Benutzer löschen",
  "edit_users": "Benutzer bearbeiten",
  "documentation": "Benutzerdokumentation"
}
</i18n>
<i18n lang="json" locale="en">
{
  "delete_users": "Delete user",
  "edit_users": "Edit user",
  "documentation": "User documentation"
}
</i18n>
