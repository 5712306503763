/**
 * Calculates the SHA-256 hash of a given message.
 * @param message - The message to be hashed.
 * @returns A promise that resolves to the hexadecimal representation of the hash.
 */
export async function sha256 (message: string): Promise<string> {
  // encode as (utf-8) Uint8Array
  const msgUint8 = new TextEncoder().encode(message)
  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
  // convert buffer to byte array
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}
