import { aliases, fa } from 'vuetify/iconsets/fa'
import { de, en } from 'vuetify/locale'
import { getLocale } from '@/i18n'
import { Intersect } from 'vuetify/directives'
import { VUETIFY_COLORS } from '@theme/colors'
import type { VuetifyOptions } from 'vuetify'

const locale = getLocale()

const vuetifyLocale: VuetifyOptions['locale'] = {
  fallback: 'en',
  locale,
  messages: {
    de,
    en,
  },
}

// The local has to be set to 'en' for the E2E tests to pass
if (import.meta.env.MODE.includes('test-e2e')) {
  vuetifyLocale.locale = 'en'
}

export const vuetifyConfig: VuetifyOptions = {
  // Global configuration
  // https://vuetifyjs.com/en/features/global-configuration/
  defaults: {
    global: {
      ripple: false,
    },
    VBtn: {
      elevation: 0,
      variant: 'elevated',
    },
    VCard: {
      elevation: 0,
    },
    VCardTitle: {
      class: 'text-h5',
    },
    VDataTable: {
      color: 'primary-darken2',
    },
    VDatePicker: {
      color: 'primary-darken2',
    },
    VDialog: {
      elevation: 0,
    },
    VList: {
      color: 'primary-darken2',
    },
    VListItem: {
      color: 'primary-darken2',
    },
    VMenu: {
      elevation: 0,
    },
    VProgressCircular: {
      color: 'primary-darken2',
    },
    VProgressLinear: {
      color: 'primary-darken2',
    },
    VSelect: {
      color: 'primary-darken2',
    },
    VTextarea: {
      color: 'primary-darken2',
    },
    VTextField: {
      color: 'primary-darken2',
      suffixColor: 'neutral-darken2',
    },
  },
  directives: {
    Intersect,
  },
  icons: {
    aliases: {
      ...aliases,
      expand: 'fa:fal fa-angle-up',
      sort: 'fa:fas fa-caret-up',
    },
    defaultSet: 'fa',
    sets: {
      fa,
    },
  },
  locale: vuetifyLocale,
  // Theme
  // https://vuetifyjs.com/en/features/theme/
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          accent: VUETIFY_COLORS.accent.base,
          anchor: VUETIFY_COLORS.anchor,
          background: VUETIFY_COLORS.neutral.lighten3,
          error: VUETIFY_COLORS.error.base,
          'error-darken2': VUETIFY_COLORS.error.darken2,
          'error-lighten2': VUETIFY_COLORS.error.lighten2,
          'error-lighten3': VUETIFY_COLORS.error.lighten3,
          'error-lighten4': VUETIFY_COLORS.error.lighten4,
          info: VUETIFY_COLORS.info.base,
          'info-darken2': VUETIFY_COLORS.info.darken2,
          'info-lighten2': VUETIFY_COLORS.info.lighten2,
          'info-lighten3': VUETIFY_COLORS.info.lighten3,
          'info-lighten4': VUETIFY_COLORS.info.lighten4,
          neutral: VUETIFY_COLORS.neutral.base,
          'neutral-darken1': VUETIFY_COLORS.neutral.darken1,
          'neutral-darken2': VUETIFY_COLORS.neutral.darken2,
          'neutral-darken3': VUETIFY_COLORS.neutral.darken3,
          'neutral-darken4': VUETIFY_COLORS.neutral.darken4,
          'neutral-lighten1': VUETIFY_COLORS.neutral.lighten1,
          'neutral-lighten2': VUETIFY_COLORS.neutral.lighten2,
          'neutral-lighten3': VUETIFY_COLORS.neutral.lighten3,
          'neutral-lighten4': VUETIFY_COLORS.neutral.lighten3,
          'neutral-lighten5': VUETIFY_COLORS.neutral.lighten5,
          primary: VUETIFY_COLORS.primary.base,
          'primary-darken2': VUETIFY_COLORS.primary.darken2,
          'primary-darken4': VUETIFY_COLORS.primary.darken4,
          'primary-lighten2': VUETIFY_COLORS.primary.lighten2,
          'primary-lighten3': VUETIFY_COLORS.primary.lighten3,
          'primary-lighten4': VUETIFY_COLORS.primary.lighten4,
          secondary: VUETIFY_COLORS.secondary.base,
          success: VUETIFY_COLORS.success.base,
          'success-darken2': VUETIFY_COLORS.success.darken2,
          'success-lighten2': VUETIFY_COLORS.success.lighten2,
          'success-lighten3': VUETIFY_COLORS.success.lighten3,
          'success-lighten4': VUETIFY_COLORS.success.lighten4,
          warning: VUETIFY_COLORS.warning.base,
          'warning-darken2': VUETIFY_COLORS.warning.darken2,
          'warning-lighten2': VUETIFY_COLORS.warning.lighten2,
          'warning-lighten3': VUETIFY_COLORS.warning.lighten3,
          'warning-lighten4': VUETIFY_COLORS.warning.lighten4,
        },
      },
    },
  },
}
