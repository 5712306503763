import { VUETIFY_COLORS } from '@theme/colors'

export function getBackgroundAndFigureColors (color: string|undefined|null) {
  if (color === 'green') {
    return {
      backgroundColor: VUETIFY_COLORS.success.lighten4,
      figureColor: VUETIFY_COLORS.success.darken2,
    }
  } else if (color === 'red') {
    return {
      backgroundColor: VUETIFY_COLORS.error.lighten4,
      figureColor: VUETIFY_COLORS.error.darken2,
    }
  } else if (color === 'yellow') {
    return {
      backgroundColor: VUETIFY_COLORS.warning.lighten4,
      figureColor: VUETIFY_COLORS.warning.darken2,
    }
  } else {
    return {
      backgroundColor: undefined,
      figureColor: undefined,
    }
  }
}
