<script setup lang="ts">
import { computed, ref } from 'vue'
import { CsvFileUploadState, useMeterStore } from '@/stores/views/Meter'
import DragAndDropZone from '@/components/DragAndDropZone.vue'
import { useI18n } from 'vue-i18n'

type Props = {
  isOpen: boolean
}

defineProps<Props>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { t } = useI18n()
const meterStore = useMeterStore()

const fileToUpload = ref<File | null>(null)

function closeDialog () {
  emit('close')
  removeFile()
  meterStore.resetCsvFileUploadState()
}

function onDialogStateChange (newState: boolean) {
  if (newState === false) {
    closeDialog()
  }
}

const title = computed<string>(() => {
  if (meterStore.csvFileUploadState === CsvFileUploadState.Success) {
    return t('title.success')
  }
  if (meterStore.csvFileUploadState === CsvFileUploadState.Error) {
    return t('title.error')
  }
  return t('title.initial')
})

const uploadButtonLabel = computed<string>(() => {
  if (meterStore.csvFileUploadState === CsvFileUploadState.Success) {
    return t('upload_button.success')
  }
  if (meterStore.csvFileUploadState === CsvFileUploadState.Error) {
    return t('upload_button.error')
  }
  return t('upload_button.initial')
})

function changeFile (file: File) {
  fileToUpload.value = file
}

function removeFile () {
  fileToUpload.value = null
}

const isUploadButtonDisabled = computed<boolean>(() => {
  if (meterStore.csvFileUploadState === CsvFileUploadState.Uploading) {
    return true
  }
  if (meterStore.csvFileUploadState === CsvFileUploadState.WaitingForUpload && fileToUpload.value === null) {
    return true
  }
  return false
})

async function uploadFile () {
  if (fileToUpload.value) {
    await meterStore.uploadMeterCsvFile(fileToUpload.value)
  }
  removeFile()
}

function onUploadButtonClicked () {
  if (meterStore.csvFileUploadState === CsvFileUploadState.Success || meterStore.csvFileUploadState === CsvFileUploadState.Error) {
    meterStore.resetCsvFileUploadState()
  } else {
    uploadFile()
  }
}
</script>

<template>
  <v-dialog
    max-width="480px"
    persistent
    :model-value="isOpen"
    @update:model-value="onDialogStateChange"
  >
    <v-card class="pa-2">
      <v-card-title
        class="text-h5 pa-4"
        tag="h3"
      >
        {{ title }}
      </v-card-title>

      <DragAndDropZone
        v-if="meterStore.csvFileUploadState === CsvFileUploadState.WaitingForUpload"
        :accepted-extensions="['csv']"
        :accepted-mime-types="['text/csv']"
        class="mx-4 mb-2"
        :current-filename="fileToUpload?.name"
        @file-change="changeFile"
        @file-remove="removeFile"
      />

      <v-row
        v-if="meterStore.csvFileUploadState === CsvFileUploadState.Uploading"
        class="ma-4"
        justify="center"
      >
        <v-progress-circular
          color="primary-darken2"
          indeterminate
          size="50"
        />
      </v-row>

      <div v-if="meterStore.csvFileUploadState === CsvFileUploadState.Success || meterStore.csvFileUploadState === CsvFileUploadState.Error">
        <v-row
          class="mx-4 mt-4"
          justify="center"
        >
          <v-icon
            :color="meterStore.csvFileUploadState === CsvFileUploadState.Success ? 'success' : 'error'"
            size="40px"
          >
            fa:fal {{ meterStore.csvFileUploadState === CsvFileUploadState.Success ? 'fa-file-check' : 'fa-file-exclamation' }}
          </v-icon>
        </v-row>
        <p class="text-body-1 text-neutral-darken3 px-4 mt-10">
          {{ meterStore.csvFileUploadState === CsvFileUploadState.Success ? t('success_text') : t('error_text') }}
        </p>
      </div>

      <v-card-actions class="d-flex">
        <v-btn
          class="flex-grow-1 flex-1"
          color="primary-lighten3"
          height="40px"
          variant="elevated"
          @click="closeDialog"
        >
          <span class="text-cta text-primary-darken2 mr-3">{{ t('actions.close') }}</span>
          <v-icon
            size="14"
            class="text-primary-darken2"
          >
            fa:far fa-xmark
          </v-icon>
        </v-btn>
        <v-btn
          class="flex-grow-1 flex-1"
          color="primary-darken2"
          variant="elevated"
          :disabled="isUploadButtonDisabled"
          height="40px"
          @click="onUploadButtonClicked"
        >
          <span class="text-cta mr-3">
            {{ uploadButtonLabel }}
          </span>
          <v-icon size="14">
            fa:far fa-upload
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<i18n locale="de">
  {
    "title": {
      "initial": "Zählerdaten Hochladen",
      "success": "Upload Erfolgreich",
      "error": "Upload Fehlgeschlagen"
    },
    "upload_button": {
      "initial": "Hochladen",
      "success": "Weitere Datei hochladen",
      "error": "Noch einmal versuchen"
    },
    "success_text": "Deine Datei wurde hochgeladen und kann in der Tabelle angezeigt werden. Möchtest du eine weitere Datei hochladen?",
    "error_text": "Leider ist es uns nicht möglich, deine Datei hochzuladen. Bitte überprüfe deine Internetverbindung und versuche es noch einmal."
  }
</i18n>

<i18n locale="en">
  {
    "title": {
      "initial": "Upload Meter Readings",
      "success": "Upload Succeeded",
      "error": "Upload Failed"
    },
    "upload_button": {
      "initial": "Upload",
      "success": "Upload another file",
      "error": "Try again"
    },
    "success_text": "Your file has been uploaded and can be viewed in the table. Do you want to upload another file?",
    "error_text": "Unfortunately, we are not able to upload your file. Please check you internet connection and try to upload the file once more."
  }
</i18n>
