<template>
  <!-- German version -->
  <div
    v-if="$i18n.locale === 'de'"
    class="px-2"
  >
    <span class="text-h5">Liste der Cookies</span><br>
    <span class="text-h6">1. aedifion Nutzeraccount</span><br>
    <span class="text-h6">Beschreibung des Services</span>
    <p>
      Mit dem Nutzeraccount wird der Zugriff auf die aedifion Produkt geregelt.
    </p>
    <span class="text-h6">Verarbeitendes Unternehmen</span>
    <p>aedifion GmbH, Hohenzollernring 72, 50672 Köln, Deutschland</p>
    <span class="text-h6">Datenschutzbeauftragter der verarbeitenden Firma</span>
    <p>
      Nachfolgend finden Sie die E-Mail-Adresse des Datenschutzbeauftragten des
      verarbeitenden Unternehmens.
    </p>
    <p>info@aedifion.com</p>
    <span class="text-h6">Datenverarbeitungszwecke</span>
    <p>
      Diese Liste stellt die Zwecke der Datenerhebung und -verarbeitung dar.
    </p>
    <li>Funktionalität</li>
    <br>
    <span class="text-h6">Genutzte Technologien</span>
    <p>
      Diese Liste enthält alle Technologien, mit denen dieser Dienst Daten
      sammelt. Typische Technologien sind Cookies und Pixel, die im Browser
      platziert werden.
    </p>
    <li>Cookies</li>
    <br>
    <span class="text-h6">Erhobene Daten</span>
    <p>
      Diese Liste enthält alle (persönlichen) Daten, die bei der oder durch die
      Nutzung des Services gesammelt werden.
    </p>
    <li>Browserinformationen</li>
    <li>Verbindungsdaten</li>
    <li>Nutzungsdaten</li>
    <br>
    <span class="text-h6">Rechtsgrundlage</span>
    <p>
      Im Folgenden wird die erforderliche Rechtsgrundlage für die Verarbeitung
      von Daten genannt.
    </p>
    <li>Art. 6 Abs. 1 s. 1 lit. a DSGVO</li>
    <li>Art. 49 Abs. 1 S. 1 lit. a DSGVO</li>
    <br>
    <span class="text-h6">Ort der Verarbeitung</span>
    <p>
      Dies ist der primäre Ort, an dem die gesammelten Daten verarbeitet werden.
      Sollten die Daten auch in anderen Ländern verarbeitet werden, werden Sie
      gesondert informiert.
    </p>
    <li>Deutschland</li>
    <br>
    <span class="text-h6">Sentry</span><br>
    <span class="text-h6">Beschreibung des Services</span>
    <p>Dies ist ein tracking für Web-Apps, mobile Apps und Spiele.</p>
    <span class="text-h6">Verarbeitendes Unternehmen</span>
    <p>
      Functional Software, Inc. dba Sentry<br>
      45 Fremont Street 8th Floor San Francisco, CA 94105, United States of
      America
    </p>
    <span class="text-h6">Datenschutzbeauftragter der verarbeitenden Firma</span>
    <p>
      Nachfolgend finden Sie die E-Mail-Adresse des Datenschutzbeauftragten des
      verarbeitenden Unternehmens.
    </p>
    <p>security@sentry.io</p>
    <span class="text-h6">Datenverarbeitungszwecke</span>
    <p>
      Diese Liste stellt die Zwecke der Datenerhebung und -verarbeitung dar.
    </p>
    <li>Tracking</li>
    <li>Erkennung von Code-Fehlern</li>
    <li>Produkte entwickeln und verbessern</li>
    <br>
    <span class="text-h6">Genutzte Technologien</span>
    <p>
      Diese Liste enthält alle Technologien, mit denen dieser Dienst Daten
      sammelt. Typische Technologien sind Cookies und Pixel, die im Browser
      platziert werden.
    </p>
    <li>Cookies</li>
    <br>
    <span class="text-h6">Erhobene Daten</span>
    <p>
      Diese Liste enthält alle (persönlichen) Daten, die bei der oder durch die
      Nutzung des Services gesammelt werden.
    </p>
    <li>Nutzungsdaten</li>
    <li>Geräteinformationen</li>
    <li>IP-Adresse</li>
    <li>Fehlerdaten</li>
    <br>
    <span class="text-h6">Rechtsgrundlage</span>
    <p>
      Im Folgenden wird die erforderliche Rechtsgrundlage für die Verarbeitung
      von Daten genannt.
    </p>
    <li>Art. 6 Abs. 1 s. 1 lit. a DSGVO</li>
    <li>Art. 49 Abs. 1 S. 1 lit. a DSGVO</li>
    <br>
    <span class="text-h6">Ort der Verarbeitung</span>
    <p>
      Dies ist der primäre Ort, an dem die gesammelten Daten verarbeitet werden.
      Sollten die Daten auch in anderen Ländern verarbeitet werden, werden Sie
      gesondert informiert.
    </p>
    <li>Vereinigte Staaten von Amerika</li>
    <br>
    <span class="text-h6">Aufbewahrungsdauer</span>
    <p>
      Die Aufbewahrungsfrist ist die Zeitspanne, in der die gesammelten Daten
      für die Verarbeitung gespeichert werden. Die Daten müssen gelöscht werden,
      sobald sie für die angegebenen Verarbeitungszwecke nicht mehr benötigt
      werden.
    </p>
    <p>
      Daten werden gelöscht, sobald sie für die Verarbeitungszwecke nicht mehr
      benötigt werden.
    </p>
    <span class="text-h6">Weitergabe an Drittländer</span>
    <p>
      Dieser Service kann die erfassten Daten an ein anderes Land weiterleiten.
      Bitte beachten Sie, dass dieser Service Daten in ein Land, welches kein
      angemessenes Datenschutzniveau bietet, übertragen kann. Falls die Daten in
      die USA übertragen werden, besteht das Risiko, dass Ihre Daten von US
      Behörden zu Kontroll- und Überwachungszwecken verarbeitet werden können,
      ohne dass Ihnen möglicherweise Rechtsbehelfsmöglichkeiten zustehen.
      Nachfolgend finden Sie eine Liste der Länder, in die die Daten übertragen
      werden. Weitere Informationen zu den Sicherheitsgarantien finden Sie in
      den Datenschutzrichtlinien des Website-Anbieters oder wenden Sie sich
      direkt an den Website-Anbieter.
    </p>
    <li>Vereinigte Staaten von Amerika</li>
    <br>
    <span class="text-h6">Datenempfänger</span>
    <p>Im Folgenden werden die Empfänger der erhobenen Daten aufgelistet.</p>
    <li>Functional Software, Inc. dba Sentry</li>
    <br>
    <p>
      Klicken Sie hier, um die Datenschutzbestimmungen des Datenverarbeiters zu
      lesen
    </p>
    <p>https://sentry.io/privacy/</p>
  </div>

  <!-- English version -->
  <div
    v-else
    class="px-2"
  >
    <span class="text-h5">List of Cookies</span><br>
    <span class="text-h6">aedifion user account</span><br>
    <span class="text-h6">Description of the service</span>
    <p>The user account is used to control access to the aedifion product.</p>
    <span class="text-h6">Processing company</span>
    <p>aedifion GmbH, Hohenzollernring 72, 50672 Cologne, Germany</p>
    <span class="text-h6">Data protection officer of the processing company</span>
    <p>
      Below you will find the e-mail address of the data protection officer of
      the processing company.
    </p>
    <p>info@aedifion.com</p>
    <span class="text-h6">Data processing purposes</span>
    <p>This list represents the purposes of data collection and processing.</p>
    <li>Functionality</li>
    <br>
    <span class="text-h6">Technologies used</span>
    <p>
      This list includes all technologies used by this service to collect data.
      Typical technologies are cookies and pixels placed in the browser.
    </p>
    <li>Cookies</li>
    <br>
    <span class="text-h6">Collected data</span>
    <p>
      This list contains all (personal) data collected during or through the use
      of the Service.
    </p>
    <li>Browser information</li>
    <br>
    <span class="text-h6">Connection data</span>
    <li>Usage data</li>
    <br>
    <span class="text-h6">Legal basis</span>
    <p>The following is the required legal basis for the processing of data.</p>
    <li>Art. 6 para. 1 s. 1 lit. a DSGVO</li>
    <li>Art. 49 para. 1 s. 1 lit. a DSGVO</li>
    <br>
    <span class="text-h6">Place of processing</span>
    <p>
      This is the primary location where the collected data is processed. If the
      data is also processed in other countries, you will be informed
      separately.
    </p>
    <li>Germany</li>
    <br>
    <span class="text-h6">Sentry</span><br>
    <span class="text-h6">Service description</span>
    <p>This is a tracking for web apps, mobile apps and games.</p>
    <span class="text-h6">Processing company</span>
    <p>
      Functional Software, Inc. dba Sentry<br>
      45 Fremont Street 8th Floor San Francisco, CA 94105, United States of
      America
    </p>
    <span class="text-h6">Data protection officer of the processing company</span>
    <p>
      Below is the email address of the data protection officer of the
      processing company.
    </p>
    <p>security@sentry.io</p>
    <span class="text-h6">Data processing purposes</span>
    <p>This list represents the purposes of data collection and processing.</p>
    <li>Tracking</li>
    <li>Detection of code errors</li>
    <li>Develop and improve products</li>
    <br>
    <span class="text-h6">Technologies used</span>
    <p>
      This list includes all technologies used by this service to collect data.
      Typical technologies are cookies and pixels placed in the browser.
    </p>
    <li>Cookies</li>
    <br>
    <span class="text-h6">Collected data</span>
    <p>
      This list contains all (personal) data collected during or through the use
      of the Service.
    </p>
    <li>Usage data</li>
    <li>Device information</li>
    <li>IP address</li>
    <li>Error data</li>
    <br>
    <span class="text-h6">Legal basis</span>
    <p>The following is the required legal basis for the processing of data.</p>
    <li>Art. 6 para. 1 s. 1 lit. a DSGVO</li>
    <li>Art. 49 para. 1 s. 1 lit. a DSGVO</li>
    <br>
    <span class="text-h6">Place of processing</span>
    <p>
      This is the primary location where the collected data is processed. If the
      data is also processed in other countries, you will be informed
      separately.
    </p>
    <li>United States of America</li>
    <br>
    <p>Retention period</p>
    <p>
      The retention period is the length of time that the collected data is
      stored for processing. Data must be deleted as soon as it is no longer
      needed for the specified processing purposes.
    </p>
    <p>
      Data is deleted as soon as it is no longer needed for the processing
      purposes.
    </p>
    <p>Transfer to third countries</p>
    <p>
      This service may transfer the collected data to another country. Please
      note that this Service may transfer data to a country that does not
      provide an adequate level of data protection. If the data is transferred
      to the U.S., there is a risk that your data may be processed by U.S.
      authorities for control and monitoring purposes, without any possible
      redress available to you. Below is a list of the countries to which the
      data will be transferred. For more information on security guarantees,
      please refer to the website provider's privacy policy or contact the
      website provider directly.
    </p>
    <li>United States of America</li>
    <br>
    <p>Data Recipients</p>
    <p>The following is a list of the recipients of the data collected.</p>
    <p>Functional Software, Inc. dba Sentry</p>
    <p>Click here to read the privacy policy of the data processor</p>
    <p>https://sentry.io/privacy/</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CookieList',
})
</script>
