<script setup lang="ts">
import { computed } from 'vue'
import { iFrameTarget } from '@/utils/helpers/url'

interface Props {
  target: string;
  title: string
}
const props = defineProps<Props>()

const target = computed<string>((): string => {
  return iFrameTarget(props.target)
})
</script>

<template>
  <div class="layout-wrapper">
    <div class="d-flex flex-wrap header mb-8">
      <h3 class="text-h3 text-neutral-darken4">
        {{ props.title }}
      </h3>
    </div>
    <iframe
      class="iframe-height"
      frameborder="0"
      scrolling="yes"
      :src="target"
      width="100%"
    />
  </div>
</template>

<style lang="sass" scoped>
.iframe-height
  // Full window height minus the top and bottom margin, the header and margin below the header.
  // Minus an additional 10px because for some reason otherwise the page is always scrollable.
  height: calc(100vh - 40px - 36px - 32px - 40px - 10px)
</style>
