import { MappingEditorState } from './types'

export default {
  componentInProjectWithContext: null,
  loading: false,
  mappedDatapointsWithContext: null,
  pins: null,
  pinsWithPendingMappingUpdates: [],
} as MappingEditorState

export function resetStoreState (state: MappingEditorState): void {
  state.componentInProjectWithContext = null
  state.loading = false
  state.mappedDatapointsWithContext = null
  state.pins = null
  state.pinsWithPendingMappingUpdates = []
}
