<script lang="ts">
import { computed, ref, watch } from 'vue'
import { type DropDownItem } from './MultipleDropDown.types'
import LabelWithArrowActivator from './LabelWithArrowActivator.vue'

export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts" generic="T">
interface Props {
  disabled?: boolean,
  items: DropDownItem<T>[],
  label?: string,
  overrideSelectionText?: string,
  value: T[]
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
})

const emit = defineEmits<{
  (e: 'change', value: T[]): void
}>()

const menuState = ref(false)

const itemsWithState = computed<Array<DropDownItem<T> & { active: boolean }>>(() => {
  return props.items.map((item) => {
    return {
      ...item,
      active: props.value.includes(item.id),
    }
  })
})

const selectedItemsLabels = computed<string[]>(() => {
  return itemsWithState.value.filter((item) => {
    return item.active
  }).map((item) => {
    return item.label
  })
})

const selectionText = computed<string>(() => {
  if (props.overrideSelectionText !== undefined) {
    return props.overrideSelectionText
  } else if (selectedItemsLabels.value.length) {
    return selectedItemsLabels.value.join(', ')
  } else {
    return ''
  }
})

function updateState (itemId: T, newValue: boolean) {
  if (newValue && !props.value.includes(itemId)) {
    const newState = props.value.slice()
    newState.push(itemId)
    emit('change', newState)
  } else {
    emit('change', props.value.filter((itemIdFromState) => {
      return itemIdFromState !== itemId
    }))
  }
}

const shouldHaveDisabledAppearance = computed<boolean>(() => {
  return props.disabled || itemsWithState.value.length <= 0
})

watch(() => props.disabled, (newValue) => {
  if (newValue) {
    updateMenuState(false)
  }
})

function updateMenuState (shouldBeOpen: boolean) {
  menuState.value = shouldBeOpen
}
</script>

<template>
  <div>
    <v-menu
      :close-on-content-click="false"
      content-class="aedifion-box-shadow"
      :disabled="shouldHaveDisabledAppearance"
      :model-value="menuState"
      @update:model-value="updateMenuState"
    >
      <template #activator="{ props: menuProps, isActive }">
        <LabelWithArrowActivator
          v-bind="{ ...menuProps, ...$attrs }"
          :disabled="shouldHaveDisabledAppearance"
          :displayed-value="selectionText"
          :is-menu-open="isActive"
          :label="label"
        />
      </template>

      <div class="tw-flex tw-flex-col tw-items-start tw-justify-center neutral-lighten5">
        <div
          v-for="item of itemsWithState"
          :key="item.id"
          class="dropdown-option tw-px-2 tw-flex-grow tw-w-full"
          data-testid="multiple-drop-down-option"
          @click="updateState(item.id, !item.active)"
        >
          <v-checkbox
            density="compact"
            hide-details
            color="primary-darken2"
            :model-value="item.active"
          >
            <template #label>
              <span class="text-body-1 tw-ml-2">
                {{ item.label }}
              </span>
            </template>
          </v-checkbox>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<style scoped lang="sass">
.dropdown-option
  cursor: pointer
  &:hover
    background-color: rgb(var(--v-theme-neutral-lighten3))

:deep(.v-overlay__content)
  background-color: rgb(var(--v-theme-neutral-lighten5))

:deep(.v-label)
  opacity: 1 !important
  color: rgb(var(--v-theme-neutral-darken3)) !important
</style>
