import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import i18n from '@/i18n'

export function copyStringToClipboard (stringToCopy: string): void {
  navigator.clipboard.writeText(stringToCopy).then(() => {
    showSuccessNotification(
      i18n.global.t('notifications.success.clipboard', { string: stringToCopy }) as string,
    )
  }, () => {
    showErrorNotification(
      i18n.global.t('notifications.errors.clipboard', { string: stringToCopy }) as string,
    )
  })
}
