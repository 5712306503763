<script setup lang="ts">
import { computed, nextTick, ref, useSlots, watch } from 'vue'
import { type DateOrRange } from '@/utils/types'
import moment from 'moment'
import { useI18n } from 'vue-i18n'

interface Props {
  date?: DateOrRange,
  disabled?: boolean,
  height?: number,
  maxDate?: string,
  menu: boolean,
  range?: boolean,
  text: string,
  type?: 'month' | 'date',
  width?: number|string
}

const props = withDefaults(defineProps<Props>(), {
  date: () => [moment().toDate()],
  disabled: false,
  height: 40,
  maxDate: moment().format('YYYY-MM-DD'),
  range: false,
  type: 'month',
  width: 200,
})

const emits = defineEmits<{
  (event: 'update:menu', value: boolean): void,
  (event: 'update:date', value: DateOrRange): void,
}>()

const slots = useSlots()

const { locale, t } = useI18n()

const dateRange = ref<DateOrRange>(sortDates(props.date))

const isValidRange = computed<boolean>(() => {
  if (dateRange.value.length < 1) {
    return false
  } else {
    return !!dateRange.value
  }
})

const sortedPropsDate = computed<DateOrRange>(() => {
  if (isRange(props.date)) {
    return sortDates(props.date)
  } else {
    return props.date
  }
})

const hasDateChanged = computed<boolean>(() => {
  if (Array.isArray(props.date)) {
    const sortedDates = sortDates(dateRange.value)
    return sortedPropsDate.value[0] !== sortedDates[0] || sortedPropsDate.value[1] !== sortedDates[1]
  } else {
    return props.date !== dateRange.value
  }
})

const formattedDateString = computed(() => dateFormatter(sortedPropsDate.value, props.type === 'month' ? 'MMM YYYY' : 'DD/MM/YYYY'))

const selectedDateString = computed(() => dateFormatter(dateRange.value, props.type === 'month' ? 'MMM YYYY' : 'DD MMM'))

function dateFormatter (input: DateOrRange, format: 'MMM'|'MMM YYYY'|'DD MMM'|'DD/MM/YYYY') {
  if (isRange(input)) {
    const firstDate = moment(input[0], 'YYYY-MM-DD').locale(locale.value).format(format)
    const lastDate = moment(input[1], 'YYYY-MM-DD').locale(locale.value).format(format)

    return `${firstDate}${lastDate ? ` - ${lastDate}` : ''}`
  } else {
    return moment(input[0], 'YYYY-MM-DD').locale(locale.value).format(format)
  }
}

function isRange (date: DateOrRange): boolean {
  return date.length === 2
}

function handleDateChange () {
  emits('update:menu', false)
  emits('update:date', sortDates(extractFirstAndLastDate(dateRange.value)))
}

function sortDates (dates: DateOrRange): DateOrRange {
  if (isRange(dates)) {
    return dates.sort((a, b) => moment(a).diff(moment(b)))
  } else {
    return dates
  }
}

function extractFirstAndLastDate (dates: Date[]): DateOrRange {
  return [dates[0], dates[dates.length - 1]]
}

watch(() => props.menu, (newVal) => {
  nextTick(() => {
    if (newVal) {
      updateClassesOfDatepickerButtons()
    }
  })
  if (!newVal) {
    dateRange.value = sortDates(props.date)
  }
})

watch(dateRange, () => {
  updateClassesOfDatepickerButtons()
})

const currentYear = ref(props.date[1]?.getFullYear() ?? props.date[0].getFullYear())

type ViewModes = 'month'|'months'|'year'

const viewMode = ref<ViewModes>('months')

function onViewModeChange (newViewMode: ViewModes): undefined|void {
  if (newViewMode === 'month') {
    return undefined
  }
  viewMode.value = newViewMode
  updateClassesOfDatepickerButtons()
}

function onMonthChange (month: number) {
  if (props.type === 'month') {
    if (dateRange.value.length === 2) {
      dateRange.value = [moment(new Date().setFullYear(currentYear.value, month, 1)).toDate()]
    } else {
      dateRange.value.push(moment(new Date().setFullYear(currentYear.value, month, 1)).toDate())
    }
  }
  updateClassesOfDatepickerButtons()
}

function onYearChange (year: number) {
  currentYear.value = year
  viewMode.value = 'months'

  updateClassesOfDatepickerButtons()
}

function applyAndRemoveBackgroundOnSelectedMonths () {
  const monthsButtons = document.querySelectorAll('.v-date-picker-months__content > .v-btn')
  const startDateMonthAndYear = {
    month: moment(dateRange.value[0]).month(),
    year: moment(dateRange.value[0]).year(),
  }
  const endDateMonthAndYear = {
    month: moment(dateRange.value[1]).month(),
    year: moment(dateRange.value[1]).year(),
  }

  monthsButtons.forEach((month) => {
    month.classList.remove('bg-primary-darken2', 'v-btn--active')
  })

  if (startDateMonthAndYear.year === currentYear.value) {
    monthsButtons[startDateMonthAndYear.month]?.classList.add('bg-primary-darken2', 'v-btn--active')
  }

  if (endDateMonthAndYear.year === currentYear.value) {
    monthsButtons[endDateMonthAndYear.month]?.classList.add('bg-primary-darken2', 'v-btn--active')
  }
}

function applyDisabledClassOnGreaterThanMaxDate () {
  const months = document.querySelectorAll('.v-date-picker-months__content > .v-btn')
  const maxDate = moment(props.maxDate).month()

  if (currentYear.value === moment(props.maxDate).year()) {
    months.forEach((month, index) => {
      if (index > maxDate) {
        month.classList.add('v-btn--disabled')
      }
    })
  }
}

function updateClassesOfDatepickerButtons () {
  nextTick(() => {
    applyAndRemoveBackgroundOnSelectedMonths()
    applyDisabledClassOnGreaterThanMaxDate()
  })
}

</script>

<template>
  <v-card
    ref="sheet"
    :class="['d-flex align-center justify-center px-2', {'hoverable-bordered': !props.disabled}, {'transparent-background disabled': props.disabled}]"
    data-cy="date-picker-wrapper"
    :height="props.height"
    :width="props.width"
    class="hover:tw-cursor-pointer"
    tabindex="0"
    @keydown.enter="emits('update:menu', true)"
    @keydown.esc="emits('update:menu', false)"
  >
    <v-menu
      ref="menu"
      content-class="aedifion-box-shadow"
      :close-on-content-click="false"
      data-cy="date-picker-menu"
      :disabled="props.disabled"
      location="bottom"
      transition="scale-transition"
      :model-value="props.menu"
      @update:model-value="emits('update:menu', $event)"
    >
      <template
        #activator="{ props: menuProps }"
      >
        <div
          v-bind="menuProps"
          data-cy="date-picker-activator"
          :class="disabled && 'disabled'"
          class="d-flex flex-row align-center justify-center fill-height"
          transition="slide-x-transition"
        >
          <span
            class="text-subtitle-2 font-weight-regular mr-2 date-picker--text__left"
            data-cy="date-picker-text-1"
            v-bind="menuProps"
          >
            {{ props.text }}
          </span>
          <slot name="date-indicator" />
          <span
            v-if="!slots['date-indicator']"
            class="text-cta text-neutral-darken3"
            data-cy="date-picker-text-2"
          >
            {{ formattedDateString }}
          </span>
          <v-icon
            class="ml-2 mr-1"
            color="neutral-darken3"
            size="small"
          >
            fa:far fa-calendar
          </v-icon>
        </div>
      </template>
      <v-date-picker
        ref="datePicker"
        model-value="dateRange"
        :view-mode="viewMode"
        color="primary-darken2"
        data-cy="date-picker"
        min-width="320"
        header-color="primary"
        :multiple="$props.range? 'range' : undefined"
        :max="props.maxDate"
        :show-current="false"
        width="auto"
        :type="props.type"
        @update:month="onMonthChange"
        @update:year="onYearChange"
        @update:view-mode="onViewModeChange"
        @update:model-value="()=>{}"
      >
        <template #header>
          <v-card-title class="text-h2">
            {{ selectedDateString }}
          </v-card-title>
        </template>
        <template #actions>
          <v-btn
            color="primary"
            data-testid="date-picker-clear-button"
            variant="text"
            class="mr-auto"
            @click="dateRange = []"
          >
            {{ t('actions.clear') }}
          </v-btn>
          <v-btn
            color="primary"
            data-testid="date-picker-cancel-button"
            variant="text"
            @click="emits('update:menu', false)"
          >
            {{ t('actions.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!isValidRange || !hasDateChanged"
            data-testid="date-picker-ok-button"
            @click="handleDateChange"
          >
            {{ t('actions.ok') }}
          </v-btn>
        </template>
      </v-date-picker>
    </v-menu>
  </v-card>
</template>

<style lang="sass" scoped>
.date-picker--text__left
  color: rgb(var(--v-theme-neutral-darken1)) !important
.v-card:not(.disabled):hover
  .date-picker--text__left
    color: rgb(var(--v-theme-primary-darken2)) !important

.transparent-background
  background-color: #00000000

.disabled
  cursor: default
  pointer-events: all

.v-card:focus-visible
  border: 1px solid rgb(var(--v-theme-primary-lighten2)) !important
  background-color: rgb(var(--v-theme-primary-lighten4)) !important
  .date-picker--text__left
    color: rgb(var(--v-theme-primary-darken2)) !important

:deep(.v-date-picker-controls__month)
  display: none
</style>
