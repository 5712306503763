<script setup lang="ts">
import { computed, ref } from 'vue'
import AddCommentForm from './AddCommentForm.vue'
import { COCKPIT_NUDGE_GRADIENT } from '@theme/colors'
import CommentsTable from './CommentsTable.vue'
import EmptyStateForPage from '@/components/EmptyStateForPage.vue'
import type { TaskComment } from '@aedifion.io/aedifion-api'
import { useChecklistStore } from '@/stores/views/Optimization/Checklist'
import { useI18n } from 'vue-i18n'
import type { JSONContent } from '@tiptap/vue-3'

defineProps<{
  comments: TaskComment[]
}>()

const { t } = useI18n()

const checklistStore = useChecklistStore()

const showAddComment = ref(false)

const loading = computed<boolean>(() => checklistStore.loadingTaskComments)

const addComment = async (newComment: JSONContent) => {
  showAddComment.value = false

  await checklistStore.postTaskComment({
    body: {
      text: JSON.stringify(newComment),
    },
    taskId: checklistStore.currentEditedTask!.id!,
  })

  checklistStore.getTaskComments(checklistStore.currentEditedTask!.id!)
}
</script>

<template>
  <div data-testid="task-comments-section">
    <v-card-title
      class="pa-6"
    >
      <div class="d-flex flex-row align-center justify-space-between tw-w-full">
        <span data-testid="task-comments-title">{{ t('comments_heading') }}</span>
        <v-btn
          v-if="comments?.length"
          icon
          size="small"
          data-testid="add-comment-button"
          @click="showAddComment = true"
        >
          <v-icon size="x-small">
            fa:far fa-message-plus
          </v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <AddCommentForm
      v-if="showAddComment"
      class="px-6 tw-mb-[14px]"
      @add-comment="addComment"
      @cancel-add-comment="showAddComment = false"
    />
    <div class="px-6 tw-pb-[34px] text-body-1">
      <CommentsTable
        v-if="comments?.length || loading"
        :comments="comments"
        :loading="loading"
      />
      <EmptyStateForPage
        v-else-if="!showAddComment"
        class="px-10"
        :icon="{
          name: 'fal fa-message-lines',
          size: '40px',
          color: `linear-gradient(${COCKPIT_NUDGE_GRADIENT[0]}, ${COCKPIT_NUDGE_GRADIENT[1]}, ${COCKPIT_NUDGE_GRADIENT[2]})`
        }"
        :title="t('empty_state.title')"
        :description="t('empty_state.description')"
      >
        <v-btn
          color="primary-darken2"
          data-testid="add-first-comment-button"
          variant="outlined"
          class="align-center"
          height="40px"
          @click="showAddComment = true"
        >
          <span class="mr-3">{{ t('empty_state.add_comment') }}</span>
          <v-icon size="small">
            fa:fal fa-message-plus
          </v-icon>
        </v-btn>
      </EmptyStateForPage>
    </div>
  </div>
</template>

<i18n lang="json" locale="de">
{
  "empty_state": {
    "description": "Füge einen Kommentar hinzu, um nützliche und wichtige Informationen im Zusammenhang mit dieser Aufgabe zu dokumentieren.",
    "title": "Du hast etwas zu sagen?",
    "add_comment": "Kommentar hinzufügen"
  },
  "comments_heading": "Kommentare"
}
</i18n>
<i18n lang="json" locale="en">
{
  "empty_state": {
    "description": "Add a comment to document useful and important information related to this task.",
    "title": "Got something to say?",
    "add_comment": "Add comment"
  },
  "comments_heading": "Comments"
}
</i18n>
