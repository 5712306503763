import { DatapointListItemData, DatapointsState, DatapointWithContextAndScore, PinInComponentInProject } from './types'
import { convertDatapointToListItem } from '@/utils/helpers/datapoints'
import { DatapointOverviewItem } from '../data_points_view/types'
import { GetterTree } from 'vuex'
import { RootState } from '../types'
import { TagAssociation } from '@aedifion.io/aedifion-api'

export default {
  datapointListItemData: (state: DatapointsState, _, rootState) =>
    (dataPointID: string): DatapointListItemData|null => {
      for (const datapoint of state.datapoints || []) {
        if (datapoint.dataPointID === dataPointID) {
          return convertDatapointToListItem(datapoint, rootState.data_points_view.selectedDatapointsHashIds, rootState.data_points_view.seriesColorMap)
        }
      }
      return null
    },

  datapointListItems: (state: DatapointsState, _, rootState): DatapointListItemData[] => {
    if (state.datapoints) {
      return state.datapoints.map((datapoint: DatapointWithContextAndScore) => {
        return convertDatapointToListItem(datapoint, rootState.data_points_view.selectedDatapointsHashIds, rootState.data_points_view.seriesColorMap)
      })
    } else {
      return []
    }
  },

  datapoints: (state: DatapointsState): DatapointWithContextAndScore[]|null => state.datapoints,

  datapointsWithPendingFavoritesUpdate: (state: DatapointsState): Array<string> => state.datapointsWithPendingFavoritesUpdate,

  datapointWithDataPointId: (state: DatapointsState, _, __, rootGetters) => (dataPointId: string): DatapointWithContextAndScore|DatapointOverviewItem|null => {
    const datapointsFromDatapointsStore = state.datapoints || []
    const datapointsFromDataInspectorOverview = rootGetters['data_points_view/getDatapointsOverviewItems'] as DatapointOverviewItem[]
    const allDatapoints: (DatapointWithContextAndScore|DatapointOverviewItem)[] = [...datapointsFromDatapointsStore, ...datapointsFromDataInspectorOverview]
    return allDatapoints.find((datapoint) => {
      return datapoint.dataPointID === dataPointId
    }) ?? null
  },

  datapointWithHashId: (state: DatapointsState) => (datapointHashId: string): DatapointWithContextAndScore|null => {
    for (const datapoint of state.datapoints || []) {
      if (datapoint.hash_id === datapointHashId) {
        return datapoint
      }
    }
    return null
  },

  getCurrentPage: (state: DatapointsState): number|null => state.pagination?.current_page ?? null,

  getTotalPages: (state: DatapointsState): number|null => state.pagination?.total_pages ?? null,

  isLoadingDatapoints: (state: DatapointsState): boolean => state.loadingDatapoints,

  selectedPinForRecommendations: (state: DatapointsState): PinInComponentInProject | null => state.selectedPinForRecommendations,

  setpointRange: (state: DatapointsState) => (dataPointID: string): [number, number] | null => {
    const datapoint = state.datapoints?.find(datapoint => datapoint.dataPointID! === dataPointID)
    if (datapoint && datapoint.setpoint_min_value !== undefined && datapoint.setpoint_max_value !== undefined) {
      return [datapoint.setpoint_min_value!, datapoint.setpoint_max_value!]
    }
    return null
  },

  tagsOfDatapoint: (state: DatapointsState) => (datapointHashId: string): TagAssociation[] => {
    for (const datapoint of state.datapoints || []) {
      if (datapoint.hash_id === datapointHashId) {
        return datapoint.tags ?? []
      }
    }
    return []
  },
} as GetterTree<DatapointsState, RootState>
