// If you edit things here do not forget to also update
// `src/scss/variables.scss` as well as the table in the `README.md`
const TYPOGRAPHY = {
  fontWeights: {
    black: '900',
    bold: '700',
    light: '300',
    medium: '500',
    regular: '400',
    semibold: '600',
    thin: '100',
  },
  headings: {
    body1: {
      letterSpacing: 'normal',
      lineHeight: '1.25rem',
      size: '.875rem',
      weight: '400',
    },
    body2: {
      letterSpacing: 'normal',
      lineHeight: '1.25rem',
      size: '.875rem',
      weight: '400',
    },
    button: {
      letterSpacing: 'normal',
      lineHeight: '1.25rem',
      size: '.875rem',
      weight: '500',
    },
    caption: {
      letterSpacing: 'normal',
      lineHeight: '1.25rem',
      size: '.875rem',
      weight: '400',
    },
    cta: {
      letterSpacing: 'normal',
      lineHeight: '1.25rem',
      size: '.875rem',
      weight: '500',
    },
    h1: {
      letterSpacing: 'normal',
      lineHeight: '3rem',
      size: '2.5rem',
      weight: '600',
    },
    h2: {
      letterSpacing: 'normal',
      lineHeight: '2.5rem',
      size: '2rem',
      weight: '600',
    },
    h3: {
      letterSpacing: 'normal',
      lineHeight: '2.25rem',
      size: '1.75rem',
      weight: '600',
    },
    h4: {
      letterSpacing: 'normal',
      lineHeight: '2rem',
      size: '1.5rem',
      weight: '600',
    },
    h5: {
      letterSpacing: 'normal',
      lineHeight: '1.75rem',
      size: '1.25rem',
      weight: '600',
    },
    h6: {
      letterSpacing: 'normal',
      lineHeight: '1.625rem',
      size: '1.125rem',
      weight: '500',
    },
    input: {
      letterSpacing: 'normal',
      lineHeight: '1.25rem',
      size: '.875rem',
      weight: '400',
    },
    legend: {
      letterSpacing: 'normal',
      lineHeight: '1rem',
      size: '.75rem',
      weight: '400',
    },
    overline: {
      letterSpacing: 'normal',
      lineHeight: '1.25rem',
      size: '.875rem',
      weight: '400',
    },
    semantics: {
      letterSpacing: 'normal',
      lineHeight: '1.75rem',
      size: '1.25rem',
      weight: '500',
    },
    subtitle1: {
      letterSpacing: 'normal',
      lineHeight: '1rem',
      size: '.75rem',
      weight: 'normal',
    },
    subtitle2: {
      letterSpacing: 'normal',
      lineHeight: '1.25rem',
      size: '.875rem',
      weight: '500',
    },
  },
}

export { TYPOGRAPHY }
