<script setup lang="ts">
import { computed, ref } from 'vue'
import { Goal, GoalBarChart, GoalsAttribute } from '@/vuex/building_analyses/types'
import { ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { docsLink } from '@/utils/helpers/locale'
import { parseComponentAttribute } from '@/utils/helpers/componentAttribute'
import TooltipCard from '@/components/TooltipCard.vue'
import { useI18n } from 'vue-i18n'

interface Props {
  buildingComponent: ComponentInProjectWithContext|null
  loading?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  loading: false,
})

const { t } = useI18n()

const assetGoalSetupLink = ref<string|null>(docsLink('asset_goal_setup'))

const goals = computed<Goal[]>(() => (goalsAttribute.value.goals || []).slice(0, 3))

const goalsAttribute = computed<GoalsAttribute>(() => {
  if (!props.buildingComponent) {
    return {
      goals: [],
      period: {},
    }
  }

  return parseComponentAttribute(props.buildingComponent, 'B+GOA')
})

const isNoDataState = computed(() => goals.value.length === 0)

function getBarColor (barChart: GoalBarChart): string {
  const barValue = getBarValue(barChart)
  if (barValue < 33.33) {
    return 'error'
  } else if (barValue >= 33.33 && barValue < 66.66) {
    return 'warning'
  } else {
    return 'success'
  }
}

function getBarValue (barChart: GoalBarChart): number {
  const normalizedValue = (barChart.act - barChart.min) * 100 / (barChart.max - barChart.min)
  if (normalizedValue < 0) {
    return 0
  }
  return normalizedValue
}

</script>
<template>
  <v-card class="fill-height">
    <v-card-title>
      {{ t('goals') }}
    </v-card-title>
    <v-card-subtitle
      v-if="loading || (!isNoDataState && goalsAttribute.period)"
      class="text-subtitle-1"
    >
      <v-skeleton-loader
        v-if="loading"
        class="pt-2"
        height="15"
        :loading="loading"
        type="text"
      />
      <div v-else>
        {{ goalsAttribute.period[$i18n.locale] || goalsAttribute.period['en'] }}
      </div>
    </v-card-subtitle>
    <v-card-text v-if="loading">
      <v-skeleton-loader
        height="22"
        :loading="loading"
        type="text"
      />
    </v-card-text>
    <v-card-text
      v-else-if="!isNoDataState"
      class="text-neutral-darken3"
    >
      <div
        v-for="goal, index in goals"
        :key="index"
        data-testid="goals-card-text"
        class="mb-4"
      >
        <v-tooltip location="top">
          <template #activator="{ props: tooltipProps }">
            <div
              class="text-truncate mb-1"
              v-bind="tooltipProps"
            >
              {{ goal.names[$i18n.locale] || goal.names['en'] }}
            </div>
          </template>
          {{ goal.names[$i18n.locale] || goal.names['en'] }}
        </v-tooltip>
        <div class="pr-3">
          <v-progress-linear
            class="bg-neutral-lighten2"
            bg-color="neutral-darken2"
            :color="getBarColor(goal.bar_chart)"
            height="6"
            rounded
            :model-value="getBarValue(goal.bar_chart)"
          />
        </div>
      </div>
    </v-card-text>
    <v-card-text
      v-else
      class="d-flex flex-column flex-grow-1 justify-center px-0"
    >
      <div
        class="px-4 mb-1"
        max-width="100%"
      >
        {{ t('messages.no_data') }}
        <tooltip-card
          :link-text="t('no_data_tooltip.tooltip_button')"
          :link-url="assetGoalSetupLink"
        >
          <template #activator="tooltipCardProps">
            <v-icon
              class="cursor-pointer"
              v-bind="tooltipCardProps"
              size="small"
            >
              fa:far fa-info-circle
            </v-icon>
          </template>
          <template #title>
            <div>{{ t('no_data_tooltip.tooltip_title') }}</div>
          </template>
          <template #text>
            <div>{{ t('no_data_tooltip.tooltip_text') }}</div>
          </template>
        </tooltip-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="sass" scoped>
.cursor-pointer
  cursor: pointer

</style>

<i18n lang="json" locale="de">
  {
    "goals": "Ziele",
    "no_data_tooltip": {
      "tooltip_title": "Keine Ziele definiert",
      "tooltip_text": "Um Ziele für dein Projekt anzuzeigen müssen diese zuerst konfiguriert werden. Bitte wende dich an aedifion.",
      "tooltip_button": "Siehe Dokumentation"
    }
  }
  </i18n>
<i18n lang="json" locale="en">
  {
    "goals": "Goals",
    "no_data_tooltip": {
      "tooltip_title": "No goals defined",
      "tooltip_text": "To display goals for your property, they must first be configured. Please contact aedifion.",
      "tooltip_button": "See documentation"

    }
  }
</i18n>
