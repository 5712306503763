<template>
  <v-sheet class="mx-auto mb-auto pt-7 pb-4 px-2">
    <v-row
      align-content="start"
      class="fill-height mx-auto"
      justify="center"
      style="max-width: 983px"
    >
      <v-col
        class="mb-8"
        cols="12"
      >
        <AvatarEditorDeprecated
          :avatar="userAvatar"
          :loading="hasPendingAvatarUpdate"
          @delete="deleteAvatar"
          @update="updateAvatar"
        />
      </v-col>
      <v-col cols="12">
        <DetailsForm
          v-bind="$attrs"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import AvatarEditorDeprecated from '@/components/AvatarEditorDeprecated.vue'
import { defineComponent } from 'vue'
import DetailsForm from './DetailsForm.vue'
import { useUserProfileStore } from '@/stores/views/User/profile'

export default defineComponent({
  name: 'UserProfile',

  components: {
    AvatarEditorDeprecated,
    DetailsForm,
  },

  computed: {
    ...mapState(useUserProfileStore, {
      hasPendingAvatarUpdate: 'isAvatarUpdatePending',
      userAvatar: 'avatar',
    }),
  },

  methods: {
    ...mapActions(useUserProfileStore, ['deleteAvatar', 'updateAvatar']),
  },
})
</script>
