import { AnalysisFunction } from '@aedifion.io/aedifion-api'
import { AnalysisFunctionState } from './types'
import { GetterTree } from 'vuex'
import { RootState } from '../types'

export default {
  getAnalysisFunctionById: (state: AnalysisFunctionState) => (id: number): null|AnalysisFunction => state.analysisFunctionsList?.find((analysisFunction) => analysisFunction.id === id) ?? null,

  getAnalysisFunctions: (state: AnalysisFunctionState): null|AnalysisFunction[] => state.analysisFunctionsList,

  isLoadingAnalysisFunctions: (state: AnalysisFunctionState): boolean => state.loading,

} as GetterTree<AnalysisFunctionState, RootState>
