<template>
  <div
    ref="horizontalStack"
  >
    <div v-if="!isLoading">
      <div
        v-if="totalProportion > 0 && showFigures"
        class="stack-figures-container"
        data-testid="stack-figures"
        :style="stackWidthCss"
      >
        <template
          v-for="(stack, index) in dataWithStart"
        >
          <span
            v-if="Number(stack.displayedFigure) > 0"
            :key="index"
            :data-testid="`stack-figure-${stack.id}`"
            :class="[{ 'greyed': hasHoveredStack && currentHoveredStack !== index }, 'stack-figure']"
            :style="stackFigureStyle(xCoordinateOfBar(stack, index), stack.figureColor ?? stack.color)"
            @mouseleave="setHoveredStack(null)"
            @mouseover="setHoveredStack(index)"
          >
            {{ stack.displayedFigure }}
          </span>
        </template>
      </div>
      <svg
        data-testid="stacked-chart"
        class="d-block rounded-sm"
        preserveAspectRatio="none"
        viewBox="0 0 100 10"
        :width="stackWidth"
        xmlns="http://www.w3.org/2000/svg"
        height="8"
      >
        <g v-if="totalProportion > 0">
          <g
            v-for="(stack, index) in dataWithStart"
            :key="index"
            :data-testid="`${stack.id}-chart-container`"
            @mouseleave="setHoveredStack(null)"
            @mouseover.capture="setHoveredStack(index)"
          >
            <rect
              :class="[
                'bar',
                {'greyed': hasHoveredStack && currentHoveredStack !== index}
              ]"
              :fill="stack.color"
              :width="widthOfBar(stack)"
              height="10"
              :x="xCoordinateOfBar(stack, index)"
              y="0"
            />
            <rect
              fill="transparent"
              :width="widthOfHoverZone(stack)"
              :x="xCoordinateOfHoverZone(stack, index)"
              height="10"
              y="0"
            />
          </g>
        </g>
        <g v-if="totalProportion <= 0">
          <rect
            :fill="colors.neutral.base"
            height="10"
            width="100"
            x="0"
            y="0"
          />
        </g>
      </svg>
    </div>
    <v-skeleton-loader
      v-else
      :width="stackWidth"
      type="list-item"
      class="rounded-sm"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import debounce from 'lodash/debounce'
import { OverviewSummary } from '@/stores/views/Optimization/types'
import type { StyleValue } from 'vue'
import { VUETIFY_COLORS } from '@theme/colors'

type StackedValueWithStart = OverviewSummary[number] & {
  start: number
}

type StackedValueWithIndex = OverviewSummary[number] & {
  index: number
}

export default defineComponent({
  name: 'HorizontalStack',

  props: {
    gapSize: {
      default: 2,
      required: false,
      type: Number as PropType<number>,
    },
    hoverable: {
      default: true,
      required: false,
      type: Boolean as PropType<boolean>,
    },
    isLoading: {
      default: false,
      required: false,
      type: Boolean as PropType<boolean>,
    },
    items: {
      required: true,
      type: Array as PropType<OverviewSummary>,
    },
    showFigures: {
      default: true,
      required: false,
      type: Boolean as PropType<boolean>,
    },
    stackSize: {
      required: false,
      type: Number as PropType<number>,
    },
  },

  emits: ['hovered'],

  data () {
    return {
      colors: VUETIFY_COLORS,
      currentHoveredStack: null as number|null,
      currentWidth: null as number|null,
      debouncedOnResize: null as (() => void)|null,
      defaultWidth: 200,
    }
  },

  computed: {
    dataWithStart (): StackedValueWithStart[] {
      return this.items.reduce((accumulator: StackedValueWithStart[], currentStack, index) => {
        const stackWithStart: StackedValueWithStart = {
          ...currentStack,
          start: 0,
        }
        if (accumulator[index - 1]) {
          const lastValue = accumulator[index - 1]
          stackWithStart.start = lastValue.start + lastValue.proportion
        }
        accumulator.push(stackWithStart)
        return accumulator
      }, [])
    },

    figureData (): StackedValueWithIndex[] {
      return this.items.map((value, index) => {
        return { ...value, index }
      }).filter((value) => {
        return value.displayedFigure !== undefined
      })
    },

    gapProportion (): number {
      return 100 * this.gapSize / this.stackWidth
    },

    hasHoveredStack (): boolean {
      return this.currentHoveredStack !== null
    },

    proportionFactor (): number {
      return (100 - (this.items.length - 1) * this.gapProportion) / this.totalProportion
    },

    stackWidth (): number {
      if (this.stackSize) return this.stackSize

      return this.currentWidth || this.defaultWidth
    },

    stackWidthCss (): StyleValue {
      return {
        width: this.stackWidth + 'px',
      }
    },

    totalProportion (): number {
      if (this.dataWithStart.length <= 0) {
        return 0
      }
      const lastElementWithStart = this.dataWithStart[this.dataWithStart.length - 1]
      return lastElementWithStart.start + lastElementWithStart.proportion
    },

  },

  created () {
    if (!this.stackSize) {
      this.debouncedOnResize = debounce(this.onResize, 200)
      window.addEventListener('resize', this.debouncedOnResize)
    }
  },

  mounted () {
    if (!this.stackSize) {
      this.onResize()
    }
  },

  beforeUnmount () {
    if (!this.stackSize) {
      window.removeEventListener('resize', this.debouncedOnResize as () => void)
    }
  },

  methods: {
    onResize () {
      this.currentWidth = (this.$refs.horizontalStack as HTMLDivElement)?.clientWidth
    },

    setHoveredStack (index: number|null) {
      if (this.hoverable) {
        this.currentHoveredStack = index
        if (index !== null) {
          this.$emit('hovered', this.items[index])
        } else {
          this.$emit('hovered', null)
        }
      }
    },

    stackFigureStyle (xCoordinateOfBar: number, stackColor: string): StyleValue {
      return {
        color: stackColor,
        left: xCoordinateOfBar + '%',
        position: 'absolute',
      }
    },

    widthOfBar (stack: OverviewSummary[number]) {
      return stack.proportion * this.proportionFactor
    },

    widthOfHoverZone (stack: OverviewSummary[number]) {
      return stack.proportion * this.proportionFactor + this.gapProportion
    },

    xCoordinateOfBar (stack: StackedValueWithStart, index: number) {
      return stack.start * this.proportionFactor + index * this.gapProportion
    },

    xCoordinateOfHoverZone (stack: StackedValueWithStart, index: number) {
      return stack.start * this.proportionFactor + index * this.gapProportion - this.gapProportion / 2
    },
  },
})
</script>

<style lang="sass" scoped>
  .figure
    color: rgb(var(--v-theme-neutral-darken3))

  .figure, span.text-body-1.neutral--text.text--darken2
    transition: color .15s ease-in-out

  .v-application
    .greyed .figure
      color: rgb(var(--v-theme-neutral)) !important

  .bar
    transition: fill .15s ease-in-out

  .bar.greyed
    fill: rgb(var(--v-theme-neutral))

  .stack-figures-container
    position: relative
    cursor: default
    height: 24px

  .stack-figure
    font-size: 14px
    font-weight: 600

  :deep(.v-skeleton-loader__text)
    border-radius: 2px !important

  :deep(.v-skeleton-loader__list-item)
    background: transparent !important
    padding: 0
    border-radius: 2px !important
    height: 8px

</style>
