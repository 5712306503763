import { ComponentNameUpdated, ComponentsInProjectState } from './types'
import { ComponentWithResults, PaginationMeta } from '@aedifion.io/aedifion-api'
import { MutationTree } from 'vuex'

export default {
  REMOVE_COMPONENT_IN_PROJECT_WITH_RESULT: (state: ComponentsInProjectState, componentInProjectID: number) => {
    const componentInProjectIndex: number = state.componentsInProjectWithResults!.findIndex((componentsInProject: ComponentWithResults) =>
      componentsInProject.componentinproject_id === componentInProjectID)

    if (componentInProjectIndex >= 0) {
      state.componentsInProjectWithResults!.splice(componentInProjectIndex, 1)
    }
  },

  SET_COMPONENTS_IN_PROJECT_WITH_RESULTS: (state: ComponentsInProjectState, components: ComponentWithResults[]) => {
    state.componentsInProjectWithResults = components
  },

  SET_LOADING_COMPONENTS_IN_PROJECT: (state: ComponentsInProjectState, loading: boolean) => (state.loadingComponentsInProject = loading),

  SET_PAGINATION: (state: ComponentsInProjectState, pagination: PaginationMeta) => (state.pagination = pagination),

  SET_PENDING_COMPONENT_IN_PROJECT_UPDATE: (state: ComponentsInProjectState, loading: boolean) => (state.pendingComponentInProjectUpdate = loading),

  UPDATE_COMPONENT_IN_PROJECT_NAME: (state: ComponentsInProjectState, componentUpdated: ComponentNameUpdated) => {
    for (const element of state.componentsInProjectWithResults!) {
      if (componentUpdated.id === element.componentinproject_id) {
        const abstractComponentNameEn: string|undefined = element.display_name?.split(' | ')?.[1]
        const abstractComponentNameDe: string|undefined = element.display_name_DE?.split(' | ')?.[1]

        if (componentUpdated.nameEN) {
          element.display_name = `${componentUpdated.nameEN} | ${abstractComponentNameEn}`
        }

        if (componentUpdated.nameDE) {
          element.display_name_DE = `${componentUpdated.nameDE} | ${abstractComponentNameDe}`
        }
        break
      }
    }
  },
} as MutationTree<ComponentsInProjectState>
