import { createI18n } from 'vue-i18n'
import de from '@/locales/de.json'
import en from '@/locales/en.json'
import moment from 'moment'
import momentDe from 'moment/dist/locale/de'
import momentEn from 'moment/dist/locale/en-gb'

const messages = {
  de,
  en,
} as const

export type AvailableLocales = keyof typeof messages

const i18n = createI18n(
  {
    allowComposition: true,
    availableLocales: ['en', 'de'],
    fallbackLocale: 'en',
    fallbackWarn: false,
    globalInjection: true,
    legacy: false,
    locale: getLocale(),
    messages,
    missingWarn: false,
    silentFallbackWarn: true,
    silentTranslationWarn: false,
    sync: true,
    warnHtmlInMessage: 'off',
    warnHtmlMessage: false,
  },
)

function getBrowserLocales (): string[] {
  let browserLocales: readonly string[]
  if (navigator.languages === undefined && navigator.language === undefined) {
    browserLocales = ['en-US']
  } else if (navigator.languages === undefined) {
    browserLocales = [navigator.language]
  } else {
    browserLocales = navigator.languages
  }

  return browserLocales.map(locale => {
    return locale.trim()
  })
}

export function getLocale (): string {
  if (globalThis.localStorage !== undefined && globalThis.localStorage.getItem('locale')) {
    return globalThis.localStorage.getItem('locale')!
  } else {
    const browserLocale = getBrowserLocales()[0].slice(0, 2)
    return Object.keys(messages).includes(browserLocale) ? browserLocale : 'en'
  }
}

export function setLocale (locale: AvailableLocales): void {
  try {
    globalThis.localStorage.setItem('locale', locale)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`i18n - setLocale: Could not set locale to '${locale}'. Local storage is not available.`)
  }

  i18n.global.locale.value = locale

  /**
   * Update the moment locale to match the current locale https://github.com/moment/moment/issues/2962#issuecomment-639386371
   */
  moment.updateLocale(locale, locale === 'de' ? momentDe : momentEn)
}

export default i18n
