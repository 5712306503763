import { ComponentsState } from './types'

export default {
  components: null,
  loadingComponents: false,
} as ComponentsState

export function resetStoreState (state: ComponentsState): void {
  state.components = null
  state.loadingComponents = false
}
