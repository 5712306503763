<script setup lang="ts">
import { CompanyRoles, CompanyWithContextProjects, CompanyWithContextUsers, NewUser, UpdateUser } from '@aedifion.io/aedifion-api'
import { computed, reactive, ref } from 'vue'
import { email, not, required } from '@vuelidate/validators'
import { getProjectRoleIds, getRoleIds } from '@/utils/helpers/roles'
import { passwordRules } from '@/utils/helpers/security'
import { ProjectRoleId } from '@/stores/views/Administration/users'
import { useI18n } from 'vue-i18n'
import UserRolesEditor from '@/components/UserRolesEditor.vue'
import { useVuelidate } from '@vuelidate/core'

const { t } = useI18n()

interface Props {
  companyRoles: CompanyRoles[]
  loading?: boolean
  projects: CompanyWithContextProjects[]
  user?: CompanyWithContextUsers|null
  users?: CompanyWithContextUsers[]
  value?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  user: null,
  users: () => [],
  value: false,
})

const emit = defineEmits<{
  (e: 'create-user', payload: {
    companyRoles: number[],
    projectRoles: number[],
    user: NewUser
  }): void
  (e: 'change', value: boolean): void,
  (e: 'update-user', payload: {
    companyRoles: number[],
    projectRoles: number[],
    user: UpdateUser|null
  }): void
}>()

const usersProjectRoleIds = computed<ProjectRoleId[]>({
  get () {
    if (props.user === null) return []
    else {
      return getProjectRoleIds(props.projects, props.user.project_roles ?? [])
    }
  },
  set (newProjectRoleIds: ProjectRoleId[]) {
    const roleIds: number[] = getRoleIds(newProjectRoleIds)
    userData.projectRoles = roleIds
  },
})

const isEdit = computed(() => {
  return props.user !== null
})

const userData = reactive({
  // TODO: Update the API to include the address field when API is ready
  address: '',
  companyRoles: props.user?.company_roles ?? [] as number[],
  email: props.user?.email ?? '',
  firstName: props.user?.firstName ?? '',
  lastName: props.user?.lastName ?? '',
  password: '',
  phone: props.user?.phone ?? '',
  projectRoles: props.user?.project_roles ?? [],
  showPassword: false,
  submittable: false,
})

const updatedProjectRoles = ref<ProjectRoleId[]>(getProjectRoleIds(props.projects, props.user?.project_roles ?? []))

const emailAlreadyExists = (value: string) => {
  return props.users.some((user: CompanyWithContextUsers) => {
    return user.email === value
  })
}

const rules = computed(() => ({
  email: isEdit.value
    ? {}
    : {
      email,
      emailDoesNotExistYet: not(emailAlreadyExists),
      required,
    },
  firstName: { required },
  lastName: { required },
  password: isEdit.value ? {} : passwordRules,
}))

const v$ = useVuelidate(rules, userData)

const emailErrors = computed<string>(() => {
  if (v$.value.email.$dirty && v$.value.email.required && v$.value.email.$errors.filter(error => error.$validator === 'required').length > 0) {
    return t('errors.is_required', { field: t('form_fields.email') })
  } else if (v$.value.email.$dirty && v$.value.email.email && v$.value.email.$errors.filter(error => error.$validator === 'email').length > 0) {
    return t('errors.is_invalid', { field: t('form_fields.email') })
  } else if (v$.value.email.$dirty && v$.value.email.emailDoesNotExistYet && v$.value.email.$errors.filter(error => error.$validator === 'emailDoesNotExistYet').length > 0) {
    return t('errors.email_already_exists')
  }
  return ''
})

const firstNameErrors = computed<string>(() => {
  if (v$.value.firstName.$dirty && v$.value.firstName.required && v$.value.firstName.$error) {
    return t('errors.is_required', { field: t('form_fields.first_name') })
  }
  return ''
})

const lastNameErrors = computed<string>(() => {
  if (v$.value.lastName.$dirty && v$.value.lastName.required && v$.value.lastName.$error) {
    return t('errors.is_required', { field: t('form_fields.last_name') })
  }
  return ''
})

const passwordErrors = computed<string>(() => {
  if (v$.value.password.$dirty && v$.value.password.$error) {
    return t('errors.does_not_respect_password_rules', { field: t('form_fields.password') })
  }
  return ''
})

function closeDialog () {
  resetUser()
  v$.value.$reset()
  emit('change', false)
}

function resetUser () {
  userData.address = ''
  userData.companyRoles = []
  userData.email = ''
  userData.firstName = ''
  userData.lastName = ''
  userData.password = ''
  userData.phone = ''
  userData.projectRoles = []
  userData.showPassword = false
  userData.submittable = false
}

type ValidationTargets = {
  email: typeof v$.value.email,
  firstName: typeof v$.value.firstName,
  lastName: typeof v$.value.lastName,
  password: typeof v$.value.password
}

function updateValidation (validationTarget: ValidationTargets[keyof ValidationTargets]) {
  validationTarget.$touch()
  userData.submittable = !v$.value.$invalid
}

async function saveUser () {
  if (isEdit.value) {
    await updateUser()
  } else {
    await createUser()
  }
}

async function createUser () {
  const newUser: NewUser = {
    // TODO: Currently the GET/PUT/POST /company endpoint is missing the `address` field
    // address: userData.address,
    email: userData.email,
    firstName: userData.firstName,
    lastName: userData.lastName,
    password: userData.password,
    phone: userData.phone,
  }

  emit('create-user', {
    companyRoles: userData.companyRoles,
    projectRoles: updatedProjectRoles.value.map((projectRole: ProjectRoleId) => projectRole.roleId),
    user: newUser,
  })
}

async function updateUser () {
  const updateUser: UpdateUser = {
    // TODO: Currently the GET/PUT/POST /company endpoint is missing the `address` field
    // address: userData.address,
    email: userData.email,
    firstName: userData.firstName,
    lastName: userData.lastName,
    phone: userData.phone,
  }

  const hasUserDataChanged: boolean = userData.firstName !== props.user?.firstName ||
    userData.lastName !== props.user?.lastName ||
    userData.phone !== props.user?.phone

  emit('update-user', {
    companyRoles: userData.companyRoles,
    projectRoles: updatedProjectRoles.value.map((projectRole: ProjectRoleId) => projectRole.roleId),
    user: hasUserDataChanged ? updateUser : null,
  })
}

function onProjectRolesChanged (projectRoles: ProjectRoleId[]) {
  updatedProjectRoles.value = projectRoles
}

defineExpose({
  userData,
})
</script>

<template>
  <v-dialog
    :model-value="value"
    max-width="600px"
    scrollable
    width="85%"
    @click:outside="closeDialog"
    @keydown.esc.prevent="closeDialog"
  >
    <form
      data-testid="create-user-form"
      @submit.prevent="saveUser()"
    >
      <v-card>
        <v-card-title class="text-h5">
          <div class="d-flex flex-row align-center justify-space-between tw-w-full">
            <span data-testid="user-form-dialog-title">{{ isEdit ? t('edit_user') : t('create_new_user') }}</span>
            <v-btn
              icon
              size="small"
              @click="closeDialog"
            >
              <v-icon size="x-small">
                fa:far fa-xmark-large
              </v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userData.firstName"
                autofocus
                data-testid="create-user-form-first-name"
                color="primary-darken2"
                data-hj-suppress
                :disabled="props.loading"
                :error-messages="firstNameErrors"
                hide-details="auto"
                :label="t('form_fields.first_name')"
                variant="filled"
                required
                @blur="updateValidation(v$.firstName)"
                @update:model-value=" updateValidation(v$.firstName)"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userData.lastName"
                data-testid="create-user-form-last-name"
                data-hj-suppress
                :disabled="props.loading"
                color="primary-darken2"
                :error-messages="lastNameErrors"
                hide-details="auto"
                :label="t('form_fields.last_name')"
                variant="filled"
                required
                @blur="updateValidation(v$.lastName)"
                @update:model-value="updateValidation(v$.lastName)"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userData.email"
                append-inner-icon="fa:fas fa-envelope"
                data-testid="create-user-form-email"
                data-hj-suppress
                color="primary-darken2"
                :disabled="isEdit || props.loading "
                :error-messages="emailErrors"
                hide-details="auto"
                :label="t('form_fields.email')"
                variant="filled"
                required
                type="email"
                @blur="updateValidation(v$.email)"
                @update:model-value="updateValidation(v$.email)"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-tooltip location="top">
                <template #activator="{ props: tooltipProps }">
                  <v-text-field
                    v-model="userData.password"
                    :append-inner-icon="userData.showPassword ? 'fa:fas fa-eye-slash' : 'fa:fas fa-eye'"
                    autocomplete="new-password"
                    data-testid="create-user-form-password"
                    :disabled="isEdit || props.loading"
                    color="primary-darken2"
                    :error-messages="passwordErrors"
                    hide-details="auto"
                    :label="t('form_fields.password')"
                    variant="filled"
                    required
                    :type="userData.showPassword ? 'text' : 'password'"
                    v-bind="tooltipProps"
                    @blur="updateValidation(v$.password)"
                    @click:append="userData.showPassword = !userData.showPassword"
                    @update:model-value="updateValidation(v$.password)"
                  />
                </template>
                <div>
                  {{ t('field_requirements.password.intro', { field: t('form_fields.password') }) }}
                  <ul>
                    <li>{{ t('field_requirements.password.number_of_characters') }}</li>
                    <li>{{ t('field_requirements.password.casing') }}</li>
                    <li>{{ t('field_requirements.password.digit') }}</li>
                    <li>{{ t('field_requirements.password.special') }}</li>
                  </ul>
                </div>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="userData.address"
                append-inner-icon="fa:fas fa-map-marker"
                color="primary-darken2"
                autocomplete="street-address"
                data-testid="create-user-form-address-work"
                data-hj-suppress
                :disabled="true || props.loading"
                hide-details="auto"
                :label="t('form_fields.address')"
                variant="filled"
              />
            </v-col>
          </v-row>

          <v-row class="mb-3">
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="userData.phone"
                append-inner-icon="fa:fas fa-phone"
                autocomplete="tel"
                color="primary-darken2"
                data-testid="create-user-form-phone"
                data-hj-suppress
                :disabled="props.loading"
                hide-details="auto"
                :label="t('form_fields.phone')"
                variant="filled"
                type="tel"
              />
            </v-col>
          </v-row>

          <UserRolesEditor
            :loading="props.loading"
            :company-roles="userData.companyRoles"
            :company-roles-from-store="props.companyRoles"
            :project-roles="usersProjectRoleIds"
            :projects="props.projects"
            @company-roles-changed="userData.companyRoles = $event"
            @project-roles-changed="onProjectRolesChanged"
          />
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                class="tw-w-full"
                color="primary-darken2"
                variant="text"
                @click="closeDialog"
              >
                {{ t('actions.cancel') }}
                <v-icon
                  end
                  size="medium"
                >
                  fa:far fa-times
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                class="tw-w-full"
                color="primary-darken2"
                data-testid="user-form-save-button"
                :disabled="!userData.submittable"
                :loading="props.loading"
                type="submit"
                variant="elevated"
              >
                {{ t('actions.save') }}
                <v-icon
                  end
                  size="medium"
                >
                  fa:far fa-check
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<i18n lang="json" locale="de">
{
  "create_new_user": "Neuen Nutzer anlegen",
  "edit_user": "Nutzer bearbeiten"
}
</i18n>
<i18n lang="json" locale="en">
{
  "create_new_user": "Create a new user",
  "edit_user": "Edit user"
}
</i18n>
