<script lang="ts">
import { computed } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export type ActionMenuItem = {
  disabled?: boolean,
  divider?: boolean,
  download?: string,
  href?: string,
  icon?: string,
  id: string,
  label: string,
  target?: string,
  to?: RouteLocationRaw,
};
</script>

<script setup lang="ts" generic="CustomEventData = never">
type Props = {
  customEventData?: CustomEventData,
  disabled?: boolean,
  items: ActionMenuItem[],
  left?: boolean,
  offsetDirection?: 'x' | 'y'
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  offsetDirection: 'x',
})

const emit = defineEmits<{
  (e: `${string}-option-click`, item: CustomEventData): void
}>()

const shouldActivatorBeDisabled = computed(() => {
  return props.disabled || props.items.length === 0
})
</script>

<template>
  <v-menu
    content-class="aedifion-box-shadow"
    :disabled="shouldActivatorBeDisabled"
    :location="props.left ? 'left' : 'bottom'"
  >
    <template #activator="{ props: menuProps, value }">
      <slot
        :disabled="shouldActivatorBeDisabled"
        v-bind="menuProps"
        :is-open="value"
      />
    </template>
    <v-list density="compact">
      <template
        v-for="item of props.items"
        :key="item.id"
      >
        <v-divider
          v-if="item.divider"
          :key="`${item.id}-divider`"
          class="my-2 border-opacity-100"
        />
        <v-list-item
          class="text-cta list-item__component"
          :data-testid="`${item.id}-option`"
          :disabled="item.disabled"
          :download="item.download"
          :href="item.href"
          link
          :target="item.target"
          :to="item.to"
          @click="emit(`${item.id}-option-click`, customEventData)"
        >
          <v-icon
            v-if="item.icon"
            class="mr-4 list-item__icon"
            size="14"
          >
            {{ item.icon }}
          </v-icon>
          <span class="list-item__text text-no-wrap">
            {{ item.label }}
          </span>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<style lang="sass" scoped>
  .list-item__component:not(.v-list-item--disabled)
    &:hover
      background-color: rgb(var(--v-theme-primary-lighten4))
      .list-item__text,.list-item__icon
        color: rgb(var(--v-theme-primary-darken2))

    .list-item__text
      color: rgb(var(--v-theme-neutral-darken3))
</style>
