export default [
  'AHU',
  'AHU_FIL',
  'AHU_HUM_CODI',
  'B',
  'BOI',
  'C_CM',
  'C_CRC',
  'CH_COMP',
  'CHP',
  'CM',
  'ELE',
  'ELM',
  'FAN',
  'GASM',
  'H_CM',
  'H_CRC',
  'H_TRA_ST',
  'HM',
  'HP',
  'HUM',
  'PLNT_C_RE',
  'ROOM',
  'S',
  'STO_TH',
  'TH_CTRL_LOO',
  'WSM',
  'WST'
] as string[]
