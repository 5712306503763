import { addTagToTagsList, removeTagFromTagsList, updateTagInTagsList } from '@/utils/helpers/tags'
import { DatapointIDAndTimeseries, DatapointOverviewItem, DataPointsViewState, DateRangeObject, Samplerate, TagItem } from './types'
import { TagAddPayload, TagRemovedPayload, TagUpdatedPayload } from '../datapoints/types'
import { DataPointWithContext } from '@aedifion.io/aedifion-api'
import { MutationTree } from 'vuex'

export default {
  ADD_DATAPOINT_TIMESERIES: (state: DataPointsViewState, payload: DatapointIDAndTimeseries) => {
    if (!state.selectedDatapointsHashIds.includes(payload.hashID)) {
      return
    }

    const foundDatapointIndex: number = state.datapointTimeseries.findIndex((datapointWithTimeseries: DatapointIDAndTimeseries) =>
      datapointWithTimeseries.dataPointID === payload.dataPointID)
    if (foundDatapointIndex >= 0) {
      state.datapointTimeseries[foundDatapointIndex].timeseries = payload.timeseries
    } else {
      state.datapointTimeseries.push(payload)
    }
  },

  ADD_DATAPOINT_TO_OVERVIEW: (state: DataPointsViewState, payload: DatapointOverviewItem) => {
    if (!state.selectedDatapointsHashIds.includes(payload.hashID)) {
      return
    }

    const foundDatapointIndex: number = state.datapointsOverview.findIndex((datapointOverviewItem: DatapointOverviewItem) =>
      datapointOverviewItem.dataPointID === payload.dataPointID,
    )
    if (foundDatapointIndex === -1) {
      state.datapointsOverview.push(payload)
    }
  },

  ADD_HASH_ID: (state: DataPointsViewState, datapointHashId: string) => (state.selectedDatapointsHashIds.push(datapointHashId)),

  ADD_TAG_FILTER: (state: DataPointsViewState, tagFilter: TagItem) => (state.tagFilters.push(tagFilter)),

  ADD_TAG_TO_OVERVIEW_ITEM: (state: DataPointsViewState, payload: TagAddPayload) => {
    // add tag to datapoints list item
    for (const datapointOverviewItem of state.datapointsOverview) {
      if (datapointOverviewItem.dataPointID === payload.dataPointID) {
        if (!datapointOverviewItem.tags) {
          datapointOverviewItem.tags = []
        }
        datapointOverviewItem.tags = addTagToTagsList(payload.tag, datapointOverviewItem.tags)
        break
      }
    }
  },

  REMOVE_ALL_SELECTED_HASH_IDS: (state: DataPointsViewState) => {
    state.selectedDatapointsHashIds = []
  },

  REMOVE_ALL_TIMESERIES: (state: DataPointsViewState) => {
    state.datapointTimeseries = []
  },

  REMOVE_DATAPOINT_FROM_OVERVIEW: (state: DataPointsViewState, datapointHashID: string) => {
    const foundIndex: number = state.datapointsOverview.findIndex((datapointOverviewItem: DatapointOverviewItem) => datapointOverviewItem.hashID === datapointHashID)
    if (foundIndex >= 0) state.datapointsOverview.splice(foundIndex, 1)
  },

  REMOVE_HASH_ID: (state: DataPointsViewState, datapointHashId: string) => {
    const indexOfRemovingHash: number = state.selectedDatapointsHashIds.findIndex((hash: string) => hash === datapointHashId)
    if (indexOfRemovingHash >= 0) {
      state.selectedDatapointsHashIds.splice(indexOfRemovingHash, 1)
    }
  },

  REMOVE_TAG_FILTER: (state: DataPointsViewState, tagFilter: TagItem) => {
    const indexToRemove: number = state.tagFilters.findIndex((currentItem: TagItem) => {
      return currentItem.key === tagFilter.key && currentItem.value === tagFilter.value
    })
    if (indexToRemove >= 0) {
      state.tagFilters.splice(indexToRemove, 1)
    }
  },

  REMOVE_TAG_FROM_OVERVIEW_ITEM: (state: DataPointsViewState, payload: TagRemovedPayload) => {
    for (const dpOverviewItem of state.datapointsOverview) {
      if (payload.dataPointID === dpOverviewItem.dataPointID && dpOverviewItem.tags) {
        dpOverviewItem.tags = removeTagFromTagsList(payload.tagId, dpOverviewItem.tags)
        break
      }
    }
  },

  REMOVE_TIMESERIES: (state: DataPointsViewState, hashID: string) => {
    const indexOfDatapoint: number = state.datapointTimeseries.findIndex((datapointAndTimeseries: DatapointIDAndTimeseries) => {
      return datapointAndTimeseries.hashID === hashID
    })
    if (indexOfDatapoint >= 0) {
      state.datapointTimeseries.splice(indexOfDatapoint, 1)
    }
  },

  SET_DATE_RANGE: (state: DataPointsViewState, dateRange: [string, string?]) => (state.dateRange = dateRange),

  SET_FAVORITES_FILTER: (state: DataPointsViewState, enabled: boolean) => (state.favoritesFilterSet = enabled),

  SET_LIVE_VIEW_RANGE: (state: DataPointsViewState, range: DateRangeObject|null) => (state.liveViewRange = range),

  SET_LIVE_VIEW_STATUS: (state: DataPointsViewState, active: boolean) => (state.liveViewActive = active),

  SET_LOADING_DATAPOINT_TIMESERIES: (state: DataPointsViewState, loading: boolean) => (state.loadingDatapointsTimeseries = loading),

  SET_SAMPLERATE: (state: DataPointsViewState, samplerate: Samplerate) => (state.samplerate = samplerate),

  SET_SEARCH: (state: DataPointsViewState, search: string) => (state.search = search),

  SET_SETPOINT_DATAPOINT: (state: DataPointsViewState, datapoint: DataPointWithContext|null) => {
    state.setpointDatapoint = datapoint
  },

  SET_SETPOINTS_DATAPOINT_ID: (state: DataPointsViewState, datapointId: string|null) => {
    state.setpointId = datapointId
  },

  SET_TAG_FILTERS: (state: DataPointsViewState, tagFilters: TagItem[]) => (state.tagFilters = tagFilters),

  SET_WRITABLE_FILTER: (state: DataPointsViewState, enabled: boolean) => (state.writableFilterSet = enabled),

  SET_ZOOM: (state: DataPointsViewState, zoom: DateRangeObject|null) => (state.zoom = zoom),

  TOGGLE_TIMESERIES_VISIBILITY: (state: DataPointsViewState, datapointIndex: Record<'dpIndexForTimeSeries'|'dpIndexForOverview', number>) => {
    if (state.datapointTimeseries[datapointIndex.dpIndexForTimeSeries] !== undefined && state.datapointsOverview[datapointIndex.dpIndexForOverview] !== undefined) {
      state.datapointTimeseries[datapointIndex.dpIndexForTimeSeries].visible = !state.datapointTimeseries[datapointIndex.dpIndexForTimeSeries].visible
      state.datapointsOverview[datapointIndex.dpIndexForOverview].visible = !state.datapointsOverview[datapointIndex.dpIndexForOverview].visible
    }
  },

  UPDATE_TAG_VALUE: (state: DataPointsViewState, updateResponse: TagUpdatedPayload) => {
    const { dataPointID, oldTagId, newTag } = updateResponse
    for (const dpOverviewItem of state.datapointsOverview) {
      if (dpOverviewItem.dataPointID === dataPointID && dpOverviewItem.tags) {
        dpOverviewItem.tags = updateTagInTagsList(oldTagId, newTag, dpOverviewItem.tags)
        break
      }
    }
  },
} as MutationTree<DataPointsViewState>
