import { CompanyWithContextUsers, UpdateUser } from '@aedifion.io/aedifion-api'
import { computed, readonly, ref } from 'vue'
import { ProjectRoleId, RolesUpdateDescriptor, useAdministrationUsersStore } from '@/stores/views/Administration/users'
import { getProjectRoleIds } from '@/utils/helpers/roles'
import { useAdministrationStore } from '@/stores/views/Administration'

type UpdateUserPayload = {
  companyRoles: number[],
  projectRoles: number[],
  user: CompanyWithContextUsers|null
}

export function useEditUser () {
  const administrationUsersStore = useAdministrationUsersStore()
  const administrationStore = useAdministrationStore()
  const idOfUserBeingEdited = ref<number|null>(null)
  const userBeingEdited = computed<CompanyWithContextUsers|null>(() => {
    return administrationStore.users.find((user) => {
      return user.id === idOfUserBeingEdited.value
    }) ?? null
  })
  const isUserFormDialogOpen = ref(false)

  async function updateUser (payload: UpdateUserPayload): Promise<void> {
    const updatingUser: UpdateUser = {
      ...payload.user,
      email: undefined,
    }

    const updateRequests: Promise<void|boolean>[] = []

    if (payload.user !== null) {
      updateRequests.push(administrationUsersStore.updateUser({
        user: updatingUser,
        userId: userBeingEdited.value!.id!,
      }))
    }

    const updatedCompanyRoles: RolesUpdateDescriptor<number> = administrationUsersStore.computeRolesUpdatePayload(userBeingEdited.value!.company_roles!, payload.companyRoles)

    updateRequests.push(administrationUsersStore.updateCompanyRoles(updatedCompanyRoles, userBeingEdited.value!.id!))

    const updatedProjectRoles: RolesUpdateDescriptor<ProjectRoleId> = administrationUsersStore.computeRolesUpdatePayload(
      getProjectRoleIds(administrationStore.projects, userBeingEdited.value!.project_roles!),
      getProjectRoleIds(administrationStore.projects, payload.projectRoles),
    )

    updateRequests.push(administrationUsersStore.updateProjectRoles(updatedProjectRoles, userBeingEdited.value!.id!))

    isUserFormDialogOpen.value = false

    await Promise.allSettled(updateRequests)
  }

  function handleEditUser (userId: number): void {
    setIdOfEditedUser(userId)
    toggleUserFormDialogState()
  }

  function toggleUserFormDialogState (): void {
    isUserFormDialogOpen.value = !isUserFormDialogOpen.value
  }

  function setIdOfEditedUser (userId: number): void {
    idOfUserBeingEdited.value = userId
  }

  function clearEditedUser (): void {
    idOfUserBeingEdited.value = null
  }

  return {
    clearEditedUser,
    handleEditUser,
    idOfUserBeingEdited,
    isUserFormDialogOpen: readonly(isUserFormDialogOpen),
    toggleUserFormDialogState,
    updateUser,
    userBeingEdited,
  }
}
