<script setup lang="ts">
import { computed, ref } from 'vue'
import { ControlsApp, ControlsAppPin } from '@aedifion.io/aedifion-api'
import LabelValueCard from '@/components/LabelValueCard.vue'
import { storeToRefs } from 'pinia'
import { useAIControlsAppPinStore } from '@/stores/views/AIControls/App/pin'
import { useUserStore } from '@/stores/user'

interface Props {
  collection: 'input' | 'output'
  controlsApp?: ControlsApp
}

defineProps<Props>()

const aiControlsAppPinStore = useAIControlsAppPinStore()
const { currentPinValues } = storeToRefs(aiControlsAppPinStore)
const userStore = useUserStore()

function getPinName (pin: ControlsAppPin): string {
  return pin[`name${userStore.userDetails?.locale?.toUpperCase()}` as keyof ControlsAppPin] || ''
}

const cardComponents = ref<InstanceType<typeof LabelValueCard>[]>([])
const cards = computed<HTMLElement[]>(() => cardComponents.value.map((val) => val.$el))

defineExpose({ cards })
</script>

<template>
  <div>
    <div v-if="controlsApp">
      <LabelValueCard
        v-for="(pin, index) of controlsApp?.pins?.[collection]"
        :key="index"
        ref="cardComponents"
        class="tw-mb-4"
        :label="getPinName(pin)"
        :value="currentPinValues[pin.dataPointID]?.value"
        :unit-symbol="currentPinValues[pin.dataPointID]?.unitSymbol"
      />
    </div>
    <div v-else>
      <LabelValueCard
        v-for="index in 4"
        :key="index"
        ref="cardComponents"
        class="tw-mb-4"
      />
    </div>
  </div>
</template>
