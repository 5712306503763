<script setup lang="ts">
import { computed, onUnmounted, ref } from 'vue'
import { DataPointWithContext, SetpointReference } from '@aedifion.io/aedifion-api'
import { formatDateTime } from '@/filters/formatting'
import { InvalidValueError } from '@/utils/helpers/validate'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { useI18n } from 'vue-i18n'
import { useSetpointEditorStore } from '@/stores/views/setpointEditor'
import { useStore } from '@/vuex'

interface SetpointTableItem {
  status: string,
  time: Date,
  user: string,
  value: string
}

interface Props {
  datapoint: DataPointWithContext,
  value?: boolean
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  value: true,
})

const emits = defineEmits<{
  'setpoint-editor:close': []
}>()

const { t } = useI18n()
const store = useStore()
const setpointEditorStore = useSetpointEditorStore()

const historyItemsPerPage = ref(10)
const historyShownOnce = ref(false)
const newSetpoint = ref('')
const setpointPollingTimeoutId = ref<ReturnType<typeof setTimeout> | null>(null)
const setpointHistoryHeaders = ref([
  { title: t('history.header.timestamp'), value: 'time' },
  { title: t('history.header.user'), value: 'user' },
  { title: t('history.header.value'), value: 'value' },
  { title: t('history.header.status'), value: 'status' },
])

const currentHistoryPage = ref(1)

function setHistoryItemsPerPage (itemsPerPage: number): void {
  if (itemsPerPage < 1) {
    itemsPerPage = totalHistoryItems.value!
  }
  historyItemsPerPage.value = itemsPerPage
  fetchSetpointHistory(1)
}

const currentManualSetpoint = computed<SetpointReference|null>(() => {
  return setpointEditorStore.currentManualSetpoint
})

const currentSetpointValue = computed<string|null>(() => {
  return setpointEditorStore.currentSetpointValue
})

const hasSetpoint = computed<boolean>(() => {
  return currentManualSetpoint.value !== null && currentManualSetpoint.value.value !== null && currentManualSetpoint.value.value !== 'null'
})

const loadingCurrentSetpoint = computed<boolean>(() => {
  return setpointPollingTimeoutId.value === null && setpointEditorStore.loadingCurrentSetpointValue
})

const loadingSetpointHistory = computed<boolean>(() => {
  return setpointPollingTimeoutId.value === null && setpointEditorStore.loadingSetpointHistory
})

const setpointHistory = computed<SetpointTableItem[]>(() => {
  const storedSetpoints: SetpointReference[] = setpointEditorStore.setpointHistory?.setpoints ?? []
  return storedSetpoints.map((item: SetpointReference) => {
    return {
      status: item.status,
      time: item.time,
      user: item.user,
      value: item.value,
    } as SetpointTableItem
  })
})

const setpointMaximum = computed<number>(() => store.getters['datapoints/setpointRange'](props.datapoint.dataPointID)[1])
const setpointMinimum = computed<number>(() => store.getters['datapoints/setpointRange'](props.datapoint.dataPointID)[0])
const setpointRange = computed<string>(() => `${setpointMinimum.value} - ${setpointMaximum.value}`)
const setpointValueIsWritable = computed<boolean>(() => newSetpoint.value.length > 0 && setpointValueIsValid(newSetpoint.value))

const totalHistoryItems = computed<number|null>(() => {
  return setpointEditorStore.setpointHistory?.meta?.total_items || 0
})

const unit = computed<string>(() => {
  for (const tag of props.datapoint.tags || []) {
    if (tag.source === 'bacnet' && tag.key === 'units') {
      return tag.value!
    }
  }
  return ''
})

function fetchCurrentManualSetpoint (): void {
  setpointEditorStore.fetchCurrentManualSetpoint(String(props.datapoint.dataPointID))
}

function fetchCurrentSetpointValue (): void {
  setpointEditorStore.fetchCurrentSetpointValue(String(props.datapoint.dataPointID))
}

function fetchSetpointHistory (page?: number): void {
  setpointEditorStore.fetchSetpointHistory(String(props.datapoint.dataPointID), historyItemsPerPage.value, page)
}

function onHistoryPaginationChanged (page: number): void {
  currentHistoryPage.value = page
  fetchSetpointHistory(page)
}

function onHistoryToggled (): void {
  if (!historyShownOnce.value) {
    fetchSetpointHistory(1)
    historyShownOnce.value = true
  }
}

async function releaseSetpoint (): Promise<void> {
  try {
    await setpointEditorStore.releaseSetpoint(String(props.datapoint.dataPointID))
    updateDisplayedSetpoints()
  } catch (error) {
    if (error instanceof InvalidValueError) {
      showErrorNotification(`${t('notifications.errors.no_project_selected_with_attempted_action', { action: t('to_release_setpoint') })}`)
    }
    reportError(error)
  }
}

function setpointStatusColor (status: string): string {
  switch (status) {
    case 'failed':
      return 'error'
    case 'initialized':
      return 'warning'
    case 'requested':
      return 'warning'
    case 'terminated':
      return 'neutral'
    case 'written':
      return 'success'
    default:
      throw new Error(`Unrecognized setpoint status: '${status}'`)
  }
}

function setpointValueIsValid (value: string): boolean {
  if (value && value.length > 0 && !isNaN(Number(value))) {
    const asNumber = Number(value)
    return asNumber >= setpointMinimum.value && asNumber <= setpointMaximum.value
  } else {
    return value.length === 0
  }
}

function updateDisplayedSetpoints (): void {
  if (historyShownOnce.value) {
    fetchSetpointHistory(currentHistoryPage.value)
  }
  fetchCurrentManualSetpoint()
}

async function writeSetpoint (): Promise<void> {
  try {
    await setpointEditorStore.writeSetpoint(String(props.datapoint.dataPointID), newSetpoint.value)
    updateDisplayedSetpoints()
    newSetpoint.value = ''
  } catch (error) {
    if (error instanceof InvalidValueError) {
      showErrorNotification(`${t('notifications.errors.no_project_selected_with_attempted_action', { action: t('to_write_setpoint') })}`)
    }
    reportError(error)
  }
}

function pollSetpointValues (): void {
  if (historyShownOnce.value) {
    fetchSetpointHistory(currentHistoryPage.value)
  }
  fetchCurrentManualSetpoint()
  fetchCurrentSetpointValue()
  setpointPollingTimeoutId.value = setTimeout(pollSetpointValues, 5000)
}

// --- execution ---

pollSetpointValues()

// --- lifecycle hooks ---

onUnmounted(() => {
  if (setpointPollingTimeoutId.value) {
    clearTimeout(setpointPollingTimeoutId.value)
  }
})
</script>

<template>
  <v-dialog
    max-width="800px"
    persistent
    :model-value="value"
  >
    <v-card>
      <v-card-title class="text-h5 pa-4">
        {{ t('title', { datapoint: datapoint.dataPointID }) }}
      </v-card-title>
      <v-card-subtitle>
        {{ datapoint.description }}
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col
            class="align-self-center"
            cols="3"
          >
            {{ t('setpoint_value_current') }}
          </v-col>
          <v-col cols="5">
            <v-text-field
              density="compact"
              variant="filled"
              hide-details
              :loading="loadingCurrentSetpoint"
              readonly
              :suffix="unit"
              :model-value="currentSetpointValue"
            />
          </v-col>
          <v-col
            class="align-self-center"
            cols="4"
          >
            <v-tooltip
              location="bottom"
              :disabled="hasSetpoint"
            >
              <template #activator="{ props: releaseTooltipProps }">
                <div v-bind="releaseTooltipProps">
                  <v-btn
                    block
                    color="primary-darken2"
                    data-cy="release-setpoint-button"
                    :disabled="loadingCurrentSetpoint || !hasSetpoint"
                    @click="releaseSetpoint"
                  >
                    {{ t('setpoint_release') }}
                  </v-btn>
                </div>
              </template>
              {{ t('setpoint_unreleasable') }}
            </v-tooltip>
          </v-col>
          <v-col
            class="align-self-center"
            cols="3"
          >
            {{ t('setpoint_value_new') }}
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="newSetpoint"
              data-cy="new-setpoint-input"
              density="compact"
              hide-details
              :label="setpointRange"
              variant="outlined"
              :rules="[setpointValueIsValid]"
              :suffix="unit"
            />
          </v-col>
          <v-col
            class="align-self-center"
            cols="4"
          >
            <v-btn
              block
              color="primary-darken2"
              data-cy="write-setpoint-button"
              :disabled="!setpointValueIsWritable"
              @click="writeSetpoint"
            >
              {{ t('setpoint_write') }}
            </v-btn>
          </v-col>
          <v-col
            class="px-2"
            cols="12"
          >
            <v-expansion-panels flat>
              <v-expansion-panel
                data-cy="history-panel"
                @group:selected="onHistoryToggled"
              >
                <v-expansion-panel-title class="text-h6 px-3">
                  {{ t('history.title') }}
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-data-table-server
                    data-cy="history-table"
                    density="compact"
                    :headers="setpointHistoryHeaders"
                    :items="setpointHistory"
                    :items-per-page="historyItemsPerPage"
                    :page="currentHistoryPage"
                    :loading="loadingSetpointHistory"
                    :items-length="totalHistoryItems"
                    @update:items-per-page="$event => setHistoryItemsPerPage($event)"
                    @update:page="$event => onHistoryPaginationChanged($event)"
                  >
                    <template #item.user="{ item }">
                      {{ item.user.substring(0, item.user.indexOf('(')) }}
                    </template>
                    <template #item.time="{ item }">
                      {{ formatDateTime(item.time) }}
                    </template>
                    <template #item.status="{ item }">
                      <v-avatar
                        class="mr-2"
                        :color="setpointStatusColor(item.status)"
                        rounded-circle
                        size="1.2rem"
                      />
                      {{ t(`history.status.${item.status}`) }}
                    </template>
                    <template #item.value="{ item }">
                      {{ item.value === "null" ? t('setpoint_released') : item.value }}
                    </template>
                  </v-data-table-server>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-auto"
          color="primary-darken2"
          variant="text"
          @click="emits('setpoint-editor:close')"
        >
          {{ t('actions.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<!-- Added this eslint ignore because the locales are actually being used, but the eslint plugin cannot detect dynamic locales -->
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n lang="json" locale="de">
  {
    "history": {
      "header": {
        "status": "Status",
        "timestamp": "Zeit",
        "user": "Benutzer",
        "value": "Wert"
      },
      "status": {
        "failed": "Fehlgeschlagen",
        "initialized": "Initialisiert",
        "requested": "Angefragt",
        "terminated": "Abgebrochen",
        "written": "Geschrieben"
      },
      "title": "Historie"
    },
    "setpoint_release": "Freigeben",
    "setpoint_released": "Freigegeben",
    "setpoint_unreleasable": "Sollwert nicht über Plattfrom geschrieben",
    "setpoint_value_current": "Aktueller Sollwert",
    "setpoint_value_new": "Neuer Sollwert",
    "setpoint_write": "Schreiben",
    "title": "{datapoint} - Sollwert schreiben",
    "to_release_setpoint": "um den Sollwert freizugeben",
    "to_write_setpoint": "um den Sollwert zu schreiben"
  }
  </i18n>
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
  <i18n lang="json" locale="en">
  {
    "history": {
      "header": {
        "status": "Status",
        "timestamp": "Time",
        "user": "User",
        "value": "Value"
      },
      "status": {
        "failed": "Failed",
        "initialized": "Initialized",
        "requested": "Requested",
        "terminated": "Terminated",
        "written": "Written"
      },
      "title": "History"
    },
    "setpoint_release": "Release",
    "setpoint_released": "Released",
    "setpoint_unreleasable": "Setpoint not written via platform",
    "setpoint_value_current": "Current setpoint",
    "setpoint_value_new": "New setpoint",
    "setpoint_write": "Write",
    "title": "{datapoint} - Write setpoint",
    "to_release_setpoint": "to release the setpoint",
    "to_write_setpoint": "to write the setpoint"
  }
  </i18n>
