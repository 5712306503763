import { FileDescriptor, FilesState } from './types'
import { GetterTree } from 'vuex'
import { RootState } from '../types'

export default {
  hasElevateReport: (state: FilesState): boolean => {
    return state.elevateReports !== null && state.elevateReports.length > 0
  },

  pathToLatestElevateReport: (state: FilesState): string|null => {
    if (state.elevateReports === null || state.elevateReports.length === 0) {
      return null
    } else {
      const sortedFiles = [...state.elevateReports].sort((a: FileDescriptor, b: FileDescriptor) => {
        return a.path < b.path ? 1 : -1
      })
      return sortedFiles[0].path
    }
  },
} as GetterTree<FilesState, RootState>
