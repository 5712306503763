<script setup lang="ts">
import { formatSubAndSuper, formatValue } from '@/filters/formatting'
import { getCo2Color, getEnergyColor } from './composables'
import { computed } from 'vue'
import DateOrMonthPicker from '@/components/DateOrMonthPicker.vue'
import EnergyAndCO2Chart from './EnergyAndCO2Chart.vue'
import { getVuetifyColorClassFromSemanticColor } from '@/filters/colors'
import { onInitialApiRequestsCompleted } from '@/utils/helpers/hooks'
import PageHeader from '@/components/PageHeader.vue'
import { useEnergyAndCO2Store } from '@/stores/views/energyAndCO2'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

// --- definition ---

const { t } = useI18n()
const store = useStore()

const energyAndCO2Store = useEnergyAndCO2Store()

const co2Kpi = computed<number|null>(() => {
  if (energyAndCO2Store.co2KpiAbsoluteAggregation?.portfolio_result?.aggregation_value === undefined) return null
  return energyAndCO2Store.co2KpiAbsoluteAggregation.portfolio_result.aggregation_value / energyAndCO2Store.portfolioNetFloorArea
})
const co2KpiUnit = computed<string>(() => {
  return store.getters['labels/label']('units', 'kilograms-per-square-meter-per-year')?.symbol as string|undefined ?? ''
})
const co2ColorClass = computed<'error'|'success'|'warning'|'neutral'>(() => {
  const co2Color = getCo2Color(co2Kpi.value ?? null)
  return getVuetifyColorClassFromSemanticColor(co2Color)
})

const energyKpi = computed<number|null>(() => {
  if (energyAndCO2Store.energyKpiAbsoluteAggregation?.portfolio_result?.aggregation_value === undefined) return null
  return energyAndCO2Store.energyKpiAbsoluteAggregation.portfolio_result.aggregation_value / energyAndCO2Store.portfolioNetFloorArea
})
const energyKpiUnit = computed<string>(() => {
  return store.getters['labels/label']('units', 'kilowatt-hours-per-square-meter-per-year')?.symbol as string|undefined ?? ''
})
const energyColorClass = computed<'error'|'success'|'warning'|'neutral'>(() => {
  const energyColor = getEnergyColor(energyKpi.value ?? null)
  return getVuetifyColorClassFromSemanticColor(energyColor)
})

const isLoading = computed<boolean>(() => {
  return energyAndCO2Store.loading
})

// --- execution ---

// this forces the loading state to true while the initial requests are running;
// otherwise there would be a flash of empty state before loading starts
energyAndCO2Store.loading = true

onInitialApiRequestsCompleted(() => {
  energyAndCO2Store.fetchKpiAggregations()
})
</script>

<template>
  <div class="layout-wrapper">
    <PageHeader
      title-key="links.meta.title.energy_and_co2"
    >
      <DateOrMonthPicker
        class="ml-auto"
        disabled
        :menu="false"
        :text="t('dates.time_period')"
        :width="240"
      >
        <template #date-indicator>
          <span class="text-cta text-neutral-darken3">
            {{ t('last_12_months') }}
          </span>
        </template>
      </DateOrMonthPicker>
    </PageHeader>
    <div
      v-if="isLoading || co2Kpi || energyKpi"
      class="d-flex mb-8"
    >
      <div class="d-flex flex-column mr-6">
        <span
          v-if="!isLoading"
          :class="`text-${co2ColorClass}-darken2`"
          data-cy="energy-and-co2-portfolio-co2-kpi"
        >
          <span class="text-h3">{{ formatValue(co2Kpi, { displayUnit: false, fallbackValue: 'N/A', unit: co2KpiUnit }) }}</span>
          <span
            class="text-body-1"
            v-html="formatSubAndSuper(` ${co2KpiUnit}`)"
          />
        </span>
        <span
          v-else
          class="d-flex align-end mb-1"
        >
          <v-skeleton-loader
            class="mr-3 bg-transparent overflow-hidden"
            type="image"
            width="60"
            height="32"
          />
          <v-skeleton-loader
            class="bg-transparent overflow-hidden"
            type="image"
            width="45"
            height="18"
          />
        </span>
        <span class="text-body-1 text-neutral-darken2">{{ t('portfolio_co2_intensity') }}</span>
      </div>
      <div class="d-flex flex-column">
        <span
          v-if="!isLoading"
          :class="`text-${energyColorClass}-darken2`"
          data-cy="energy-and-co2-portfolio-energy-kpi"
        >
          <span class="text-h3">{{ formatValue(energyKpi, { displayUnit: false, fallbackValue: 'N/A', unit: energyKpiUnit }) }}</span>
          <span
            class="text-body-1"
            v-html="formatSubAndSuper(` ${energyKpiUnit}`)"
          />
        </span>
        <span
          v-else
          class="d-flex align-end mb-1"
        >
          <v-skeleton-loader
            class="mr-3 bg-transparent overflow-hidden"
            type="image"
            width="60"
            height="32"
          />
          <v-skeleton-loader
            class="bg-transparent overflow-hidden"
            type="image"
            width="45"
            height="18"
          />
        </span>
        <span class="text-body-1 text-neutral-darken2">{{ t('portfolio_energy_intensity') }}</span>
      </div>
    </div>
    <EnergyAndCO2Chart />
  </div>
</template>

<i18n locale="de">
{
  "last_12_months": "Letzte 12 Monate",
  "portfolio_co2_intensity": "CO₂-Intensität des Portfolios",
  "portfolio_energy_intensity": "Energieintensität des Portfolios",
}
</i18n>
<i18n locale="en">
{
  "last_12_months": "Last 12 months",
  "portfolio_co2_intensity": "Portfolio CO₂ intensity",
  "portfolio_energy_intensity": "Portfolio energy intensity",
}
</i18n>
