export function getEmptyColumnsCount (rowWidth: number, columnWidth: number, numberOfElements: number): number {
  if (!numberOfElements) {
    return 0
  }

  const acceptableResidual = 0.1
  const columnsPerRow: number = Math.floor((rowWidth / columnWidth) + acceptableResidual)
  const remainder: number = numberOfElements % columnsPerRow

  if (remainder > 0) {
    return columnsPerRow - remainder
  }

  return 0
}
