import { DatapointListItemData, DatapointWithContextAndScore } from '@/vuex/datapoints/types'
import { DataPointWithContext, TagAssociation } from '@aedifion.io/aedifion-api'
import { DatapointOverviewItem } from '@/vuex/data_points_view/types'
import { getDescriptionTag } from '@/utils/helpers/tags'

export function convertDatapointToListItem (datapoint: DatapointWithContextAndScore, selectedDatapointsHashIds: string[], seriesColorMap: Map<string, string>): DatapointListItemData {
  const descriptionTag: TagAssociation|null = getDescriptionTag(datapoint?.tags || [])
  const selectedIndex: number = selectedDatapointsHashIds.findIndex((hash: string) => hash === datapoint.hash_id)
  return {
    description: descriptionTag?.value,
    hash_id: datapoint.hash_id,
    id: datapoint.dataPointID,
    isFavorite: datapoint.favorite ?? false,
    score: datapoint.score,
    selected: selectedIndex > -1,
    seriesColor: seriesColorMap.get(datapoint.hash_id!) as string,
    tags: datapoint?.tags || [],
    title: datapoint.dataPointID,
    writable: ('setpoint_min_value' in datapoint && 'setpoint_max_value' in datapoint),
  } as DatapointListItemData
}

/**
 * Returns the Datapoint IDs for the given hash IDs, by searching in the datapointsList and datapointsOverviewList.
 * Throws an error if a datapoint is not found for a given hash ID.
 * @param hashIds The passed hash IDs.
 * @param datapointsList The datapointsList from the store.
 * @param datapointsOverviewList The datapointsOverviewList from the store.
 * @returns The Datapoint IDs.
 */
export function getDatapointIdsByHashIds (hashIds: string[], datapointsList: DataPointWithContext[], datapointsOverviewList: DatapointOverviewItem[]): string[] {
  const datapointIds: string[] = []
  hashIds.forEach((hashId: string) => {
    let datapointId = datapointsList?.find((datapoint: DataPointWithContext) => datapoint.hash_id === hashId)?.dataPointID
    if (datapointId) {
      datapointIds.push(datapointId)
    } else {
      datapointId = datapointsOverviewList.find((datapoint: DatapointOverviewItem) => datapoint.hashID === hashId)?.dataPointID
      if (datapointId) {
        datapointIds.push(datapointId)
      }
    }

    if (!datapointId) {
      const error = new Error('data_points_view.fetchTimeseries has not found a datapoint for hashID: ' + hashId)
      throw error
    }
  })
  return datapointIds
}

/**
 * Returns a boolean representing whether the passed string would be the name of
 * a binary datapoint in BACnet.
 * @param name The string to test.
 * @returns Whether this name would be chosen by BACnet for a binary datapoint.
 */
export function isBinaryBacnetName (name: string) {
  // ends with BV, BI or BO and a number
  return /[-_]B[VIO]\d+$/.test(name)
}
