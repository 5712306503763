import * as Sentry from '@sentry/vue'
import { shouldReportToConsole } from '@/utils/helpers/errors'

export function registerOfflineDetection () {
  // TODO: notify the user when this happens?
  window.addEventListener('offline', () => {
    Sentry.addBreadcrumb({
      category: 'connection-status',
      level: 'warning',
      message: 'User’s internet connection was cut off',
      timestamp: Date.now(),
      type: 'info',
    })

    if (shouldReportToConsole()) {
      // eslint-disable-next-line no-console
      console.warn('internet connection lost')
    }
  })

  window.addEventListener('online', () => {
    Sentry.addBreadcrumb({
      category: 'connection-status',
      level: 'info',
      message: 'User’s internet connection was restored',
      timestamp: Date.now(),
      type: 'info',
    })

    if (shouldReportToConsole()) {
      // eslint-disable-next-line no-console
      console.info('internet connection restored')
    }
  })
}
