<i18n locale="de">
{
  "description": "Bitte gib einen Namen für die neue Komponente ein",
  "placeholder": "Name"
}
</i18n>
<i18n locale="en">
{
  "description": "Please enter a name for the new component",
  "placeholder": "Name"
}
</i18n>

<template>
  <v-row
    justify="center"
  >
    <v-col
      cols="12"
      lg="3"
      md="4"
      sm="6"
    >
      <ComponentCard
        :component-item="selectedComponent"
        disabled
      />
    </v-col>
    <v-col
      cols="12"
    >
      <v-row>
        <v-col cols="3" />
        <v-col cols="6 text-center">
          <span class="text-body-1">
            {{ t('description') }}
          </span>
          <v-text-field
            autofocus
            class="pt-0 pb-4 mt-3"
            data-cy="newComponentNameInput"
            hide-details
            variant="outlined"
            :placeholder="t('placeholder')"
            @update:model-value="setComponentNameToCreate"
          />
        </v-col>
        <v-col cols="3" />
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ComponentCard from '@/components/ComponentCard.vue'
import { ComponentCardItem } from '@/vuex/components_in_project/types'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'SelectNamePage',

  components: {
    ComponentCard,
  },

  props: {
    selectedComponent: {
      required: true,
      type: Object as PropType<ComponentCardItem|null>,
    },
  },

  emits: ['selectnamepage:name-selected'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  methods: {
    setComponentNameToCreate (name: string) {
      if (name !== '' && name.length > 0) {
        this.$emit('selectnamepage:name-selected', { name, status: true })
      } else {
        this.$emit('selectnamepage:name-selected', { name, status: false })
      }
    },
  },
})
</script>
