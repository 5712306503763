import { CompanyRole, Role } from '@aedifion.io/aedifion-api'
import { computed } from 'vue'
import { hasOneRoleAmong } from '@/utils/helpers/roles'
import { useUserStore } from '@/stores/user'
import vuexStore from '@/vuex'

export default function useIsReadOnly () {
  const userStore = useUserStore()

  const companyRoles = computed<CompanyRole[]>(() => userStore.companyRoles)
  const projectRoles = computed<Role[]>(() => userStore.projectRoles)
  const projectId = computed<number|undefined>(() => vuexStore.getters['projects/currentProjectId'])

  const isReadOnly = computed(() => !hasOneRoleAmong({
    companyRoles: ['admin'],
    projectId: projectId.value,
    projectRoles: ['admin'],
  }, companyRoles.value, projectRoles.value))

  return {
    isReadOnly,
  }
}
