<script setup lang="ts">
import { formatSubAndSuper, formatValue } from '@/filters/formatting'
import { computed } from 'vue'
import { SummaryKPIResult } from '@aedifion.io/aedifion-api'

interface Props {
  loading?: boolean,
  summaryKpi?: SummaryKPIResult
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
})

const currentUnit = computed(() => {
  const currentSummary = props.summaryKpi?.current
  if (currentSummary === undefined) {
    return null
  }

  return formatSubAndSuper(currentSummary.unit ?? '')
})

const currentValue = computed(() => {
  const currentSummary = props.summaryKpi?.current
  if (currentSummary === undefined || (!currentSummary.value && !currentSummary.unit)) {
    return null
  }

  return formatValue(currentSummary.value, {
    displayUnit: false,
    unit: currentSummary.unit,
  })
})
</script>

<template>
  <div class="pb-4">
    <div
      v-if="props.loading"
      class="mb-3 pt-2"
    >
      <v-skeleton-loader
        height="15"
        :loading="props.loading"
        type="text"
      />
      <v-skeleton-loader
        height="10"
        :loading="props.loading"
        max-width="60%"
        type="text"
      />
      <v-skeleton-loader
        class="mt-5"
        height="15"
        :loading="props.loading"
        type="text"
      />
    </div>
    <div v-else>
      <div
        v-if="currentValue"
        class="text-neutral-darken3"
      >
        <span class="text-h3 mr-2">{{ currentValue }}</span>
        <span v-html="currentUnit" />
      </div>
      <div
        v-if="!props.loading && props.summaryKpi && props.summaryKpi.current && props.summaryKpi.current.time_reference"
        class="text-subtitle-1 time-reference"
      >
        {{ props.summaryKpi.current.time_reference }}
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.time-reference
  margin-top: 2px
</style>
