<script setup lang="ts">
import type { Attribute } from '@/vuex/component_attributes_editor/types'
import ComponentAttributeBaseRow from './ComponentAttributeBaseRow.vue'

interface Props {
  attribute: Attribute
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'update:attribute': [ value: Attribute ]
}>()

function handleUpdateAttributeValue (newAttributeValue: string) {
  emits('update:attribute', {
    ...props.attribute,
    new_value: newAttributeValue.trim(),
  })
}
</script>

<template>
  <ComponentAttributeBaseRow
    :attribute="attribute"
    @update:attribute-value="handleUpdateAttributeValue"
  />
</template>
