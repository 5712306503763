<script setup lang="ts">
import { computed, ref } from 'vue'
import { ComponentPinCardData } from '@/vuex/mapping_editor/types'
import { DatapointListItemData } from '@/vuex/datapoints/types'
import { useI18n } from 'vue-i18n'

type Props = {
  loading?: boolean,
  pinData: ComponentPinCardData,
  selected?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  selected: false,
})

const emit = defineEmits<{
  (e: 'component-pin-card:click-datapoint', datapoint: DatapointListItemData): void,
  (e: 'component-pin-card:click-pin', id: number): void,
  (e: 'component-pin-card:map', id: number, dropData: string): void,
  (e: 'component-pin-card:replace', id: number, dropData: string): void,
  (e: 'component-pin-card:unmap', id: number, datapoint: DatapointListItemData): void
}>()

const { t } = useI18n()

const elementBeingDraggedOver = ref<EventTarget | null>(null)

const isMapped = computed(() => {
  return props.pinData.datapoint !== undefined
})

const isDragInProgress = computed(() => {
  return elementBeingDraggedOver.value !== null
})

function onDatapointSlotClicked (event: MouseEvent): void {
  if (isMapped.value) {
    event.stopPropagation()
    emit('component-pin-card:click-datapoint', props.pinData.datapoint!)
  }
}

function onDragEnter (event: DragEvent): void {
  if (event.dataTransfer && event.dataTransfer.types.includes('asidelist-item')) {
    elementBeingDraggedOver.value = event.target
  }
}

function onDragLeave (event: DragEvent): void {
  if (elementBeingDraggedOver.value === event.target) {
    elementBeingDraggedOver.value = null
  }
}

function onDrop (event: DragEvent): void {
  if (event.dataTransfer && event.dataTransfer.types.includes('asidelist-item')) {
    if (isMapped.value) {
      emit('component-pin-card:replace', props.pinData.id, event.dataTransfer.getData('asidelist-item'))
    } else {
      emit('component-pin-card:map', props.pinData.id, event.dataTransfer.getData('asidelist-item'))
    }
  }
  elementBeingDraggedOver.value = null
}
</script>

<template>
  <v-sheet
    v-ripple
    :class="['pin-card', {'aedifion-active': props.selected}, 'pa-2']"
    data-testid="component-pin-card"
    border
    rounded
    @click.stop="emit('component-pin-card:click-pin', props.pinData.id)"
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
    @dragover.prevent
    @drop.prevent="onDrop"
  >
    <span
      class="text-subtitle-2 pb-2"
    >
      {{ props.pinData.name }}
    </span>
    <v-sheet
      v-ripple="isMapped"
      :class="['py-1 d-flex align-center', isDragInProgress ? 'drag_over': (isMapped ? 'mapped-slot' : 'unmapped-slot')]"
      height="38px"
      border
      rounded
      @click="onDatapointSlotClicked"
    >
      <div
        v-if="props.loading"
        class="d-flex flex-row justify-center tw-w-full"
      >
        <v-progress-circular
          color="primary-darken2"
          indeterminate
          size="30"
          width="4"
        />
      </div>
      <div
        v-else-if="isDragInProgress"
        class="mx-auto"
      >
        <v-icon
          v-if="isMapped"
          color="warning"
          size="small"
        >
          fa:fas fa-exclamation-triangle
        </v-icon>
        <span
          class="text-body-1 font-italic text-center"
          v-text="isMapped ? t('drag_over_mapped_pin_message') : t('drag_over_empty_pin_message')"
        />
      </div>
      <div
        v-else-if="isMapped && !isDragInProgress"
        class="d-flex flex-row tw-justify-between tw-w-full"
        @click.stop="emit('component-pin-card:click-datapoint', props.pinData.datapoint)"
      >
        <div
          class="datapoint-color-indicator"
          :style="`border-color: ${props.pinData.datapoint.selected ? props.pinData.datapoint.seriesColor : 'transparent'}`"
        />
        <span
          class="align-self-center text-body-1 pl-2"
          v-text="props.pinData.datapoint.title"
        />
        <v-spacer />
        <v-btn
          icon
          size="24"
          @click.stop.prevent="emit('component-pin-card:unmap', props.pinData.id, props.pinData.datapoint)"
        >
          <v-icon size="small">
            fa:far fa-times
          </v-icon>
        </v-btn>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<style scoped lang="sass">
  .drag_over
    border: 2px dashed rgba(0, 0, 0, 0.3) !important
    display: flex
    align-items: center
    justify-content: center

  .pin-card
    cursor: pointer
    display: flex
    flex-direction: column
    position: relative
    transition: background-color 0.3s ease

    &:hover
      background-color: rgba(0, 0, 0, 0.02)
      transition: background-color 0.3s ease

  .mapped-slot
    cursor: pointer
    position: relative
    transition: background-color 0.3s ease

    &:hover
      background-color: rgba(0, 0, 0, 0.04)
      transition: background-color 0.3s ease

    .datapoint-color-indicator
      border-radius: 3px
      border-width: 3px
      border-style: solid
      position: absolute
      top: 0
      bottom: 0
      transition: border-color 0.2s ease

  span
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  .unmapped-slot
    background-color: rgb(var(--v-theme-neutral-lighten3))
    pointer-events: none
</style>

<i18n locale="de">
  {
    "drag_over_empty_pin_message": "Ablegen um Datenpunkt zuzuordnen",
    "drag_over_mapped_pin_message": "Ablegen um Datenpunkt zu ersetzen"
  }
  </i18n>
  <i18n locale="en">
  {
    "drag_over_empty_pin_message": "Drop to assign datapoint",
    "drag_over_mapped_pin_message": "Drop to replace datapoint"
  }
  </i18n>
