<i18n locale="de">
{
  "loading": "Lade Information"
}
</i18n>
<i18n locale="en">
{
  "loading": "Loading information"
}
</i18n>

<template>
  <v-sheet
    :class="['d-flex', loading ? 'justify-center' : 'justify-start', 'align-center', 'fault-card__container', 'ma-0', 'px-4', 'py-10']"
    ripple
    rounded
  >
    <v-progress-circular
      v-if="loading"
      class="mr-4"
      color="neutral-darken1"
      indeterminate
      size="25"
    />
    <v-icon
      v-else
      class="mr-4"
      color="neutral-darken1"
    >
      {{ icon }}
    </v-icon>
    <div
      v-if="!loading"
      class="d-flex flex-column align-start justify-center"
    >
      <span
        class="text-body-1 clamp-text font-weight-medium"
        v-text="title"
      />
      <span
        v-if="tooltipText"
        class="text-subtitle-1 clamp-text text-neutral-darken2"
      >
        {{ tooltipText }}
      </span>
    </div>
    <v-tooltip
      v-if="!loading && link"
      location="bottom"
      data-testid="external-link-tooltip"
    >
      <template #activator="{ props: externalLinkTooltipProps }">
        <v-btn
          class="ma-0 fault-card-open-link"
          data-cy="fault-card-open-link"
          :href="link"
          icon
          rel="noopener noreferrer"
          size="small"
          target="_blank"
          v-bind="externalLinkTooltipProps"
        >
          <v-icon
            color="neutral-darken1"
            size="13"
          >
            fa:far fa-external-link
          </v-icon>
        </v-btn>
      </template>
      <span v-text="linkTooltip" />
    </v-tooltip>
    <v-tooltip
      location="bottom"
      data-testid="avatar-tooltip"
    >
      <template #activator="{ props: tooltipProps }">
        <v-avatar
          class="ma-0 fault-card-lamp"
          :color="loading ? 'neutral' : borderedColor"
          rounded-circle
          size="20px"
          v-bind="tooltipProps"
        >
          <div
            v-if="isBorderedSignalLamp"
            class="inner-circle neutral-lighten5"
          />
        </v-avatar>
      </template>
      <span v-text="loading ? t('loading') : tooltipText" />
    </v-tooltip>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'FaultCard',

  props: {
    color: {
      type: String as PropType<string>,
      required: true,
    },

    icon: {
      type: String as PropType<string>,
      required: false,
      default: 'fa:fal fa-question-circle',
    },

    link: {
      type: String as PropType<string|null>,
      required: false,
      default: null,
    },

    loading: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },

    title: {
      type: String as PropType<string>,
      required: true,
    },

    tooltipText: {
      type: String as PropType<string>,
      required: false,
    },
  },

  setup () {
    const { t } = useI18n()
    return { t }
  },

  computed: {
    borderedColor (): string|null {
      if (!this.isBorderedSignalLamp) return this.color
      else return this.color.split('--')[0]
    },

    isBorderedSignalLamp (): boolean {
      return this.color.includes('--bordered')
    },

    linkTooltip (): string|null {
      if (this.link) {
        // Matches a string starting with any word followed by "://", e.g. "http://", or just "//" and optionally followed by one "www."
        const withoutScheme = this.link.replace(/(^\w+:|^)\/\/(www\.){0,1}/, '')
        const firstSlashIndex = withoutScheme.indexOf('/')
        return firstSlashIndex === -1 ? withoutScheme : withoutScheme.slice(0, firstSlashIndex)
      } else {
        return null
      }
    },
  },
})
</script>

<style lang="sass" scoped>
    .fault-card__container
      --card-size: 275px

      width: var(--card-size)
      height: 4rem

      position: relative

      .clamp-text
        max-width: 100%
        overflow-x: hidden
        overflow-y: hidden
        text-overflow: ellipsis
        word-wrap: normal
        line-height: 1.2rem

      .fault-card-open-link
        position: absolute
        right: 0.2rem
        bottom: 0.2rem

      .fault-card-lamp
        position: absolute
        right: -0.6rem

        .inner-circle
          position: absolute
          top: 2px
          left: 2px
          height: 16px
          width: 16px
          border-radius: 50%
</style>
