import { helpers, maxLength, minLength, required } from '@vuelidate/validators'

const atLeastOneDigit = (value?: string) => {
  if (value === '') return true
  if (typeof value !== 'string') return false
  return helpers.regex(/[0-9]/)(value)
}

const atLeastOneLowercaseLetter = (value?: string) => {
  if (value === '') return true
  if (typeof value !== 'string') return false
  return helpers.regex(/[a-z]/)(value)
}

const atLeastOneUppercaseLetter = (value?: string) => {
  if (value === '') return true
  if (typeof value !== 'string') return false
  return helpers.regex(/[A-Z]/)(value)
}

const atLeastOneSpecialCharacter = (value?: string) => {
  if (value === '') return true
  if (typeof value !== 'string') return false
  return helpers.regex(/[#$@^.,;:!?%~=*/+\\&()[\]<>{}'"`|]/)(value)
}

const passwordRules = {
  atLeastOneDigit,
  atLeastOneLowercaseLetter,
  atLeastOneSpecialCharacter,
  atLeastOneUppercaseLetter,
  maxLength: maxLength(128),
  minLength: minLength(12),
  required,
}

export {
  atLeastOneDigit,
  atLeastOneLowercaseLetter,
  atLeastOneSpecialCharacter,
  atLeastOneUppercaseLetter,
  passwordRules,
}
