<i18n locale="de">
  {
    "intensity": "Intensität",
    "relative": "Relativ",
    "savings_type": "Einsparpotential",
    "total": "Total",
    "yearly_hint": "Berechnungen zeigen Einsparpotentiale auf Jahresbasis.",
    "yearly_suffix": "Jährlich"
  }
  </i18n>
  <i18n locale="en">
  {
    "intensity": "Intensity",
    "relative": "Relative",
    "savings_type": "Savings Potential",
    "total": "Total",
    "yearly_hint": "Calculations show savings potential on a yearly basis.",
    "yearly_suffix": "Yearly"
  }
  </i18n>

<script setup lang="ts">
import DropDown, { DropDownItem } from '@/components/DropDown.vue'
import { inject, ref } from 'vue'
import Search, { ComponentSearchItem } from './Search.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const components = inject('componentSearchItems') as ComponentSearchItem
const loading = inject('loadingSearchRelevantItems') as boolean

// Temporary, will be done correctly when implementing application of filter
const savingsTypeItems = ref<DropDownItem[]>([
  {
    title: t('total'),
    value: '0',
  },
  {
    subtitle: 'Per m<sup>2</sup>',
    title: t('intensity'),
    value: '1',
  },
  {
    subtitle: '%',
    title: t('relative'),
    value: '2',
  },
])

// this is needed because for some reason, the search bar keeps focus when the
// dropdown is clicked
function blur (): void {
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur()
  }
}
</script>

<template>
  <div class="d-flex gap">
    <Search
      :components="components"
      :loading="loading"
      v-bind="$attrs"
    />
    <DropDown
      class="savings-type-drop-down"
      disabled
      :items="savingsTypeItems"
      :label="t('savings_type')"
      :menu-hint="t('yearly_hint')"
      :selection-suffix="t('yearly_suffix')"
      value=""
      @dropdown:focus="blur"
    />
  </div>
</template>

<style lang="sass" scoped>
.savings-type-drop-down
  max-width: 300px
  min-width: fit-content
</style>
