<script setup lang="ts">
import { Alignment, ColumnHeader, RowData, SortDirection, Width } from '@/components/SummarizedContent/types'
import { computed, ref } from 'vue'
import { Role } from '@aedifion.io/aedifion-api'
import SummarizedTable from '@/components/SummarizedTable.vue'
import { useAdministrationStore } from '@/stores/views/Administration/index'
import { useI18n } from 'vue-i18n'

type Keys = 'name' | 'role'

const administrationStore = useAdministrationStore()
const { t } = useI18n()

interface Props {
  loading: boolean
  userId: number
  usersProjectRoles: Role[]
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
})

const emit = defineEmits<{
  (e: 'toggleRole', projectId: number, roleIdToAdd: number, roleIdToRemove: number): void
}>()

const headers = ref<ColumnHeader<Keys>[]>([{
  key: 'name',
  text: t('projects'),
  width: Width.Grow,
}, {
  align: Alignment.Left,
  key: 'role',
  secondaryText: t('reader'),
  text: t('admin'),
  width: Width.MaxContent,
}])

function hasRole (roleId: number|undefined): boolean {
  return roleId !== undefined && props.usersProjectRoles.some((role: Role) => role.id === roleId)
}

function getRole (projectReaderRoleId: number|undefined, projectAdminRoleId: number|undefined) {
  if (hasRole(projectReaderRoleId)) {
    return 'reader'
  } else if (hasRole(projectAdminRoleId)) {
    return 'admin'
  }

  return ''
}

const rows = computed<RowData<Keys>[]>(() => {
  return administrationStore.projects.map((project) => {
    const projectAdminRole = project.roles?.find((role) => {
      return role.name?.toLowerCase() === 'admin'
    })

    const projectReaderRole = project.roles?.find((role) => {
      return role.name?.toLowerCase() === 'reader'
    })
    return {
      name: {
        custom: {
          avatar: project.avatar_url,
          description: project.description,
          id: project.id,
        },
        text: project.name,
      },
      role: {
        custom: {
          adminRoleId: projectAdminRole?.id ?? undefined,
          projectId: project.id,
          readerRoleId: projectReaderRole?.id ?? undefined,
          role: getRole(projectReaderRole?.id, projectAdminRole?.id),
        },
        text: '',
      },
    }
  })
})
</script>

<template>
  <div>
    <SummarizedTable
      v-if="rows.length"
      :default-sorting="{
        direction: SortDirection.ASC,
        key: 'name'
      }"
      :headers="headers"
      :rows="rows"
      :sticky-headers="false"
    >
      <template #cell.name="{ data }">
        <div class="d-flex align-center overflow-hidden">
          <v-img
            :alt="t('avatar_alt')"
            cover
            aspect-ratio="1"
            class="aedifion-border neutral-lighten1 rounded mr-2"
            :src="data.custom.avatar ?? '/img/dummy-image.jpg'"
            width="42px"
          />
          <div class="d-flex flex-column align-start overflow-hidden">
            <router-link
              class="asset-profile-link text-body-1 font-weight-semibold text-decoration-none text-truncate"
              :to="{
                name: 'asset-profile',
                params: {
                  project: data.custom.id
                }
              }"
            >
              {{ data.text }}
            </router-link>
            <span
              v-if="data.custom.description"
              class="asset-description text-subtitle-1 text-neutral-darken1 text-truncate"
            >
              {{ data.custom.description }}
            </span>
          </div>
        </div>
      </template>
      <template #cell.role="{ data }">
        <v-radio-group
          :model-value="data.custom.role"
          inline
        >
          <v-radio
            value="admin"
            color="primary-darken2"
            class="px-2 mx-auto"
            :disabled="loading"
            @click="emit('toggleRole', data.custom.projectId, data.custom.adminRoleId, data.custom.readerRoleId)"
          />
          <v-radio
            value="reader"
            color="primary-darken2"
            class="px-2 mx-auto"
            :disabled="loading"
            @click="emit('toggleRole', data.custom.projectId, data.custom.readerRoleId, data.custom.adminRoleId)"
          />
        </v-radio-group>
      </template>
    </SummarizedTable>
  </div>
</template>

<style scoped lang="sass">
.asset-profile-link, .asset-description
  max-width: 100%

.asset-profile-link
  color: rgb(var(--v-theme-neutral-darken4))

.asset-profile-link:hover
  color: rgb(var(--v-theme-primary-darken2))
</style>

<i18n lang="json" locale="de">
  {
    "avatar_alt": "Projekt-Avatar",
    "projects": "Projekte",
    "admin": "Admin",
    "reader": "Reader"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "avatar_alt": "Project avatar",
    "projects": "Projects",
    "admin": "Admin",
    "reader": "Reader"
  }
</i18n>
