<script setup lang="ts">
import { Alignment, ColumnHeader, RowData, Width } from '@/components/SummarizedContent/types'
import { ComponentOverviewItem, OverviewSummary } from '@/stores/views/Optimization/types'
import { computed, ref, watchEffect } from 'vue'
import DropDown, { DropDownItem } from '@/components/DropDown.vue'
import { NavigationGuardNext, onBeforeRouteUpdate, RouteLocation, RouteLocationNormalized, useRouter } from 'vue-router'
import AedifionDisclaimer from '@/components/AedifionDisclaimer.vue'
import ComponentCountsCard from '@/components/ComponentCountsCard.vue'
import { getUnit } from '@/stores/views/Reporting/helpers'
import HorizontalStack from '@/components/HorizontalStack.vue'
import PageHeader from '@/components/PageHeader.vue'
import SummarizedContent from '@/components/SummarizedContent/index.vue'
import { useI18n } from 'vue-i18n'
import { useOptimizationsOverviewStore } from '@/stores/views/Optimization/overview'
import { useSavingsPotentialStore } from '@/stores/views/Optimization/savingsPotential'
import { useUserStore } from '@/stores/user'
import { useStore as useVuexStore } from '@/vuex'

// --- definition ---

const optimizationsStore = useOptimizationsOverviewStore()
const savingsPotentialsStore = useSavingsPotentialStore()
const vuexStore = useVuexStore()
const userStore = useUserStore()
const { t } = useI18n()
const router = useRouter()

if (!sessionStorage.getItem('hasTheOptimizationsDisclaimerBeenConsented')) {
  sessionStorage.setItem('hasTheOptimizationsDisclaimerBeenConsented', 'false')
}
const OptimizationsDisclaimerState = ref<boolean>(
  sessionStorage.getItem('hasTheOptimizationsDisclaimerBeenConsented') === 'false',
)

watchEffect(() => {
  if (OptimizationsDisclaimerState.value === false) {
    sessionStorage.setItem('hasTheOptimizationsDisclaimerBeenConsented', 'true')
  }
})

const overviewComponents = computed< ComponentOverviewItem[]>(() => optimizationsStore.componentSummaryItems)
const overviewSummary = computed<OverviewSummary>(() => optimizationsStore.overviewSummary)
const isLoadingOverview = computed<boolean>(() => {
  return optimizationsStore.isLoadingOverviewItems || savingsPotentialsStore.loading
})

const getComponentLink = (alphanumericId: string): RouteLocation => {
  vuexStore.dispatch('optimization/unselectComponentInProject')
  return router.resolve({
    name: 'optimization-components',
    query: {
      filter: alphanumericId,
    },
  })
}

const highlightedSeverity = ref<string|null>(null)

const componentCountsCardBindings = computed(() => (component: ComponentOverviewItem) => ({
  class: [
    'count-card',
    'hoverable-bordered',
    { 'has-high-potential': component.countHigh > 0 },
    { 'has-medium-potential': component.countMedium > 0 },
    {
      'is-optimized':
        component.countHigh <= 0 &&
        component.countMedium <= 0 &&
        component.countOptimized > 0,
    },
  ],
  'count-high': component.countHigh,
  'count-medium': component.countMedium,
  'count-optimized': component.countOptimized,
  name: component.name,
  'number-of-components': component.numberOfComponents,
  savings: component.savings,
  to: getComponentLink(component.alphanumericId),
}))

const highlightSeverity = (severity: OverviewSummary[number]|null) => {
  highlightedSeverity.value = severity?.id ?? null
}

const currentProjectId = computed<number>(() => vuexStore.getters['projects/currentProjectId'])

const userCurrency = computed<'$'|'€'>(() => {
  if (userStore.userDetails?.currency_system === 'USD') {
    return '$'
  }

  return '€'
})

const savingsTypeItems = ref<DropDownItem[]>([
  {
    title: t('total'),
    value: 'total',
  },
  {
    subtitle: 'Per m<sup>2</sup>',
    title: t('intensity'),
    value: 'intensity',
  },
  {
    subtitle: '%',
    title: t('relative'),
    value: 'relative',
  },
])

const selectedSavingsType = ref('total')

const additionalItems = ref<DropDownItem[]>([
  {
    subtitle: userCurrency.value,
    title: t('cost'),
    value: 'cost',
  },
  {
    subtitle: 'kgCO<sub>2</sub>',
    title: t('co2_emissions'),
    value: 'co2_emissions',
  },
  {
    subtitle: 'Wh',
    title: t('energy'),
    value: 'energy',
  },
])

type Keys = 'cost' | 'co2' | 'energy'| 'result'

const headers = computed<ColumnHeader<Keys>[]>(() => [{
  key: 'result',
  text: t('analysis_result'),
  width: Width.Grow,
},
{
  align: Alignment.Right,
  key: 'cost',
  text: t('cost'),
  tooltip: t('cost_tooltip'),
  unit: getUnit('euro') as string,
  width: Width.MaxContent,
}, {
  align: Alignment.Right,
  key: 'co2',
  text: 'CO<sub>2</sub>',
  tooltip: t('co2_emissions_tooltip'),
  unit: getUnit('kilograms') as string,
  width: Width.MaxContent,
}, {
  align: Alignment.Right,
  key: 'energy',
  text: t('energy'),
  tooltip: t('energy_tooltip'),
  unit: getUnit('kilowatt-hours') as string,
  width: Width.MaxContent,
}])

const summary = computed<RowData<Keys>>(() => ({
  co2: {
    text: optimizationsStore.totalCo2Savings,
  },
  cost: {
    text: optimizationsStore.totalCostSavings,
  },
  energy: {
    text: optimizationsStore.totalEnergySavings,
  },
  link: {
    class: 'text-primary-darken2',
    color: 'primary-lighten3',
    flatButton: false,
    icon: 'fa:far fa-list',
    text: t('all_results'),
    to: { name: 'optimization-potential', params: { project: currentProjectId.value.toString() } },
  },
}))
// --- execution ---

savingsPotentialsStore.fetchSavingsPotential()
optimizationsStore.getOverview()

// --- lifecycle hooks ---

onBeforeRouteUpdate(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.params.project && from.params.project && to.params.project !== from.params.project) {
    savingsPotentialsStore.fetchSavingsPotential()
    optimizationsStore.getOverview()
  }
  next()
})
</script>

<template>
  <div class="layout-wrapper">
    <PageHeader
      sticky
      title-key="links.meta.title.optimization"
    >
      <DropDown
        :value="selectedSavingsType"
        class="savings-type-drop-down ml-auto"
        :items="savingsTypeItems"
        :prepended-items="additionalItems"
        :label="t('savings_type')"
        :menu-hint="t('yearly_hint')"
        :selection-suffix="t('yearly_suffix')"
        disabled
      />
    </PageHeader>
    <SummarizedContent
      :headers="headers"
      :summary="summary"
      show-action-column
    >
      <template #summary.result>
        <HorizontalStack
          :is-loading="isLoadingOverview"
          :items="overviewSummary"
          :gap-size="4.35"
          class="d-flex align-center fill-height"
          :stack-size="324"
          @hovered="highlightSeverity"
        />
      </template>
      <template #body>
        <div
          v-if="overviewComponents !== null && !isLoadingOverview"
          :class="[
            { 'has-highlighted': highlightedSeverity !== null },
            highlightedSeverity ? `highlighted-${highlightedSeverity}` : '',
            'summarized-content__body'
          ]"
        >
          <v-col
            v-for="(component, index) of overviewComponents"
            :key="index"
            class="px-0"
          >
            <ComponentCountsCard
              v-bind="componentCountsCardBindings(component)"
            />
          </v-col>
        </div>
        <div
          v-else-if="isLoadingOverview"
          class="summarized-content__body mt-n2"
        >
          <v-skeleton-loader
            v-for="i in overviewComponents?.length || 25"
            :key="i"
            class="mt-6"
            type="card"
            height="184"
          />
        </div>
        <AedifionDisclaimer
          v-model="OptimizationsDisclaimerState"
          :is-loading="isLoadingOverview"
          :title="t('savings_potential_title')"
          :text="t('savings_potential_text')"
          :button-text="t('ok_button_text')"
          icon="fa:fal fa-lightbulb-dollar"
        />
      </template>
    </SummarizedContent>
  </div>
</template>

<style lang="sass" scoped>
.layout-wrapper
  .count-card
    transition: opacity 0.2s ease-in-out
  .has-highlighted .count-card
    opacity: 0.4
  .has-highlighted.highlighted-high .count-card.has-high-potential,
  .has-highlighted.highlighted-medium .count-card.has-medium-potential,
  .has-highlighted.highlighted-optimized .count-card.is-optimized
    opacity: 1

  :deep(.v-snack)
    width: calc(100% - 200px)
    margin-left: 200px

  .savings-type-drop-down
    width: fit-content
    max-width: fit-content
    min-width: 249px

  :deep(.v-skeleton-loader__image)
    height: 128px

  .header
    position: sticky
    top: 40px
    right: 0
    left: 0
    margin-bottom: 32px
    z-index: 1

  .summarized-content__body
    display: grid
    grid-template-columns: repeat(auto-fill , minmax(254px, 1fr))
    grid-template-rows: auto
    grid-auto-rows: auto
    grid-auto-flow: row dense
    grid-column: 1 / -1
    column-gap: 24px
</style>

<i18n lang="json" locale="de">
  {
    "all_results": "Alle Ergebnisse",
    "analysis_result": "Analyseergebnis",
    "cost": "Kosten",
    "cost_tooltip": "Kosten-Einsparpotenzial (Jährlich)",
    "co2_emissions": "CO₂-Emissionen",
    "co2_emissions_tooltip": "CO₂-Einsparpotenzial (Jährlich)",
    "energy": "Energie",
    "energy_tooltip": "Energie-Einsparpotenzial (Jährlich)",
    "intensity": "Intensität",
    "relative": "Relativ",
    "savings_type": "Einsparpotential",
    "total": "Total",
    "yearly_hint": "Berechnungen zeigen Einsparpotentiale auf Jahresbasis.",
    "yearly_suffix": "Jährlich",
    "savings_potential_title": "Einsparpotential",
    "savings_potential_text": "Die berechneten Kosten, CO₂-Emissionen und Energieverbräuche sind Potentiale und stellen keine garantierten Zahlen dar.",
    "ok_button_text": "Ok, verstanden!"
  }
  </i18n>
  <i18n lang="json" locale="en">
  {
    "all_results": "All results",
    "analysis_result": "Analysis Result",
    "cost": "Cost",
    "cost_tooltip": "Cost savings potential (Yearly)",
    "co2_emissions": "CO₂ emissions",
    "co2_emissions_tooltip": "CO₂ emissions savings potential (Yearly)",
    "energy": "Energy",
    "energy_tooltip": "Energy consumption savings potential (Yearly)",
    "intensity": "Intensity",
    "relative": "Relative",
    "savings_type": "Savings Potential",
    "total": "Total",
    "yearly_hint": "Calculations show savings potential on a yearly basis.",
    "yearly_suffix": "Yearly",
    "savings_potential_title": "Savings Potential",
    "savings_potential_text": "The calculated cost, CO₂ emissions and energy consumption are potentials and do not illustrate any guaranteed numbers.",
    "ok_button_text": "Ok, got it!"
  }
  </i18n>
