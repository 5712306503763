type KpiWithAssociatedColors = 'co2_emissions' | 'energy_cost' | 'energy_consumption' | 'productivity' | 'technical_availability'

export function getColorForKPI (aggregationValue: number|undefined, kpiType: KpiWithAssociatedColors) {
  if (aggregationValue === undefined) {
    return 'grey'
  }

  switch (kpiType) {
    case 'co2_emissions':
      if (aggregationValue >= 0 && aggregationValue < 25) {
        return 'green'
      } else if (aggregationValue >= 25 && aggregationValue < 50) {
        return 'yellow'
      } else if (aggregationValue >= 50) {
        return 'red'
      }
      break

    case 'energy_cost':
      if (aggregationValue >= 0 && aggregationValue < 6) {
        return 'green'
      } else if (aggregationValue >= 6 && aggregationValue < 12) {
        return 'yellow'
      } else if (aggregationValue >= 12) {
        return 'red'
      }
      break

    case 'energy_consumption':
      if (aggregationValue >= 0 && aggregationValue < 50) {
        return 'green'
      } else if (aggregationValue >= 50 && aggregationValue < 100) {
        return 'yellow'
      } else if (aggregationValue >= 100) {
        return 'red'
      }
      break

    case 'productivity':
      return getProductivityOrTechnicalAvailabilityColor(aggregationValue)

    case 'technical_availability':
      return getProductivityOrTechnicalAvailabilityColor(aggregationValue)
  }

  return 'grey'
}

function getProductivityOrTechnicalAvailabilityColor (aggregationValue: number) {
  if (aggregationValue < 90) {
    return 'red'
  } else if (aggregationValue >= 90 && aggregationValue <= 95) {
    return 'yellow'
  } else if (aggregationValue > 95) {
    return 'green'
  }

  return 'grey'
}
