import { DatapointsState } from './types'

export default {
  datapoints: null,
  datapointsWithPendingFavoritesUpdate: [],
  loadingDatapoints: true,
  pagination: null,
  pendingTagUpdate: false,
  selectedPinForRecommendations: null,
} as DatapointsState

export function resetStoreState (state: DatapointsState): void {
  state.datapoints = null
  state.datapointsWithPendingFavoritesUpdate = []
  state.loadingDatapoints = true
  state.pagination = null
  state.pendingTagUpdate = false
  state.selectedPinForRecommendations = null
}
