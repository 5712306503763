<i18n locale="de">
{
  "create_component": "um eine neue Komponente zu erstellen",
  "title": "Neue Komponente erstellen"
}
</i18n>
<i18n locale="en">
{
  "create_component": "to create a new component",
  "title": "Create New Component"
}
</i18n>

<template>
  <v-dialog
    max-width="70%"
    persistent
    :model-value="showNewComponentDialog"
    @keydown.escape="onEscapePressed"
  >
    <v-card>
      <v-form
        autocomplete="on"
        @submit.prevent="createNewComponent()"
      >
        <v-card-title class="text-h5 pa-4">
          <span v-text="t('title')" />
        </v-card-title>
        <v-card-text>
          <SelectComponentPage
            v-if="selectedComponent === null"
            :select-new-component="selectNewComponent"
            @select="selectNewComponent"
          />

          <SelectNamePage
            v-else
            :selected-component="selectedComponent"
            @selectnamepage:name-selected="setButtonAbleAndNewComponentName"
          />
        </v-card-text>

        <div class="sticky-card-actions neutral-lighten5">
          <v-divider />
          <v-card-actions class="pb-2">
            <v-btn
              color="primary-darken2"
              variant="text"
              @click="cancel"
            >
              {{ t('actions.cancel') }}
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="selectedComponent !== null"
              color="primary-darken2"
              variant="text"
              @click="selectedComponent = null"
            >
              {{ t('actions.back') }}
            </v-btn>
            <v-btn
              v-if="selectedComponent !== null"
              color="primary-darken2"
              data-cy="createNewComponentSaveButton"
              :disabled="!ableToSave"
              type="submit"
            >
              {{ t('actions.save') }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ComponentCardItem } from '@/vuex/components_in_project/types'
import { InvalidValueError } from '@/utils/helpers/validate'
import { reportError } from '@/utils/helpers/errors'
import SelectComponentPage from '@/views/Optimization/Components/NewComponentInProjectDialog/SelectComponentPage.vue'
import SelectNamePage from '@/views/Optimization/Components/NewComponentInProjectDialog/SelectNamePage.vue'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'NewComponentInProjectDialog',

  components: {
    SelectComponentPage,
    SelectNamePage,
  },

  props: {
    showNewComponentDialog: {
      required: true,
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },

  emits: ['newcomponentinprojectdialog:close'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      ableToSave: false as boolean,
      componentName: '' as string,
      selectedComponent: null as ComponentCardItem|null,
    }
  },

  created () {
    this.$store.dispatch('components/fetchComponents')
  },

  methods: {
    cancel (): void {
      this.selectedComponent = null
      this.$emit('newcomponentinprojectdialog:close')
    },

    async createNewComponent () {
      try {
        const success = await this.$store.dispatch('components_in_project/createComponentInProject', {
          componentID: this.selectedComponent?.componentID,
          name: this.componentName,
        })

        if (success) {
          this.cancel()
        }
      } catch (error) {
        if (error instanceof InvalidValueError) {
          showErrorNotification(`${this.t('notifications.errors.no_project_selected_with_attempted_action', { action: this.t('create_component') })}`)
        }
        reportError(error)
      }
    },

    onEscapePressed () {
      if (this.selectedComponent) {
        this.selectedComponent = null
      } else {
        this.cancel()
      }
    },

    selectNewComponent (item: ComponentCardItem): void {
      this.selectedComponent = item
    },

    setButtonAbleAndNewComponentName (data: {name: string, status: boolean}): void {
      this.ableToSave = data.status
      this.componentName = data.name
    },
  },
})
</script>

<style lang="sass" scoped>
.sticky-card-actions
  border-top: 1px solid rgb(var(--v-theme-neutral-lighten2))
  background: #ffffff
  position: sticky
  bottom: 0px
</style>
