<script setup lang="ts">
import { NewTask, Task } from '@aedifion.io/aedifion-api'
import { reactive } from 'vue'
import type { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import PriorityIcon from '@/components/TaskList/PriorityIcon.vue'
import PriorityLabel from '@/components/TaskList/PriorityLabel.vue'
import TaskView from './TaskView.vue'

// #region Initializations for props and emits
interface Props {
  analysisResultId?: string|null,
  isReadOnly: boolean,
  selectedComponent?: ComponentInProjectListItemData,
  task: Task|null,
  value: boolean
}

const props = defineProps<Props>()

const emits = defineEmits<{
  (event: 'close'): void,
  (event: 'create-task', newTask: NewTask): void,
}>()
// #endregion

// #region Task data and its related computed properties
const taskData = reactive({
  priority: props.task?.priority ?? Task.PriorityEnum.Medium,
})
// #endregion

// #region Items for v-select 'priority' and 'status'
const priorityOptions = [Task.PriorityEnum.High, Task.PriorityEnum.Medium, Task.PriorityEnum.Low]
// #endregion

// #region CRUD operations for task
function onCreateTask (task: NewTask) {
  emits('create-task', extractProperties(task))
}

function extractProperties (task: NewTask): NewTask {
  return {
    ...task,
    priority: taskData.priority,
  }
}
// #endregion
</script>

<template>
  <div>
    <v-dialog
      :model-value="value"
      max-width="640px"
      width="85%"
      @click:outside="emits('close')"
      @keydown.esc.prevent="emits('close')"
      @update:model-value="emits('close')"
    >
      <v-card>
        <v-card-title
          class="pt-6 pb-0 mb-5 d-flex tw-align-middle tw-items-center tw-justify-center"
        >
          <v-select
            v-model="taskData.priority"
            :items="priorityOptions"
            :disabled="isReadOnly"
            hide-selected
            hide-details
            :menu-props="{
              'offset': [0, 0]
            }"
            menu-icon="fal fa-angle-down"
            variant="plain"
            single-line
            class="tw-w-fit tw-grow-0 pt-0 tw-mt-[-20px] priority-selector"
            data-testid="priority-selector"
            @update:model-value="handleUpdateTask"
          >
            <template #item="{ item, props: prioritySelectionProps }">
              <v-list-item
                class="d-flex px-2"
                v-bind="prioritySelectionProps"
              >
                <template #prepend>
                  <PriorityIcon :priority="item.raw" />
                </template>
                <PriorityLabel :priority="item.raw" />
                <template #title />
              </v-list-item>
            </template>
            <template #selection="{ item }">
              <v-list-item class="d-flex px-2">
                <template #prepend>
                  <PriorityIcon
                    :priority="item.raw"
                    data-testid="task-priority-icon"
                  />
                </template>
                <PriorityLabel :priority="item.raw" />
              </v-list-item>
            </template>
          </v-select>
          <v-spacer />
          <v-btn
            color="neutral-darken3"
            icon
            size="small"
            variant="text"
            @click="emits('close')"
          >
            <v-icon size="12">
              far fa-xmark-large
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-container
          class="px-0 pt-0 pb-6"
        >
          <TaskView
            is-from-component-page="true"
            :task="null"
            :is-read-only="isReadOnly"
            :analysis-result-id="analysisResultId"
            :selected-component="selectedComponent"
            @create-task="onCreateTask"
          />
        </v-container>
      </v-card>
    </v-dialog>
  </div>
  <div />
</template>

<style lang="sass" scoped>
  .priority-selector
    :deep(.v-field__append-inner)
      margin: auto !important
</style>
