<!--
  Use in conjunction with the `noDefaultContainer` route meta option

  TODO: make compatible with fixed elements, like SummarizedContent.
    It’s probably possible by using the contain CSS property, but I wasn’t able
    to find a solution that doesn’t also break scrolling.
-->

<script setup lang="ts">
import { computed } from 'vue'
import { ActionMenuItem } from '../ActionMenu.vue'
import KebabMenu from '../KebabMenu.vue'

const TRANSITION_DURATION = 300 // ms

interface Props {
  isOpen: boolean,
  // `compress`: the sidepeek and the page content are placed side-by-side, and
  // the page content shrinks upon opening the sidepeek.
  // `cover`: the sidepeek is placed over the page content and hides part of it.
  layoutStrategy?: 'compress' | 'cover',
  menuItems?: ActionMenuItem[],
  altStyle?: boolean,
  preventPageContentScroll?: boolean,
  width?: string,
}

const props = withDefaults(defineProps<Props>(), {
  layoutStrategy: 'cover',
  menuItems: () => [],
  altStyle: false,
  preventPageContentScroll: false,
  width: '640px',
})

const emit = defineEmits<{
  (e: 'close'): void,
}>()

const shouldCover = computed(() => props.layoutStrategy === 'cover')
</script>

<template>
  <div
    class="tw-w-full tw-h-full tw-overflow-x-hidden tw-overflow-y-hidden"
    :style="{
      '--sidepeek-width': width,
      '--sidepeek-transition-duration': `${TRANSITION_DURATION}ms`,
    }"
  >
    <div class="tw-flex tw-flex-row tw-h-full tw-w-[calc(100%_+_var(--sidepeek-width))]">
      <div
        :class="[
          'tw-relative tw-grow-0 tw-shrink tw-w-[calc(100%_-_var(--sidepeek-width))]',
          { 'tw-overflow-x-auto tw-overflow-y-auto': !preventPageContentScroll }
        ]"
      >
        <slot name="pageContent" />
      </div>
      <Transition
        name="slide"
        :duration="TRANSITION_DURATION"
      >
        <aside
          v-if="isOpen"
          :class="[
            'tw-z-0 tw-grow-0 tw-shrink-0 tw-overflow-x-auto tw-overflow-y-auto tw-w-[var(--sidepeek-width)] tw-bg-[rgb(var(--v-theme-neutral-lighten5))]',
            { 'sidepeek-should-slide tw-z-[1101] tw-fixed tw-top-0 tw-right-0 tw-bottom-0': shouldCover },
            { 'tw-border-l tw-border-solid tw-border-[rgb(var(--v-theme-neutral-lighten1))]': !altStyle },
          ]"
        >
          <header
            :class="['tw-w-full tw-flex tw-border-b tw-flex-row tw-justify-between tw-items-start tw-border-solid tw-border-[rgb(var(--v-theme-neutral-lighten1))]',
                     {'bg-neutral-lighten3 tw-border-none' : altStyle}
            ]"
          >
            <div class="tw-grow-0 tw-shrink tw-overflow-x-hidden">
              <slot name="topBar" />
            </div>
            <div>
              <div
                v-if="menuItems.length > 0"
                class="tw-inline-block tw-ml-3"
                data-testid="task-kebab-menu"
              >
                <KebabMenu
                  v-bind="$attrs"
                  :items="menuItems"
                  small
                />
              </div>
              <v-btn
                data-testid="close-sidepeek-button"
                class="tw-m-3 tw-border-[rgb(var(--v-theme-neutral-lighten1))]"
                density="compact"
                icon
                :rounded="true"
                size="small"
                variant="outlined"
                @click="emit('close')"
              >
                <v-icon size="14">
                  fa:far fa-xmark-large
                </v-icon>
              </v-btn>
            </div>
          </header>
          <slot name="default" />
        </aside>
      </Transition>
      <div
        :class="[
          'tw-min-w-0 tw-transition-[min-width] tw-ease-in-out tw-duration-[var(--sidepeek-transition-duration)]',
          { 'tw-min-w-[var(--sidepeek-width)]': isOpen },
        ]"
      />
    </div>
  </div>
</template>

<style scoped lang="sass">
.sidepeek-should-slide.slide-enter-active,
.sidepeek-should-slide.slide-leave-active
  transform: translateX(0)
  transition: transform var(--sidepeek-transition-duration) ease-in-out

.sidepeek-should-slide.slide-enter-from,
.sidepeek-should-slide.slide-leave-to
  transform: translateX(var(--sidepeek-width))
</style>
