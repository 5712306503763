import { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import i18n from '@/i18n'

export const titleGuardMiddleware: (appName: string) => NavigationGuard = (appName: string) => (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (to.meta) {
    if (to.meta.titleKey) {
      document.title = `${appName} | ${i18n.global.t(to.meta.titleKey as string)}`
    } else if (to.meta.title) {
      document.title = `${appName} | ${to.meta.title}`
    } else {
      document.title = appName
    }
  }
  next()
}
