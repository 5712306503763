<template>
  <div
    class="header text-legend font-weight-medium text-truncate text-neutral-lighten1"
    v-text="text"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'NavigationHeader',

  props: {
    text: {
      required: true,
      type: String as PropType<string>,
    },
  },
})
</script>

<style lang="sass" scoped>
.header
  line-height: 40px !important
  margin-left: 12px
  overflow: hidden

</style>
