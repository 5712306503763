/* eslint-disable camelcase */
import { AnalysisFunction, AnalyticsResultsForComponentResultIds, ComponentInProjectWithContext, InstanceConfig } from '@aedifion.io/aedifion-api'
import { GetterTree } from 'vuex'
import { OptimizationState } from './types'
import { RootState } from '../types'
import { textForLocale } from '@/utils/helpers/locale'

export type CondensedAnalysisFunctionWithResult = {
  enabled: boolean;
  function_alphanumeric_id: string;
  function_id: number;
  function_names: {
    de: string;
    en: string;
  }
  instance_id: number|null;
  interpretation: string|null;
  result_id: string|null;
  signal_color: string;
}

function getAnalysisResultForAnalysisFunction (alphanumericId: string, results: AnalyticsResultsForComponentResultIds[]): AnalyticsResultsForComponentResultIds|null {
  return results.find(
    (currentResult: AnalyticsResultsForComponentResultIds) =>
      currentResult.function_name === alphanumericId,
  ) || null
}

function getInstanceForResult (result: AnalyticsResultsForComponentResultIds, instances: InstanceConfig[]): InstanceConfig|null {
  return instances.find(
    (instance: InstanceConfig) => result.instance_id === instance.id,
  ) || null
}

function getInstanceForAnalysisFunctionId (analysisFunctionId: number, instances: InstanceConfig[]): InstanceConfig|null {
  return instances.find(
    (instance: InstanceConfig) => instance.config.analysisfunction_id === analysisFunctionId,
  ) || null
}

function combineAnalysisFunctionsWithInstanceResults (
  analysisFunctions: AnalysisFunction[],
  instances: InstanceConfig[],
  analysisResults: AnalyticsResultsForComponentResultIds[],
): Array<CondensedAnalysisFunctionWithResult> {
  const results: Array<CondensedAnalysisFunctionWithResult> = []
  for (const analysisFunction of analysisFunctions) {
    const result: AnalyticsResultsForComponentResultIds|null = getAnalysisResultForAnalysisFunction(
      analysisFunction.alphanumeric_id!,
      analysisResults,
    )

    let instance: InstanceConfig|null = null
    if (result) instance = getInstanceForResult(result, instances)
    else instance = getInstanceForAnalysisFunctionId(analysisFunction.id!, instances)

    results.push({
      enabled: (instance?.config.enabled === true || (!!instance && instance?.config.enabled === undefined)) ?? false,
      function_alphanumeric_id: analysisFunction.alphanumeric_id!,
      function_id: analysisFunction.id!,
      function_names: {
        de: analysisFunction.nameDE!,
        en: analysisFunction.nameEN!,
      },
      instance_id: instance?.id ?? null,
      interpretation: result?.interpretation ?? null,
      result_id: result?.result_id ?? null,
      signal_color: result?.signal_color || 'neutral',
    })
  }

  return results
}

export default {
  followAnalysisPeriod: (state: OptimizationState) => state.followAnalysisPeriod,

  getAnalysisFunctionsWithResults: (state: OptimizationState, getters, rootState, rootGetters): Array<CondensedAnalysisFunctionWithResult>|null => {
    if (state.selectedComponentInProject === null) return null
    if (rootState.analysis_functions.analysisFunctionsList === null) return null
    const instances = rootGetters['analysis_instances/getInstancesForSelectedComponentInProject'] as InstanceConfig[]
    return combineAnalysisFunctionsWithInstanceResults(
      rootState.analysis_functions.analysisFunctionsList ?? [],
      instances,
      state.selectedComponentInProjectResults?.result_ids ?? [],
    )
  },

  getSelectedComponent: (state: OptimizationState): ComponentInProjectWithContext|null => state.selectedComponentInProject,

  getSelectedComponentResults: (state: OptimizationState): AnalyticsResultsForComponentResultIds[] => state.selectedComponentInProjectResults?.result_ids ?? [],

  isLoadingComponentInProject: (state: OptimizationState) => state.loadingComponentInProject,

  isLoadingComponentInProjectResults: (state: OptimizationState) => state.loadingComponentsAnalysisResults,

  search: (state: OptimizationState): string|null => state.search,

  selectedComponentsMappedPinHashIds: (state: OptimizationState): Array<string>|null => {
    if (state.selectedComponentInProject) {
      const result: string[] = []
      for (const pin of state.selectedComponentInProject!.pins!) {
        result.push(pin.datapoint_hash_id!)
      }
      return result
    } else {
      return null
    }
  },

  selectedComponentsMappedPinNames: (state: OptimizationState): Map<string, string>|null => {
    if (state.selectedComponentInProject) {
      const result: Map<string, string> = new Map()
      for (const pin of state.selectedComponentInProject!.pins!) {
        result.set(pin.dataPointID!, textForLocale(pin.nameDE!, pin.nameEN!))
      }
      return result
    } else {
      return null
    }
  },
} as GetterTree<OptimizationState, RootState>
