<script setup lang="ts">
import { Alignment, CellData, ColumnHeader, Severity } from './types'
import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue'
import ColoredFigure from '../ColoredFigure.vue'
import debounce from 'lodash.debounce'
import { formatValue } from '@/filters/formatting'
import { VUETIFY_COLORS } from '@theme/colors'

interface Props {
  data?: CellData;
  header: ColumnHeader;
  hideUnitsIfNoValue?: boolean;
  isChild?: boolean;
  summaryLineFormatting?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  hideUnitsIfNoValue: false,
  isChild: false,
  summaryLineFormatting: false,
})

const textContainerElement = ref()
const textElement = ref()
const textIsTruncated = ref(false)
const textWidth = ref(0)

const hasTooltip = computed<boolean>(() => {
  return textIsTruncated.value || !!props.data?.link
})

const headerAlignAsJustify = computed<string>(() => {
  let direction: 'start' | 'center' | 'end'

  switch (props.header.align) {
    case Alignment.Left:
      direction = 'start'
      break
    case Alignment.Center:
      direction = 'center'
      break
    case Alignment.Right:
      direction = 'end'
      break
    default:
      direction = 'start'
  }
  return `justify-${direction}`
})

const hideUnitsIfNoValue = computed<boolean>(() => {
  return props.hideUnitsIfNoValue && props.data?.text === undefined
})

const formattedText = computed<string>(() => {
  if (typeof props.data?.text === 'number') {
    return formatValue(props.data.text, { displayUnit: false, integer: true, unit: props.header.unit })
  } else if (showNoValueIndicator.value) {
    return '–'
  } else {
    return props.data?.text ?? ''
  }
})

const tooltipText = computed<string>(() => {
  if (textIsTruncated.value && props.data?.link) {
    return `${props.data.link.text} - ${formattedText.value}`
  } else if (textIsTruncated.value) {
    return formattedText.value
  } else if (props.data?.link) {
    return props.data.link.text
  } else {
    return ''
  }
})

// @ts-ignore
const textColor = computed<string>(() => {
  switch (props.data?.severity) {
    case Severity.Success:
      return VUETIFY_COLORS.success.darken2
    case Severity.Warning:
      return VUETIFY_COLORS.warning.darken2
    case Severity.Error:
      return VUETIFY_COLORS.error.darken2
    default:
      return VUETIFY_COLORS.neutral.darken3
  }
})

const textWrapperAttributes = computed(() => {
  if (props.data?.link) {
    return {
      is: 'router-link',
      tag: 'div',
      target: props.data?.link?.target ?? '_self',
      to: props.data?.link?.to,
    }
  } else {
    return { is: 'div' }
  }
})

const severityIcon = computed<string>(() => {
  switch (props.data?.severity) {
    case Severity.Success:
      return props.isChild ? 'fa:fas fa-circle-small' : 'fa:fas fa-check-circle'
    case Severity.Warning:
      return props.isChild ? 'fa:fas fa-circle-small' : 'fa:fas fa-octagon-exclamation'
    case Severity.Error:
      return props.isChild ? 'fa:fas fa-circle-small' : 'fa:fas fa-diamond-exclamation'
    default:
      return ''
  }
})

const severityIconColor = computed<string>(() => {
  switch (props.data?.severity) {
    case Severity.Success:
      return VUETIFY_COLORS.success.base
    case Severity.Warning:
      return VUETIFY_COLORS.warning.base
    case Severity.Error:
      return VUETIFY_COLORS.error.base
    default:
      return ''
  }
})

const coloredFigureColors = computed<{ backgroundColor: string, figureColor: string }>(() => {
  switch (props.data?.severity) {
    case Severity.Error:
      return {
        backgroundColor: VUETIFY_COLORS.error.lighten4,
        figureColor: VUETIFY_COLORS.error.darken2,
      }
    case Severity.Warning:
      return {
        backgroundColor: VUETIFY_COLORS.warning.lighten4,
        figureColor: VUETIFY_COLORS.warning.darken2,
      }
    case Severity.Success:
      return {
        backgroundColor: VUETIFY_COLORS.success.lighten4,
        figureColor: VUETIFY_COLORS.success.darken2,
      }
    default:
      return {
        backgroundColor: '',
        figureColor: '',
      }
  }
})

const showNoValueIndicator = computed<boolean>(() => {
  return props.data?.text === undefined && props.summaryLineFormatting && props.header.unit !== undefined
})

const textClasses = computed<string[]>(() => {
  const classes: string[] = ['text-truncate']
  if (props.data?.severity) {
    classes.push('minimize', 'severity-text')
  } else {
    classes.push('text-body-1')
    if (props.summaryLineFormatting) {
      classes.push('font-weight-semibold')
    }
  }

  if (props.data?.cssClass) {
    classes.push(props.data.cssClass)
  }
  return classes
})

function calculateTextWidth (): void {
  if (!props.data || !textContainerElement.value) {
    textWidth.value = 0
  } else {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    // This is added to make the tests pass
    if (!context) {
      return
    }

    context.font = window.getComputedStyle(textElement.value).font
    textWidth.value = Math.ceil(context.measureText(formattedText.value).width)
    canvas.remove()
  }
}

function checkIfTextIsTruncated (): void {
  nextTick(() => {
    if (textWidth.value === 0) {
      textIsTruncated.value = false
    } else {
      textIsTruncated.value = textWidth.value > textContainerElement.value?.offsetWidth
    }
  })
}

const checkIfTextIsTruncatedDelayed = debounce(checkIfTextIsTruncated, 500)

onMounted (() => {
  calculateTextWidth()
  window.addEventListener('resize', checkIfTextIsTruncatedDelayed)
  checkIfTextIsTruncated()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkIfTextIsTruncatedDelayed)
})
</script>

<template>
  <div
    class="d-flex fill-height"
  >
    <div
      class="d-flex flex-column justify-center text-truncate fill-height cell "
      :class="[{'flex-grow-1': props.data?.chipText === undefined}]"
    >
      <div
        v-if="data !== undefined || showNoValueIndicator"
        ref="textContainerElement"
        class="d-flex flex-column"
      >
        <div :class="['d-flex', headerAlignAsJustify]">
          <v-icon
            v-if="data?.severity !== undefined"
            :class="props.isChild ? 'severity-icon--child' : 'severity-icon'"
            :color="severityIconColor"
          >
            {{ severityIcon }}
          </v-icon>
          <v-tooltip
            class="tooltip"
            :disabled="!hasTooltip"
            location="top"
          >
            <template #activator="{ props: tooltipProps }">
              <div
                v-bind="textWrapperAttributes"
                class="text-wrapper text-truncate"
              >
                <router-link
                  v-slot="{ navigate }"
                  :to="data?.link?.to ?? {}"
                  custom
                >
                  <div
                    v-bind="tooltipProps"
                    :class="[{'clickable': data?.link}, 'd-flex align-baseline']"
                    @click="data?.link?.to ? navigate() : undefined"
                  >
                    <span
                      ref="textElement"
                      :class="textClasses"
                      v-text="formattedText"
                    />
                    <ColoredFigure
                      v-if="(data?.numberOfComponents ?? 0) > 1"
                      :background-color="coloredFigureColors.backgroundColor"
                      class="ml-1"
                      :figure-color="coloredFigureColors.figureColor"
                      small
                      :value="data?.numberOfComponents?.toString()"
                    />
                    <span
                      v-if="!hideUnitsIfNoValue && (header.unit !== undefined || showNoValueIndicator)"
                      class="text-body-1 unit"
                    >
                      &nbsp;{{ header.unit }}
                    </span>
                    <v-icon
                      v-if="data?.link?.icon"
                      class="link-icon"
                      end
                    >
                      {{ data.link.icon }}
                    </v-icon>
                  </div>
                </router-link>
              </div>
            </template>
            <span v-text="tooltipText" />
          </v-tooltip>
        </div>
        <div class="d-flex">
          <span
            v-if="data?.subtitle !== undefined"
            class="text-neutral-darken1 text-subtitle-1 text-truncate"
            v-text="data.subtitle"
          />
          <v-tooltip
            v-if="data?.subtitleTooltip"
            location="right"
            :offset="[40, 45]"
            content-class="subtitle--tooltip__content"
          >
            <template
              #activator="{ props: subtitleTooltipProps }"
            >
              <v-icon
                size="14"
                class="subtitle--tooltip__icon"
                v-bind="subtitleTooltipProps"
              >
                {{ data.subtitleTooltip?.icon }}
              </v-icon>
            </template>
            <span
              class="text-legend text-neutral-lighten5"
            >{{ data.subtitleTooltip?.text }}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <v-chip
      v-if="data?.chipText"
      size="small"
      class="my-auto mr-auto text-info-darken2 text-legend"
    >
      {{ data.chipText }}
    </v-chip>
  </div>
</template>

<style lang="sass" scoped>
.cell
  padding-left: 16px
  padding-right: 16px

  .severity-icon
    font-size: 20px
    margin-right: 14px
    z-index: 0

  .severity-icon--child
    font-size: 14px
    margin-right: 19px
    margin-left: 6px
    z-index: 0

  .severity-text
    font-size: 14px
    font-weight: 600

  .unit
    color: rgb(var(--v-theme-neutral))

  .text-wrapper
    color: v-bind(textColor)
    cursor: default !important
    text-decoration: none

  .clickable
    border-radius: 4px
    cursor: pointer !important
    pointer-events: auto

    &:hover
      background-color: rgb(var(--v-theme-primary-lighten4)) !important

      & span, .link-icon
        color: rgb(var(--v-theme-primary-darken2)) !important

    .link-icon
      color: v-bind(textColor)
      font-size: 14px !important
      display: none

.subtitle--tooltip__icon
  line-height: 20px
  color: rgb(var(--v-theme-neutral-darken1))
  margin-left: 4px
  cursor: pointer

.subtitle--tooltip__content
  opacity: 1 !important
  padding: 6px 8px
  background-color: rgb(var(--v-theme-neutral-lighten5))
  border: 1px solid rgb(var(--v-theme-neutral-lighten1))
  box-shadow: 0px 3px 0px rgba(23, 25, 38, 0.12)

// Removes the hover effect on the v-chip
.v-chip:hover::before
  opacity: 0 !important
</style>
