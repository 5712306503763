import { AnalysisResult } from '@aedifion.io/aedifion-api'

export enum SemanticColor {
  Green = 'green',
  Red = 'red',
  Yellow = 'yellow'
}

/**
 * Returns the Vuetify color class that should be used to represent the
 * `SemanticColor` passed as an argument.
 * @param color SemanticColor | null
 * @returns string
 */
export function getVuetifyColorClassFromSemanticColor (color: SemanticColor|null) {
  if (color === SemanticColor.Green) {
    return 'success'
  } else if (color === SemanticColor.Yellow) {
    return 'warning'
  } else if (color === SemanticColor.Red) {
    return 'error'
  }

  return 'neutral'
}

// TODO use the function above instead of the ones below when possible
// https://aedifion.atlassian.net/browse/FE-1104

export function colorName (color: AnalysisResult.SignalColorEnum | string): string {
  switch (color) {
    case AnalysisResult.SignalColorEnum.Green:
      return 'success'
    case AnalysisResult.SignalColorEnum.Yellow:
      return 'warning'
    case AnalysisResult.SignalColorEnum.Red:
      return 'error'
    default:
      return String(color)
  }
}

export function whitelabelColor (color: string): string {
  switch (color) {
    case 'green':
      return 'success'
    case 'yellow':
      return 'warning'
    case 'red':
      return 'error'
    default:
      return color
  }
}
