import { StatusAndAlertsState, SystemComponentsTimeseries } from './types'
import { ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { MutationTree } from 'vuex'

export default {
  SET_LOADING_SYSTEM_COMPONENTS: (state: StatusAndAlertsState, loading: boolean) => (state.loadingSystemComponents = loading),

  SET_LOADING_SYSTEMS_OPERATIONAL_STATUS_CODES: (state: StatusAndAlertsState, loading: boolean) => (state.loadingSystemsOperationalStatusCodes = loading),

  SET_SYSTEM_COMPONENTS: (state: StatusAndAlertsState, systemComponents: ComponentInProjectWithContext[]|null) => (state.systemComponents = systemComponents),

  SET_SYSTEM_COMPONENTS_OPERATIONAL_STATE: (state: StatusAndAlertsState, systemComponentsTimeseries: SystemComponentsTimeseries) => (state.systemsOperationalStatusCodes = systemComponentsTimeseries),
} as MutationTree<StatusAndAlertsState>
