<script setup lang="ts">
import { type ActionMenuItem } from '@/components/ActionMenu.vue'
import KebabMenu from '@/components/KebabMenu.vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const emit = defineEmits<{
  (e: 'edit-option-click'): void
}>()

const { t } = useI18n()

const items = ref<ActionMenuItem[]>([{
  icon: 'fa:far fa-pen',
  id: 'edit',
  label: t('edit_user'),
}])
</script>

<template>
  <KebabMenu
    :items="items"
    @edit-option-click="emit('edit-option-click')"
  />
</template>

<i18n lang="json" locale="de">
{
  "edit_user": "Nutzer bearbeiten"
}
</i18n>
<i18n lang="json" locale="en">
{
  "edit_user": "Edit user"
}
</i18n>
