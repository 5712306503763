<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { minValue, required } from '@vuelidate/validators'
import isEqual from 'lodash/isEqual'
import { Meter } from '@/vuex/asset_overview/types'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/vuex'
import { useVuelidate } from '@vuelidate/core'
import type { Validation } from '@vuelidate/core'

interface Props {
  meter?: Meter | null
  value?: boolean
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  meter: null,
  value: false,
})

const emit = defineEmits<{
  (e: 'meter-editor:close'): void
}>()

const { t } = useI18n()
const store = useStore()

const editedMeter = ref<Meter>({
  billing: true,
})
const meterTypes = ref([
  { title: t('type.gas'), value: 'GASM' },
  { title: t('type.heat'), value: 'HM' },
  { title: t('type.cold'), value: 'CM' },
  { title: t('type.electricity'), value: 'ELM' },
])
// eslint-disable-next-line vue/no-setup-props-destructure
const originalMeter = ref<Meter | null>(props.meter)
const submittable = ref(false)

const rules = computed(() => ({
  editedMeter: {
    alphanumericId: { required },
    energyPrice: { minValue: minValue(0) },
    meterName: { required },
  },
}))

const v$ = useVuelidate(rules, { editedMeter })

const disableTypeDependentInput = computed<boolean>(() => {
  return editedMeter.value?.alphanumericId === 'WSM'
})

const hasMeterChanged = computed<boolean>(() => {
  return !isEqual(originalMeter.value, editedMeter.value)
})

const energyPriceErrors = computed<string>(() => {
  if (v$?.value.editedMeter?.energyPrice?.$dirty && v$.value?.editedMeter?.energyPrice?.minValue && v$.value?.editedMeter?.energyPrice?.$error) {
    return t('errors.is_negative', { field: t('label.energy_price') })
  }
  return ''
})

const meterNameErrors = computed<string>(() => {
  if (v$?.value.editedMeter?.meterName?.$dirty && v$.value?.editedMeter?.meterName?.required && v$.value?.editedMeter?.meterName?.$error) {
    return t('errors.is_required', { field: t('label.name') })
  }
  return ''
})

const meterTypeErrors = computed<string>(() => {
  if (v$?.value.editedMeter?.alphanumericId?.$dirty && v$.value?.editedMeter?.alphanumericId?.required && v$.value?.editedMeter?.alphanumericId?.$error) {
    return String(t('errors.is_required', { field: t('label.type') }))
  }
  return ''
})

const updatePending = computed<boolean>(() => store.state.asset_overview.pendingMeterUpdate)

watch(editedMeter, () => {
  // @ts-expect-error when the the user changes the input and clears it, its value is set to empty string
  if (editedMeter.value.co2Emissions === '') {
    editedMeter.value.co2Emissions = undefined
  }
  // @ts-expect-error when the the user changes the input and clears it, its value is set to empty string
  if (editedMeter.value.energyPrice === '') {
    editedMeter.value.energyPrice = undefined
  }
})

watch(
  () => props.meter,
  (newMeter) => {
    if (newMeter !== null) {
      editedMeter.value = { ...newMeter }
    }
  }, {
    deep: true,
    immediate: true,
  },
)

async function saveMeter (): Promise<void> {
  if (props.meter) {
    await store.dispatch('asset_overview/editUserMeter', { meter: editedMeter.value, originalMeter: originalMeter.value })
  } else {
    await store.dispatch('asset_overview/createUserMeter', editedMeter.value)
  }
  emit('meter-editor:close')
}

function updateValidation (vuelidateElement?: Validation) {
  vuelidateElement?.$touch()
  submittable.value = !v$.value.$invalid
}
</script>

<template>
  <v-dialog
    persistent
    :model-value="value"
    width="700"
  >
    <v-card class="px-2 py-4">
      <v-card-title class="pb-4">
        {{ meter ? t('edit_title') : t('add_title') }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model.trim="editedMeter.meterName"
              data-cy="meter-editor-name"
              :error-messages="meterNameErrors"
              hide-details="auto"
              :label="t('label.name')"
              variant="filled"
              required
              type="text"
              @blur="updateValidation(v$.editedMeter.meterName)"
              @update:model-value="updateValidation(v$.editedMeter.meterName)"
            />
          </v-col>
          <v-col
            class="pr-1"
            cols="6"
          >
            <v-text-field
              v-model.number="editedMeter.co2Emissions"
              data-cy="meter-editor-co2-emissions"
              :disabled="disableTypeDependentInput"
              hide-details="auto"
              :label="t('label.co2_emissions')"
              variant="filled"
              suffix="kg/kWh"
              type="number"
              @blur="updateValidation()"
              @update:model-value="updateValidation()"
            />
          </v-col>
          <v-col
            class="pl-1"
            cols="6"
            data-cy="meter-editor-type"
          >
            <v-select
              v-model="editedMeter.alphanumericId"
              :disabled="!!meter"
              :error-messages="meterTypeErrors"
              hide-details="auto"
              :items="meterTypes"
              :label="t('label.type')"
              variant="filled"
              :menu-icon=" meter ? 'fa:far fa-lock' : '$dropdown'"
              :readonly="!!meter"
              required
              @blur="updateValidation(v$.editedMeter.alphanumericId)"
              @update:model-value="updateValidation(v$.editedMeter.alphanumericId)"
            />
          </v-col>
          <v-col
            class="pr-1"
            cols="6"
          >
            <v-text-field
              v-model.number="editedMeter.energyPrice"
              data-cy="meter-editor-energy-price"
              :disabled="disableTypeDependentInput"
              :error-messages="energyPriceErrors"
              hide-details="auto"
              :label="t('label.energy_price')"
              variant="filled"
              suffix="€/kWh"
              type="number"
              @blur="updateValidation(v$.editedMeter.energyPrice)"
              @update:model-value="updateValidation(v$.editedMeter.energyPrice)"
            />
          </v-col>
          <v-col
            class="pl-1"
            cols="6"
          >
            <v-text-field
              v-model.trim="editedMeter.number"
              data-cy="meter-editor-number"
              hide-details="auto"
              :label="t('label.number')"
              variant="filled"
              @blur="updateValidation()"
              @update:model-value="updateValidation()"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex px-4">
        <v-btn
          class="flex-grow-1 flex-1"
          color="primary-lighten3"
          height="40px"
          variant="elevated"
          data-cy="meter-editor-close-button"
          :disabled="updatePending"
          @click="$emit('meter-editor:close')"
        >
          <span class="text-cta text-primary-darken2 mr-3">{{ t('actions.close') }}</span>
          <v-icon
            size="14"
            class="text-primary-darken2"
          >
            fa:far fa-xmark
          </v-icon>
        </v-btn>
        <v-btn
          class="flex-grow-1 flex-1"
          color="primary"
          height="40px"
          variant="elevated"
          data-cy="meter-editor-save-button"
          :disabled="!submittable || !hasMeterChanged"
          :loading="updatePending"
          @click="saveMeter"
        >
          <span class="text-cta mr-3">{{ t('actions.save') }}</span>
          <v-icon size="14">
            fa:far fa-check
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="sass" scoped>
:deep(.v-input:not(.v-input--is-focused) .v-text-field__suffix)
  color: rgb(var(--v-theme-secondary))

.flex-1
  flex: 1
</style>

<i18n lang="json" locale="de">
  {
    "add_title": "Zähler erstellen",
    "edit_title": "Zähler bearbeiten",
    "label": {
      "co2_emissions": "CO₂ Emissions-Faktor",
      "energy_price": "Energiepreis",
      "name": "Name",
      "number": "Nummer",
      "type": "Typ"
    },
    "type": {
      "gas": "Gaszähler",
      "heat": "Wärmezähler",
      "cold": "Kältezähler",
      "electricity": "Stromzähler"
    }
  }
</i18n>
<i18n locale="en">
  {
    "add_title": "Add meter",
    "edit_title": "Edit meter",
    "label": {
      "co2_emissions": "CO₂ emission factor",
      "energy_price": "Energy price",
      "name": "Name",
      "number": "Number",
      "type": "Type"
    },
    "type": {
      "gas": "Gas meter",
      "heat": "Heat meter",
      "cold": "Cold meter",
      "electricity": "Electricity meter"
    }
  }
</i18n>
