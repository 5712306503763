/* eslint-disable camelcase */
import { LocationQuery, RouteLocation } from 'vue-router'
import { ExtendedAnalysisResult } from '@/vuex/analysis_instances/types'

/**
 * Checks the current router query and updates the instance result reference. If the store has an instance result reference it changes or adds it, if the store has no instance result reference, but the query does,
 * it removes it.
 * @param newResult, newInstance The analysis result id that was set in the store.
 * @returns a query with updated instance result reference.
 */
function updateResultInQuery (payload: ExtendedAnalysisResult|null, currentRoute: RouteLocation): LocationQuery|undefined {
  const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
  if (payload &&
    payload.result_id !== undefined &&
    payload.instance_id !== undefined
  ) {
    newQuery.result = payload.result_id
    newQuery.instance = payload.instance_id.toString()
  } else if (payload === null) {
    delete newQuery.result
    delete newQuery.instance
  }
  return newQuery
}

/**
 * Checks the name of the mutation that was called and may replace the router query, depending on the changes in the store.
 * @param mutationName Name of the mutation that was called.
 * @param payload Payload the mutation was called with.
 * @returns The result of the indivial methods, either undefined or a new query.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function updateAssetOverviewQuery (mutationName: string, payload: any, currentRoute: RouteLocation): LocationQuery|undefined {
  if (mutationName === 'SET_ANALYSIS_RESULT' && currentRoute.query.result !== payload?.result_id) {
    return updateResultInQuery(payload, currentRoute)
  } else {
    return undefined
  }
}
