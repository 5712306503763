<script setup lang="ts">
import { COCKPIT_NUDGE_GRADIENT, VUETIFY_COLORS } from '@theme/colors'
import { getCo2Color, getEnergyColor } from './composables'
import AskAedifionDialog from '@/components/AskAedifionDialog.vue'
import { Chart } from 'highcharts-vue'
import { computed } from 'vue'
import EmptyStateForPage from '@/components/EmptyStateForPage.vue'
import { formatBuildingAttributes } from '@/filters/buildingAttributes'
import { formatValue } from '@/filters/formatting'
import Highcharts from 'highcharts'
import { SemanticColor } from '@/filters/colors'
import { TYPOGRAPHY } from '@/utils/designConstants'
import { useEnergyAndCO2Store } from '@/stores/views/energyAndCO2'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/vuex'

type CustomPointData = {
  color: SemanticColor;
  description: string;
  projectName: string;
}

enum SeparatorType {
  Decimal = 'decimal',
  Thousands = 'group'
}

// --- definition ---

const colors = {
  [SemanticColor.Green]: {
    backgroundColor: VUETIFY_COLORS.success.lighten4,
    figureColor: VUETIFY_COLORS.success.darken2,
  },
  [SemanticColor.Yellow]: {
    backgroundColor: VUETIFY_COLORS.warning.lighten4,
    figureColor: VUETIFY_COLORS.warning.darken2,
  },
  [SemanticColor.Red]: {
    backgroundColor: VUETIFY_COLORS.error.lighten4,
    figureColor: VUETIFY_COLORS.error.darken2,
  },
}

const { locale, t } = useI18n()
const store = useStore()
const energyAndCO2Store = useEnergyAndCO2Store()

const co2KpiUnit = computed<string>(() => {
  return store.getters['labels/label']('units', 'kilograms-per-square-meter-per-year')?.symbol as string|undefined ?? ''
})
const energyKpiUnit = computed<string>(() => {
  return store.getters['labels/label']('units', 'kilowatt-hours-per-square-meter-per-year')?.symbol as string|undefined ?? ''
})

const chartData = computed<{
  custom: CustomPointData,
  x: number,
  y: number
}[]>(() => {
  const chartProjects = energyAndCO2Store.chartProjects

  return chartProjects.map((chartProject) => {
    const co2Color = getCo2Color(chartProject.co2KpiValue)
    const energyColor = getEnergyColor(chartProject.energyKpiValue)
    let color = SemanticColor.Green
    if (co2Color === SemanticColor.Yellow || energyColor === SemanticColor.Yellow) {
      color = SemanticColor.Yellow
    }
    if (co2Color === SemanticColor.Red || energyColor === SemanticColor.Red) {
      color = SemanticColor.Red
    }

    const assetDescription = formatBuildingAttributes(chartProject.netFloorArea, chartProject.type, chartProject.address)

    return {
      custom: {
        color,
        description: assetDescription,
        projectName: chartProject.name,
      },
      x: chartProject.energyKpiValue,
      y: chartProject.co2KpiValue,
    }
  })
})

const chartOptions = computed<Highcharts.Options>(() => {
  const options: Highcharts.Options = {
    chart: {
      height: '50%',
      type: 'scatter',
      zooming: {
        type: 'xy',
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      scatter: {
        stickyTracking: false,
      },
    },
    series: [
      {
        data: chartData.value,
        dataLabels: {
          borderRadius: 4,
          crop: false,
          enabled: true,
          formatter: function () {
            const point = this.point as Highcharts.Point & { custom: CustomPointData }

            return `
              <span class="text-subtitle-1 rounded" style="color: ${colors[point.custom.color].figureColor}; background-color: ${colors[point.custom.color].backgroundColor}; padding: 8px;">${point.custom.projectName}</span>
              `
          },
          overflow: 'allow',
          useHTML: true,
          verticalAlign: 'middle',
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        showInLegend: false,
        type: 'scatter',
      },
    ],
    title: {
      style: {
        visibility: 'hidden',
      },
    },
    tooltip: {
      backgroundColor: VUETIFY_COLORS.neutral.lighten5,
      borderColor: VUETIFY_COLORS.neutral.lighten1,
      borderRadius: 8,
      distance: 0,
      formatter: function () {
        // the tooltip is outside the scope of .v-application, so we need to
        // define the utility classes manually
        const header5Style = `font-size: ${TYPOGRAPHY.headings.h5.size}; font-weight: ${TYPOGRAPHY.headings.h5.weight}; line-height: ${TYPOGRAPHY.headings.h5.lineHeight}; letter-spacing: ${TYPOGRAPHY.headings.h5.letterSpacing};`
        const body1Style = `font-size: ${TYPOGRAPHY.headings.body1.size}; font-weight: ${TYPOGRAPHY.headings.body1.weight}; line-height: ${TYPOGRAPHY.headings.body1.lineHeight}; letter-spacing: ${TYPOGRAPHY.headings.body1.letterSpacing};`
        const semanticsStyle = `font-size: ${TYPOGRAPHY.headings.semantics.size}; font-weight: ${TYPOGRAPHY.headings.semantics.weight}; line-height: ${TYPOGRAPHY.headings.semantics.lineHeight}; letter-spacing: ${TYPOGRAPHY.headings.semantics.letterSpacing};`

        const point = this.point as Highcharts.Point & { custom: CustomPointData }

        const co2Color = getCo2Color(this.point.y!)!
        const energyColor = getEnergyColor(this.point.x)!

        const content = `
          <div>
            <div>
              <div class="energy-and-co2-tooltip-title" style="${header5Style}">${point.custom.projectName}</div>
              ${point.custom.description ? `<div class="energy-and-co2-tooltip-description">${point.custom.description}</div>` : ''}
            </div>
            <div class="energy-and-co2-tooltip-figure-container">
              <div>
                <span class="energy-and-co2-tooltip-figure" style="color: ${colors[co2Color].figureColor}; background-color: ${colors[co2Color].backgroundColor}; ${semanticsStyle}">
                  ${formatValue(this.point.y, { displayUnit: false, unit: co2KpiUnit.value })}
                  <span style="${body1Style}">${co2KpiUnit.value}</span>
                </span>
                <div class="energy-and-co2-tooltip-figure-text" style="${body1Style}">${t('co2_emissions')}</div>
              </div>
              <div class="energy-and-co2-tooltip-figure-spacing">
                <span class="energy-and-co2-tooltip-figure" style="color: ${colors[energyColor].figureColor}; background-color: ${colors[energyColor].backgroundColor}; ${semanticsStyle}">
                  ${formatValue(this.point.x, { displayUnit: false, unit: energyKpiUnit.value })}
                  <span style="${body1Style}">${energyKpiUnit.value}</span>
                </span>
                <div class="energy-and-co2-tooltip-figure-text" style="${body1Style}">${t('total_energy')}</div>
              </div>
            </div>
          </div>
        `
        return content
      },
      hideDelay: 0,
      outside: true,
      padding: 24,
      positioner: function (labelWidth, labelHeight, point) {
        const NAVBAR_WIDTH = 200
        const DATA_LABEL_HEIGHT = 31

        const chartContainerBounds = this.chart.container.getBoundingClientRect()

        const pointX = chartContainerBounds.x + this.chart.plotLeft + point.plotX
        // default x position
        let x = pointX - (labelWidth / 2)

        const tooltipLeftSideOverflow = -(x - NAVBAR_WIDTH)

        const maxRightSideCoordinate = pointX + (labelWidth / 2)
        const tooltipRightSideOverflow = -(window.innerWidth - maxRightSideCoordinate)

        // if the tooltip overflows the screen either on the left or on the
        // right, we offset its position to bring it back into the viewport
        if (tooltipLeftSideOverflow > 0) {
          x = x + tooltipLeftSideOverflow
        }
        if (tooltipRightSideOverflow > 0) {
          x = x - tooltipRightSideOverflow
        }

        const pointY = window.scrollY + chartContainerBounds.y + this.chart.plotTop + point.plotY
        // default y position
        let y = pointY - labelHeight - (DATA_LABEL_HEIGHT / 2)

        // if the tooltip overflows the screen on the top side, then we position
        // it under the data label instead
        if (y < 0) {
          y = pointY + (DATA_LABEL_HEIGHT / 2)
        }

        return { x, y }
      },
      shadow: false,
      shape: 'rect',
      style: {
        fontSize: TYPOGRAPHY.headings.body1.size,
      },
      useHTML: true,
    },
    xAxis: {
      gridLineColor: VUETIFY_COLORS.neutral.lighten2,
      gridLineDashStyle: 'ShortDash',
      gridLineWidth: 1,
      labels: {
        style: {
          color: VUETIFY_COLORS.neutral.darken2,
          fontSize: TYPOGRAPHY.headings.legend.size,
          fontWeight: TYPOGRAPHY.headings.legend.weight,
        },
      },
      lineColor: VUETIFY_COLORS.neutral.darken3,
      maxPadding: 0.1,
      min: 0,
      tickLength: 0,
      title: {
        style: {
          color: VUETIFY_COLORS.neutral.darken3,
          fontSize: TYPOGRAPHY.headings.legend.size,
          fontWeight: TYPOGRAPHY.headings.legend.weight,
        },
        text: `
          <div class="text-center">
            <span>${t('energy_intensity')}</span>
            <br>
            <span class="text-neutral-darken2">(${energyKpiUnit.value})</span>
          </div>`,
        useHTML: true,
      },
    },
    yAxis: {
      gridLineColor: VUETIFY_COLORS.neutral.lighten2,
      gridLineDashStyle: 'ShortDash',
      gridLineWidth: 1,
      labels: {
        style: {
          color: VUETIFY_COLORS.neutral.darken2,
          fontSize: TYPOGRAPHY.headings.legend.size,
          fontWeight: TYPOGRAPHY.headings.legend.weight,
        },
      },
      lineColor: VUETIFY_COLORS.neutral.darken3,
      lineWidth: 1,
      maxPadding: 0.1,
      min: 0,
      tickLength: 0,
      title: {
        offset: 80,
        rotation: 0,
        style: {
          color: VUETIFY_COLORS.neutral.darken3,
          fontSize: TYPOGRAPHY.headings.legend.size,
          fontWeight: TYPOGRAPHY.headings.legend.weight,
        },
        text: `
          <div class="text-center">
            <span>${t('co2_intensity')}</span>
            <br>
            <span class="text-neutral-darken2">(${co2KpiUnit.value})</span>
          </div>`,
        useHTML: true,
      },
    },
  }
  return options
})

const isLoading = computed<boolean>(() => {
  return energyAndCO2Store.loading
})

const hasProjects = computed<boolean>(() => {
  return chartData.value.length > 0
})

const numberOfProjectsInPortfolio = computed<number>(() => {
  return (store.getters['projects/projectIds'] as number[]).length
})

// from https://stackoverflow.com/a/51411377
// to get the different kinds of separators that correspond to the current
// locale, we extract them from the result of Intl.NumberFormat.formatToParts()
const numberWithGroupAndDecimalSeparator = 1000.1
const numberFormattingParts = Intl.NumberFormat(locale.value).formatToParts(numberWithGroupAndDecimalSeparator)

function getSeparator (type: SeparatorType): string|undefined {
  return numberFormattingParts.find(part => part.type === type)?.value
}

// --- execution ---

/**
 * https://github.com/highcharts/highcharts-vue/issues/71#issuecomment-485784315
 * The 'lang' object can not be updated to force a re-draw. Languages only change on rendering (create/destroy hooks)
 */
Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: "'Inter', sans-serif",
    },
  },
  lang: {
    decimalPoint: getSeparator(SeparatorType.Decimal) ?? ',',
    loading: t('highcharts.loading') as string,
    thousandsSep: getSeparator(SeparatorType.Thousands) ?? ' ',
  },
})
</script>

<template>
  <div class="tw-h-full">
    <v-card v-if="isLoading">
      <div
        class="d-flex justify-center align-center flex-column"
      >
        <v-progress-circular
          class="my-16"
          color="primary-darken2"
          indeterminate
        />
      </div>
    </v-card>
    <v-card
      v-else-if="hasProjects"
      class="pt-2 pr-10 pb-4"
    >
      <Chart
        v-if="hasProjects"
        id="chart"
        ref="chart"
        class="rounded"
        :options="chartOptions"
      />
    </v-card>
    <EmptyStateForPage
      v-else
      class="mt-n8"
      :icon="{
        name: 'fal fa-face-monocle',
        size: '40px',
        color: `linear-gradient(${COCKPIT_NUDGE_GRADIENT[0]}, ${COCKPIT_NUDGE_GRADIENT[1]}, ${COCKPIT_NUDGE_GRADIENT[2]})`
      }"
      :title="t('no_data.title')"
      :description="t('no_data.explanation', { numberOfAssets: numberOfProjectsInPortfolio })"
    >
      <div class="text-center tw-flex tw-flex-col">
        <AskAedifionDialog>
          <template #button="buttonProps">
            <v-btn
              v-bind="buttonProps"
              class="mr-2"
              color="primary-darken2"
              variant="text"
            >
              {{ t('no_data.contact') }}
            </v-btn>
          </template>
        </AskAedifionDialog>
        <v-btn
          class="no-active-button-styling"
          color="primary-darken2"
          :to="{ name: 'home' }"
        >
          {{ t('no_data.select_an_asset') }}
        </v-btn>
      </div>
    </EmptyStateForPage>
  </div>
</template>

<style lang="sass" scoped>
.v-btn--active.no-active-button-styling:not(:hover)::before
  opacity: 0
</style>

<style lang="sass">
.energy-and-co2-tooltip-title
  white-space: nowrap !important
  overflow: hidden !important
  text-overflow: ellipsis !important
  padding-bottom: 4px

.energy-and-co2-tooltip-description
  color: rgb(var(--v-theme-neutral-darken2))
  overflow: hidden
  text-overflow: ellipsis

.energy-and-co2-tooltip-figure-container
  display: flex
  flex-wrap: nowrap !important
  padding-top: 24px

.energy-and-co2-tooltip-figure
  border-radius: 4px
  padding-top: 4px
  padding-right: 10px
  padding-bottom: 4px
  padding-left: 10px
  font-weight: 600 !important
  white-space: nowrap !important

.energy-and-co2-tooltip-figure-text
  margin-top: 8px
  color: rgb(var(--v-theme-neutral-darken2))
  white-space: nowrap !important

.energy-and-co2-tooltip-figure-spacing
  margin-left: 24px
</style>

<i18n locale="de">
{
  "total_energy": "Gesamtenergie",
  "co2_emissions": "CO₂-Emissionen",
  "co2_intensity": "CO₂-Intensität",
  "energy_intensity": "Energieintensität",
  "no_data": {
    "contact": "Kontakt zu unserem Team",
    "explanation": "Derzeit liegen uns keine Zählerdaten für die {numberOfAssets} Anlagen in diesem Portfolio vor. Bitte stellen Sie uns Zählerdaten aus der Vergangenheit zur Verfügung, damit wir Ihnen einen Einblick in die Energieintensität dieses Portfolios geben können.",
    "select_an_asset": "Wählen Sie ein Asset →",
    "title": "Fehlende Zählerdaten"
  }
}
</i18n>
<i18n locale="en">
{
  "total_energy": "Total energy",
  "co2_emissions": "CO₂ emissions",
  "co2_intensity": "CO₂ intensity",
  "energy_intensity": "Energy intensity",
  "no_data": {
    "contact": "Contact our team",
    "explanation": "Currently we do not have meter data for any of the {numberOfAssets} assets in this portfolio. Please provide us past meter data to give you some insights about this portfolio’s energy intensity.",
    "select_an_asset": "Select an asset →",
    "title": "Missing Meter Data"
  }
}
</i18n>
