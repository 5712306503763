<script setup lang="ts">
import { ref, watch } from 'vue'
import DataCardInput from '@/components/DataCard/DataCardInput.vue'
import { DataRow } from '@/components/DataCard/types'
import { useI18n } from 'vue-i18n'

interface Props {
  data: DataRow[],
  loading?: boolean,
  readOnly?: boolean,
  title: string
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  readOnly: false,
})

const emit = defineEmits<{
  (event: 'data-card:change', params: DataRow[]): void
}>()

const { t } = useI18n()

const editMode = ref(false)
const editedRows = ref<DataRow[]>([])
const dataCache = ref<DataRow[]>([])

function writeOriginalDataToCache (newData: DataRow[]) {
  dataCache.value = []
  // we need to copy by value, not by reference
  newData.forEach((row: DataRow) => dataCache.value.push({ ...row }))
}

function startEditMode () {
  editMode.value = true
}

function saveChanges () {
  emit('data-card:change', editedRows.value)
  editMode.value = false
  editedRows.value = []
}

function cancelEditMode () {
  editMode.value = false
  editedRows.value = []
  writeOriginalDataToCache(props.data)
}

function onRowUpdated (key: string, newValue: string) {
  const cachedRow = dataCache.value.find((row: DataRow) => row.key === key)
  if (cachedRow) {
    cachedRow.value = newValue
    const previouslyEditedRow = editedRows.value.find((row: DataRow) => row.key === key)
    if (previouslyEditedRow) {
      previouslyEditedRow.value = newValue
    } else {
      editedRows.value.push(cachedRow)
    }
  }
}

watch(
  () => props.data,
  (newData) => {
    writeOriginalDataToCache(newData)
  },
)

// --- execution ---

// eslint-disable-next-line vue/no-setup-props-destructure
writeOriginalDataToCache(props.data)
</script>

<template>
  <v-card
    v-bind="$attrs"
    @keydown.escape="cancelEditMode"
  >
    <v-card-title class="pa-4 d-flex align-center">
      {{ props.title }}
      <v-spacer />
      <v-btn
        v-if="!editMode && !props.readOnly"
        color="primary-darken2"
        data-cy="edit-button"
        :disabled="props.loading"
        variant="text"
        @click="startEditMode"
      >
        {{ t('actions.edit') }}
      </v-btn>
      <v-btn
        v-if="editMode"
        class="mr-2"
        color="primary-darken2"
        data-cy="cancel-button"
        variant="text"
        @click="cancelEditMode"
      >
        {{ t('actions.cancel') }}
      </v-btn>
      <v-btn
        v-if="editMode"
        color="primary-darken2"
        data-cy="save-button"
        @click="saveChanges"
      >
        {{ t('actions.save') }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader
        v-if="props.loading"
        type="list-item@6"
      />
      <table
        v-else
        width="100%"
      >
        <tbody>
          <tr
            v-for="(row, index) in dataCache"
            :key="index"
          >
            <td>{{ row.name }}</td>
            <td>
              <DataCardInput
                :data-cy="`data-card-input-${row.key}`"
                :edit-mode="editMode"
                :row="row"
                @change="onRowUpdated"
                @save="saveChanges"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<style lang="sass" scoped>
  table td
    vertical-align: top

  table td:nth-of-type(1)
    width: 35%
    padding-top: 9px
</style>
