<script setup lang="ts">
import { RowData } from './types'

interface Props {
  data: RowData;
  isExpanded?: boolean;
}

withDefaults(defineProps<Props>(), {
  isExpanded: false,
})

const emits = defineEmits(['toggleExpansion'])
</script>

<template>
  <div
    class="d-flex flex-column align-end justify-center action-cell"
  >
    <v-btn
      v-if="data.children !== undefined"
      color="primary-lighten3"
      class="text-primary"
      @click="emits('toggleExpansion')"
    >
      {{ data.children.length }}
      <v-icon
        :class="['action-icon', { 'rotated': !isExpanded }]"
        end
      >
        fa:far fa-chevron-up
      </v-icon>
    </v-btn>

    <v-btn
      v-else-if="data.link !== undefined"
      :color="data.link.color ?? 'primary-darken2'"
      :variant="data.link.flatButton ? 'text': 'elevated'"
      :to="data.link.to"
      :class="data.link.class"
    >
      {{ data.link.text }}
      <v-icon
        class="action-icon"
        end
      >
        {{ data.link.icon }}
      </v-icon>
    </v-btn>
  </div>
</template>

<style lang="sass" scoped>
.action-cell
  padding-left: 8px
  padding-right: 8px

  .action-icon
    font-size: 14px !important

    &.rotated
      transform: rotate(-180deg)
      transition: transform 0.2s ease
</style>
