import { aedifionApi, Timeseries, TimeseriesWithContext } from '@aedifion.io/aedifion-api'
import { TimeseriesShort, TimeseriesValue } from '@/vuex/data_points_view/types'
import { HttpError } from '@/utils/types'

/**
 * Converts a given timeseries into plottable data for plotting libraries like highcharts.
 * @param timeseries Timeseries to be converted.
 * @returns Timeseries with the values being transformed into correct numbers and removed null-values and the time being transformed into a number representation format.
 */
export function convertTimeseriesToPlottableData (timeseries: Timeseries): TimeseriesShort<number> {
  const resultTimeseries: TimeseriesShort<number> = []
  for (const obs of timeseries) {
    const valueType: string = typeof obs.value

    if (valueType === 'string') {
      return []
    } else {
      resultTimeseries.push([
        new Date(obs.time).valueOf(),
        valueType === 'boolean' ? (obs.value ? Number(1) : Number(0)) : obs.value,
      ])
    }
  }
  return resultTimeseries
}

/**
 * Retrieves the timeseries for the specified datapoints. If possible it retrieves them converted to the user's unit system and currency,
 * otherwise in their base unit.
 *
 * PLEASE NOTE: This function hardcodes several of the parameters passed to the API endpoint. Before using it, please check if they work for your usecase.
 *
 * @param projectId The project the datapoints are part of.
 * @param dataPointIDs The dataPointIDs or hashIDs of the datapoints for which to fetch the timeseries.
 * @param unitSystem The unit system of the user, if available.
 * @param currency The currency of the user, if available.
 * @param start Passed along to the endpoint without change.
 * @param end Defaults to current time, otherwise passed along to the endpoint.
 * @param samplerate Passed along to the endpoint without change.
 * @param closedInterval Defaults to false, otherwise passed along to the endpoint.
 * @throws Any error caused by the API call except if it returns status 422 while applying the user's unit system and currency.
 * @returns The response of the API
 */
export async function fetchProjectTimeseries (
  projectId: number,
  dataPointIDs: string[],
  unitSystem?: string,
  currency?: string,
  start?: Date,
  end?: Date,
  samplerate?: string,
  closedInterval?: boolean,
  max?: number,
  abortControllerSignal?: AbortSignal): Promise<TimeseriesWithContext[]> {
  const callGetProjectTimeseries = async (unitSystem?: string, currency?: string) => {
    return await aedifionApi.Project.getProjectTimeseries(
      projectId,
      dataPointIDs,
      start,
      end ?? new Date(Date.now()),
      max,
      samplerate,
      undefined,
      undefined,
      false,
      closedInterval ?? false,
      unitSystem,
      currency,
      undefined,
      { signal: abortControllerSignal },
    )
  }
  let result: TimeseriesWithContext[] = []
  try {
    result = await callGetProjectTimeseries(unitSystem, currency)
  } catch (error) {
    if ((unitSystem !== undefined || currency !== undefined) && (error as HttpError).status === 422) {
      result = await callGetProjectTimeseries()
    } else {
      throw error
    }
  }
  return result
}

/**
 * Returns an object containing the minimum and maximum values of a given timeseries.
 * @param input - An array of arrays containing the timestamp and value of each datapoint in the timeseries.
 * @returns An object with the minimum and maximum values of the timeseries. If the input array is empty or null, both values will be null.
 */
export function getMinAndMaxValuesOfTimeSeries (input: Array<Array<number|null>>): Record<'min'|'max', number|null> {
  if (!input || input?.length === 0) {
    return {
      max: null,
      min: null,
    }
  }

  let min: null|number = null
  let max: null|number = null

  input.forEach((item) => {
    if (item[1] !== null) {
      if (min !== null && max !== null) {
        min = Math.min(item[1], min)
        max = Math.max(item[1], max)
      } else {
        min = item[1]
        max = item[1]
      }
    }
  })
  return { max, min }
}

/**
 * Internal function to calculate the weighted arithmetic mean of two values.
 * @internal
 * @param firstValue Value that appears to be observed before the second value.
 * @param lastValue Value that appears to be observed after the first value.
 * @returns Number representing the weighted arithmetic mean of the two values or null, if no valid value could be calculated.
 */
function meanValue (firstValue: TimeseriesValue<number>, lastValue: TimeseriesValue<number>): number|null {
  if (firstValue[1] === null) return null
  const timeDifference = lastValue[0] - firstValue[0]
  return timeDifference * (firstValue[1] as number)
}

/**
 * Returns the weighted arithmetic mean of the given timeseries.
 * @param timeseries The timeseries to calculate the mean value of.
 * @returns The mean value as a number or null, if no valid value could be calculated.
 */
export function calculateAverage (timeseries: TimeseriesShort<number>): number|null {
  const timeseriesWithoutNullValues: TimeseriesShort<number> = timeseries.filter((value) => value[1] !== null)

  if (timeseriesWithoutNullValues.length === 0) return null
  else if (timeseriesWithoutNullValues.length === 1) return Number(timeseriesWithoutNullValues[0][1])

  let sum = null
  for (let i = 0; i < timeseriesWithoutNullValues.length - 1; i++) {
    const mean = meanValue(timeseriesWithoutNullValues[i], timeseriesWithoutNullValues[i + 1])
    if (mean !== null) {
      if (sum !== null) {
        sum += mean
      } else {
        sum = mean
      }
    }
  }

  const lastTime = timeseriesWithoutNullValues[timeseriesWithoutNullValues.length - 1][0]
  const firstTime = timeseriesWithoutNullValues[0][0]
  const timeDifference = lastTime - firstTime * 1.0

  if (sum === null) return null
  else return sum / timeDifference
}

/* Returns the stringified type of a timeseries value.
 * @param value The value to get the type of.
 * @returns string The stringified type of the value.
 */
export function getTypeOfTimeseriesValue (value: unknown) {
  if (value === null) {
    return 'null'
  } else {
    return typeof value
  }
}
