<script setup lang="ts">
import { computed, onUpdated } from 'vue'
import { RouteLocation, useRouter } from 'vue-router'
import { AnalyticsResultsForComponentResultIds } from '@aedifion.io/aedifion-api'
import ColoredFigure from '@/components/ColoredFigure.vue'
import { formatValue } from '@/filters/formatting'
import { getBackgroundAndFigureColors } from '@/utils/helpers/colors'
import { getColorForKPI } from '@/utils/helpers/kpiAggregations'
import { KPIItem } from '@/stores/views/Overview/types'
import { STATUS } from '@/services/alerta/types'
import { useI18n } from 'vue-i18n'
import { useOverviewStore } from '@/stores/views/Overview/overview'
import { useStore } from '@/vuex'
import { useAlertsStore } from '@/stores/views/Alerts/alerts'

type StatusIndicator = {
  backgroundColor: string|undefined
  figureColor: string|undefined
  icon: boolean
  text: string
  to: RouteLocation
  value: string
  tooltipText: string
}

interface Props {
  projectId: number
}

const props = defineProps<Props>()

const { t } = useI18n()
const router = useRouter()
const overviewStore = useOverviewStore()
const vuexStore = useStore()
const alertsStore = useAlertsStore()

const energyConsumptionKpi = computed<KPIItem | undefined>(() => {
  const kpi = overviewStore.energyKpiAggregation.find(project => project.projectId === props.projectId)
  if (kpi?.aggregationValue !== undefined) {
    return kpi
  } else {
    return undefined
  }
})

const energyConsumptionKpiValue = computed<string | undefined>(() => {
  return formatValue(energyConsumptionKpi.value?.aggregationValue, {
    displayUnit: false,
    unit: energyConsumptionKpi.value?.unit,
  })
})

/*
    no `energyConsumptionKpiUnit` because the unit that is returned by the API
    is wrong: it returns a /month unit, but we aggregate (sum) over a year, so
    it is effectively a yearly value, not monthly.
*/

const productivityKpi = computed<KPIItem | undefined>(() => {
  const kpi = overviewStore.productivityKpiAggregation.find(project => project.projectId === props.projectId)
  if (kpi?.aggregationValue !== undefined) {
    return kpi
  } else {
    return undefined
  }
})

const productivityKpiValue = computed<string | undefined>(() => {
  return formatValue(productivityKpi.value?.aggregationValue, {
    displayUnit: false,
    unit: productivityKpi.value?.unit,
  })
})

const productivityKpiUnit = computed<string | undefined>(() => {
  return productivityKpi.value?.unit
})

// Necessary to fetch the data if the user searches for a project
onUpdated(() => {
  if (overviewStore.energyKpiAggregation.find(project => project.projectId === props.projectId) === undefined) {
    overviewStore.fetchEnergyConsumptionKpi([props.projectId])
  }

  if (overviewStore.productivityKpiAggregation.find(project => project.projectId === props.projectId) === undefined) {
    overviewStore.fetchProductivityKpi([props.projectId])
  }
})

// Fetch the data once the component is scrolled into view
if (overviewStore.energyKpiAggregation.find(project => project.projectId === props.projectId) === undefined) {
  overviewStore.fetchEnergyConsumptionKpi([props.projectId])
}

if (overviewStore.productivityKpiAggregation.find(project => project.projectId === props.projectId) === undefined) {
  overviewStore.fetchProductivityKpi([props.projectId])
}

const isLoading = computed<boolean>(() => {
  const projectLoading = vuexStore.getters['projects/isLoadingProject'](props.projectId)
  const buildingComponentLoading = vuexStore.getters['building_analyses/isLoadingBuildingComponentOfProject'](props.projectId)
  const analysesLoading = vuexStore.getters['building_analyses/isLoadingAnalysesOfProject'](props.projectId)
  const alertsLoading = alertsStore.isLoadingAlertsOfProject(props.projectId)

  return projectLoading || buildingComponentLoading || analysesLoading || alertsLoading || overviewStore.isLoadingProjectEnergyKPI(props.projectId) || overviewStore.isLoadingProjectProductivityKPI(props.projectId)
})

const energyConsumptionStatusIndicator = computed<StatusIndicator | null>(() => {
  const energyConsumptionColor = getColorForKPI(energyConsumptionKpi.value?.aggregationValue, 'energy_consumption')

  if (energyConsumptionColor !== 'grey') {
    const energyConsumptionLocation = router.resolve({
      name: 'asset-overview',
      params: {
        project: props.projectId.toString(),
      },
    })

    const energyConsumptionColorFigure = getBackgroundAndFigureColors(energyConsumptionColor)

    return {
      backgroundColor: energyConsumptionColorFigure.backgroundColor,
      figureColor: energyConsumptionColorFigure.figureColor,
      icon: !energyConsumptionKpiValue.value,
      id: 1,
      tooltipText: getTooltipText(overviewStore.energyKpiAggregation),
      text: t('analysis_titles.energy_consumption_analysis') as string,
      to: energyConsumptionLocation,
      unit: energyConsumptionKpi.value?.unit,
      value: energyConsumptionKpiValue.value ?? 'fa:far fa-circle-xmark',
    }
  }

  return null
})

const wellbeingAnalysis = computed<AnalyticsResultsForComponentResultIds|null>(() => {
  if (vuexStore.getters['building_analyses/projectUsesSampleData'](props.projectId)) {
    return null
  } else {
    return vuexStore.getters['building_analyses/analysisOfProject'](props.projectId, 'wellbeing_analysis')
  }
})

const productivityStatusIndicator = computed<StatusIndicator | null>(() => {
  const productivityColor = getColorForKPI(productivityKpi.value?.aggregationValue, 'productivity')

  if (productivityColor !== 'grey') {
    const productivityLocation = router.resolve({
      name: 'asset-overview',
      params: {
        project: props.projectId.toString(),
      },
      query: {
        instance: wellbeingAnalysis.value?.instance_id?.toString(),
        result: wellbeingAnalysis.value?.result_id,
      },
    })

    const productivityColorFigure = getBackgroundAndFigureColors(productivityColor)

    return {
      backgroundColor: productivityColorFigure.backgroundColor,
      figureColor: productivityColorFigure.figureColor,
      icon: !productivityKpiValue.value,
      id: 2,
      tooltipText: getTooltipText(overviewStore.productivityKpiAggregation),
      text: t('analysis_titles.wellbeing_analysis') as string,
      to: productivityLocation,
      unit: productivityKpiUnit.value,
      value: productivityKpiValue.value ?? 'fa:far fa-circle-xmark',
    }
  }

  return null
})

const incidentsColor = computed(() => {
  const alertCounts: Partial<Record<STATUS, number>>|null = alertsStore.incidentAlertCountsOfProject(props.projectId)
  if (alertCounts === null) {
    return 'grey'
  }
  if (alertCounts.open && alertCounts.open > 0) {
    return 'red'
  } else if (alertCounts.ack && alertCounts.ack > 0) {
    return 'yellow'
  } else {
    return 'green'
  }
})

const openIncidents = computed<string|undefined>(() => {
  const alertCounts: Partial<Record<STATUS, number>>|null = alertsStore.incidentAlertCountsOfProject(props.projectId)
  return formatValue(alertCounts?.open, {
    displayUnit: false,
  })
})

const incidentsStatusIndicator = computed(() => {
  if (incidentsColor.value !== 'grey') {
    const incidentsLocation = router.resolve({
      name: 'status-and-alerts',
      params: {
        project: props.projectId.toString(),
      },
    })

    const incidentsIcon = incidentsColor.value ? 'fa:far fa-circle-check' : 'fa:far fa-circle-xmark'

    return {
      backgroundColor: getBackgroundAndFigureColors(incidentsColor.value).backgroundColor,
      figureColor: getBackgroundAndFigureColors(incidentsColor.value).figureColor,
      icon: !openIncidents.value,
      id: 3,
      text: t('analysis_titles.incidents') as string,
      to: incidentsLocation,
      value: openIncidents.value || incidentsIcon,
    }
  }

  return null
})

const statusIndicators = computed<StatusIndicator[]>(() => {
  return [
    energyConsumptionStatusIndicator.value,
    productivityStatusIndicator.value,
    incidentsStatusIndicator.value,
  ].filter((value): value is StatusIndicator => value !== null)
})

function getTooltipText (kpiAggregationValues: KPIItem[]): string {
  const kpi = kpiAggregationValues.find(project => project.projectId === props.projectId)
  if (kpi?.startDate && kpi?.endDate) {
    return kpi.tooltipText!
  } else {
    return t('no_active_analyses')
  }
}

// #region FETCH DATA
async function fetchRequiredData () {
  if (!vuexStore.getters['building_analyses/isBuildingComponentOfProjectLoaded'](props.projectId)) {
    await vuexStore.dispatch('building_analyses/fetchBuildingComponentForProject', props.projectId)
  }
  if (!alertsStore.areIncidentAlertsOfProjectLoaded(props.projectId)) {
    alertsStore.fetchIncidentAlertsForProject(props.projectId)
  }
  if (!vuexStore.getters['building_analyses/areAnalysesOfProjectLoaded'](props.projectId)) {
    vuexStore.dispatch('building_analyses/fetchBuildingAnalysesForProject', props.projectId)
  }
}

fetchRequiredData()
// #endregion
</script>

<template>
  <div
    v-if="isLoading"
    class="skeleton-loader"
  >
    <div>
      <v-skeleton-loader
        type="image"
        class="bg-transparent mb-1 overflow-hidden"
        width="80px"
        height="30px"
      />
      <v-skeleton-loader
        type="image"
        class="bg-transparent mt-1 overflow-hidden"
        width="70px"
        height="20px"
      />
    </div>
    <div>
      <v-skeleton-loader
        type="image"
        class="bg-transparent mb-1 overflow-hidden"
        width="30px"
        height="30px"
      />
      <v-skeleton-loader
        type="image"
        class="bg-transparent mt-1 overflow-hidden"
        width="70px"
        height="20px"
      />
    </div>
    <div>
      <v-skeleton-loader
        type="image"
        class="bg-transparent mb-1 overflow-hidden"
        width="30px"
        height="30px"
      />
      <v-skeleton-loader
        type="image"
        class="bg-transparent mt-1 overflow-hidden"
        width="70px"
        height="20px"
      />
    </div>
  </div>
  <div
    v-else
    class="d-flex flex-nowrap"
  >
    <router-link
      v-for="(statusIndicator, index) in statusIndicators"
      :key="statusIndicator.id"
      :to="statusIndicator.to"
    >
      <ColoredFigure
        :background-color="statusIndicator.backgroundColor"
        :class="{ 'ml-6': index > 0 }"
        :figure-color="statusIndicator.figureColor"
        :icon="statusIndicator.icon"
        :text="statusIndicator.text"
        :unit="statusIndicator.unit"
        :value="statusIndicator.value"
        :tooltip-text="statusIndicator.tooltipText"
      />
    </router-link>
  </div>
</template>

<style lang="sass" scoped>
  .skeleton-loader
    display: flex
    justify-content: space-between
    gap: 15px
    height: 70px

  a
    text-decoration: none
</style>

<i18n lang="json" locale="de">
  {
    "no_active_analyses": "Keine aktiven Analysen"
  }
  </i18n>
  <i18n lang="json" locale="en">
  {
    "no_active_analyses": "No active analyses"
  }
  </i18n>
