import { CondensedAnalysisFunctionWithResult } from '@/vuex/optimization/getters'

/**
 * Sorts analysis functions by their signal_color. Order is: red, yellow, green, grey.
 * @param analysisFunctionsWithResults List of analysis functions including results
 * @returns Analysis functions with results, sorted by signal_color
 */
export function sortAnalysisFunctions (analysisFunctionsWithResults: CondensedAnalysisFunctionWithResult[]): CondensedAnalysisFunctionWithResult[] {
  let sortedAnalysisFunctions: CondensedAnalysisFunctionWithResult[] = []
  const redFunctions: CondensedAnalysisFunctionWithResult[] = []
  const yellowFunctions: CondensedAnalysisFunctionWithResult[] = []
  const greenFunctions: CondensedAnalysisFunctionWithResult[] = []
  const restFunctions: CondensedAnalysisFunctionWithResult[] = []

  for (const analysisFunction of analysisFunctionsWithResults) {
    switch (analysisFunction.signal_color) {
      case 'red':
        redFunctions.push(analysisFunction)
        break
      case 'yellow':
        yellowFunctions.push(analysisFunction)
        break
      case 'green':
        greenFunctions.push(analysisFunction)
        break
      default:
        restFunctions.push(analysisFunction)
        break
    }
  }

  sortedAnalysisFunctions = redFunctions.concat(yellowFunctions, greenFunctions, restFunctions)

  return sortedAnalysisFunctions
}
