<i18n locale="de">
{
  "patience": "Die KPIs werden in wenigen Minuten zur Verfügung stehen.",
  "started": "gestartet",
  "title_done": "Analysen werden durchgeführt",
  "title_preparing": "Analysen werden vorbereitet",
  "title_starting": "Analysen werden gestartet"
}
</i18n>
<i18n locale="en">
{
  "patience": "The KPIs will be available in a few minutes.",
  "started": "started",
  "title_done": "Analyses are running",
  "title_preparing": "Analyses are being prepared",
  "title_starting": "Analyses are being started"
}
</i18n>

<template>
  <v-dialog
    persistent
    :model-value="value"
    width="500"
  >
    <v-card>
      <v-card-title>
        {{ cardTitle }}
      </v-card-title>
      <v-card-text class="d-flex flex-column align-center">
        <v-progress-circular
          class="ma-6"
          color="primary-darken2"
          :indeterminate="progressBarIndeterminate"
          :rotate="-90"
          :size="127"
          :model-value="analysisExecutionsProgressPercent"
          :width="7"
        >
          <div
            v-if="totalAnalysisExecutions > 0 && doneAnalysisExecutions < totalAnalysisExecutions"
            class="d-flex flex-column align-center text-neutral-darken2"
          >
            <div>{{ analysisExecutionsProgressPercent }} %</div>
            <div>{{ t('started') }}</div>
          </div>
          <v-icon
            v-else-if="totalAnalysisExecutions > 0 && doneAnalysisExecutions === totalAnalysisExecutions && !isUserMeterAnalysisRunning"
            color="success"
            size="large"
          >
            fa:far fa-check
          </v-icon>
        </v-progress-circular>
        <div
          v-if="doneAnalysisExecutions > 0 && doneAnalysisExecutions === totalAnalysisExecutions && !isUserMeterAnalysisRunning"
          class="text-body-1"
        >
          {{ t('patience') }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-auto"
          color="primary"
          :disabled="doneAnalysisExecutions === 0 || doneAnalysisExecutions < totalAnalysisExecutions"
          @click="$emit('calculate-kpis-info:close')"
        >
          {{ t('actions.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'CalculateKpisPendingInfo',

  props: {
    value: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },

  emits: ['calculate-kpis-info:close'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  computed: {
    analysisExecutionsProgressPercent (): number {
      if (this.totalAnalysisExecutions > 0) {
        return Math.floor(this.doneAnalysisExecutions * (100 / this.totalAnalysisExecutions))
      } else {
        return 100
      }
    },

    cardTitle (): string {
      if (this.totalAnalysisExecutions === 0) {
        return this.t('title_preparing') as string
      } else if (this.doneAnalysisExecutions !== this.totalAnalysisExecutions) {
        return this.t('title_starting') as string
      } else {
        return this.t('title_done') as string
      }
    },

    doneAnalysisExecutions (): number {
      return this.$store.getters['asset_overview/numberOfDoneAnalysisExecutions']
    },

    isUserMeterAnalysisRunning (): boolean {
      return this.$store.getters['asset_overview/isUserMeterAnalysisRunning']
    },

    progressBarIndeterminate (): boolean {
      return this.totalAnalysisExecutions === 0 || (this.isUserMeterAnalysisRunning && this.doneAnalysisExecutions === this.totalAnalysisExecutions)
    },

    totalAnalysisExecutions (): number {
      return this.$store.getters['asset_overview/numberOfTotalAnalysisExecutions']
    },
  },
})
</script>
