import { AssetProfileState } from './types'
import { MutationTree } from 'vuex'

export default {
  SET_UPDATING_CERTIFICATIONS_DATA: (state: AssetProfileState, updating: boolean) => (state.updatingCertificationsData = updating),

  SET_UPDATING_EQUIPMENT_DATA: (state: AssetProfileState, updating: boolean) => (state.updatingEquipmentData = updating),

  SET_UPDATING_GENERAL_DATA: (state: AssetProfileState, updating: boolean) => (state.updatingGeneralData = updating),

  SET_UPDATING_STAKEHOLDERS_DATA: (state: AssetProfileState, updating: boolean) => (state.updatingStakeholdersData = updating),
} as MutationTree<AssetProfileState>
