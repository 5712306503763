<script setup lang="ts">
import ComponentIcon from './ComponentIcon.vue'
import ComponentPinCard from './ComponentPinCard.vue'
import { ComponentPinCardData } from '@/vuex/mapping_editor/types'
import { computed } from 'vue'

type Props = {
  componentAlphanumericId?: string,
  componentInProjectName?: string,
  componentName?: string,
  loading?: boolean,
  pins: ComponentPinCardData[],
  pinsWithPendingMappingUpdates: number[]
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
})

const emit = defineEmits<{
  (e: 'component-pins:pinSelected', id: number): void
}>()

const sortedPins = computed<ComponentPinCardData[]>(() => {
  return props.pins.slice().sort((a: ComponentPinCardData, b: ComponentPinCardData) => {
    return a.name.localeCompare(b.name)
  })
})

function hasPendingMappingUpdate (id: number): boolean {
  return props.pinsWithPendingMappingUpdates.includes(id)
}
</script>

<template>
  <v-card data-testid="component-pins">
    <div class="d-flex">
      <ComponentIcon
        :alphanumeric-id="props.componentAlphanumericId"
        class="ma-3"
        size="60"
      />
      <div>
        <v-card-title>
          {{ props.componentInProjectName }}
        </v-card-title>
        <v-card-subtitle class="text-subtitle-1">
          {{ props.componentName }}
        </v-card-subtitle>
      </div>
    </div>
    <v-card-text v-if="props.loading">
      <v-row>
        <v-col
          v-for="index of 3"
          :key="`upper_list_skeleton_item_${index}`"
        >
          <v-sheet
            v-ripple
            class="pa-3"
            border
            rounded
          >
            <v-skeleton-loader
              class="tw-overflow-hidden tw-bg-transparent ml-n4 mt-n4 mb-n1"
              type="text"
              width="40%"
            />
            <v-skeleton-loader
              class="tw-overflow-hidden"
              height="35px"
              type="image"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <v-item-group
        @update:model-value="emit('component-pins:pinSelected', $event)"
      >
        <v-row>
          <v-col
            v-for="pin in sortedPins"
            :key="pin.id"
            cols="6"
            md="4"
            xl="3"
          >
            <v-item
              v-slot="{ toggle, isSelected }"
              :value="pin.id"
            >
              <ComponentPinCard
                :loading="hasPendingMappingUpdate(pin.id)"
                :pin-data="pin"
                :selected="isSelected"
                v-bind="$attrs"
                @component-pin-card:click-pin="toggle"
              />
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </v-card-text>
  </v-card>
</template>
