
import { storeToRefs } from 'pinia'
import { Task, UpdateTask } from '@aedifion.io/aedifion-api'
import { useUserStore } from '@/stores/user'

export function extractProperties (task: Task): UpdateTask {
  const { userDetails: currentUser } = storeToRefs(useUserStore())
  return {
    analytics_result: task.analytics_result?.length ? [task.analytics_result[0].id!] : undefined,
    assignee_user_id: task.assignee! ? task.assignee!.id! : undefined,
    componentinproject: task.componentinproject?.length ? [task.componentinproject[0].id!] : undefined,
    description: task.description!,
    end: task.end,
    priority: task.priority!,
    reporter_user_id: task.reporter?.id ?? currentUser.value!.id!,
    start: task.start,
    status: task.status!,
    title: task.title!,
  }
}
