import { ProjectsState } from './types'

export default {
  currentProjectId: null,
  loading: false,
  projects: [],
} as ProjectsState

export function resetStoreState (state: ProjectsState): void {
  state.currentProjectId = null
  state.loading = false
  state.projects = []
}
