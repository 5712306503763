import actions from './actions'
import getters from './getters'
import { Module } from 'vuex'
import mutations from './mutations'
import { RootState } from '../types'
import state from './state'
import { StatusAndAlertsState } from './types'

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
} as Module<StatusAndAlertsState, RootState>
