<script setup lang="ts">
import ActionMenu, { ActionMenuItem } from '@/components/ActionMenu.vue'

interface Props {
  defaultUnit: string;
  unitList: ActionMenuItem[];
  disabled?: boolean;
}

defineProps<Props>()

const emit = defineEmits<{(e: 'update-unit', unit: string): void}>()

const onUpdateUnit = (event: MouseEvent) => {
  const unit = (event.target as HTMLElement).innerText

  emit('update-unit', unit)
}
</script>

<template>
  <ActionMenu
    :items="unitList"
    :disabled
    offset-direction="x"
    @click="onUpdateUnit($event)"
  >
    <template #default="menuProps">
      <v-btn
        v-bind="menuProps"
        color="neutral-darken1"
        variant="outlined"
        class="ma-1"
        size="small"
        data-testid="unit-selector-button"
      >
        <span class="mr-2 text-neutral-darken2">{{ defaultUnit }}</span>
        <v-icon
          :class="[ 'dropdown-activator-arrow mt-1', {'active': menuProps.isOpen} ]"
          color="neutral-darken2"
          size="small"
        >
          fa:far fa-chevron-down
        </v-icon>
      </v-btn>
    </template>
  </ActionMenu>
</template>
