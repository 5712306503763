import { AnalysisResultsOverview, AnalyticsOverview, AnalyticsOverviewItem, KPIResult, Savings } from '@aedifion.io/aedifion-api'
import { type ComponentOverviewItem, type OverviewSummary } from './types'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { textForLocale } from '@/utils/helpers/locale'
import { useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { useAppStore } from '@/stores/app'
import { useSavingsPotentialStore } from './savingsPotential'
import { validateNotNullish } from '@/utils/helpers/validate'
import { VUETIFY_COLORS } from '@theme/colors'

const COST_SAVINGS_ID = 'financial_absolute'
const CO2_SAVINGS_ID = 'co2_emissions_absolute'
const ENERGY_SAVINGS_ID = 'energy_consumption_absolute'

export const useOptimizationsOverviewStore = defineStore('optimizationsOverview', () => {
  const analyticsApiStore = useAnalyticsApiStore()
  const savingsPotentialsStore = useSavingsPotentialStore()
  const appStore = useAppStore()

  const isLoadingOverviewItems = ref(false)

  const overview = ref<AnalyticsOverview|null>(null)

  async function getOverview () {
    const projectId = validateNotNullish(appStore.projectId, { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string })
    try {
      isLoadingOverviewItems.value = true
      const result: AnalyticsOverview = await analyticsApiStore.getOverview({ projectId })
      overview.value = result
    } catch (error) {
      showErrorNotification('Could not fetch overview information for the optimizations.')
    } finally {
      isLoadingOverviewItems.value = false
    }
  }

  const componentSummaryItems = computed<ComponentOverviewItem[]>(() => {
    const componentSummaryItems: ComponentOverviewItem[] = []
    const componentSavingsMap: Map<number, KPIResult[]> = new Map();

    (savingsPotentialsStore.savingsPotential ?? []).forEach((savingsPotentialItem: Savings) => {
      const costSavings: KPIResult|undefined = savingsPotentialItem.savings.find((saving: KPIResult) => saving.context === COST_SAVINGS_ID)
      if (costSavings) {
        if (componentSavingsMap.has(savingsPotentialItem.component!.id!)) {
          componentSavingsMap.get(savingsPotentialItem.component!.id!)!.push(costSavings)
        } else {
          componentSavingsMap.set(savingsPotentialItem.component!.id!, [costSavings])
        }
      }
    });

    (overview.value?.items ?? []).forEach((overviewItem: AnalyticsOverviewItem) => {
      const costSavingsForComponent = componentSavingsMap.get(overviewItem.component!.id!)
      let savings = 0
      if (costSavingsForComponent) {
        costSavingsForComponent.forEach((result: KPIResult) => {
          savings += result.value ?? 0
        })
      }

      const newItem: ComponentOverviewItem = {
        alphanumericId: overviewItem.component.alphanumeric_id,
        countHigh: overviewItem.analyses.red,
        countMedium: overviewItem.analyses.yellow,
        countOptimized: overviewItem.analyses.green,
        name: textForLocale(overviewItem.component.nameDE, overviewItem.component.nameEN),
        numberOfComponents: overviewItem.component.components_in_project_count,
        savings: savings !== 0 ? savings : undefined,
      }

      componentSummaryItems.push(newItem)
    })

    return componentSummaryItems
      .sort((itemA: ComponentOverviewItem, itemB: ComponentOverviewItem) => {
        // Sort by savings
        if (itemA.savings !== itemB.savings) {
          return (itemB.savings ?? 0) - (itemA.savings ?? 0)
        }

        // Sort by severity = high
        if (itemA.countHigh !== itemB.countHigh) {
          return (itemB.countHigh ?? 0) - (itemA.countHigh ?? 0)
        }

        // Sort by severity = medium
        if (itemA.countMedium !== itemB.countMedium) {
          return (itemB.countMedium ?? 0) - (itemA.countMedium ?? 0)
        }

        // Sort by severity = optimized
        if (itemA.countOptimized !== itemB.countOptimized) {
          return (itemB.countOptimized ?? 0) - (itemA.countOptimized ?? 0)
        }

        // sort by name
        return itemA.name.localeCompare(itemB.name)
      })
  })

  const overviewSummary = computed<OverviewSummary>(() => {
    const overviewSummary = overview.value?.overview ?? null as AnalysisResultsOverview|null
    const numberOfRedAnalyses = overviewSummary?.analyses.red ?? 0
    const numberOfYellowAnalyses = overviewSummary?.analyses.yellow ?? 0
    const numberOfGreenAnalyses = overviewSummary?.analyses.green ?? 0
    return [{
      color: VUETIFY_COLORS.error.base,
      displayedFigure: numberOfRedAnalyses.toString(),
      figureColor: VUETIFY_COLORS.error.darken2,
      id: 'high',
      proportion: numberOfRedAnalyses,
    }, {
      color: VUETIFY_COLORS.warning.base,
      displayedFigure: numberOfYellowAnalyses.toString(),
      figureColor: VUETIFY_COLORS.warning.darken2,
      id: 'medium',
      proportion: numberOfYellowAnalyses,
    }, {
      color: VUETIFY_COLORS.success.base,
      displayedFigure: numberOfGreenAnalyses.toString(),
      figureColor: VUETIFY_COLORS.success.darken2,
      id: 'optimized',
      proportion: numberOfGreenAnalyses,
    }]
  })

  const totalCostSavings = computed<number>(() => {
    let totalCostsSavings = 0;
    (savingsPotentialsStore.savingsPotential ?? []).forEach((savingsPotentialItem: Savings) => {
      savingsPotentialItem.savings.forEach((saving: KPIResult) => {
        if (saving.context === COST_SAVINGS_ID) {
          totalCostsSavings += saving.value ?? 0
        }
      })
    })
    return totalCostsSavings
  })

  const totalCo2Savings = computed<number>(() => {
    let totalCo2Savings = 0;
    (savingsPotentialsStore.savingsPotential ?? []).forEach((savingsPotentialItem: Savings) => {
      const co2Savings: number|null = savingsPotentialItem.savings.reduce((acc: number|null, saving: KPIResult) => {
        if (saving.context === CO2_SAVINGS_ID) {
          if (acc === null) {
            return saving.value ?? 0
          }
          return acc + (saving.value ?? 0)
        } else {
          return acc
        }
      }, 0) ?? null
      if (co2Savings !== null) {
        totalCo2Savings += co2Savings
      }
    })
    return totalCo2Savings
  })

  const totalEnergySavings = computed<number>(() => {
    let totalEnergySaving = 0;
    (savingsPotentialsStore.savingsPotential ?? []).forEach((savingsPotentialItem: Savings) => {
      const energySavings: number|null = savingsPotentialItem.savings.reduce((acc: number|null, saving: KPIResult) => {
        if (saving.context === ENERGY_SAVINGS_ID) {
          if (acc === null) {
            return saving.value ?? 0
          }
          return acc + (saving.value ?? 0)
        } else {
          return acc
        }
      }, 0) ?? null
      if (energySavings !== null) {
        totalEnergySaving += energySavings
      }
    })
    return totalEnergySaving
  })

  return {
    componentSummaryItems,
    getOverview,
    isLoadingOverviewItems,
    overview,
    overviewSummary,
    totalCo2Savings,
    totalCostSavings,
    totalEnergySavings,
  }
})
