<template>
  <v-container
    class="fill-height mx-auto pa-10 main-container align-start"
    fluid
  >
    <slot />
  </v-container>
</template>

<style lang="sass">
.main-container
    /* Max-width: 1640 + (2 * 40) = 1720 */
    max-width: 1720px
    position: relative
</style>
