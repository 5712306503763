/**
 * @param componentAlphanumericId The alphanumeric id of the component in question
 * @returns The alphanumeric id of the energy price attribute for the component or undefined if it does not have one
 */
export function energyPriceAttributeId (componentAlphanumericId: string): string|undefined {
  switch (componentAlphanumericId) {
    case 'CM':
      return 'CM+PRIC_EN'
    case 'ELM':
      return 'ELM+PRIC_EL'
    case 'GASM':
      return 'GASM+PRIC_FUEL'
    case 'HM':
      return 'HM+PRIC_EN'
    default:
      return undefined
  }
}

/**
 * @param componentAlphanumericId The alphanumeric id of the component in question
 * @returns The alphanumeric id of the pin for the readings datapoint.
 * @throws All meters should have one, so if it's an unknown meter it throws an error instead.
 */
export function readingsPinAlphanumericId (componentAlphanumericId: string): string {
  switch (componentAlphanumericId) {
    case 'CM':
      return 'CM+EN_C'
    case 'ELM':
      return 'ELM+EN_EL_ACT'
    case 'GASM':
      return 'GASM+VOL'
    case 'HM':
      return 'HM+EN_H'
    case 'WSM':
      return 'WSM+VOL'
    default:
      throw Error(`Missing readings pin id for meter with id ${componentAlphanumericId}`)
  }
}

/**
 * @param componentAlphanumericId The alphanumeric id of the component in question
 * @returns The id of the label for the unit of the readings datapoint.
 * @throws All meters should have one, so if it's an unknown meter it throws an error instead.
 */
export function unitLabelId (componentAlphanumericId: string): string {
  switch (componentAlphanumericId) {
    case 'CM':
      return 'kilowatt-hours'
    case 'ELM':
      return 'kilowatt-hours'
    case 'GASM':
      return 'cubic-meters'
    case 'HM':
      return 'kilowatt-hours'
    case 'WSM':
      return 'generic'
    default:
      throw Error(`Missing readings pin id for meter with id ${componentAlphanumericId}`)
  }
}
