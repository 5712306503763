/* eslint-disable camelcase */

export type DocsLinks = {
  analysis: {
    alarm_state_analysis: string,
    control_loop_oscillation_analysis: string,
    dew_point_alert_analysis: string,
    energy_conversion_analysis: string,
    fan_speed_analysis: string,
    filter_servicing_analysis: string,
    humidity_conditioning_analysis: string,
    operating_cycles_analysis: string,
    reduced_load_analysis: string,
    room_air_quality_analysis: string,
    schedule_analysis: string,
    sensor_outage_analysis: string,
    sequence_analysis: string,
    setpoint_deviation_analysis: string,
    setpoint_plausibility_analysis: string,
    synchronized_operation_analysis: string,
    temperature_spread_analysis: string,
    thermal_comfort_analysis: string,
    virtual_heat_meter_analysis: string,
    weather_station_analysis: string
  },
  asset_action_card_missing_kpi: string,
  asset_goal_setup: string,
  contact: string,
  home: string,
  incidents_alarms: string,
  mapping_manual: string,
  userMeters: string,
  optimization_controls_card_info: string
}

export const defaultLinks: DocsLinks = {
  analysis: {
    alarm_state_analysis: '{domain}/en/products/{products.analytics}/algorithms/#alarm-state',
    control_loop_oscillation_analysis: '{domain}/en/products/{products.analytics}/algorithms/#control-loop-oscillation',
    dew_point_alert_analysis: '{domain}/en/products/{products.analytics}/algorithms/#dew-point-alert',
    energy_conversion_analysis: '{domain}/en/products/{products.analytics}/algorithms/#energy-conversion',
    fan_speed_analysis: '{domain}/en/products/{products.analytics}/algorithms/#dynamic-control',
    filter_servicing_analysis: '{domain}/en/products/{products.analytics}/algorithms/#filter-servicing',
    humidity_conditioning_analysis: '{domain}/en/products/{products.analytics}/algorithms/#humidity-conditioning',
    operating_cycles_analysis: '{domain}/en/products/{products.analytics}/algorithms/#operating-cycles',
    reduced_load_analysis: '{domain}/en/products/{products.analytics}/algorithms/#reduced-load',
    room_air_quality_analysis: '{domain}/en/products/{products.analytics}/algorithms/#room-air-quality',
    schedule_analysis: '{domain}/en/products/{products.analytics}/algorithms/#schedule',
    sensor_outage_analysis: '{domain}/en/products/{products.analytics}/algorithms/#sensor-functionality',
    sequence_analysis: '{domain}/en/products/{products.analytics}/algorithms/#sequence',
    setpoint_deviation_analysis: '{domain}/en/products/{products.analytics}/algorithms/#setpoint-deviation',
    setpoint_plausibility_analysis: '{domain}/en/products/{products.analytics}/algorithms/#setpoint-plausibility',
    synchronized_operation_analysis: '{domain}/en/products/{products.analytics}/algorithms/#system-control',
    temperature_spread_analysis: '{domain}/en/products/{products.analytics}/algorithms/#temperature-spread',
    thermal_comfort_analysis: '{domain}/en/products/{products.analytics}/algorithms/#thermal-comfort',
    virtual_heat_meter_analysis: '{domain}/en/products/{products.analytics}/algorithms/#virtual-meter',
    weather_station_analysis: '{domain}/en/products/{products.analytics}/algorithms/#weather-station'
  },
  asset_action_card_missing_kpi: '',
  asset_goal_setup: '',
  contact: '{domain}/en/contact/',
  home: '{domain}/en/',
  incidents_alarms: '{domain}/en/products/{products.io}/features/#alarming-and-notifications',
  mapping_manual: '{domain}/en/user/guides-and-tutorials/#instancing-components',
  userMeters: '{domain}/en/user/frontend/asset-cockpit/meter',
  optimization_controls_card_info: '{domain}/en/products/{products.controls}/#ui'
}

export const germanLinks: Partial<DocsLinks> = {
  contact: '{domain}/de/contact/',
  home: '{domain}/de/',
  incidents_alarms: '{domain}/de/products/{products.io}/features/#alarme-und-benachrichtigungen',
  optimization_controls_card_info: '{domain}/de/products/{products.controls}/#ui'
}
