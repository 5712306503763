import LeaderLine from 'leader-line-new'
import { VUETIFY_COLORS } from '@theme/colors'

const leaderLines: {
  id: unknown,
  leaderLine: LeaderLine
}[] = []

export function addLeaderLine (startElement: HTMLElement | SVGElement | LeaderLine.AnchorAttachment, endElement: HTMLElement | SVGElement | LeaderLine.AnchorAttachment, id: unknown, shouldAnimate = false) {
  const gravity = 30
  const leaderLineOptions: LeaderLine.Options = {
    dash: { gap: 4, len: 4, animation: shouldAnimate },
    size: 1,
    color: VUETIFY_COLORS.neutral.base,
    endPlug: 'behind',
    startSocketGravity: [gravity, 0],
    endSocketGravity: [-gravity, 0],
  }
  const leaderLine = new LeaderLine(startElement, endElement, leaderLineOptions)
  leaderLine.position()
  leaderLines.push({ id, leaderLine })
}

export function removeLeaderLine (id: unknown) {
  leaderLines.filter((leaderLine) => leaderLine.id === id)
    .forEach((leaderLine) => leaderLine.leaderLine.remove())
}
