import { DBSchema, IDBPDatabase, openDB } from 'idb'
import { isIDBAvailable } from '@/utils/helpers/idb'

const CURRENT_SCHEMA_VERSION = 4
const DATAPOINTS_DATABASE = 'datapoints'
const DATAPOINTS_STORE = 'datapoints'

const DATAPOINTS_STORE_V1_TO_V3 = 'datapoints'

export type DatapointResource = {
  hashID: string;
  isBinary: boolean;
  timeseriesType: string;
}

interface DatapointsDb extends DBSchema {
  [DATAPOINTS_STORE]: {
    key: string,
    value: DatapointResource
  }
}

let database: IDBPDatabase<DatapointsDb> | undefined

/**
 * Use this function to open or retrieve (if it’s already open) the datapoints
 * IndexedDb database using a singleton.
 * @returns The current datapoints IndexedDb database.
 */
async function getDatabase (): Promise<IDBPDatabase<DatapointsDb> | undefined> {
  const idbAvailable: boolean = await isIDBAvailable()
  if (!database && idbAvailable) {
    database = await openDB(DATAPOINTS_DATABASE, CURRENT_SCHEMA_VERSION, {
      upgrade (db, oldSchemaVersion) {
        if (oldSchemaVersion < 1) {
          // this database has never been open before, we create the store
          db.createObjectStore(DATAPOINTS_STORE_V1_TO_V3, { keyPath: 'identifier' })
        }
        if (oldSchemaVersion < 4) {
          db.deleteObjectStore(DATAPOINTS_STORE_V1_TO_V3)
          db.createObjectStore(DATAPOINTS_STORE)
        }
        if (oldSchemaVersion < 5) {
          // put your code to migrate from v4 to v5 here
        }
      },
    })
  }

  return database
}

/**
 * Gets the datapoint resource identifier.
 * @param projectHandle The handle of the project to get the datapoint from.
 * @param hashId The hash ID of the datapoint to get.
 * @returns The datapoint resource identifier.
 */
export function getDatapointResourceId (projectHandle: string, hashId: string): string {
  return `${projectHandle}-${hashId}`
}

/**
 * Gets a datapoint from the database.
 * @param projectHandle The handle of the project to get the datapoint from.
 * @param hashId The hash ID of the datapoint to get.
 * @returns The corresponding datapoint resource.
 */
export async function getDatapoint (projectHandle: string, hashId: string): Promise<DatapointResource|null> {
  const db = await getDatabase()

  const identifier = getDatapointResourceId(projectHandle, hashId)
  return await db?.get(DATAPOINTS_STORE, identifier) ?? null
}

/**
 * Sets a datapoint in the database.
 * @param projectHandle The handle of the project to set the datapoint in.
 * @param hashId The hash ID of the datapoint to set.
 * @param isBinary Whether the timeseries should be treated as binary.
 * @param timeseriesType The JS type of the first observation of the timeseries.
 * @returns void
 */
export async function setDatapoint (projectHandle: string, hashId: string, isBinary: boolean, timeseriesType: string): Promise<void> {
  const db = await getDatabase()

  const identifier = getDatapointResourceId(projectHandle, hashId)
  await db?.put(DATAPOINTS_STORE, {
    hashID: hashId,
    isBinary,
    timeseriesType,
  }, identifier)
}
