import { ComponentAttributesEditorState } from './types'

export default {
  componentAttributeDefinitions: null,
  componentInProject: null,
  loading: false,
  loadingForFetch: false,
} as ComponentAttributesEditorState

export function resetStoreState (state: ComponentAttributesEditorState): void {
  state.componentAttributeDefinitions = null
  state.componentInProject = null
  state.loading = false
  state.loadingForFetch = false
}
