<template>
  <v-card
    class="d-flex flex-column pa-4"
    height="310"
  >
    <v-card-title
      v-if="loading"
      class="pb-1"
    >
      <v-skeleton-loader
        v-if="loading"
        class="mt-2"
        height="22"
        :loading="loading"
        min-width="75%"
        type="text"
      />
    </v-card-title>
    <v-card-text
      class="fill-height"
    >
      <div
        v-if="loading"
        class="mt-12"
      >
        <v-skeleton-loader
          height="20"
          :loading="loading"
          type="text"
        />
        <v-skeleton-loader
          height="20"
          :loading="loading"
          type="text"
        />
        <v-skeleton-loader
          height="20"
          :loading="loading"
          type="text"
        />
      </div>
      <div
        v-else-if="plot && plot.data"
        class="align-center fill-height"
      >
        <BulletGraph
          v-if="plot.type === 'bullet_graph'"
          :is-label="bulletGraphIsLabel"
          :is-value="bulletGraphIsValue"
          :set-label="bulletGraphSetLabel"
          :set-value="bulletGraphSetValue"
          :thresholds="bulletGraphThresholds"
          :title="plot.title"
          :unit="plot.unit"
        />
        <BarChart
          v-else-if="plot.type === 'bar_chart'"
          v-bind="plot"
          :custom-chart-options="{
            chart: {
              height: '240px'
            },
            plotOptions: {
              column: {
                colorByPoint: false
              }
            }
          }"
          :title="plot.title"
        />
        <PieChart
          v-else-if="plot.type === 'pie_chart'"
          v-bind="plot"
          :custom-chart-options="{
            plotOptions: {
              pie: {
                size: 140,
                states: {
                  inactive: {
                    opacity: 1
                  }
                }
              }
            }
          }"
          :title="plot.title"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { BulletGraphExtras, BulletGraphThreshold } from '@/components/Charts/types'
import { defineComponent, PropType } from 'vue'
import BarChart from '@/components/Charts/BarChart.vue'
import BulletGraph from '@/components/Charts/BulletGraph.vue'
import PieChart from '@/components/Charts/PieChart.vue'
import { PlotsResult } from '@aedifion.io/aedifion-api'

export const SUPPORTED_PLOT_TYPES = [
  'bar_chart',
  'bullet_graph',
  'pie_chart',
]

export default defineComponent({
  name: 'DetailedContentCardPlot',

  components: {
    BarChart,
    BulletGraph,
    PieChart,
  },

  props: {
    loading: {
      default: false,
      required: false,
      type: Boolean as PropType<boolean>,
    },

    plot: {
      default: null,
      required: false,
      type: Object as PropType<PlotsResult|null>,
    },
  },

  computed: {
    bulletGraphDataIsIndex (): number {
      if (this.plot && this.plot.type === 'bullet_graph' && this.plot.categories) {
        return this.plot.categories.findIndex((item) => item === 'is')
      } else {
        return -1
      }
    },

    bulletGraphDataSetIndex (): number {
      if (this.plot && this.plot.type === 'bullet_graph' && this.plot.categories) {
        return this.plot.categories.findIndex((item) => item === 'set')
      } else {
        return -1
      }
    },

    bulletGraphIsLabel (): string|null {
      if (this.bulletGraphDataIsIndex > -1 && this.plot && this.plot.type === 'bullet_graph') {
        return this.plot.data![this.bulletGraphDataIsIndex].name!
      } else {
        return null
      }
    },

    bulletGraphIsValue (): number|null {
      if (this.bulletGraphDataIsIndex > -1 && this.plot && this.plot.type === 'bullet_graph') {
        return this.plot.data![this.bulletGraphDataIsIndex].values![0]
      } else {
        return null
      }
    },

    bulletGraphSetLabel (): string|null {
      if (this.bulletGraphDataSetIndex > -1 && this.plot && this.plot.type === 'bullet_graph') {
        return this.plot.data![this.bulletGraphDataSetIndex].name!
      } else {
        return null
      }
    },

    bulletGraphSetValue (): number|null {
      if (this.bulletGraphDataSetIndex > -1 && this.plot && this.plot.type === 'bullet_graph') {
        return this.plot.data![this.bulletGraphDataSetIndex].values![0]
      } else {
        return null
      }
    },

    bulletGraphThresholds (): Array<BulletGraphThreshold> {
      if (this.plot && this.plot.type === 'bullet_graph') {
        return (this.plot.extras as BulletGraphExtras).thresholds
      } else {
        return []
      }
    },
  },
})
</script>
