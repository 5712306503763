<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { passwordRules } from '@/utils/helpers/security'
import { required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useUserSecurityStore } from '@/stores/views/User/security'
import { useVuelidate } from '@vuelidate/core'

const { t } = useI18n()

const router = useRouter()

const userSecurityStore = useUserSecurityStore()

const passwordData = reactive({
  currentPassword: '',
  newPassword: '',
})

const showCurrentPassword = ref(false)
const showNewPassword = ref(false)

const rules = computed(() => ({
  currentPassword: { required },
  newPassword: passwordRules,
}))

const v$ = useVuelidate(rules, passwordData)

const currentPasswordErrors = computed<string>(() => {
  if (v$.value.currentPassword.$dirty && !v$.value.currentPassword.required && v$.value.currentPassword.$error) {
    return t('errors.is_required', { field: t('current_password') })
  }
  return ''
})

const newPasswordErrors = computed<string>(() => {
  if (v$.value.newPassword.$error) {
    return t('errors.does_not_respect_password_rules', { field: t('new_password') })
  }
  return ''
})

const formIsIncomplete = computed<boolean>(() => {
  return !v$.value.$dirty
})

const hasErrors = computed<boolean>(() => {
  return v$.value.$errors.length > 0
})

function eraseForm () {
  passwordData.currentPassword = ''
  passwordData.newPassword = ''
  v$.value.$reset()
}

async function submit () {
  v$.value.$touch()
  if (!hasErrors.value) {
    const wasSuccessful = await userSecurityStore.updatePassword({
      currentPassword: passwordData.currentPassword,
      newPassword: passwordData.newPassword,
    })
    if (wasSuccessful) {
      router.push({ name: 'home' })
    } else {
      eraseForm()
    }
  }
}
</script>

<template>
  <div
    class="d-flex justify-center password-form-container"
    @keydown.esc="eraseForm"
  >
    <form
      @submit.prevent="submit"
    >
      <v-row>
        <input
          id="username"
          autocomplete="username email"
          data-hj-suppress
          name="username"
          readonly
          style="display:none;"
          type="text"
          :value="userSecurityStore.email"
        >
        <v-col cols="12">
          <v-text-field
            id="current-password"
            v-model="passwordData.currentPassword"
            :append-icon="showCurrentPassword ? 'fa:fas fa-eye-slash' : 'fa:fas fa-eye'"
            autocomplete="current-password"
            data-hj-suppress
            :error-messages="currentPasswordErrors"
            variant="filled"
            hide-details="auto"
            :label="t('current_password')"
            name="current-password"
            placeholder=" "
            :type="showCurrentPassword ? 'text' : 'password'"
            data-testid="current-password"
            @blur="v$.currentPassword.$touch()"
            @click:append="showCurrentPassword = !showCurrentPassword"
            @update:model-value="v$.currentPassword.$touch()"
          />
        </v-col>
        <v-col cols="12">
          <v-tooltip location="top">
            <template #activator="{ props: tooltipProps }">
              <v-text-field
                id="new-password"
                v-model="passwordData.newPassword"
                :append-icon="showNewPassword ? 'fa:fas fa-eye-slash' : 'fa:fas fa-eye'"
                autocomplete="new-password"
                data-hj-suppress
                :error-messages="newPasswordErrors"
                variant="filled"
                hide-details="auto"
                :label="t('new_password')"
                name="new-password"
                :type="showNewPassword ? 'text' : 'password'"
                data-testid="new-password"
                v-bind="tooltipProps"
                @blur="v$.newPassword.$touch()"
                @click:append="showNewPassword = !showNewPassword"
                @update:model-value="v$.newPassword.$touch()"
              />
            </template>
            <div>
              {{ t('field_requirements.password.intro', { field: t('new_password') }) }}
              <ul>
                <li>{{ t('field_requirements.password.number_of_characters') }}</li>
                <li>{{ t('field_requirements.password.casing') }}</li>
                <li>{{ t('field_requirements.password.digit') }}</li>
                <li>{{ t('field_requirements.password.special') }}</li>
              </ul>
            </div>
          </v-tooltip>
        </v-col>
        <v-col
          class="text-right"
          cols="12"
        >
          <v-btn
            :block="$vuetify.display.name === 'xs'"
            color="primary"
            :disabled="hasErrors || formIsIncomplete"
            :loading="userSecurityStore.isPasswordUpdatePending"
            type="submit"
          >
            {{ t('actions.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<style lang="sass" scoped>
.password-form-container
  max-width: 600px
</style>

<i18n lang="json" locale=de>
  {
    "current_password": "Altes Passwort",
    "new_password": "Neues Passwort"
  }
</i18n>
<i18n lang="json" locale=en>
  {
    "current_password": "Old password",
    "new_password": "New Password"
  }
</i18n>
