<script setup lang="ts">
import { getPriorityClass, getPriorityColor, getPriorityIcon } from '@/utils/helpers/tasks'
import { RowData } from '@/components/SummarizedContent/types'
import { useI18n } from 'vue-i18n'
const emit = defineEmits<{
  (e: 'delete-task'): void,
  (e: 'close'): void}
>()

const { t } = useI18n()

defineProps<{
  task: RowData,
  value: boolean
}>()

const onClose = () => {
  emit('close')
}

const onDeleteTask = () => {
  emit('delete-task')
}
</script>

<template>
  <v-dialog
    max-width="400px"
    :model-value="value"
    @click:outside="onClose"
    @keydown.esc.prevent="onClose"
  >
    <v-card data-testid="task-delete-dialog">
      <v-card-title
        data-testid="task-delete-dialog-title"
        class="text-h6 px-6 pt-6 pb-0 mb-6"
      >
        {{ t('dialog_heading') }}
      </v-card-title>
      <v-card-text class="px-6 pt-0">
        <div class="d-flex pa-4 bordered-title">
          <div
            class="tw-w-[24px] tw-h-[24px] tw-rounded-[4px] my-auto pa-1 d-flex justify-center align-center"
            :style="{'background-color': `rgb(var(--v-theme-${getPriorityColor(task?.priority)}))`}"
          >
            <v-icon
              color="white"
              size="16"
            >
              {{ getPriorityIcon(task?.priority) }}
            </v-icon>
          </div>
          <span
            :class="`text-${getPriorityClass(task?.priority)} ml-4 tw-font-semibold`"
            data-testid="task-delete-dialog-title"
          >{{ task?.title }}</span>
        </div>
        <p
          class="mt-6 text-neutral-darken3 text-body-1"
        >
          {{ t('delete_description_1') }}
          <br>
          {{ t('delete_description_2') }}
        </p>
      </v-card-text>
      <v-card-actions class="px-6 pb-6 pt-4">
        <v-btn
          class="flex-grow-1 text-primary-darken2"
          color="primary-lighten3"
          data-testid="task-delete-dialog-keep-button"
          height="40px"
          variant="elevated"
          @click="onClose"
        >
          {{ t('keep_btn_text') }}
        </v-btn>
        <v-btn
          color="error"
          class="flex-grow-1 text-white task-delete-dialog-delete-button"
          data-testid="task-delete-dialog-delete-button"
          height="40px"
          variant="elevated"
          @click="onDeleteTask"
        >
          {{ t('delete_btn_text') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="sass" scoped>
  .bordered-title
    border: 1px solid rgb(var(--v-theme-neutral-lighten1))
    border-radius: 5px
</style>

<i18n lang="json" locale="de">
  {
    "dialog_heading": "Aufgabe löschen?",
    "delete_description_1": "Du bist dabei diese Aufgabe, ihre Details, Kommentare und alle dazugehörigen Daten endgültig zu löschen.",
    "delete_description_2": "Wenn du nicht sicher bist, kannst du den Status auch auf Erledigt, Abgelehnt oder Abgebrochen setzen.",
    "keep_btn_text": "Behalten",
    "delete_btn_text": "Löschen"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "dialog_heading": "Delete task?",
    "delete_description_1": "You’re about to permanently delete this task, its details, comments, and all of its own data.",
    "delete_description_2": "If you’re not sure, you can also set the status to Done, Rejected or Failed.",
    "keep_btn_text": "Keep",
    "delete_btn_text": "Delete"
  }
</i18n>
