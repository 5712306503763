import { DateRangeObject, TagItem } from '@/vuex/data_points_view/types'
import { LocationQuery, RouteLocationNormalized } from 'vue-router'

import { updateCheckboxFlag } from './helpers'

/**
 * Checks the current router query and creates a query with updated search, if necessary.
 * If the search is unchanged returns early without a result.
 * If the store has a search it changes or adds it. If the store has no search, but the query does, it removes it.
 * @param newSearch The data points view search that was set in the store
 * @param currentRoute The current route, used to check if the query needs to be updated.
 * @returns a new query with the required updates or undefined if nothing changed.
 */
function updateSearch (newSearch: string, currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if (currentRoute.query.search === newSearch) {
    return undefined
  }
  const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
  if (newSearch && newSearch.length > 0) {
    newQuery.search = newSearch
  } else {
    delete newQuery.search
  }
  return newQuery
}

/**
 * Checks the current router query and creates a query with updated datapoint selection, if necessary.
 * If the selection is unchanged returns early without a result.
 * If the the query already has a datapoint selection, the new datapoint is added. Otherwise it creates a new entry in the query.
 * @param newDatapoint The data points view search that was set in the store
 * @param currentRoute The current route, used to check if the query needs to be updated.
 * @returns a new query with the required updates or undefined if nothing changed.
 */
function addSelectedDatapointToQuery (newDatapoint: string, currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if (currentRoute.query.datapoints && currentRoute.query.datapoints.includes(newDatapoint)) {
    return undefined
  }
  const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
  if ('datapoints' in newQuery) {
    newQuery.datapoints += ',' + newDatapoint
  } else {
    newQuery.datapoints = newDatapoint
  }
  return newQuery
}

/**
 * Checks the current router query and creates a query without datapoint selection, if necessary.
 * If the query already has no datapoints returns early without a result.
 * @param currentRoute The current route, used to check if the query needs to be updated.
 * @returns a new query with the required updates or undefined if nothing changed.
 */
function removeAllSelectedDatapointsFromQuery (currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if (currentRoute.query.datapoints) {
    const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
    delete newQuery.datapoints
    return newQuery
  } else {
    return undefined
  }
}

/**
 * Checks the current router query and creates a query with updated datapoint selection, if necessary.
 * If the query already has no datapoints returns early without a result.
 * If the the query has a datapoint selection with more than one entry, the datapoint is removed. Otherwise the whole key is removed from the query.
 * @param removedDatapoint The datapoint that was deselected in the store
 * @param currentRoute The current route, used to check if the query needs to be updated.
 * @returns a new query with the required updates or undefined if nothing changed.
 */
function removeSelectedDatapointFromQuery (removedDatapoint: string, currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if (!currentRoute.query.datapoints) {
    return undefined
  }
  const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
  const datapointsInQuery = (newQuery.datapoints as string).split(',')
  const indexToRemove = datapointsInQuery.indexOf(removedDatapoint)
  if (indexToRemove > -1) {
    datapointsInQuery.splice(indexToRemove, 1)
    if (datapointsInQuery.length === 0) {
      delete newQuery.datapoints
    } else {
      newQuery.datapoints = datapointsInQuery.join(',')
    }
  }
  return newQuery
}

/**
 * Checks if the query already contains the filter, either as a key with one value or in a key with multiple values.
 * @param tagFilter The filter to look for.
 * @param query The query to check.
 * @returns True if the query contains the filter, otherwise false.
 */
function queryContainsFilter (tagFilter: TagItem, query: LocationQuery): boolean {
  const queryKey = `tag.${tagFilter.key}`
  if (Array.isArray(query[queryKey])) {
    return (query[queryKey] as string[]).includes(tagFilter.value)
  } else {
    return query[queryKey] === tagFilter.value
  }
}

/**
 * Checks the current router query and creates a query with updated filters, if necessary.
 * If the query already contains the filter returns early without a result.
 * If the the query already has a filter with the same key, the value is added to it. If not, a new key value pair is added with prefix "tag.".
 * @param newDatapoint The data points view search that was set in the store
 * @param currentRoute The current route, used to check if the query needs to be updated.
 * @returns a new query with the required updates or undefined if nothing changed.
 */
function addTagFilterToQuery (tagFilter: TagItem, currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if (queryContainsFilter(tagFilter, currentRoute.query)) {
    return undefined
  }
  const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
  const queryKey = `tag.${tagFilter.key}`
  if (queryKey in newQuery) {
    if (Array.isArray(newQuery[queryKey])) {
      (newQuery[queryKey] as string[]).push(tagFilter.value)
    } else {
      newQuery[queryKey] = [(newQuery[queryKey] as string), tagFilter.value]
    }
  } else {
    newQuery[queryKey] = tagFilter.value
  }
  return newQuery
}

/**
 * Checks the current router query and creates a query with updated filters, if necessary.
 * If the query does not contain the filter returns early without a result.
 * If the the query contains the key with multiple values, just the value from the filter is removed. If not, the whole key is removed.
 * @param newDatapoint The data points view search that was set in the store
 * @param currentRoute The current route, used to check if the query needs to be updated.
 * @returns a new query with the required updates or undefined if nothing changed.
 */
function removeTagFilterFromQuery (tagFilter: TagItem, currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if (!queryContainsFilter(tagFilter, currentRoute.query)) {
    return undefined
  }
  const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
  const queryKey = `tag.${tagFilter.key}`
  if (Array.isArray(newQuery[queryKey])) {
    const indexToRemove = (newQuery[queryKey] as string[]).indexOf(tagFilter.value)
    if (indexToRemove > -1) {
      (newQuery[queryKey] as string[]).splice(indexToRemove, 1)
    }
    if ((newQuery[queryKey] as string[]).length === 1) {
      newQuery[queryKey] = newQuery[queryKey]![0]!
    }
  } else {
    delete newQuery[queryKey]
  }
  return newQuery
}

/**
 * Checks the current router query and creates a query with updated date range, if necessary.
 * If the query already contains the range returns early without a result.
 * Otherwise it adds the range. If the query also contains a zoom, it removes the zoom.
 * @param newDatapoint The data points view search that was set in the store
 * @param currentRoute The current route, used to check if the query needs to be updated.
 * @returns a new query with the required updates or undefined if nothing changed.
 */
function updateDateRange (dateRange: [string, string?], currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if (currentRoute.query.start && currentRoute.query.start === dateRange[0] &&
      currentRoute.query.end && currentRoute.query.end === dateRange[dateRange.length - 1]) {
    return undefined
  }
  const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
  newQuery.start = dateRange[0]
  newQuery.end = dateRange[dateRange.length - 1]!
  if (newQuery.zoomStart) {
    delete newQuery.zoomStart
  }
  if (newQuery.zoomEnd) {
    delete newQuery.zoomEnd
  }
  return newQuery
}

/**
 * Checks the current router query and creates a query with updated zoom range, if necessary.
 * If the query already contains the zoom or if the parameter is null and the query does not contain a zoom, returns early without a result.
 * Otherwise it adds the zoom if it has a value or otherwise removes it.
 * @param newDatapoint The data points view search that was set in the store
 * @param currentRoute The current route, used to check if the query needs to be updated.
 * @returns a new query with the required updates or undefined if nothing changed.
 */
function updateZoom (zoom: DateRangeObject|null, currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if ((zoom && currentRoute.query.zoomStart === zoom.start.toISOString() && currentRoute.query.zoomEnd === zoom.end.toISOString()) ||
      (!zoom && !currentRoute.query.zoomStart && !currentRoute.query.zoomEnd)) {
    return undefined
  }
  const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
  if (zoom) {
    newQuery.zoomStart = zoom.start.toISOString()
    newQuery.zoomEnd = zoom.end.toISOString()
  } else {
    delete newQuery.zoomStart
    delete newQuery.zoomEnd
  }
  return newQuery
}

/**
 * Checks the current router query and creates a query with updated page, if necessary.
 * If the query already contains the page returns early without a result. Otherwise it adds the page.
 * @param newPage The new page that was set in the store
 * @param currentRoute The current route, used to check if the query needs to be updated.
 * @returns a new query with the required updates or undefined if nothing changed.
 */
function updatePage (newPage: number, currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if (currentRoute.query.page === newPage.toString()) {
    return undefined
  }
  const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
  newQuery.page = newPage.toString()
  return newQuery
}

/**
 * Checks the current router query and creates a query with updated setpoint editor, if necessary.
 * @param setpointId The id of the setpoint that was set in the store
 * @param currentRoute The current route, used to check if the query needs to be updated.
 * @returns a new query with the required updates or undefined if nothing changed.
 */
function updateSetpointEditor (setpointId: string|null, currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if (currentRoute.query.setpoint === setpointId) {
    return
  }
  const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
  if (setpointId) {
    newQuery.setpoint = setpointId
  } else {
    delete newQuery.setpoint
  }
  return newQuery
}

/**
 * Checks the name of the mutation that was called in the data_points_view store and may replace the router query, depending on the changes in the store.
 * @param mutationName Name of the mutation that was called.
 * @param payload Payload the mutation was called with.
 * @returns The result of the indivial methods, either undefined or a new query.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function updateDataPointsViewQuery (mutationName: string, payload: any, currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if (currentRoute.name !== 'data-points') {
    return undefined
  }
  if (mutationName === 'SET_SEARCH') {
    return updateSearch(payload, currentRoute)
  } else if (mutationName === 'ADD_HASH_ID') {
    return addSelectedDatapointToQuery(payload, currentRoute)
  } else if (mutationName === 'REMOVE_ALL_SELECTED_HASH_IDS') {
    return removeAllSelectedDatapointsFromQuery(currentRoute)
  } else if (mutationName === 'REMOVE_HASH_ID') {
    return removeSelectedDatapointFromQuery(payload, currentRoute)
  } else if (mutationName === 'ADD_TAG_FILTER') {
    return addTagFilterToQuery(payload, currentRoute)
  } else if (mutationName === 'REMOVE_TAG_FILTER') {
    return removeTagFilterFromQuery(payload, currentRoute)
  } else if (mutationName === 'SET_FAVORITES_FILTER') {
    return updateCheckboxFlag('favorites', payload, currentRoute)
  } else if (mutationName === 'SET_WRITABLE_FILTER') {
    return updateCheckboxFlag('writable', payload, currentRoute)
  } else if (mutationName === 'SET_DATE_RANGE') {
    return updateDateRange(payload, currentRoute)
  } else if (mutationName === 'SET_ZOOM') {
    return updateZoom(payload, currentRoute)
  } else if (mutationName === 'SET_LIVE_VIEW_STATUS') {
    return updateCheckboxFlag('live', payload, currentRoute)
  } else if (mutationName === 'SET_SETPOINTS_DATAPOINT_ID') {
    return updateSetpointEditor(payload, currentRoute)
  } else {
    return undefined
  }
}

/**
 * Checks the name of the mutation that was called in the datapoints store and may replace the router query, depending on the changes in the store.
 * @param mutationName Name of the mutation that was called.
 * @param payload Payload the mutation was called with.
 * @returns The result of the indivial methods, either undefined or a new query.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function updateDataPointsViewQueryForDatapointsStore (mutationName: string, payload: any, currentRoute: RouteLocationNormalized): LocationQuery|undefined {
  if (mutationName === 'SET_PAGINATION') {
    return updatePage(payload.current_page, currentRoute)
  } else {
    return undefined
  }
}
