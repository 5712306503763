import { AnalysisFunctionState } from './types'

export default {
  analysisFunctionsList: null,
  loading: false,
} as AnalysisFunctionState

export function resetStoreState (state: AnalysisFunctionState): void {
  state.analysisFunctionsList = null
  state.loading = false
}
