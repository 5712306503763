<script setup lang="ts">
import { computed, ref } from 'vue'
import { NavigationGuardNext, onBeforeRouteUpdate, RouteLocationNormalized, useRoute } from 'vue-router'
import { DatapointOverviewItem } from '@/vuex/data_points_view/types'
import DatapointsList from './DatapointsList/index.vue'
import DatapointsTable from '@/components/DatapointsTable.vue'
import { DataPointWithContext } from '@aedifion.io/aedifion-api'
import DescriptionEditor from '@/views/DataPointsView/DescriptionEditor.vue'
import { DIKebabItemDatapointPayload } from '@/utils/types'
import PageHeader from '@/components/PageHeader.vue'
import SetpointEditor from '@/views/DataPointsView/SetpointEditor.vue'
import TagEditor from '@/views/DataPointsView/TagEditor.vue'
import { useI18n } from 'vue-i18n'
import useIsReadOnly from '@/composables/useIsReadOnly'
import { useStore } from '@/vuex'
import Visualization from './Visualization.vue'

type VisualizationComponent = InstanceType<typeof Visualization>

interface Props {
  componentPinMode?: boolean
}

// --- definition ---

const visualization = ref<VisualizationComponent>()

const vuexStore = useStore()
const route = useRoute()
const { isReadOnly } = useIsReadOnly()

const { t } = useI18n()

onBeforeRouteUpdate ((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  visualization.value?.onRouteUpdate(to, from)
  if (to.params.project && from.params.project && to.params.project !== from.params.project) {
    vuexStore.dispatch('tags/fetchTags')
    fetchDatapoints()
  }
  next()
})

const props = withDefaults(defineProps<Props>(), {
  componentPinMode: false,
})

const datapointListKebabActionItem = ref<DIKebabItemDatapointPayload | null>(null)
const showDescriptionEditor = ref(false)
const showTagEditor = ref(false)

const loading = computed<boolean>(() => vuexStore.getters['data_points_view/isLoadingDatapointTimeseries'])
const datapointOverviewItems = computed<DatapointOverviewItem[]>(() => vuexStore.getters['data_points_view/getDatapointsOverviewItems'])
const showSetpointEditor = computed<boolean>(() => vuexStore.getters['data_points_view/isShowingSetpointEditor'])
const selectedSetpointDatapoint = computed<DataPointWithContext | null>(() => vuexStore.state.data_points_view.setpointDatapoint)

// @ts-ignore
const datapointsListTop = computed<string>(() => {
  if (props.componentPinMode) {
    return '40px'
  } else {
    return '108px'
  }
})

async function deselectAllDatapoints () {
  await vuexStore.dispatch('data_points_view/deselectAllDatapoints')
}

async function deselectDatapoint (item: DatapointOverviewItem): Promise<void> {
  await vuexStore.dispatch('data_points_view/deselectDatapoint', item.hashID)
}

async function fetchDatapoints (page = 1): Promise<void> {
  await vuexStore.dispatch('datapoints/fetchDatapoints', page)
}

function onSetAllTimeseriesVisibility (visible: boolean): void {
  vuexStore.dispatch('data_points_view/setVisibilityOfAllTimeseries', visible)
}

function openDescriptionEditor (item: DIKebabItemDatapointPayload): void {
  setDatapointListKebabActionItem(item)
  showDescriptionEditor.value = true
}

function openTagEditor (item: DIKebabItemDatapointPayload): void {
  setDatapointListKebabActionItem(item)
  showTagEditor.value = true
}

function openSetpointEditor (item: DIKebabItemDatapointPayload): void {
  const datapoint: DataPointWithContext = {
    dataPointID: item.id as string,
    hash_id: item.hashId,
    tags: item.tags,
  }
  vuexStore.dispatch('data_points_view/setSetpointEditorsDatapoint', datapoint)
}

function closeSetpointEditor () {
  vuexStore.dispatch('data_points_view/setSetpointEditorsDatapoint', null)
}

function setDatapointListKebabActionItem (item: DIKebabItemDatapointPayload): void {
  const kebabItemData: DIKebabItemDatapointPayload = {
    description: item.description,
    id: item.id,
    tags: item.tags,
    title: item.title,
  }
  datapointListKebabActionItem.value = kebabItemData
}

function toggleTimeseries (datapointID: string): void {
  vuexStore.dispatch('data_points_view/toggleVisibilityOfTimeseries', datapointID)
}

async function fetchDatapointsOnCreated () {
  vuexStore.dispatch('tags/fetchTags')
  const page = 'page' in route.query ? parseInt(route.query.page as string) : 1
  await fetchDatapoints(page)
}
// --- execution ---

fetchDatapointsOnCreated()
</script>

<template>
  <div class="layout-wrapper">
    <PageHeader
      v-if="!props.componentPinMode"
      title-key="links.meta.title.data_points_view"
      sticky
    />
    <DatapointsList
      class="datapoints-list pr-3"
      :items-draggable="props.componentPinMode"
      @datapointlistitem:edit-description="openDescriptionEditor"
      @datapointlistitem:edit-tags="openTagEditor"
      @datapointlistitem:write-setpoint="openSetpointEditor"
    />
    <div class="visualization__wrapper">
      <slot />
      <Visualization
        ref="visualization"
      />
      <DatapointsTable
        :datapoint-table-items="datapointOverviewItems"
        :read-only="isReadOnly"
        :label-getter="$store.getters['labels/label']"
        :no-data-text="t('data_points_view.select_datapoint')"
        :loading="loading"
        @datapoints-table:deselect-all-datapoints="deselectAllDatapoints"
        @datapoints-table:deselect-datapoint="deselectDatapoint"
        @datapoints-table:edit-description="openDescriptionEditor"
        @datapoints-table:edit-tags="openTagEditor"
        @datapoints-table:set-all-series-visibility="onSetAllTimeseriesVisibility"
        @datapoints-table:toggle-series="toggleTimeseries"
      />
    </div>
    <DescriptionEditor
      v-if="showDescriptionEditor"
      :datapoint="datapointListKebabActionItem"
      @descriptioneditor:close="showDescriptionEditor = false"
    />
    <SetpointEditor
      v-if="showSetpointEditor"
      :datapoint="selectedSetpointDatapoint"
      @setpoint-editor:close="closeSetpointEditor"
    />
    <TagEditor
      v-if="showTagEditor"
      :kebab-menu-item="datapointListKebabActionItem"
      @tags:close="showTagEditor = false"
    />
  </div>
</template>

<style lang="sass" scoped>
  .datapoints-list
    position: fixed
    top: v-bind(datapointsListTop)
    width: clamp(290px, 30vw, 400px)
    max-height: calc(100vh - v-bind(datapointsListTop) - 40px) !important
    height: 100%

  .visualization__wrapper
    margin-left: clamp(290px, 30vw, 400px)
    max-height: 100%
</style>
