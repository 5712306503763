import type { Attribute, ComponentAttributeDefinitionWithError, ComponentAttributesEditorState, ComponentInProjectWithContextAndError } from './types'
import { MutationTree } from 'vuex'

export default {
  SET_COMPONENT_ATTRIBUTE (state: ComponentAttributesEditorState, updatedAttribute: Attribute) {
    if (!state.componentInProject) {
      return
    }

    const attributes = state.componentInProject.attributes ?? []

    const index = attributes.findIndex(attr => attr.alphanumeric_id === updatedAttribute.alphanumeric_id)

    if (index !== -1) {
      state.componentInProject.attributes = [
        ...attributes.slice(0, index),
        { ...attributes[index], ...updatedAttribute },
        ...attributes.slice(index + 1),
      ]
    }
  },

  SET_COMPONENT_IN_PROJECT: (state: ComponentAttributesEditorState, componentInProject: ComponentInProjectWithContextAndError) => {
    state.componentInProject = componentInProject
  },

  SET_COMPONENTS_ATTRIBUTES_DEFINITIONS: (state: ComponentAttributesEditorState, componentAttributeDefinitions: ComponentAttributeDefinitionWithError[]) => {
    state.componentAttributeDefinitions = componentAttributeDefinitions
  },

  SET_LOADING: (state: ComponentAttributesEditorState, loading: boolean) => (state.loading = loading),

  SET_LOADING_FOR_FETCH: (state: ComponentAttributesEditorState, loading: boolean) => (state.loadingForFetch = loading),
} as MutationTree<ComponentAttributesEditorState>
