<script setup lang="ts">
import { computed } from 'vue'
import type { DoubleChipItem } from '@/utils/types'

type Props = {
  closable?: boolean,
  color: string,
  colorOnLeft?: boolean,
  dark?: boolean,
  disabled?: boolean,
  leftItem: DoubleChipItem,
  rightItem: DoubleChipItem
}

const props = withDefaults(defineProps<Props>(), {
  closable: false,
  colorOnLeft: true,
  dark: true,
  disabled: false,
})

const emits = defineEmits<{
  'doublechip:close': [leftItem: DoubleChipItem, rightItem: DoubleChipItem]
}>()

const closeIconColor = computed<string>(() => {
  if (props.dark) {
    if (props.colorOnLeft) {
      return props.color
    }
    return 'neutral-lighten5'
  }
  if (props.colorOnLeft) {
    return 'neutral-darken2'
  }
  return 'neutral-darken4'
})

const colorForCss = computed<string>(() => {
  return props.color.startsWith('#') ? props.color : `rgb(var(--v-theme-${props.color}))`
})

const highlightTextColor = computed<string>(() => {
  if (props.dark) {
    return 'rgb(var(--v-theme-neutral-lighten5))'
  }
  return 'rgb(var(--v-theme-neutral-darken4))'
})

const emptyTextColor = computed<string>(() => {
  if (props.dark) {
    return colorForCss.value
  }
  return 'rgb(var(--v-theme-neutral-darken4))'
})

// @ts-ignore
const leftColor = computed<string>(() => {
  if (props.colorOnLeft) {
    return colorForCss.value
  }
  return 'transparent'
})

// @ts-ignore
const leftTextColor = computed<string>(() => {
  if (props.colorOnLeft) {
    return highlightTextColor.value
  }
  return emptyTextColor.value
})

// @ts-ignore
const rightColor = computed<string>(() => {
  if (props.colorOnLeft) {
    return 'transparent'
  }
  return colorForCss.value
})

// @ts-ignore
const rightTextColor = computed<string>(() => {
  if (props.colorOnLeft) {
    return emptyTextColor.value
  }
  return highlightTextColor.value
})
</script>

<template>
  <div
    :class="['chip__wrapper', {
      'closable': props.closable,
      'disabled': props.disabled
    }]"
    data-testid="double-chip"
  >
    <div
      class="chip-item pl-2 pr-1"
      data-testid="double-chip-left-item"
    >
      <v-tooltip
        location="bottom"
        :disabled="!props.leftItem.tooltip"
      >
        <template #activator="{ props: leftItemTooltipProps }">
          <span
            class="text-subtitle-1"
            v-bind="leftItemTooltipProps"
            v-text="props.leftItem.text"
          />
        </template>
        <span v-text="props.leftItem.tooltip" />
      </v-tooltip>
    </div>
    <div
      class="chip-item pl-1 pr-1"
      data-testid="double-chip-right-item"
    >
      <v-tooltip
        location="bottom"
        :disabled="!props.rightItem.tooltip"
      >
        <template #activator="{ props: rightItemTooltipProps }">
          <span
            class="pr-1 text-subtitle-1"
            v-bind="rightItemTooltipProps"
            v-text="props.rightItem.text"
          />
        </template>
        <span v-text="props.rightItem.tooltip" />
      </v-tooltip>
      <v-icon
        v-if="props.closable"
        class="close-icon"
        :color="closeIconColor"
        @click.stop.prevent="emits('doublechip:close', props.leftItem, props.rightItem)"
      >
        fa:fas fa-times-circle
      </v-icon>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@use '~/vuetify/settings' as vuetifySettings

.chip__wrapper
  border-radius: #{calc(map-get(vuetifySettings.$icon-sizes, "small") / 2)}em
  cursor: default
  display: flex
  font-size: #{map-get(vuetifySettings.$icon-sizes, "small")}em
  font-weight: vuetifySettings.$list-item-subtitle-font-weight
  line-height: vuetifySettings.$list-item-subtitle-line-height
  gap: 0 !important
  height: 26px
  position: relative
  width: fit-content
  &.disabled
    pointer-events: none
  &:hover
    background-color: rgba(0, 0, 0, 0.03) !important
  .chip-item
    align-items: center
    border: 1px solid v-bind(colorForCss)
    display: flex
    &:first-child
      background-color: v-bind(leftColor)
      border-top-left-radius: 12px
      border-bottom-left-radius: 12px
      color: v-bind(leftTextColor)
      max-width: 7rem
    &:last-child
      background-color: v-bind(rightColor)
      border-top-right-radius: 12px
      border-bottom-right-radius: 12px
      color: v-bind(rightTextColor)
      max-width: 10rem
    span
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
  .closable
    height: #{map-get(vuetifySettings.$icon-sizes, "small")}em
.close-icon
  font-size: vuetifySettings.$chip-close-size
  pointer-events: auto !important
  &:hover,
  &:focus,
  &:active
    opacity: .72
</style>
