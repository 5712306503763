import { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RootState } from '@/vuex/types'
import { Store } from 'vuex'

export const componentInProjectGuardMiddleware: (store: Store<RootState>) => NavigationGuard = (
  store: Store<RootState>,
) => async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!to.meta || !to.meta.requiresComponentInProject) {
    return next()
  }

  const componentInProjectId: number|null = to.params.componentInProjectId ? parseInt(to.params.componentInProjectId as string) : null
  if (!componentInProjectId) {
    return next({ name: 'page-not-found' })
  }

  if (!store.getters['optimization/getSelectedComponent'] || componentInProjectId !== store.getters['optimization/getSelectedComponent'].id) {
    try {
      await store.dispatch('optimization/selectComponentInProject', componentInProjectId)
    } catch (error) {
      return next({ name: 'page-not-found' })
    }
  }
  next()
}
