import type { Attribute, AttributesPayload } from '@/vuex/component_attributes_editor/types'
import type { ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import vuexStore from '@/vuex'

export async function useComponentAttributeUpdater (attribute: Attribute) {
  const componentInProject: ComponentInProjectWithContext | null = vuexStore.getters['component_attributes_editor/getComponentInProject']

  if (!componentInProject) return

  const payload: AttributesPayload = {
    componentInProjectID: componentInProject.id!,
    key: attribute.key!,
    value: attribute.new_value!,
  }

  if (attribute.new_unit) {
    payload.unit = attribute.new_unit
    await handleAttributeAction('update', payload, attribute)
  } else if (attribute.value && attribute.value !== attribute.new_value && attribute.new_value !== '') {
    await handleAttributeAction('update', payload, attribute)
  } else if (attribute.value && attribute.new_value === '') {
    await handleAttributeAction('delete', payload, attribute)
  } else if (!attribute.value && attribute.new_value && attribute.new_value !== '') {
    await handleAttributeAction('create', payload, attribute)
  }
}

async function handleAttributeAction (
  action: 'create' | 'update' | 'delete',
  payload: AttributesPayload,
  attribute: Attribute,
): Promise<void> {
  try {
    await vuexStore.dispatch(`component_attributes_editor/${action}ComponentAttribute`, payload)

    const componentInProjectId = vuexStore.state.component_attributes_editor.componentInProject?.id as number

    // Fetch the component in project again to update the attribute list, otherwise the update would not be visible in the dialog
    if (action === 'create') {
      await vuexStore.dispatch('component_attributes_editor/fetchComponentInProject', componentInProjectId)
    }

    const newValue = action === 'delete' ? '' : attribute.new_value
    const newUnit = action === 'delete' ? attribute.default_unit : attribute.new_unit ?? attribute.unit

    vuexStore.commit('component_attributes_editor/SET_COMPONENT_ATTRIBUTE', {
      ...attribute,
      unit: newUnit,
      error: false,
      value: newValue,
    })
  } catch (error) {
    vuexStore.commit('component_attributes_editor/SET_COMPONENT_ATTRIBUTE', {
      ...attribute,
      unit: attribute.unit,
      error: true,
      value: attribute.value,
    })
  }
}
