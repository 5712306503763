<template>
  <div class="chip__wrapper">
    <DoubleChip
      v-for="(item, index) in tagFilters"
      :key="index"
      closable
      color="neutral-darken3"
      :left-item="createTagItem(item.key)"
      :right-item="createTagItem(item.key, item.value)"
      @doublechip:close="removeTagFilter"
    />
    <DoubleChip
      v-if="isFavoritesFilterSet"
      closable
      color="neutral-darken3"
      :left-item="{ text: t('data_points_view.quick_filters.favorites') }"
      :right-item="{ text: t('data_points_view.tags.yes') }"
      @doublechip:close="removeFavoritesFilter"
    />
    <DoubleChip
      v-if="isWritableFilterSet"
      closable
      color="neutral-darken3"
      :left-item="{ text: t('data_points_view.tags.writable') }"
      :right-item="{ text: t('data_points_view.tags.yes') }"
      @doublechip:close="removeWritableFilter"
    />
  </div>
</template>

<script lang="ts">
import { createDoubleChipItem } from '@/utils/helpers/tags'
import { defineComponent } from 'vue'
import DoubleChip from '@/components/DoubleChip.vue'
import { DoubleChipItem } from '@/utils/types'
import { mapGetters } from 'vuex'
import { TagItem } from '@/vuex/data_points_view/types'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'DatapointsFilterChips',

  components: {
    DoubleChip,
  },

  emits: ['datapointsfilter:change'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  computed: {
    ...mapGetters({
      isFavoritesFilterSet: 'data_points_view/isFavoritesFilterSet',
      isWritableFilterSet: 'data_points_view/isWritableFilterSet',
      tagFilters: 'data_points_view/getTagFilters',
    }),
  },

  methods: {
    createTagItem (tagKey: string, tagValue?: string): DoubleChipItem {
      const label = this.$store.getters['labels/label'](tagKey, tagValue)
      return createDoubleChipItem(label, tagKey, tagValue)
    },

    removeFavoritesFilter (): void {
      this.$store.commit('data_points_view/SET_FAVORITES_FILTER', false)
      this.$emit('datapointsfilter:change')
    },

    removeTagFilter (keyItem: DoubleChipItem, valueItem: DoubleChipItem): void {
      this.$store.commit('data_points_view/REMOVE_TAG_FILTER', { key: keyItem.value, value: valueItem.value } as TagItem)
      this.$emit('datapointsfilter:change')
    },

    removeWritableFilter (): void {
      this.$store.commit('data_points_view/SET_WRITABLE_FILTER', false)
      this.$emit('datapointsfilter:change')
    },
  },
})
</script>

<style lang="sass" scoped>
  .chip__wrapper
    display: flex
    flex-flow: row wrap
    gap: 0.1rem

  // Adjusts the close button position to the one in DoubleChip
  .v-chip
    padding: 0 8px
</style>
