import { ComponentInProjectListItemData, ComponentsInProjectState } from './types'
import { ComponentWithResults } from '@aedifion.io/aedifion-api'
import { GetterTree } from 'vuex'
import { RootState } from '../types'
import { textForLocale } from '@/utils/helpers/locale'

export function mapComponentWithResults (component: ComponentWithResults) {
  const nameSource = textForLocale(component.display_name_DE!, component.display_name!)
  const nameParts: string[] = nameSource.split('|') ?? ['', '']
  const description: string|undefined = nameParts.length === 2 ? nameParts[1].trim() : undefined
  const nameEN: string = component.display_name?.split('|')[0].trim() ?? ''
  const nameDE: string = component.display_name_DE?.split('|')[0].trim() ?? ''
  const title: string = textForLocale(nameDE, nameEN)

  return {
    description,
    id: component.componentinproject_id!,
    nameDE,
    nameEN,
    signalColor: component.signal_color ?? 'neutral',
    title,
  }
}

export default {
  componentsInProjectListItems: (state: ComponentsInProjectState): ComponentInProjectListItemData[] => {
    if (state.componentsInProjectWithResults === null) {
      return []
    }

    return state.componentsInProjectWithResults.map(mapComponentWithResults)
  },

  currentPage: (state: ComponentsInProjectState): number => state.pagination?.current_page || 1,

  firstComponentInProjectId: (state: ComponentsInProjectState): number|null => {
    if (state.componentsInProjectWithResults === null || state.componentsInProjectWithResults.length === 0) {
      return null
    } else {
      return state.componentsInProjectWithResults[0].componentinproject_id ?? null
    }
  },

  isCurrentPageValid: (state: ComponentsInProjectState): boolean => {
    return !!state.pagination && !!state.pagination.current_page && !!state.pagination.total_pages && state.pagination.current_page <= state.pagination.total_pages
  },

  isLoadingComponentsInProject: (state: ComponentsInProjectState): boolean => state.loadingComponentsInProject,

  totalItems: (state: ComponentsInProjectState): number|null => state.pagination?.total_items || 0,

  totalPages: (state: ComponentsInProjectState): number|null => state.pagination?.total_pages || 0,
} as GetterTree<ComponentsInProjectState, RootState>
