import { TagsState } from './types'

export default {
  loading: false,
  pendingTagUpdate: false,
  tagGroups: null,
  tagKeys: null,
} as TagsState

export function resetStoreState (state: TagsState): void {
  state.loading = false
  state.pendingTagUpdate = false
  state.tagGroups = null
  state.tagKeys = null
}
