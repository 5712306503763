import { AssetOverviewState, Meter, MeterIdAndObservation, MeterIdAndTimeseries } from './types'
import { ComponentInProjectWithContext, Observation, Timeseries } from '@aedifion.io/aedifion-api'
import { MutationTree } from 'vuex'
import { reportError } from '@/utils/helpers/errors'

export default {
  ADD_ANALOG_METER_TO_EXECUTE_ANALYSES: (state: AssetOverviewState, meter: Meter) => (state.analogMetersToExecuteAnalysis.add(meter)),

  ADD_OR_UPDATE_METER_READING: (state: AssetOverviewState, payload: MeterIdAndObservation) => {
    const observations: Timeseries = state.meterTimeseries[payload.meterId].data!
    const index = observations.findIndex((item: Observation) => {
      return item.time === payload.observation.time
    })
    if (index !== -1) {
      observations.splice(index, 1, payload.observation)
    } else {
      state.meterTimeseries[payload.meterId].data!.push(payload.observation)
    }
  },

  ADD_USER_METER: (state: AssetOverviewState, meter: ComponentInProjectWithContext) => (state.userMeters?.push(meter)),

  CLEAR_ANALOG_METER_ANALYSIS_EXECUTIONS: (state: AssetOverviewState) => (state.analogMetersToExecuteAnalysis.clear()),

  DELETE_METER_READING: (state: AssetOverviewState, payload: MeterIdAndObservation) => {
    const { meterId, observation } = payload

    const timeseries: Timeseries = state.meterTimeseries[meterId].data ?? []
    const observationIndex = timeseries.findIndex((observationToDelete: Observation) => {
      return new Date(observationToDelete.time).toISOString() === new Date(observation.time).toISOString()
    })

    if (observationIndex !== -1) {
      timeseries.splice(observationIndex, 1)
    } else {
      reportError(`Could not find observation with time ${observation.time} in meter ${meterId}`)
    }
  },

  INCREMENT_FINISHED_ANALOG_METER_ANALYSIS_EXECUTIONS: (state: AssetOverviewState) => {
    if (state.pendingAnalogMeterExecutionCount.done === state.pendingAnalogMeterExecutionCount.total) {
      return
    }
    state.pendingAnalogMeterExecutionCount.done++
  },

  INCREMENT_FINISHED_ANALYSIS_EXECUTIONS: (state: AssetOverviewState) => {
    if (state.pendingAnalysisExecutionCount.done === state.pendingAnalysisExecutionCount.total) {
      return
    }
    state.pendingAnalysisExecutionCount.done++
  },

  REMOVE_USER_METER: (state: AssetOverviewState, meterId: number) => {
    const index = state.userMeters!.findIndex((meter) => meter.id === meterId)
    if (index !== -1) {
      state.userMeters?.splice(index, 1)
    }
  },

  REMOVE_USER_METER_READINGS: (state: AssetOverviewState, meterId: number) => {
    delete state.meterTimeseries[meterId]
  },

  RESET_PENDING_ANALOG_METER_ANALYSIS_EXECUTIONS_COUNT: (state: AssetOverviewState) => (state.pendingAnalogMeterExecutionCount = { done: 0, total: 0 }),

  RESET_PENDING_ANALYSIS_EXECUTIONS_COUNT: (state: AssetOverviewState) => (state.pendingAnalysisExecutionCount = { done: 0, total: 0 }),

  SET_LOADING_METER_READINGS: (state: AssetOverviewState, loading: boolean) => (state.loadingMeterReadings = loading),

  SET_LOADING_USER_METERS: (state: AssetOverviewState, loading: boolean) => (state.loadingUserMeters = loading),

  SET_METER_TIMESERIES: (state: AssetOverviewState, payload: MeterIdAndTimeseries) => {
    state.meterTimeseries[payload.meterId] = payload.timeseries
  },

  SET_PENDING_ANALOG_METER_ANALYSIS_EXECUTIONS: (state: AssetOverviewState, pending: boolean) => (state.pendingAnalogMeterAnalysisExecutions = pending),

  SET_PENDING_ANALYSIS_EXECUTIONS: (state: AssetOverviewState, pending: boolean) => (state.pendingAnalysisExecutions = pending),

  SET_PENDING_METER_UPDATE: (state: AssetOverviewState, pending: boolean) => (state.pendingMeterUpdate = pending),

  SET_PENDING_READING_UPDATE: (state: AssetOverviewState, pending: boolean) => (state.pendingReadingUpdate = pending),

  SET_SELECTED_INSTANCE_ID: (state: AssetOverviewState, instanceId: number|null) => (state.selectedInstanceId = instanceId),

  SET_SELECTED_RESULT_ID: (state: AssetOverviewState, resultId: string|null) => (state.selectedResultId = resultId),

  SET_SELECTED_USER_METER: (state: AssetOverviewState, meter: Meter|null) => (state.selectedUserMeter = meter),

  SET_TOTAL_PENDING_ANALOG_METER_ANALYSIS_EXECUTIONS: (state: AssetOverviewState, total: number) => (state.pendingAnalogMeterExecutionCount.total += total),

  SET_TOTAL_PENDING_ANALYSIS_EXECUTIONS: (state: AssetOverviewState, total: number) => (state.pendingAnalysisExecutionCount.total = total),

  SET_USER_METERS: (state: AssetOverviewState, meters: ComponentInProjectWithContext[]) => (state.userMeters = meters),

  UPDATE_USER_METER: (state: AssetOverviewState, meter: ComponentInProjectWithContext) => {
    const index = state.userMeters?.findIndex((editedMeter) => editedMeter.id === meter.id)
    if (index !== undefined && index !== -1) {
      state.userMeters![index] = meter
    }
  },
} as MutationTree<AssetOverviewState>
