import { CompanyRole, CompanyWithContextProjects, Role } from '@aedifion.io/aedifion-api'
import { ProjectRoleId } from '@/stores/views/Administration/users'

/**
 * Get the project role ids from the projects
 * @param projects Projects of the company
 * @param roleIds Numeric role IDs that have to match
 * @returns Project ID and Role ID pairs
 */
export function getProjectRoleIds (projects: CompanyWithContextProjects[], roleIds: number[]): ProjectRoleId[] {
  const projectRoleIds: ProjectRoleId[] = []
  for (const project of projects) {
    for (const role of project.roles ?? []) {
      if (roleIds.includes(role.id ?? 0)) {
        projectRoleIds.push({ projectId: project.id!, roleId: role.id! })
      }
    }
  }

  return projectRoleIds
}

/**
 * Returns the role IDs from the array
 * @param projectRoleIds Array of project ID and role ID pairs
 * @returns Array of role IDs
 */
export function getRoleIds (projectRoleIds: ProjectRoleId[]): number[] {
  return projectRoleIds.map((projectRoleId) => {
    return projectRoleId.roleId
  })
}

/**
 * Checks of the passed role name is included in the set of company roles
 * @param roleName Requested role name
 * @param companyRoles Company roles
 * @returns True if the role is included in the company roles
 */
export function hasCompanyRole (roleName: string, companyRoles: CompanyRole[]): boolean {
  return companyRoles.some((companyRole) => {
    return companyRole.name === roleName
  })
}

/**
 * Checks if the role name is included in the project roles
 * @param roleName Requested role name
 * @param projectRoles Project roles
 * @param projectId Project ID, if the role should be checked for a specific project
 * @returns True if the role is included in the project roles and matched the optional project ID
 */
export function hasProjectRole (roleName: string, projectRoles: Role[], projectId?: number): boolean {
  return projectRoles.some((projectRole) => {
    if (projectId !== undefined && projectId !== projectRole.project_id) {
      return false
    }
    return projectRole.name === roleName
  })
}

type RolesList = {
  companyRoles?: string[],
  projectId?: number,
  projectRoles?: string[]
}

// TODO: this function would be more efficient algorithmically if it implemented
// role-checking in one pass, instead of relying on hasCompanyRole() and
// hasProjectRole().
/**
 * Checks if the user has at least one of the roles in the list
 * @param roles List of roles
 * @param companyRoles Company roles
 * @param projectRoles Project roles
 * @returns True if the user has at least one of the roles in the list
 */
export function hasOneRoleAmong (roles: RolesList, companyRoles: CompanyRole[], projectRoles: Role[]): boolean {
  if (roles.companyRoles?.length) {
    for (const companyRole of roles.companyRoles) {
      if (hasCompanyRole(companyRole, companyRoles)) {
        return true
      }
    }
  }

  if (roles.projectRoles?.length) {
    for (const projectRole of roles.projectRoles) {
      if (hasProjectRole(projectRole, projectRoles, roles.projectId)) {
        return true
      }
    }
  }

  return false
}
