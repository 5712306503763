<template>
  <v-sheet class="pa-4 mx-auto mb-auto">
    <v-row
      class="fill-height"
      justify="center"
    >
      <v-col>
        <PasswordForm />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PasswordForm from './PasswordForm.vue'

export default defineComponent({
  name: 'UserProfile',

  components: {
    PasswordForm,
  },
})
</script>
