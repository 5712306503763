import { BuildingAnalysesState } from './types'

export default {
  buildingAnalysesResults: {},
  buildingComponents: {},
  loadingProjectBuildingAnalyses: [],
  loadingProjectBuildingComponent: [],
  projectsWithSampleData: [],
} as BuildingAnalysesState

export function resetStoreState (state: BuildingAnalysesState): void {
  state.buildingAnalysesResults = {}
  state.buildingComponents = {}
  state.loadingProjectBuildingAnalyses = []
  state.loadingProjectBuildingComponent = []
  state.projectsWithSampleData = []
}
