import { TagGroups, TagsState } from './types'
import { GetterTree } from 'vuex'
import { RootState } from '../types'

export default {
  getTagGroups: (state: TagsState): TagGroups|null => state.tagGroups,

  getTagKeys: (state: TagsState): string[]|null => state.tagKeys,

  getTagValues: (state: TagsState) => (key: string): string[]|null => {
    return state.tagGroups?.get(key) ?? null
  },

  isLoading: (state: TagsState): boolean => state.loading,
} as GetterTree<TagsState, RootState>
