<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
  acceptedExtensions?: string[],
  acceptedMimeTypes?: string[],
  currentFilename?: string
}

const props = withDefaults(defineProps<Props>(), {
  acceptedExtensions: () => [],
  acceptedMimeTypes: () => [],
})

const emit = defineEmits<{
  (e: 'file-change', file: File): void,
  (e: 'file-remove'): void
}>()

const { t } = useI18n()

const fileInput = ref<HTMLInputElement | null>(null)
const elementBeingDraggedOver = ref<EventTarget | null>(null)

const acceptedExtensionsText = computed<string>(() => {
  const extensions = props.acceptedExtensions.map((extension) => {
    return extension.toUpperCase()
  })
  if (extensions.length <= 1) {
    return extensions.join()
  }
  const lastExtension = extensions.pop()
  return t('extensions', {
    extensionsExceptLast: extensions.join(', '),
    lastExtension,
  })
})

const acceptAttribute = computed<string>(() => {
  const extensions = props.acceptedExtensions.map((extension) => {
    return `.${extension}`
  })
  return [...extensions, ...props.acceptedMimeTypes].join(',')
})

function doesFileTypeMatch (file: File): boolean {
  const fileExtension = file.name.split('.').pop()
  if (fileExtension) {
    for (const extension of props.acceptedExtensions) {
      if (extension === fileExtension) {
        return true
      }
    }
  }

  for (const mimeType of props.acceptedMimeTypes) {
    if (mimeType === file.type) {
      return true
    }
  }

  return false
}

function isFileAccepted (file: File): boolean {
  if (props.acceptedExtensions.length === 0 && props.acceptedMimeTypes.length === 0) {
    return true
  }
  return doesFileTypeMatch(file)
}

function setIsBeingDraggedOver (event: DragEvent) {
  elementBeingDraggedOver.value = event.target
}

function setIsNotBeingDraggedOver (event: DragEvent) {
  if (elementBeingDraggedOver.value === event.target) {
    elementBeingDraggedOver.value = null
  }
}

function onDrop (event: DragEvent) {
  elementBeingDraggedOver.value = null
  const file = event.dataTransfer?.files[0] ?? null
  if (file && isFileAccepted(file)) {
    emit('file-change', file)
  }
}

function showFileSelector () {
  fileInput.value?.click()
}

function onFileSelected () {
  const file = fileInput.value?.files?.[0] ?? null
  if (file && isFileAccepted(file)) {
    emit('file-change', file)
  }
}

function resetFile () {
  emit('file-remove')
}
</script>

<template>
  <div>
    <div
      v-if="props.currentFilename === undefined"
      :class="[
        'drag-and-drop-zone rounded pa-8 d-flex flex-column justify-center text-center',
        {
          'dragged-over': !!elementBeingDraggedOver
        }
      ]"
      data-testid="drag-and-drop-zone"
      @click="showFileSelector"
      @dragenter="setIsBeingDraggedOver"
      @dragleave="setIsNotBeingDraggedOver"
      @dragover.prevent
      @drop.prevent="onDrop"
    >
      <v-icon
        color="neutral-darken1"
        size="20"
        class="mx-auto"
      >
        fa:fal fa-cloud-arrow-up
      </v-icon>
      <span class="text-body-1 text-neutral-darken3 pt-2">
        {{ t('drag_and_drop_part_1') }}
        <span class="fake-link">{{ t('choose_file') }}</span>
        {{ t('drag_and_drop_part_2') }}
      </span>
      <span
        v-if="props.acceptedExtensions"
        class="text-subtitle-1 text-neutral-darken1 pt-2"
      >
        {{ acceptedExtensionsText }}
      </span>

      <input
        ref="fileInput"
        :accept="acceptAttribute"
        class="d-none"
        data-testid="drag-and-drop-zone-file-input"
        type="file"
        @change="onFileSelected"
      >
    </div>
    <div
      v-else
      class="d-flex justify-space-between neutral-lighten3 overflow-hidden rounded px-4 py-2"
      data-testid="drag-and-drop-zone-current-file-indicator"
    >
      <div class="d-flex align-center overflow-hidden">
        <v-icon
          class="mr-2"
          color="neutral-darken3"
          size="small"
        >
          fa:fal fa-file
        </v-icon>
        <span class="text-body-1 text-truncate text-neutral-darken3 mr-2">{{ props.currentFilename }}</span>
      </div>
      <v-btn
        class="mr-n2"
        color="neutral-darken3"
        icon
        size="small"
        @click="resetFile"
      >
        <v-icon size="small">
          fa:fal fa-xmark
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="sass">
.drag-and-drop-zone
  border-style: dashed
  border-width: 1px
  border-color: rgb(var(--v-theme-neutral-lighten1))
  cursor: pointer
  &.dragged-over
    border-style: solid
    border-color: rgb(var(--v-theme-primary))
    background-color: rgb(var(--v-theme-primary-lighten4))

.fake-link
  color: rgb(var(--v-theme-primary-darken2))
  &:hover
    text-decoration: underline
</style>

<i18n lang="json" locale="de">
  {
    "drag_and_drop_part_1": "Ziehen und ablegen oder",
    "choose_file": "Datei zum Hochladen auswählen",
    "drag_and_drop_part_2": "",
    "extensions": "{extensionsExceptLast} oder {lastExtension}"
  }
</i18n>

<i18n lang="json" locale="en">
  {
    "drag_and_drop_part_1": "Drag and drop or",
    "choose_file": "Choose file",
    "drag_and_drop_part_2": "to upload",
    "extensions": "{extensionsExceptLast} or {lastExtension}"
  }
</i18n>
