import { AssignedPin, ComponentInProjectWithContext, Pin } from '@aedifion.io/aedifion-api'
import { ComponentPinCardData, MappingEditorState } from './types'
import { convertDatapointToListItem } from '@/utils/helpers/datapoints'
import { DatapointListItemData } from '../datapoints/types'
import { GetterTree } from 'vuex'
import { RootState } from '../types'
import { textForLocale } from '@/utils/helpers/locale'
import { validateNotNullish } from '@/utils/helpers/validate'

function datapointListItemData (state: MappingEditorState, rootState: RootState, dataPointID: string): DatapointListItemData|null {
  for (const datapoint of state.mappedDatapointsWithContext || []) {
    if (datapoint.dataPointID === dataPointID) {
      return convertDatapointToListItem(datapoint, rootState.data_points_view.selectedDatapointsHashIds, rootState.data_points_view.seriesColorMap)
    }
  }
  return null
}

export default {
  componentInProjectWithContext: (state: MappingEditorState): ComponentInProjectWithContext|null => state.componentInProjectWithContext,

  isLoading: (state: MappingEditorState): boolean => state.loading,

  isPinMapped: (state: MappingEditorState) => (pinId: number): boolean => {
    const componentInProjectWithContext = validateNotNullish(
      state.componentInProjectWithContext,
      { errorMessage: 'mapping_editor.getters.isPinMapped: No componentInProjectWithContext' },
    )
    const pins = componentInProjectWithContext.pins
    return pins !== undefined && pins.some((pin: Pin) => { return pin.id === pinId })
  },

  pins: (state: MappingEditorState): Pin[]|null => state.pins,

  pinsCardData: (state: MappingEditorState, getters, rootState: RootState): ComponentPinCardData[] => {
    if (!state.componentInProjectWithContext || !state.pins) {
      return []
    }
    const result: Array<ComponentPinCardData> = []
    for (const pin of state.pins) {
      const assignedPin: AssignedPin|undefined = state.componentInProjectWithContext.pins?.find((assignedPin: AssignedPin) => assignedPin.id === pin.id)
      let datapoint: DatapointListItemData|undefined
      if (assignedPin) {
        datapoint = datapointListItemData(state, rootState, assignedPin.dataPointID!) || undefined
      }
      result.push({
        datapoint,
        id: pin.id!,
        name: textForLocale(pin.nameDE!, pin.nameEN!),
      })
    }
    return result
  },

  pinsWithPendingMappingUpdates: (state: MappingEditorState): number[] => state.pinsWithPendingMappingUpdates,
} as GetterTree<MappingEditorState, RootState>
