<script setup lang="ts">
import ButtonGroup, { type Button } from '@/components/ButtonGroup.vue'
import { computed, ref, watch } from 'vue'
import DateOrMonthPicker from '@/components/DateOrMonthPicker.vue'
import moment from 'moment'
import PageHeader from '@/components/PageHeader.vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useReportingStore } from '@/stores/views/Reporting'
import { useUserStore } from '@/stores/user'

const reportingStore = useReportingStore()
const userStore = useUserStore()
const { t } = useI18n()

const perSquareUnitLabel = computed<'m²'|'ft²'>(() => {
  const { units_system: unitSystem } = userStore.userDetails ?? {}

  if (unitSystem === 'imperial' || unitSystem === 'usc') return 'ft²'

  return 'm²'
})

const { isPerSquareUnit } = storeToRefs(reportingStore)

const MAX_DATE = moment().endOf('month').subtract(1, 'month').format('YYYY-MM')

const dateRange = computed(() => reportingStore.dateRange)

const menu = ref(false)

const aggregationType = ref<'absolute'|'perSquareUnit'>('perSquareUnit')

const buttons = computed<Button[]>(() => [{
  activeIcon: 'fa:far fa-check',
  id: 'absolute',
  label: t('absolute'),
}, {
  activeIcon: 'fa:far fa-check',
  id: 'perSquareUnit',
  label: `${t('per')} ${t(perSquareUnitLabel.value)}`,
}])

function handleUpdateDate (value: [start: Date, end: Date]) {
  reportingStore.dateRange = value
  reportingStore.clearState()
}

watch(
  aggregationType,
  (value) => {
    isPerSquareUnit.value = value === 'perSquareUnit'
  },
  { immediate: true },
)
</script>

<template>
  <PageHeader
    title-key="links.meta.title.reporting"
  >
    <div class="d-flex flex-wrap ml-auto controls-div">
      <ButtonGroup
        v-model="aggregationType"
        :buttons="buttons"
        class="mr-2"
      />
      <DateOrMonthPicker
        :menu="menu"
        :date="dateRange"
        :disabled="false"
        :max-date="MAX_DATE"
        :width="'auto'"
        :text="t('dates.time_period')"
        range
        @update:menu="menu = $event"
        @update:date="handleUpdateDate($event)"
      />
    </div>
  </PageHeader>
</template>

<i18n lang="json" locale="de">
{
  "absolute": "Absolut",
  "per": "Pro"
}
</i18n>
<i18n lang="json" locale="en">
{
  "absolute": "Absolute",
  "per": "Per"
}
</i18n>

<style scoped lang="sass">
@media screen and (max-width: 907px)
  .controls-div
    margin-top: 8px
    margin-bottom: 8px
    gap: 8px
</style>
