<script setup lang="ts">
import { Alignment, ColumnHeader, SortDirection } from './types'
import { computed } from 'vue'

interface Props {
  header: ColumnHeader;
  sortByKey?: string|null;
  sortDirection?: SortDirection|null;
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'resetSort'): void;
  (e: 'sortBy', key: string, direction: SortDirection): void;
}>()

const flexJustifyValue = computed<string>(() => {
  let direction

  switch (props.header.align) {
    case Alignment.Left:
      direction = 'start'
      break
    case Alignment.Right:
      direction = 'end'
      break
    case Alignment.Center:
      direction = 'center'
      break
    default:
      direction = 'start'
  }

  return `justify-${direction}`
})

const classesForHeader = computed<string[]>(() => {
  const result = [
    'header-cell',
    'd-flex',
    `${flexJustifyValue.value}`,
  ]
  if (sortingEnabled.value) {
    result.push('sortable')
    if (props.sortByKey === props.header.key) {
      result.push('sorted')
    }
  }
  return result
})

const classesForSortIcon = computed<string[]>(() => {
  const result = ['sort-icon']
  if (sortIconIsOnLeftSide.value) {
    result.push('order-first')
  } else {
    result.push('order-last')
  }

  const sortOptions = props.header.sortOptions || []
  const currentlySortedDescending = props.sortByKey === props.header.key && props.sortDirection === SortDirection.DESC
  const clickWillSortDescending = props.sortByKey !== props.header.key && sortOptions.length > 0 && sortOptions[0] === SortDirection.DESC
  if (currentlySortedDescending || clickWillSortDescending) {
    result.push('rotated')
  }
  return result
})

const sortIconColor = computed<string>(() => {
  if (props.sortByKey === props.header.key && props.sortDirection !== null) {
    return 'primary'
  } else {
    return 'neutral'
  }
})

const sortIconIsOnLeftSide = computed<boolean>(() => {
  return sortingEnabled.value && (props.header.align ?? Alignment.Left) === 'right'
})

const sortingEnabled = computed<boolean>(() => {
  return props.header.sortOptions !== undefined && props.header.sortOptions.length > 0 && props.header.key !== 'summarized-table-action'
})

function onClick (): void {
  if (!sortingEnabled.value) {
    return
  }
  const sortOptions = props.header.sortOptions || []
  if (sortOptions.length === 1) {
    if (props.sortByKey === props.header.key) {
      emit('resetSort')
    } else {
      emit('sortBy', props.header.key, sortOptions[0])
    }
  } else {
    if (props.sortByKey === props.header.key) {
      if (props.sortDirection === sortOptions.at(-1)) {
        emit('resetSort')
      } else {
        emit('sortBy', props.header.key, props.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC)
      }
    } else {
      emit('sortBy', props.header.key, sortOptions[0])
    }
  }
}
</script>

<template>
  <div
    :class="classesForHeader"
    :data-testid="`header.${props.header.key}`"
    @click="onClick"
  >
    <v-tooltip
      location="top"
      :disabled="!header.tooltip"
      content-class="aedifion-tooltip"
    >
      <template #activator="{ props: tooltipProps }">
        <div

          :class="['label d-flex flex-nowrap', {
            'tw-justify-around': header.secondaryText,
            'tw-w-full': header.secondaryText
          }]"
          v-bind="tooltipProps"
        >
          <v-icon
            v-if="sortingEnabled"
            :class="classesForSortIcon"
            :start="sortIconIsOnLeftSide"
            :end="!sortIconIsOnLeftSide"
            :color="sortIconColor"
          >
            fa:far fa-arrow-up-long
          </v-icon>
          <span
            class="tw-whitespace-nowrap"
            v-html="header.text"
          />
          <span
            v-if="header.secondaryText"
            v-html="header.secondaryText"
          />
        </div>
      </template>
      <span
        class="text-legend text-neutral-darken3"
        v-html="header.tooltip"
      />
    </v-tooltip>
  </div>
</template>

<style lang="sass" scoped>
.header-cell
  border: 1px solid transparent
  background-color: rgb(var(--v-background))
  color: rgb(var(--v-theme-neutral-darken1))

  &.sortable
    cursor: pointer !important
    pointer-events: auto

    .sort-icon
      font-size: 12px !important

      &.rotated
        transform: rotate(-180deg)
        transition: transform 0.2s ease

    &:not(.sorted)
      & .sort-icon
        visibility: hidden

    &.sorted
      color: rgb(var(--v-theme-primary-darken2))

      & .sort-icon
        color: rgb(var(--v-theme-primary-darken2))
        visibility: visible

    &:hover
      background-color: rgb(var(--v-theme-neutral-lighten2))
      color: rgb(var(--v-theme-primary-darken2)) !important

      &::before
        content: ''
        position: absolute
        top: -1px
        bottom: -1px
        left: -1px
        right: -1px
        color: rgb(var(--v-theme-primary-darken2)) !important
        background-color: rgb(var(--v-theme-primary-lighten4)) !important
        border: 1px solid rgb(var(--v-theme-primary-lighten2))
        border-radius: 4px
        z-index: -1

      & .sort-icon
        visibility: visible

  .label
    font-size: 12px
    font-weight: 500
    line-height: 16px
    padding-bottom: 8px
    padding-left: 16px
    padding-right: 16px
    padding-top: 8px
</style>
