import { ComponentsInProjectState } from './types'

export default {
  componentsInProjectWithResults: null,
  loadingComponentsInProject: false,
  pagination: null,
  pendingComponentInProjectUpdate: false,
} as ComponentsInProjectState

export function resetStoreState (state: ComponentsInProjectState): void {
  state.componentsInProjectWithResults = null
  state.loadingComponentsInProject = false
  state.pagination = null
  state.pendingComponentInProjectUpdate = false
}
