
import actions from './actions'
import { AssetOverviewState } from './types'
import getters from './getters'
import { Module } from 'vuex'
import mutations from './mutations'
import { RootState } from '../types'
import state from './state'

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
} as Module<AssetOverviewState, RootState>
