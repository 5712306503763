import { TimeseriesColors } from 'assets-repo/evolution-frontend/types'

/**
 * Returns the next available series color that is being used the least. Following the order of the AvailableSeriesColors as they are passed to the function.
 * @param seriesColorMap Map of used colors.
 * @param AvailableSeriesColors Colros that are available for the selection.
 * @returns color which is being used the least.
 */
export function getLeastUsedSeriesColor (seriesColorMap: Map<string, string>, AvailableSeriesColors: TimeseriesColors): string {
  if (AvailableSeriesColors.length === 0) {
    throw new Error('Passed \'AvailableSeriesColors\' pramater does not contain any colors.')
  }
  const colorsUsageCount: Map<string, number> = new Map()
  AvailableSeriesColors.forEach((color: string) => (colorsUsageCount.set(color, 0)))
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [hashID, color] of seriesColorMap) {
    const amount: number = colorsUsageCount.get(color) as number
    colorsUsageCount.set(color, amount + 1)
  }

  let chosenColor: string|undefined
  let chosenColorUsageCount = 0
  for (const [currentColor, currentColorUsageCount] of colorsUsageCount) {
    if (chosenColor === undefined || currentColorUsageCount < chosenColorUsageCount) {
      chosenColor = currentColor
      chosenColorUsageCount = currentColorUsageCount
      if (chosenColorUsageCount === 0) {
        break
      }
    }
  }

  return chosenColor!
}
