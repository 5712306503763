<script setup lang="ts">
import { computed } from 'vue'
import { VUETIFY_COLORS } from '@theme/colors'

type Props = {
  backgroundColor?: string,
  figureColor?: string,
  icon?: boolean,
  small?: boolean,
  text?: string,
  tooltipText?: string,
  unit?: string,
  value: string
}

const props = withDefaults(defineProps<Props>(), {
  backgroundColor: VUETIFY_COLORS.neutral.lighten3,
  figureColor: VUETIFY_COLORS.neutral.darken3,
  icon: false,
  small: false,
})

// @ts-ignore
const figurePadding = computed(() => {
  return props.small ? '2px 6px' : '2px 8px'
})

const valueTextSize = computed(() => {
  return props.small ? 'text-body-1' : 'text-semantics'
})
</script>

<template>
  <div data-testid="colored-figure">
    <v-tooltip
      :disabled="!props.tooltipText"
      location="top"
      content-class="aedifion-tooltip"
    >
      <template #activator="{props: tooltipProps}">
        <div
          :class="[ valueTextSize, 'figure font-weight-semibold rounded text-no-wrap d-inline-flex justify-center align-center tw-h-[32px]']"
          :style="{ backgroundColor: props.backgroundColor, color: props.figureColor }"
          v-bind="tooltipProps"
        >
          {{ props.icon ? '' : props.value }}
          <v-icon
            v-if="props.icon"
            class="figure-icon tw-self-start"
            :color="props.figureColor"
            size="20"
          >
            {{ props.value }}
          </v-icon>
          <span
            v-if="!props.icon && props.unit"
            class="text-body-1 ml-1"
          >{{ props.unit }}</span>
        </div>
      </template>
      <span
        v-if="props.tooltipText"
      >{{ props.tooltipText }}</span>
    </v-tooltip>
    <div
      v-if="text"
      data-test-id="colored-figure-text"
      class="text-body-1 text-neutral-darken2 mt-2"
    >
      {{ props.text }}
    </div>
    <slot />
  </div>
</template>

<style scoped lang="sass">
.figure
  padding: v-bind(figurePadding)

.figure-icon
  line-height: 28px !important
</style>
