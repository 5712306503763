import { ComponentInProjectAttribute, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { GoalsAttribute } from '@/vuex/building_analyses/types'

/**
 * Extracts an attribute specified by an attribute key, from a component in project.
 * @param component A component in project.
 * @param attributeKey The key of a component attribute, e.g. 'B+GOA' for goals attribute.
 * @returns Stringified attribute extracted from the component will be returned.
 */
export function getComponentAttribute (component: ComponentInProjectWithContext|null, attributeKey: string): ComponentInProjectAttribute | null {
  if (!component || !component.attributes) {
    return null
  }
  return component.attributes.find((attribute: ComponentInProjectAttribute) => attribute.key === attributeKey) || null
}

interface ComponentAttributeInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [attributeKey: string]: any;
  'B+GOA': GoalsAttribute
}

/**
 * Extracts an attribute specified by an attribute key, from a component in project. Then parse it to a well defined JSON.
 * @param component A component in project.
 * @param attributeKey The key of a component attribute, e.g. 'B+GOA' for goals attribute.
 * @returns The specified attribute extracted from the component will be returned in JSON format.
 */
export function parseComponentAttribute<T extends keyof ComponentAttributeInterface> (component: ComponentInProjectWithContext, attributeKey: T): ComponentAttributeInterface[T] {
  try {
    const attribute: ComponentInProjectAttribute | null = getComponentAttribute(component, attributeKey as string)
    if (!attribute || attribute.value === undefined) {
      return {}
    }
    return JSON.parse(attribute.value)
  } catch (e) {
    return {}
  }
}

/**
 * Search for a specific attribute in a component in project, identified through the passed alhpanumeric id.
 * @param component The component in project to search the attribute for.
 * @param alphanumericId The alphanumeric id identifies the requested attribute.
 * @returns ComponentInProjectAttribute or null, if the attribute was not found by the passed alphanumeric id.
 */
export function getComponentAttributeByAlphanumericId (component: ComponentInProjectWithContext, alphanumericId: string): ComponentInProjectAttribute|null {
  if (component.attributes === undefined) {
    return null
  }
  const attributes: ComponentInProjectAttribute[]|null = component.attributes
  const foundAttribute: ComponentInProjectAttribute|undefined = attributes.find((attribute: ComponentInProjectAttribute) => attribute.alphanumeric_id === alphanumericId)
  if (!foundAttribute) return null
  else return foundAttribute
}
