import { formatValue } from './formatting'
import i18n from '@/i18n'

/**
 * Returns the net floor area building attribute formatted in standardized way.
 * @param netFloorArea string | number | undefined
 * @param unit string | undefined, default `m²`
 * @returns string
 */
export function formatNetFloorArea (netFloorArea?: string|number, unit = 'm²') {
  if (typeof netFloorArea === 'string') {
    netFloorArea = Number.parseInt(netFloorArea ?? '')
  }
  return formatValue(netFloorArea, { integer: true, unit })
}

/**
 * Returns the translated string for a building type if it exists, and
 * `undefined` otherwise.
 * @param type string | undefined
 * @returns string | undefined
 */
export function formatBuildingType (type?: string, capitalize = false) {
  if (i18n.global.te(`building_type.${type}`)) {
    const result = i18n.global.t(`building_type.${type}`) as string
    if (capitalize) {
      return result.charAt(0).toUpperCase() + result.slice(1)
    } else {
      return result
    }
  }
  return undefined
}

/**
 * Returns only the last line of an address.
 * @param address string | undefined
 * @returns string | undefined
 */
export function formatAddressLastLine (address?: string) {
  return address?.split('\n').pop()
}

/**
 * Returns the building attributes of a project formatted in a standardized way.
 * What this function returns is often used as project "description" in the UI.
 *
 * Example: `843 m² · Office · Köln`
 * @param netFloorArea string | number | undefined
 * @param type string | undefined
 * @param address string | undefined
 * @param netFloorArea string | undefined, default `m²`
 * @returns string
 */
export function formatBuildingAttributes (netFloorArea?: string|number, type?: string, address?: string, netFloorAreaUnit?: string, options = { capitalize: false }) {
  return [
    formatNetFloorArea(netFloorArea, netFloorAreaUnit),
    formatBuildingType(type, options.capitalize),
    formatAddressLastLine(address),
  ].filter((formattedBuildingProperty) => {
    // filters out undefined and '' values
    return formattedBuildingProperty
  }).join(' · ')
}
