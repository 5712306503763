<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

interface Props {
  avatar?: string
  hideToolbar?: boolean
  loading?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'remove-avatar'): void,
  (e: 'update-avatar', file: File): void
}>()

const { t } = useI18n()

const fileInput = ref<HTMLInputElement | null>(null)

const avatar = computed(() => {
  if (props.loading || !props.avatar) {
    return '/img/dummy-image-4-3.jpg'
  }
  return props.avatar
})

const removeAvatar = () => {
  if (fileInput.value) {
    // reset file input
    fileInput.value.value = ''
  }
  emit('remove-avatar')
}

const showFileSelector = () => {
  fileInput.value?.click()
}

const onFileSelected = () => {
  const selectedFiles = fileInput.value?.files
  if (selectedFiles && selectedFiles.length) {
    emit('update-avatar', selectedFiles[0])
  }
}
</script>

<template>
  <div class="avatar-editor-frame">
    <v-img
      :alt="t('avatar_alt')"
      :aspect-ratio="4/3"
      class="neutral-lighten1 rounded"
      cover
      min-width="150px"
      :src="avatar"
    >
      <v-row
        v-if="loading"
        align="center"
        class="fill-height ma-0"
        justify="center"
      >
        <v-progress-circular
          color="neutral-darken2"
          indeterminate
          size="80"
        />
      </v-row>
    </v-img>
    <div
      v-if="!hideToolbar"
      class="avatar-editor-toolbar d-flex justify-end pr-2"
    >
      <div class="avatar-editor-toolbar-background rounded-b" />
      <v-tooltip location="top">
        <template #activator="{ props: deleteTooltipProps }">
          <v-btn
            theme="dark"
            icon
            variant="text"
            color="neutral-lighten2"
            size="small"
            :disabled="loading || !props.avatar"
            v-bind="deleteTooltipProps"
            @click="removeAvatar"
          >
            <v-icon size="16">
              fa:far fa-trash
            </v-icon>
          </v-btn>
        </template>
        {{ t('remove_avatar') }}
      </v-tooltip>
      <v-tooltip location="top">
        <template #activator="{ props: avatarTooltipProps }">
          <v-btn
            theme="dark"
            size="small"
            icon
            variant="text"
            color="neutral-lighten2"
            :disabled="loading"
            v-bind="avatarTooltipProps"
            @click="showFileSelector"
          >
            <v-icon size="16">
              fa:far fa-camera
            </v-icon>
          </v-btn>
        </template>
        {{ t('choose_new_avatar') }}
      </v-tooltip>
    </div>

    <input
      ref="fileInput"
      class="d-none"
      data-testid="avatar-editor-file-input"
      enctype="image/*"
      type="file"
      @change="onFileSelected"
    >
  </div>
</template>

<style lang="sass" scoped>
.avatar-editor-frame
  position: relative

.avatar-editor-toolbar
  position: absolute
  bottom: 0

  width: 100%

.avatar-editor-toolbar-background
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0

  background-color: rgb(var(--v-theme-neutral-darken3))
  opacity: 50%
</style>

<i18n lang="json" locale="de">
{
  "avatar_alt": "Avatar",
  "remove_avatar": "Avatar entfernen",
  "choose_new_avatar": "Neuen Avatar wählen"
}
</i18n>
<i18n lang="json" locale="en">
  {
    "avatar_alt": "Avatar",
    "remove_avatar": "Remove avatar",
    "choose_new_avatar": "Choose new avatar"
  }
</i18n>
