import { AssetProfileState } from './types'

export default {
  updatingCertificationsData: false,
  updatingEquipmentData: false,
  updatingGeneralData: false,
  updatingStakeholdersData: false,
} as AssetProfileState

export function resetStoreState (state: AssetProfileState): void {
  state.updatingCertificationsData = false
  state.updatingEquipmentData = false
  state.updatingGeneralData = false
  state.updatingStakeholdersData = false
}
