<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { type UpdateCompany } from '@aedifion.io/aedifion-api'
import { useAdministrationStore } from '@/stores/views/Administration/index'
import { useI18n } from 'vue-i18n'

interface Props {
  isOpen: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { t } = useI18n()

const administrationStore = useAdministrationStore()

const address = ref('')
const phone = ref('')
const description = ref('')
const isSaving = ref(false)

watch(() => props.isOpen, (newValue) => {
  if (newValue === true) {
    address.value = administrationStore.company?.address ?? ''
    phone.value = administrationStore.company?.phone ?? ''
    description.value = administrationStore.company?.description ?? ''
  }
})

const hasChanged = computed<boolean>(() => {
  return address.value !== (administrationStore.company?.address ?? '') ||
    phone.value !== (administrationStore.company?.phone ?? '') ||
    description.value !== (administrationStore.company?.description ?? '')
})

async function save () {
  if (hasChanged.value) {
    isSaving.value = true
    const companyChangesObject: UpdateCompany = {}
    if (address.value !== (administrationStore.company?.address ?? '')) {
      companyChangesObject.address = address.value
    }
    if (phone.value !== (administrationStore.company?.phone ?? '')) {
      companyChangesObject.phone = phone.value
    }
    if (description.value !== (administrationStore.company?.description ?? '')) {
      companyChangesObject.description = description.value
    }
    await administrationStore.updateCompany(companyChangesObject)
    emit('close')
    isSaving.value = false
  }
}
</script>

<template>
  <v-dialog
    :model-value="props.isOpen"
    max-width="480px"
    persistent
    @update:model-value="emit('close')"
  >
    <v-card
      class="aedifion-box-shadow"
      data-testid="company-edit-dialog"
    >
      <form @submit.prevent="save()">
        <v-card-title class="text-h6 font-weight-semibold justify-space-between flex-nowrap d-flex align-center">
          <span>
            {{ t('edit_company') }}
          </span>
          <v-btn
            data-testid="company-edit-dialog-close"
            icon
            size="small"
            @click="emit('close')"
          >
            <v-icon size="x-small">
              fa:far fa-xmark-large
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            class="mb-4"
            data-testid="company-edit-dialog-company"
            disabled
            variant="filled"
            hide-details="auto"
            :label="t('field_labels.company_name')"
            :model-value="administrationStore.company?.name ?? ''"
          />
          <v-text-field
            v-model="address"
            class="mb-4"
            data-testid="company-edit-dialog-address"
            :disabled="isSaving"
            variant="filled"
            hide-details="auto"
            :label="t('field_labels.location')"
          />
          <v-text-field
            v-model="phone"
            class="mb-4"
            data-testid="company-edit-dialog-phone"
            :disabled="isSaving"
            variant="filled"
            hide-details="auto"
            :label="t('field_labels.phone_number')"
          />
          <v-textarea
            v-model="description"
            auto-grow
            data-testid="company-edit-dialog-description"
            :disabled="isSaving"
            variant="filled"
            hide-details="auto"
            :label="t('field_labels.description')"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary-lighten3"
            class="flex-grow-1 text-primary"
            data-testid="company-edit-dialog-cancel"
            height="40px"
            variant="elevated"
            @click="emit('close')"
          >
            {{ t('actions.cancel') }}
            <v-icon
              class="ml-3"
              size="x-small"
            >
              fa:far fa-xmark
            </v-icon>
          </v-btn>
          <v-btn
            color="primary-darken2"
            class="flex-grow-1 text-white"
            data-testid="company-edit-dialog-save"
            :disabled="!hasChanged"
            variant="elevated"
            height="40px"
            :loading="isSaving"
            type="submit"
          >
            {{ t('actions.save') }}
            <v-icon
              class="ml-3"
              size="x-small"
            >
              fa:far fa-check
            </v-icon>
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<i18n lang="json" locale="de">
  {
    "edit_company": "Firma bearbeiten",
    "field_labels": {
      "company_name": "Firmenname",
      "description": "Beschreibung",
      "location": "Standort",
      "phone_number": "Telefonnummer"
    }
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "edit_company": "Edit Company",
    "field_labels": {
      "company_name": "Company name",
      "description": "Description",
      "location": "Location",
      "phone_number": "Phone number"
    }
  }
</i18n>
