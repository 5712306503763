<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'
import MultipleDropDown from '@/components/MultipleDropDown.vue'
import SearchInput from '@/components/SearchInput.vue'
import { useAdministrationStore } from '@/stores/views/Administration'
import { useAdministrationUsersStore } from '@/stores/views/Administration/users'
import { useI18n } from 'vue-i18n'

const route = useRoute()
const router = useRouter()
const administrationStore = useAdministrationStore()
const administrationUsersStore = useAdministrationUsersStore()
const { t } = useI18n()

function updateSearchFilter (newSearch: string) {
  administrationUsersStore.searchFilter = newSearch
  router.replace({
    query: {
      ...route.query,
      search: newSearch.length ? newSearch : undefined,
    },
  })
}

const companyRolesFilterSelectionIndicator = computed(() => {
  if (administrationUsersStore.companyRolesFilter.length <= 0) {
    return ''
  } else if (administrationUsersStore.companyRolesOptions.length >= 2 && administrationUsersStore.companyRolesFilter.length >= administrationUsersStore.companyRolesOptions.length) {
    return t('all_roles')
  } else if (administrationUsersStore.companyRolesFilter.length >= 4) {
    return t('number_selected', { number: administrationUsersStore.companyRolesOptions.length })
  }
  return administrationUsersStore.companyRolesOptions.filter((companyRolesOption) => {
    return companyRolesOption.id && administrationUsersStore.companyRolesFilter.includes(companyRolesOption.id)
  }).map((companyRolesOption) => {
    return companyRolesOption.label
  }).join(', ')
})

function updateCompanyRolesFilter (newCompanyRoleFilterState: number[]) {
  newCompanyRoleFilterState = newCompanyRoleFilterState.slice().sort((idA, idB) => {
    return idA - idB
  })
  administrationUsersStore.companyRolesFilter = newCompanyRoleFilterState
  router.replace({
    query: {
      ...route.query,
      roles: newCompanyRoleFilterState.length ? newCompanyRoleFilterState.join(',') : undefined,
    },
  })
}

const projectsFilterSelectionIndicator = computed(() => {
  if (administrationUsersStore.projectsFilter.length <= 0) {
    return ''
  } else if (administrationUsersStore.projectsOptions.length >= 2 && administrationUsersStore.projectsFilter.length >= administrationUsersStore.projectsOptions.length) {
    return t('all_projects')
  } else if (administrationUsersStore.projectsFilter.length >= 4) {
    return t('number_selected', { number: administrationUsersStore.projectsFilter.length })
  }
  return administrationUsersStore.projectsOptions.filter((projectsOption) => {
    return projectsOption.id !== undefined && administrationUsersStore.projectsFilter.includes(projectsOption.id)
  }).map((projectsOption) => {
    return projectsOption.label
  }).join(', ')
})

function updateProjectsFilter (newProjectFilterState: number[]) {
  newProjectFilterState = newProjectFilterState.slice().sort((idA, idB) => {
    return idA - idB
  })
  administrationUsersStore.projectsFilter = newProjectFilterState
  router.replace({
    query: {
      ...route.query,
      projects: newProjectFilterState.length ? newProjectFilterState.join(',') : undefined,
    },
  })
}

const loading = computed(() => {
  return administrationStore.loading
})
</script>

<template>
  <div class="d-flex flex-wrap">
    <SearchInput
      class="search-field mr-2 mb-2"
      data-testid="administration-users-search"
      :disabled="loading"
      :placeholder="t('search_placeholder')"
      :model-value="administrationUsersStore.searchFilter"
      @update:model-value="updateSearchFilter"
    />
    <MultipleDropDown
      class="mr-2 mb-2"
      data-testid="administration-users-roles"
      :disabled="loading"
      :items="administrationUsersStore.companyRolesOptions"
      :label="t('filter_by_role')"
      :override-selection-text="companyRolesFilterSelectionIndicator"
      :value="administrationUsersStore.companyRolesFilter"
      @change="updateCompanyRolesFilter"
    />
    <MultipleDropDown
      class="mr-2 mb-2"
      data-testid="administration-users-projects"
      :disabled="loading"
      :items="administrationUsersStore.projectsOptions"
      :label="t('filter_by_project')"
      :override-selection-text="projectsFilterSelectionIndicator"
      :value="administrationUsersStore.projectsFilter"
      @change="updateProjectsFilter"
    />
  </div>
</template>

<style scoped lang="sass">
.search-field
  min-width: 340px
</style>

<i18n lang="json" locale="de">
  {
    "all_projects": "Alle Projekte",
    "all_roles": "Alle Rollen",
    "filter_by_role": "Nach Rolle filtern",
    "filter_by_project": "Nach Projektzugang filtern",
    "number_selected": "{number} ausgewählt",
    "search_placeholder": "Nach Name oder E-Mail-Adresse suchen"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "all_projects": "All projects",
    "all_roles": "All roles",
    "filter_by_role": "Filter by role",
    "filter_by_project": "Filter by project access",
    "number_selected": "{number} selected",
    "search_placeholder": "Find by name or email address"
  }
</i18n>
