<i18n lang="json" locale="de">
{
  "create_component_hint": "Fang an indem du eine Komponente erstellst.",
  "delete_component": "um eine Komponente zu löschen",
  "delete_component_confirmation": {
    "text": "Soll die Komponente wirklich gelöscht werden?",
    "title": "Komponente löschen",
    "additional_data": "Zusätzlich zur Komponente werden diese Daten gelöscht:",
    "attributes": "Attribute",
    "mappings": "Datenpunktverknüpfungen",
    "conclusion": "Das Löschen der Komponente <b>kann nicht rückgängig gemacht werden</b>. Wenn sie fälschlich gelöscht wurde muss sie per Hand neu erstellt werden."
  }
}
</i18n>
<i18n lang="json" locale="en">
{
  "create_component_hint": "Start by creating a component.",
  "delete_component": "to delete a component",
  "delete_component_confirmation": {
    "text": "Should the component really be deleted?",
    "title": "Delete component",
    "additional_data": "In addition to the component, these will be deleted as well:",
    "attributes": "Attributes",
    "mappings": "Mapped datapoints",
    "conclusion": "Deleting the component <b>cannot be undone</b>. If it was deleted in error it has to be setup again by hand."
  }
}
</i18n>

<template>
  <div class="layout-wrapper">
    <PageHeader
      sticky
      title-key="links.meta.title.optimization_components"
    />
    <div class="components-list-wrapper">
      <ComponentsInProjectList
        class="components-list"
        data-cy="components-in-project-list"
        @asidelist:click-item="onComponentSelected"
        @componentlistitem:delete-component="requestDeleteComponentInProject"
        @componentlistitem:edit-attributes="editAttributesComponentInProject"
        @componentlistitem:edit-mapping="openMappingEditor"
        @componentlistitem:rename-component="renameComponentInProject"
      />
    </div>
    <router-view
      v-if="hasProjectComponent"
      class="visualization__wrapper"
    />
    <span
      v-else
      class="visualization__wrapper text-h6 d-flex justify-center align-center fill-height"
    >
      {{ t('create_component_hint') }}
    </span>
    <MappingEditor
      v-if="showMappingEditor"
      :component-in-project="componentsInProjectListKebabActionItem"
      @mapping-editor:close="closeMappingEditor"
    />
    <ConfirmationDialog
      v-model="showDeleteComponentInProjectConfirmationDialog"
      accept-button-text-key="actions.delete"
      :required-input="componentInProjectToDeleteTitle"
      :title="t('delete_component_confirmation.title')"
      @confirmation-dialog:accept="deleteComponentInProject"
      @confirmation-dialog:cancel="abortDeleteComponentInProject"
    >
      <span class="mb-4 d-block">{{ t('delete_component_confirmation.text') }}</span>
      <template #warning>
        <div class="mb-2">
          {{ t('delete_component_confirmation.additional_data') }}
          <ul class="tw-list-disc ml-6">
            <li>{{ t('delete_component_confirmation.attributes') }}</li>
            <li>{{ t('delete_component_confirmation.mappings') }}</li>
          </ul>
        </div>
        <p
          class="mb-0"
          v-html="t('delete_component_confirmation.conclusion')"
        />
      </template>
    </ConfirmationDialog>
    <RenameComponentDialog
      v-if="showRenameComponentDialog"
      v-model="showRenameComponentDialog"
      :item-data="componentInProjectToRename"
    />
    <ComponentAttributesEditor
      v-if="showEditAttributesComponentDialog"
      v-model="showEditAttributesComponentDialog"
      :attributes="componentInProjectAttributes"
      :loading="isLoadingComponentInProjectAttributes"
      :component-in-project="componentsInProjectListKebabActionItem"
      @update:attribute="useComponentAttributeUpdater"
      @set-attribute-error="handleSetAttributeError"
    />
  </div>
</template>

<script lang="ts">
import { ComponentInProjectListItemData, FetchComponentsInProjectPayload } from '@/vuex/components_in_project/types'
import { NavigationGuardNext, RouteLocation, RouteLocationNormalized } from 'vue-router'
import type { Attribute } from '@/vuex/component_attributes_editor/types'
import ComponentAttributesEditor from './ComponentAttributes/ComponentAttributesEditor.vue'
import { ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import ComponentsInProjectList from './ComponentsInProjectList/index.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { defineComponent } from 'vue'
import { InvalidValueError } from '@/utils/helpers/validate'
import MappingEditor from './MappingEditor.vue'
import PageHeader from '@/components/PageHeader.vue'
import RenameComponentDialog from '@/views/Optimization/Components/ComponentsInProjectList/RenameComponentDialog.vue'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState as resetComponentAttributeStore } from '@/vuex/component_attributes_editor/state'
import { setQueryUpdaterPluginEnabled } from '@/vuex/plugins/QueryUpdater/queryUpdater'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { useComponentAttributeUpdater } from './ComponentAttributes/Composables/useComponentAttributeUpdater'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'OptimizationDetails',

  components: {
    ComponentAttributesEditor,
    ComponentsInProjectList,
    ConfirmationDialog,
    MappingEditor,
    PageHeader,
    RenameComponentDialog,
  },

  async beforeRouteUpdate (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    if (to.params.project && from.params.project && to.params.project !== from.params.project) {
      await this.ensureAnyComponentInProjectIsSelected(true)
      const newLocation = this.routeWithComponentInProject(true, true)

      if (newLocation.params!.componentInProjectId && newLocation.params!.componentInProjectId !== to.params.componentInProjectId) {
        next(newLocation)
      } else {
        next()
      }
    } else {
      next()
    }
  },

  beforeRouteLeave (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    setQueryUpdaterPluginEnabled(false)
    this.$store.commit('optimization/SET_SEARCH', '')
    setQueryUpdaterPluginEnabled(true)
    next()
  },

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      componentInProjectToDelete: null as null|ComponentInProjectListItemData,
      componentInProjectToRename: null as null|ComponentInProjectListItemData,
      componentsInProjectListKebabActionItem: null as ComponentInProjectListItemData|null,
      showDeleteComponentInProjectConfirmationDialog: false,
      showEditAttributesComponentDialog: false as boolean,
      showMappingEditor: false,
      showRenameComponentDialog: false as boolean,
    }
  },

  computed: {
    componentInProjectAttributes (): Attribute[] {
      return this.$store.getters['component_attributes_editor/getAttributeDefinitionList']
        .filter((attribute: Attribute) => attribute.alphanumeric_id !== 'B+CERTS')
    },

    componentInProjectToDeleteTitle (): string {
      return this.componentInProjectToDelete ? this.componentInProjectToDelete.title : ''
    },

    hasProjectComponent (): boolean {
      return this.$store.getters['components_in_project/totalItems'] > 0
    },

    isLoadingComponentInProjectAttributes (): boolean {
      return this.$store.getters['component_attributes_editor/isLoadingForFetch']
    },
  },

  watch: {
    showEditAttributesComponentDialog (newVal) {
      if (newVal === false) {
        resetComponentAttributeStore(this.$store.state.component_attributes_editor)
      }
    },
  },

  async created () {
    await this.ensureAnyComponentInProjectIsSelected()
    this.updateRouteWithComponentInProject()
  },

  methods: {
    abortDeleteComponentInProject (): void {
      this.componentInProjectToDelete = null
      this.showDeleteComponentInProjectConfirmationDialog = false
    },

    closeMappingEditor (componentInProjectWithUpdatedMapping?: ComponentInProjectWithContext): void {
      this.showMappingEditor = false
      const selectedComponentInProject: ComponentInProjectWithContext|null = this.$store.getters['optimization/getSelectedComponent']
      if (componentInProjectWithUpdatedMapping && selectedComponentInProject &&
          componentInProjectWithUpdatedMapping.id === selectedComponentInProject.id) {
        this.$store.commit('optimization/SET_SELECTED_COMPONENT_IN_PROJECT', componentInProjectWithUpdatedMapping)
      }
    },

    async deleteComponentInProject () {
      try {
        const componentId = this.componentInProjectToDelete?.id
        this.componentInProjectToDelete = null
        this.showDeleteComponentInProjectConfirmationDialog = false
        await this.$store.dispatch('components_in_project/deleteComponentInProject', componentId)
      } catch (error) {
        if (error instanceof InvalidValueError) {
          showErrorNotification(`${this.t('notifications.errors.no_project_selected_with_attempted_action', { action: this.t('delete_component') })}`)
        }
        reportError(error)
      }
    },

    editAttributesComponentInProject (item: ComponentInProjectListItemData): void {
      this.$store.dispatch('component_attributes_editor/fetchData', item.id)

      this.componentsInProjectListKebabActionItem = item
      this.showEditAttributesComponentDialog = true
    },

    async ensureAnyComponentInProjectIsSelected (shouldResetPage = false): Promise<void> {
      try {
        await this.$store.dispatch('analysis_instances/fetchInstances')
        await this.fetchComponents(shouldResetPage)
        await this.$store.dispatch('optimization/ensureAnyComponentInProjectIsSelected')
      } catch (error) {
        reportError(error)
      }
    },

    async fetchComponents (shouldResetPage = false): Promise<void> {
      // watch out! if we’re inside `beforeRouteUpdate()`, the `queriedPage` is
      // the one from the previous page (because the navigation didn’t happen yet)
      const queriedPage: string|undefined = this.$route.query.page as string || undefined
      const page: number = queriedPage ? parseInt(queriedPage) : (this.$store.getters['components_in_project/currentPage'])
      const payload: FetchComponentsInProjectPayload = {
        filter: this.$route.query.filter ? `alphanumeric_id=${this.$route.query.filter}` : undefined,
        page: shouldResetPage ? 1 : page,
        search: this.$route.query.filter ? undefined : this.$store.getters['optimization/search'],
      }
      if (shouldResetPage) {
        setQueryUpdaterPluginEnabled(false)
      }
      await this.$store.dispatch('components_in_project/fetchComponentsInProjectWithResults', payload)
      if (!this.$store.getters['components_in_project/isCurrentPageValid'] && this.$store.getters['components_in_project/currentPage'] !== 1) {
        payload.page = 1
        await this.$store.dispatch('components_in_project/fetchComponentsInProjectWithResults', payload)
      }
      setQueryUpdaterPluginEnabled(true)
    },

    handleSetAttributeError (attribute: Attribute): void {
      this.$store.commit('component_attributes_editor/SET_COMPONENT_ATTRIBUTE', {
        ...attribute,
        error: true,
      })
    },

    async onComponentSelected (item: ComponentInProjectListItemData): Promise<void> {
      if (this.$store.getters['optimization/getSelectedComponent']?.id !== item.id) {
        try {
          await this.$store.dispatch('optimization/selectComponentInProject', item.id)
        } catch (error) {
          reportError(error)
        }
        this.$router.replace(this.routeWithComponentInProject(true)).catch(() => ({}))
      }
    },

    openMappingEditor (item: ComponentInProjectListItemData): void {
      this.componentsInProjectListKebabActionItem = item
      this.showMappingEditor = true
    },

    renameComponentInProject (item: ComponentInProjectListItemData): void {
      this.componentInProjectToRename = item
      this.showRenameComponentDialog = true
    },

    requestDeleteComponentInProject (item: ComponentInProjectListItemData): void {
      this.componentInProjectToDelete = item
      this.showDeleteComponentInProjectConfirmationDialog = true
    },

    routeWithComponentInProject (clearComponentSpecificQueryValues = false, resetPageQuery = false): RouteLocation {
      const result = { params: {}, query: { ...this.$route.query } } as RouteLocation
      if (clearComponentSpecificQueryValues && result.query) {
        delete result.query.instance
        delete result.query.result
      }
      if (resetPageQuery && result.query?.page) {
        result.query.page = '1'
      }
      const componenInProjectId: string|undefined = this.$store.getters['optimization/getSelectedComponent']?.id.toString() ?? undefined
      if (componenInProjectId) {
        result.name = 'optimization-selected-component-analyses'
        result.params!.componentInProjectId = componenInProjectId.toString()
      } else {
        result.name = 'optimization-components'
      }

      result.params!.project = this.$store.getters['projects/currentProjectId'].toString()
      return result
    },

    updateRouteWithComponentInProject (): void {
      if (this.$store.getters['optimization/getSelectedComponent'] &&
        this.$store.getters['optimization/getSelectedComponent']!.id.toString() !== this.$route.params.componentInProjectId
      ) {
        this.$router.replace(this.routeWithComponentInProject()).catch(() => ({}))
      }
    },

    useComponentAttributeUpdater,

  },
})
</script>

<style lang="sass" scoped>
.components-list
  position: fixed
  top: 108px
  width: clamp(260px, 30vw, 350px)
  min-width: 260px
  max-width: 350px
  max-height: calc(100vh - 148px) !important
  height: 100%

.visualization__wrapper
  margin-left: clamp(260px, 30vw, 350px)
  max-height: 100%
</style>
