import store from '@/vuex/index'

function isLocalDashboard (url: string): boolean {
  // This makes it possible to use the feature to automatically log in to Grafana dashboards on a local deployment
  if (url.includes('dashboard.') && window.location.hostname === 'localhost') {
    return true
  }

  let dashboardHostname = window.location.hostname
  if (dashboardHostname.includes('www.')) {
    dashboardHostname = dashboardHostname.replace('www.', 'dashboard.')
  } else {
    dashboardHostname = 'dashboard.' + dashboardHostname
  }
  return url.includes(dashboardHostname)
}

export function iFrameTarget (rawTarget: string): string {
  const accessToken: string|null = store.state.auth.access_token
  if (accessToken && isLocalDashboard(rawTarget)) {
    const url = new URL(rawTarget)
    url.searchParams.append('auth_token', accessToken)
    return url.toString()
  } else {
    return rawTarget
  }
}
