<script setup lang="ts">
import { ComponentCardItem } from '@/vuex/components_in_project/types'
import ComponentIcon from './ComponentIcon.vue'

type Props = {
  componentItem: ComponentCardItem,
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), { disabled: false })

const emit = defineEmits<{
  (e: 'click'): void
}>()

function onClick () {
  if (!props.disabled) {
    emit('click')
  }
}
</script>

<template>
  <v-card
    :class="[{ 'hoverable-bordered': !props.disabled }, 'pa-4']"
    data-test-id="component-card"
    :link="false"
    variant="outlined"
    @click.stop="onClick"
  >
    <div class="text-center">
      <ComponentIcon
        :alphanumeric-id="props.componentItem.alphanumericID"
        size="80"
      />
    </div>
    <v-card-text
      class="d-flex align-center justify-center text-cta text-center text-neutral-darken3 pa-0 mt-4"
    >
      {{ props.componentItem.componentName }}
    </v-card-text>
  </v-card>
</template>
