<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { NewTask, Task, UpdateTask, PlotView } from '@aedifion.io/aedifion-api'
import ChecklistTable from './ChecklistTable.vue'
import { COCKPIT_NUDGE_GRADIENT } from '@theme/colors'
import DefaultPageContainer from '@/components/Layout/DefaultPageContainer.vue'
import EmptyStateForPage from '@/components/EmptyStateForPage.vue'
import { type GetTasksPayload, Language, SortOrder } from '@aedifion.io/pinia-aedifion-api-stores'
import moment from 'moment'
import PageHeader from '@/components/PageHeader.vue'
import SidePeek from '@/components/Layout/SidePeek.vue'
import { storeToRefs } from 'pinia'
import TaskDeleteDialog from './Tasks/TaskDeleteDialog.vue'
import TaskView from './Tasks/TaskView.vue'
import { useAppStore } from '@/stores/app'
import { useChecklistStore, type TasksSortingKeys } from '@/stores/views/Optimization/Checklist'
import { useI18n } from 'vue-i18n'
import useIsReadonly from '@/composables/useIsReadOnly'
import TrendView from '@/components/TrendView.vue'
import vuexStore from '@/vuex'
import { usePlotViewsInProjectStore } from '@/stores/views/Optimization/Checklist/plotViewsInProject'
import { extractProperties } from '@/stores/views/Optimization/Checklist/helpers'

const appStore = useAppStore()
const checklistStore = useChecklistStore()
const plotViewStore = usePlotViewsInProjectStore()

const { locale, t } = useI18n()
const { loading, sortedTasksTableRows } = storeToRefs(checklistStore)
const { isReadOnly } = useIsReadonly()

function onHandleNewTask () {
  showTask(null)
}

const tasksPayload = computed<GetTasksPayload>(() => ({
  language: locale.value as Language,
  projectId: appStore.projectId,
  sortBy: [checklistStore.tasksSorting.key],
  sortOrder: checklistStore.tasksSorting.order,
}))

onMounted(() => {
  checklistStore.getTasks(tasksPayload.value)
})

const isSidePeekOpen = ref(false)
const isDeleteDialogOpen = ref(false)
const isPlottingDrawerOpen = ref(false)

const selectedTask = computed<Task|null>(() => checklistStore.currentEditedTask)

function showTask (data: Task|null) {
  if (data) {
    checklistStore.setCurrentEditedTask(data)
  } else {
    checklistStore.clearCurrentEditedTask()
  }

  isSidePeekOpen.value = true
}

function handleCloseDeleteDialog () {
  isDeleteDialogOpen.value = false
}

function closeSidePeek () {
  isSidePeekOpen.value = false
}

function deleteTask () {
  isDeleteDialogOpen.value = true
}

async function deleteTaskConfirmed () {
  await checklistStore.deleteTask({
    taskId: selectedTask.value!.id!,
  })
  isDeleteDialogOpen.value = false
  isSidePeekOpen.value = false
}

function hasTaskBeenUpdated (newBody: UpdateTask) {
  const newAnalyticsResult = newBody.analytics_result?.[0]
  const newComponentInProject = newBody.componentinproject?.[0]
  const newTaskTitle = newBody.title
  const newTaskDescription = newBody.description
  const newTaskAssigneeId = newBody.assignee_user_id
  const newTaskStatus = newBody.status
  const newTaskPriority = newBody.priority
  const newStartDate = moment(newBody.start).format('YYYY-MM-DD')
  const newEndDate = moment(newBody.end).format('YYYY-MM-DD')

  return newStartDate !== (moment(selectedTask.value!.start).format('YYYY-MM-DD')) ||
    newEndDate !== (moment(selectedTask.value!.end).format('YYYY-MM-DD')) ||
    (newAnalyticsResult && (newAnalyticsResult !== selectedTask.value?.analytics_result?.[0]?.id)) ||
    (newComponentInProject && (newComponentInProject !== selectedTask.value?.componentinproject?.[0]?.id)) ||
    newTaskTitle !== selectedTask.value?.title ||
    newTaskDescription !== selectedTask.value?.description ||
    newTaskAssigneeId !== selectedTask.value?.assignee?.id ||
    newTaskStatus !== selectedTask.value?.status ||
    newTaskPriority !== selectedTask.value?.priority
}

function onUpdateTask (newBody: UpdateTask) {
  if (!hasTaskBeenUpdated(newBody)) {
    return
  }
  checklistStore.putTask({
    body: {
      ...newBody,
    },
    taskId: selectedTask.value!.id!,
  })
}

async function onCreateTask (task: NewTask) {
  isSidePeekOpen.value = false
  await checklistStore.postTask({
    body: task,
    projectId: appStore.projectId,
  })
  checklistStore.getTasks(tasksPayload.value)
}

function handleSortingTriggered (payload: {key: TasksSortingKeys, direction: SortOrder}) {
  if (!payload) { // this means sorting was reset
    checklistStore.tasksSorting.key = 'priority'
    checklistStore.tasksSorting.order = SortOrder.Descending
  } else {
    checklistStore.tasksSorting.key = payload.key
    checklistStore.tasksSorting.order = payload.direction
  }
}

function handleTogglePlotting () {
  if (isPlottingDrawerOpen.value) {
    closePlottingDrawer()
    return
  }

  vuexStore.dispatch('optimization/selectComponentInProject', selectedTask.value?.componentinproject?.[0]?.id)
  openPlottingDrawer()
}

const sidePeekMenuItems = computed(() => {
  if (selectedTask.value && !isReadOnly.value) {
    return [{
      icon: 'far fa-trash',
      id: 'delete',
      label: t('delete_taskData'),
    }]
  }

  return []
})

const showDatapointsButton = computed<boolean>(() => {
  return !!selectedTask.value && (selectedTask.value.componentinproject?.[0]?.id !== undefined)
})

function openPlottingDrawer () {
  isPlottingDrawerOpen.value = true
}

function closePlottingDrawer () {
  isPlottingDrawerOpen.value = false
}

const sidePeekButtonIcon = computed(() => {
  return isPlottingDrawerOpen.value ? 'fa:far fa-arrow-right-to-line' : 'fa:far fa-chart-line'
})

watch(() => [appStore.projectId, checklistStore.tasksSorting], () => {
  closeSidePeek()
  checklistStore.clearStore()
  checklistStore.getTasks(tasksPayload.value)
}, { deep: true })

async function updatePlotView () {
  if (selectedTask.value?.id) {
    const timeseries = vuexStore.getters['optimization/selectedComponentsMappedPinHashIds']
    const sampleRate = vuexStore.state.optimization.samplerate
    const dateRange = vuexStore.state.optimization.dateRange
    const hiddenPinsHashIds = vuexStore.state.optimization.hiddenPinsHashIds
    const visiblePinsHashIds = timeseries.filter((item: string) => !hiddenPinsHashIds.includes(item))
    let plotViewId = selectedTask.value?.plot_view?.id || ''
    const plotViewData: PlotView = {
      start: new Date(dateRange[0]),
      end: new Date(dateRange[1]),
      samplerate: sampleRate,
      datapoints: visiblePinsHashIds,
      hidden_datapoints: hiddenPinsHashIds,
    }
    if (!plotViewId) {
      const newPlotViewId: string|undefined = await plotViewStore.postPlotView({
        plotView: plotViewData,
        projectId: appStore.projectId,
      })
      plotViewId = newPlotViewId || ''
    } else {
      await plotViewStore.putPlotView({
        plotView: plotViewData,
        plotViewId,
        projectId: appStore.projectId,
      })
    }

    await checklistStore.putTask({
      body: {
        ...extractProperties(selectedTask.value),
        plot_view_id: plotViewId,
      },
      taskId: selectedTask.value!.id!,
    })
  }
}
</script>

<template>
  <SidePeek
    class="tw-h-screen"
    :is-open="isSidePeekOpen"
    :menu-items="sidePeekMenuItems"
    :alt-style="isPlottingDrawerOpen"
    rounded
    :prevent-page-content-scroll="isPlottingDrawerOpen"
    @close="closeSidePeek(), closePlottingDrawer()"
    @delete-option-click="deleteTask"
  >
    <template #topBar>
      <v-btn
        v-if="showDatapointsButton"
        class="tw-mt-1 tw-bg-transparent"
        @click="handleTogglePlotting"
      >
        <v-icon
          size="14"
          class="mr-2"
        >
          {{ sidePeekButtonIcon }}
        </v-icon>
        <span>
          {{ t('links.meta.title.data_points_view') }}
        </span>
      </v-btn>
    </template>

    <div
      :class="['tw-bg-[rgb(var(--v-theme-neutral-lighten5))] ', { 'tw-rounded-tl tw-border-t tw-border-l tw-border-[rgb(var(--v-theme-neutral-lighten1))] tw-h-[calc(100%-48px)]': isPlottingDrawerOpen }]"
    >
      <TaskView
        :task="selectedTask"
        :is-read-only="isReadOnly"
        @update-task="onUpdateTask"
        @create-task="onCreateTask"
      />
      <TaskDeleteDialog
        v-if="isDeleteDialogOpen"
        :task="selectedTask"
        :value="isDeleteDialogOpen"
        @close="handleCloseDeleteDialog"
        @delete-task="deleteTaskConfirmed"
      />
    </div>

    <template #pageContent>
      <DefaultPageContainer>
        <div class="layout-wrapper">
          <PageHeader
            title-key="links.meta.title.optimization_checklist"
          >
            <v-btn
              v-if="(!loading && sortedTasksTableRows.length) && !isReadOnly"
              variant="outlined"
              color="primary-darken2"
              class="ml-auto text-primary btn-border tw-bg-white"
              @click="onHandleNewTask"
            >
              {{ t('new_task') }}
              <v-icon
                size="14"
                class="ml-3"
              >
                fa:far fa-circle-plus
              </v-icon>
            </v-btn>
          </PageHeader>
          <ChecklistTable
            v-show="sortedTasksTableRows.length || loading"
            :rows="sortedTasksTableRows"
            :loading="loading"
            :default-sorting="{
              key: checklistStore.tasksSorting.key,
              direction: checklistStore.tasksSorting.order
            }"
            :sticky-headers="false"
            @sort-triggered="handleSortingTriggered($event)"
            @click-task="showTask"
          />
          <EmptyStateForPage
            v-if="!loading && !sortedTasksTableRows.length"
            class="mt-n8"
            :icon="{
              name: 'fal fa-list-check',
              size: '40px',
              color: `linear-gradient(${COCKPIT_NUDGE_GRADIENT[0]}, ${COCKPIT_NUDGE_GRADIENT[1]}, ${COCKPIT_NUDGE_GRADIENT[2]})`
            }"
            :title="t('empty_state.title')"
            :description="t('empty_state.description')"
          >
            <v-btn
              v-if="!isReadOnly"
              color="primary-darken2"
              class="align-center"
              height="40px"
              data-testid="add-task-button-empty-state"
              @click="onHandleNewTask"
            >
              <span class="mr-3">{{ t('empty_state.add_new_task') }}</span>
              <v-icon size="small">
                fal fa-circle-plus
              </v-icon>
            </v-btn>
          </EmptyStateForPage>
        </div>
      </DefaultPageContainer>
      <Transition name="slide-right">
        <div
          v-if="isPlottingDrawerOpen"
          class="tw-fixed tw-z-[1100] tw-top-0 tw-right-[var(--sidepeek-width)] tw-bottom-0 tw-w-[calc(100vw-var(--sidepeek-width))] bg-neutral-lighten3 tw-px-2 tw-overflow-auto"
        >
          <TrendView
            show-controls-outside-chart
            :plot-configs="selectedTask?.plot_view"
            @update-plot-view="updatePlotView"
          />
        </div>
      </Transition>
    </template>
  </SidePeek>
</template>

<style lang="sass" scoped>
.slide-right-enter-active
  transition: transform 0.3s ease
.slide-right-leave-active
  transition: transform 0.6s ease
.slide-right-enter-from
 transform: translateX(100%)
.slide-right-leave-to
  transform: translateX(200%)

.btn-border
  border: 1px solid rgb(var(--v-theme-primary-lighten2))
:deep(.v-navigation-drawer)
  height: 100vh
</style>

<i18n lang="json" locale="de">
  {
  "delete_taskData": "Aufgabe löschen",
  "empty_state": {
    "description": "Erstelle Aufgaben, um Aktionen zu verfolgen, zu sehen, wer daran arbeitet und wie der aktuelle Status ist.",
    "title": "Ein Neubeginn ...",
    "add_new_task": "Neue Aufgabe"
  },
  "new_task": "Neue Aufgabe"
  }
</i18n>

<i18n lang="json" locale="en">
  {
  "delete_taskData": "Delete task",
  "empty_state": {
    "description": "Create tasks to keep track of actions, see who’s working on it and what the current status is.",
    "title": "A fresh start ...",
    "add_new_task": "New task"
  },
  "new_task": "New task"
 }
</i18n>
