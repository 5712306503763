import { AssetOverviewState } from './types'

export default {
  analogMetersToExecuteAnalysis: new Set(),
  loadingMeterReadings: false,
  loadingUserMeters: false,
  meterTimeseries: {},
  pendingAnalogMeterAnalysisExecutions: false,
  pendingAnalogMeterExecutionCount: { done: 0, total: 0 },
  pendingAnalysisExecutionCount: { done: 0, total: 0 },
  pendingAnalysisExecutions: false,
  pendingMeterUpdate: false,
  pendingReadingUpdate: false,
  selectedInstanceId: null,
  selectedResultId: null,
  selectedUserMeter: null,
  userMeters: null,
} as AssetOverviewState

export function resetStoreState (state: AssetOverviewState): void {
  state.analogMetersToExecuteAnalysis.clear()
  state.loadingMeterReadings = false
  state.loadingUserMeters = false
  state.meterTimeseries = {}
  state.pendingAnalogMeterAnalysisExecutions = false
  state.pendingAnalogMeterExecutionCount = { done: 0, total: 0 }
  state.pendingAnalysisExecutionCount = { done: 0, total: 0 }
  state.pendingAnalysisExecutions = false
  state.pendingMeterUpdate = false
  state.pendingReadingUpdate = false
  state.selectedInstanceId = null
  state.selectedResultId = null
  state.selectedUserMeter = null
  state.userMeters = null
}
