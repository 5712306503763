import { DataPointsViewState } from './types'
import moment from 'moment'

export default {
  datapointsOverview: [],
  datapointTimeseries: [],
  dateRange: [
    moment.utc().subtract(7, 'd').toISOString().split('T')[0],
    moment.utc().toISOString().split('T')[0],
  ],
  favoritesFilterSet: false,
  liveViewActive: false,
  liveViewRange: null,
  loadingDatapointsTimeseries: false,
  samplerate: 'auto',
  search: null,
  selectedDatapointsHashIds: [],
  seriesColorMap: new Map(),
  setpointDatapoint: null,
  setpointId: null,
  tagFilters: [],
  writableFilterSet: false,
  zoom: null,
} as DataPointsViewState

export function resetStoreState (state: DataPointsViewState): void {
  state.datapointsOverview = []
  state.datapointTimeseries = []
  state.dateRange = [
    moment.utc().subtract(7, 'd').toISOString().split('T')[0],
    moment.utc().toISOString().split('T')[0],
  ]
  state.favoritesFilterSet = false
  state.liveViewActive = false
  state.liveViewRange = null
  state.loadingDatapointsTimeseries = false
  state.search = null
  state.selectedDatapointsHashIds = []
  state.seriesColorMap = new Map()
  state.setpointDatapoint = null
  state.setpointId = null
  state.tagFilters = []
  state.writableFilterSet = false
  state.zoom = null
}
