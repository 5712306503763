import { addTagToTagsList, removeTagFromTagsList, updateTagInTagsList } from '@/utils/helpers/tags'
import {
  DatapointsState,
  DatapointWithContextAndScore,
  PinInComponentInProject,
  SetIsFavoritePayload,
  TagAddPayload,
  TagRemovedPayload,
  TagUpdatedPayload,
} from './types'
import { MutationTree } from 'vuex'
import { PaginationMeta } from '@aedifion.io/aedifion-api'

export default {
  ADD_DATAPOINT: (state: DatapointsState, datapoint: DatapointWithContextAndScore) => {
    if (state.datapoints === null) {
      state.datapoints = [datapoint]
    } else {
      const existingDatapointIndex = state.datapoints.findIndex((item: DatapointWithContextAndScore) => {
        return item.dataPointID === datapoint.dataPointID
      })
      if (existingDatapointIndex === -1) {
        state.datapoints.push(datapoint)
      } else {
        state.datapoints[existingDatapointIndex] = datapoint
      }
    }
  },

  ADD_PENDING_FAVORITES_UPDATE: (state: DatapointsState, datapointID: string) => {
    state.datapointsWithPendingFavoritesUpdate.push(datapointID)
  },

  ADD_TAG: (state: DatapointsState, payload: TagAddPayload) => {
    for (const datapoint of state.datapoints || []) {
      if (datapoint.dataPointID === payload.dataPointID) {
        datapoint.tags = addTagToTagsList(payload.tag, datapoint.tags)
        break
      }
    }
  },

  REMOVE_PENDING_FAVORITES_UPDATE: (state: DatapointsState, datapointID: string) => {
    state.datapointsWithPendingFavoritesUpdate.splice(state.datapointsWithPendingFavoritesUpdate.indexOf(datapointID))
  },
  REMOVE_TAG: (state: DatapointsState, payload: TagRemovedPayload) => {
    for (const datapoint of state.datapoints || []) {
      if (payload.dataPointID === datapoint.dataPointID) {
        datapoint.tags = removeTagFromTagsList(payload.tagId, datapoint.tags)
        break
      }
    }
  },

  SET_DATAPOINTS: (state: DatapointsState, datapoints: DatapointWithContextAndScore[]) => (state.datapoints = datapoints),

  SET_IS_FAVORITE: (state: DatapointsState, payload: SetIsFavoritePayload) => {
    for (const datapoint of state.datapoints || []) {
      if (datapoint.dataPointID === payload.dataPointID) {
        datapoint.favorite = payload.favorite
        break
      }
    }
  },

  SET_LOADING_DATAPOINTS: (state: DatapointsState, loading: boolean) => (state.loadingDatapoints = loading),

  SET_PAGINATION: (state: DatapointsState, pagination: PaginationMeta) => (state.pagination = pagination),

  SET_PENDING_TAG_UPDATE: (state: DatapointsState, status: boolean) => (state.pendingTagUpdate = status),

  SET_PIN_FOR_RECOMMENDATIONS: (state: DatapointsState, value: PinInComponentInProject|null) => (state.selectedPinForRecommendations = value),

  UPDATE_TAG_VALUE: (state: DatapointsState, updateResponse: TagUpdatedPayload) => {
    const { dataPointID, oldTagId, newTag } = updateResponse
    for (const datapoint of state.datapoints || []) {
      if (datapoint.dataPointID === dataPointID) {
        datapoint.tags = updateTagInTagsList(oldTagId, newTag, datapoint.tags)
        break
      }
    }
  },
} as MutationTree<DatapointsState>
