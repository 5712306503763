import { ComponentInProjectAttribute, ComponentInProjectWithContext, Project } from '@aedifion.io/aedifion-api'
import { DataRow, DataType } from '@/components/DataCard/types'
import { AssetProfileState } from './types'
import { getComponentAttribute } from '@/utils/helpers/componentAttribute'
import { GetterTree } from 'vuex'
import i18n from '@/i18n'
import { RootState } from '../types'
import { validateNotNullish } from '@/utils/helpers/validate'

export default {
  certificationsData: (_state: AssetProfileState, _getters, _rootState, rootGetters): DataRow[] => {
    const buildingComponent: ComponentInProjectWithContext|null = rootGetters['building_analyses/buildingComponentOfCurrentProject']
    return [{
      key: 'B+CERTS_BREEAM',
      name: i18n.global.t('attributes.B+CERTS_BREEAM') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+CERTS_BREEAM')?.value,
    }, {
      key: 'B+CERTS_DGNB',
      name: i18n.global.t('attributes.B+CERTS_DGNB') as string,
      selectOptions: [
        { title: i18n.global.t('certification_grades.platinum') as string, value: 'platinum' },
        { title: i18n.global.t('certification_grades.gold') as string, value: 'gold' },
        { title: i18n.global.t('certification_grades.silver') as string, value: 'silver' },
        { title: i18n.global.t('certification_grades.bronze') as string, value: 'bronze' },
      ],
      type: DataType.Selection,
      value: getComponentAttribute(buildingComponent, 'B+CERTS_DGNB')?.value,
    }, {
      key: 'B+CERTS_ECORE',
      name: i18n.global.t('attributes.B+CERTS_ECORE') as string,
      type: DataType.Number,
      value: getComponentAttribute(buildingComponent, 'B+CERTS_ECORE')?.value,
    }, {
      key: 'B+CERTS_ESTAR',
      name: i18n.global.t('attributes.B+CERTS_ESTAR') as string,
      type: DataType.Number,
      value: getComponentAttribute(buildingComponent, 'B+CERTS_ESTAR')?.value,
    }, {
      key: 'B+CERTS_GRESB',
      name: i18n.global.t('attributes.B+CERTS_GRESB') as string,
      type: DataType.Number,
      value: getComponentAttribute(buildingComponent, 'B+CERTS_GRESB')?.value,
    }, {
      key: 'B+CERTS_LEED',
      name: i18n.global.t('attributes.B+CERTS_LEED') as string,
      selectOptions: [
        { title: i18n.global.t('certification_grades.platinum') as string, value: 'platinum' },
        { title: i18n.global.t('certification_grades.gold') as string, value: 'gold' },
        { title: i18n.global.t('certification_grades.silver') as string, value: 'silver' },
        { title: i18n.global.t('certification_grades.certified') as string, value: 'certified' },
      ],
      type: DataType.Selection,
      value: getComponentAttribute(buildingComponent, 'B+CERTS_LEED')?.value,
    }, {
      key: 'B+CERTS_WSCORE',
      name: i18n.global.t('attributes.B+CERTS_WSCORE') as string,
      selectOptions: [
        { title: i18n.global.t('certification_grades.platinum') as string, value: 'platinum' },
        { title: i18n.global.t('certification_grades.gold') as string, value: 'gold' },
        { title: i18n.global.t('certification_grades.silver') as string, value: 'silver' },
        { title: i18n.global.t('certification_grades.certified') as string, value: 'certified' },
      ],
      type: DataType.Selection,
      value: getComponentAttribute(buildingComponent, 'B+CERTS_WSCORE')?.value,
    }]
  },

  equipmentData: (_state: AssetProfileState, _getters, _rootState, rootGetters): DataRow[] => {
    const buildingComponent: ComponentInProjectWithContext|null = rootGetters['building_analyses/buildingComponentOfCurrentProject']
    return [{
      key: 'B+BMS_BRAND',
      name: i18n.global.t('attributes.B+BMS_BRAND') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+BMS_BRAND')?.value,
    }, {
      key: 'B+BA_BRAND',
      name: i18n.global.t('attributes.B+BA_BRAND') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+BA_BRAND')?.value,
    }, {
      key: 'B+BA_PROT',
      name: i18n.global.t('attributes.B+BA_PROT') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+BA_PROT')?.value,
    }, {
      key: 'B+DP_COUNT',
      name: i18n.global.t('attributes.B+DP_COUNT') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+DP_COUNT')?.value,
    }, {
      key: 'B+AHU',
      name: i18n.global.t('attributes.B+AHU') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+AHU')?.value,
    }, {
      key: 'B+HS',
      name: i18n.global.t('attributes.B+HS') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+HS')?.value,
    }, {
      key: 'B+CS',
      name: i18n.global.t('attributes.B+CS') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+CS')?.value,
    }, {
      key: 'B+M',
      name: i18n.global.t('attributes.B+M') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+M')?.value,
    }]
  },

  generalInformationData: (_state: AssetProfileState, _getters, _rootState, rootGetters): DataRow[] => {
    const project: Project = validateNotNullish(rootGetters['projects/currentProject'])
    const projectData: DataRow[] = [{
      key: 'B++NAME',
      name: i18n.global.t('attributes.B++NAME') as string,
      type: DataType.String,
      value: project.name,
    }, {
      key: 'B++DESCRIPTION',
      name: i18n.global.t('attributes.B++DESCRIPTION') as string,
      type: DataType.String,
      value: project.description,
    }, {
      key: 'B++ADDRESS',
      name: i18n.global.t('attributes.B++ADDRESS') as string,
      type: DataType.String,
      value: project.address,
    }]

    const buildingComponent: ComponentInProjectWithContext|null = rootGetters['building_analyses/buildingComponentOfCurrentProject']
    const attributeData: DataRow[] = [{
      key: 'B+TYP',
      name: i18n.global.t('attributes.B+TYP') as string,
      selectOptions: [{ title: i18n.global.t('building_type.office') as string, value: 'office' }, { title: i18n.global.t('building_type.retail') as string, value: 'retail' }],
      type: DataType.Selection,
      value: getComponentAttribute(buildingComponent, 'B+TYP')?.value,
    }, {
      key: 'B+BUILD_YEAR',
      name: i18n.global.t('attributes.B+BUILD_YEAR') as string,
      type: DataType.Year,
      value: getComponentAttribute(buildingComponent, 'B+BUILD_YEAR')?.value,
    }, {
      key: 'B+RENOV_YEAR',
      name: i18n.global.t('attributes.B+RENOV_YEAR') as string,
      type: DataType.Year,
      value: getComponentAttribute(buildingComponent, 'B+RENOV_YEAR')?.value,
    }, {
      key: 'B+NFA',
      name: i18n.global.t('attributes.B+NFA') as string,
      type: DataType.Number,
      unit: 'm²',
      value: getComponentAttribute(buildingComponent, 'B+NFA')?.value,
    }, {
      key: 'B_GFA_AV',
      name: i18n.global.t('attributes.B_GFA_AV') as string,
      type: DataType.Number,
      unit: 'm²',
      value: getComponentAttribute(buildingComponent, 'B_GFA_AV')?.value,
    }, {
      key: 'B_COUNT_PEO_MAX',
      name: i18n.global.t('attributes.B_COUNT_PEO_MAX') as string,
      type: DataType.Number,
      value: getComponentAttribute(buildingComponent, 'B_COUNT_PEO_MAX')?.value,
    }, {
      key: 'B_COUNT_CAR_MAX',
      name: i18n.global.t('attributes.B_COUNT_CAR_MAX') as string,
      type: DataType.Number,
      value: getComponentAttribute(buildingComponent, 'B_COUNT_CAR_MAX')?.value,
    }]
    return projectData.concat(attributeData)
  },

  hasAnyCertification: (_state: AssetProfileState, _getters, _rootState, rootGetters): boolean => {
    const buildingComponent: ComponentInProjectWithContext = rootGetters['building_analyses/buildingComponentOfCurrentProject']
    if (!buildingComponent || !buildingComponent.attributes) {
      return false
    } else {
      return buildingComponent.attributes.some((attribute: ComponentInProjectAttribute) => attribute.key?.startsWith('B+CERTS_'))
    }
  },

  stakeholdersData: (_state: AssetProfileState, _getters, _rootState, rootGetters): DataRow[] => {
    const buildingComponent: ComponentInProjectWithContext|null = rootGetters['building_analyses/buildingComponentOfCurrentProject']
    const result: DataRow[] = [{
      key: 'B+ASSET_OWNER',
      name: i18n.global.t('attributes.B+ASSET_OWNER') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+ASSET_OWNER')?.value,
    }, {
      key: 'B+ASSET_MANAGER',
      name: i18n.global.t('attributes.B+ASSET_MANAGER') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+ASSET_MANAGER')?.value,
    }, {
      key: 'B+PROP_MANA',
      name: i18n.global.t('attributes.B+PROP_MANA') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+PROP_MANA')?.value,
    }, {
      key: 'B+FACILITY_MANAGER',
      name: i18n.global.t('attributes.B+FACILITY_MANAGER') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+FACILITY_MANAGER')?.value,
    }, {
      key: 'B+BA_CNSTR',
      name: i18n.global.t('attributes.B+BA_CNSTR') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+BA_CNSTR')?.value,
    }, {
      key: 'B+ICT',
      name: i18n.global.t('attributes.B+ICT') as string,
      type: DataType.String,
      value: getComponentAttribute(buildingComponent, 'B+ICT')?.value,
    }]
    return result
  },
} as GetterTree<AssetProfileState, RootState>
