<i18n locale="de">
{
  "edit_description": "um die Beschreibung eines Datenpunktes zu bearbeiten",
  "label": "Beschreibung",
  "title": "{datapoint} - Beschreibung bearbeiten",
  "description_from_building_network": "Die Beschreibung ist im Automationssystem hinterlegt und kann nicht überschrieben werden."
}
</i18n>
<i18n locale="en">
{
  "edit_description": "to edit a datapoint's description",
  "label": "Description",
  "title": "{datapoint} - Edit description",
  "description_from_building_network": "The description was read from the automation system and cannot be overridden."
}
</i18n>

<template>
  <v-dialog
    max-width="700px"
    persistent
    data-testid="description-editor"
    :model-value="value"
    width="70%"
    @keydown.escape="$emit('descriptioneditor:close')"
  >
    <v-card>
      <v-card-title class="text-h5 pa-4">
        {{ t('title', { datapoint: datapoint.title }) }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-textarea
          v-model="newDescription"
          :disabled="!isUserDescription && !!datapoint.description"
          hide-details="auto"
          :label="t('label')"
          :messages="updateDescriptionPrevented"
          no-resize
          variant="outlined"
          row-height="5"
          rows="3"
          @keydown.shift.enter.prevent="saveDescription"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-auto"
          data-testid="close-description-editor"
          color="primary-darken2"
          variant="text"
          @click="$emit('descriptioneditor:close')"
        >
          {{ t('actions.close') }}
        </v-btn>
        <v-btn
          color="primary-darken2"
          data-testid="save-description"
          variant="elevated"
          :disabled="isSaveButtonDisabled"
          @click="saveDescription"
        >
          {{ t('actions.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Tag, TagAssociation } from '@aedifion.io/aedifion-api'
import { DIKebabItemDatapointPayload } from '@/utils/types'
import { getDescriptionTag } from '@/utils/helpers/tags'
import { InvalidValueError } from '@/utils/helpers/validate'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'DescriptionEditor',
  props: {
    datapoint: {
      required: true,
      type: Object as PropType<DIKebabItemDatapointPayload>,
    },
    value: {
      required: false,
      default: true,
      type: Boolean as PropType<boolean>,
    },
  },

  emits: ['descriptioneditor:close'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      newDescription: this.datapoint.description as string|undefined,
    }
  },
  computed: {
    isSaveButtonDisabled (): boolean {
      return this.newDescription === this.datapoint.description
    },

    isUserDescription (): boolean {
      const descriptionTag: TagAssociation|null = getDescriptionTag(this.datapoint.tags || [])
      return descriptionTag !== null && descriptionTag.source === 'user'
    },

    updateDescriptionPrevented (): string|undefined {
      if (!this.isUserDescription && this.datapoint.description) return this.t('description_from_building_network') as string
      return undefined
    },
  },
  methods: {

    async createDescription (): Promise<void> {
      const createdtag: { dataPointID: string; tagID: number } = await this.$store.dispatch('tags/createTag', {
        dataPointID: this.datapoint.title,
        payload: {
          key: 'description',
          value: this.newDescription,
        },
      })
      await this.$store.dispatch('datapoints/addDescription', createdtag)
    },

    async deleteDescription (tagId: number): Promise<void> {
      await this.$store.dispatch('datapoints/removeDescription', {
        dataPointID: this.datapoint.title,
        tagId,
      })
    },

    async saveDescription () {
      const descriptionTag: Tag|undefined = this.datapoint.tags?.find((tag: Tag) => {
        return tag.key === 'description'
      })

      try {
        if (!this.newDescription && this.datapoint && descriptionTag) {
          await this.deleteDescription(descriptionTag.id)
        } else if (!!this.newDescription && this.datapoint.description && descriptionTag) {
          await this.updateDescription(descriptionTag.id)
        } else if (!!this.newDescription && !this.datapoint.description) {
          await this.createDescription()
        }
      } catch (error) {
        if (error instanceof InvalidValueError) {
          showErrorNotification(`${this.t('notifications.errors.no_project_selected_with_attempted_action', { action: this.t('edit_description') })}`)
        }
        reportError(error)
      }
      this.$emit('descriptioneditor:close')
    },

    setNewDescription (newDescription: string) {
      this.newDescription = newDescription
    },

    async updateDescription (tagId: number): Promise<void> {
      await this.$store.dispatch('datapoints/updateDescription', {
        dataPointID: this.datapoint.title,
        key: 'description',
        newValue: this.newDescription,
        tagId,
      })
    },

  },
})
</script>
