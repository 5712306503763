<i18n locale="de">
{
  "title": "404 - Die angefragte Seite wurde nicht gefunden",
  "text": "Bitte versichere dich dass die angeforderte URL korrekt ist oder kehre zur Startseite zurück.",
  "btn": "Zurück zur Startseite"
}
</i18n>
<i18n locale="en">
{
  "title": "404 - The requested page was not found",
  "text": "Please make sure that the requested URL is correct or return to the home page.",
  "btn": "Go to Home Page"
}
</i18n>
<template>
  <div class="aedifion__auth__callback__wrapper d-flex justify-center align-center">
    <div class="pageNotFound__wrapper d-flex justify-center align-center ma-4 pa-4 flex-column">
      <span class="text-h5 mb-2">
        <v-icon
          class="mb-1 mr-1"
          color="neutral-darken3"
        >
          fa:far fa-triangle-exclamation
        </v-icon>
        {{ t('title') }}</span>
      <span class="body text-center mb-8">{{ t('text') }}</span>
      <v-btn
        color="primary-darken2"
        to="/"
      >
        {{ t('btn') }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'PageNotFound',

  setup () {
    const { t } = useI18n()
    return { t }
  },

})
</script>

<style lang="sass" scoped>
  .pageNotFound__wrapper
    max-width: 800px
</style>
