import { LocationQuery, RouteLocation, RouteLocationNormalized } from 'vue-router'
import { createNextLocation } from './helpers'
import { resetStoreState } from '@/vuex/optimization/state'
import { RootState } from '@/vuex/types'
import { Store } from 'vuex'

/**
 * Clears all optimization values that are displayed in the route query from the store.
 * @param store vuex store.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function clearOptimizationStore (store: Store<RootState>): RouteLocation|void {
  resetStoreState(store.state.optimization)
}

/**
 * Checks the store and the query for operation cockpit values that should be displayed in the query and adds all that are missing or different.
 * @param store vuex store.
 * @param to Target route with the new query.
 * @returns a new location with updated query, if the query was changed.
 */
export function updateOptimizationQuery (store: Store<RootState>, to: RouteLocationNormalized): RouteLocation|void {
  const newQuery: LocationQuery = {}

  const storeSearch: string|null = store.getters['optimization/search']
  if (storeSearch && storeSearch !== to.query.search) {
    newQuery.search = storeSearch
  }

  const storeSelectedInstanceId: number|null = store.state.optimization.selectedInstanceId
  if (storeSelectedInstanceId && storeSelectedInstanceId.toString() !== to.query.instance) {
    newQuery.instance = storeSelectedInstanceId.toString()
  }

  const storeSelectedResultId: string|null = store.state.optimization.selectedResultId
  if (storeSelectedResultId && storeSelectedResultId !== to.query.result) {
    newQuery.result = storeSelectedResultId
  }

  if (store.getters['optimization/followAnalysisPeriod']) {
    newQuery.followAnalysisPeriod = 'true'
  }

  if (Object.keys(newQuery).length > 0) {
    return createNextLocation(to, newQuery)
  }
}

/**
 * Checks the query of the target route and if it contains values that differ from the stored values it updates the store.
 * @param store vuex store.
 * @param to Target route with values to be stored.
 */
export function updateOptimizationStore (store: Store<RootState>, to: RouteLocationNormalized): RouteLocation|void {
  if (to.query.search && store.getters['optimization/search'] !== to.query.search) {
    store.commit('optimization/SET_SEARCH', to.query.search)
  }

  if (to.query.instance && store.state.optimization.selectedInstanceId !== parseInt(to.query.instance as string)) {
    store.commit('optimization/SET_SELECTED_INSTANCE_ID', parseInt(to.query.instance as string))
  }

  if (to.query.result && store.state.optimization.selectedResultId !== to.query.result) {
    store.commit('optimization/SET_SELECTED_RESULT_ID', to.query.result)
  }

  store.commit('optimization/SET_FOLLOW_ANALYSIS_PERIOD', 'followAnalysisPeriod' in to.query)
}
