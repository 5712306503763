import { ComponentInProjectKPIAggregations, KPIAggregationItem, KPIResult, Savings } from '@aedifion.io/aedifion-api'
import { reportError } from '@/utils/helpers/errors'
import { SavingsGroup } from '@/stores/views/Optimization/savingsPotential'

type ItemToSort = ComponentInProjectKPIAggregations|Savings|SavingsGroup

function isComponentInProjectKPIAggregations (item: ItemToSort): item is ComponentInProjectKPIAggregations {
  return 'kpi_aggregations' in item
}

function isSavingsOrSavingsGroup (item: ItemToSort): item is Savings|SavingsGroup {
  return 'savings' in item
}

function isSavingWithContext (context: string) {
  return (saving: KPIResult) => saving.context === context
}

function isAggregationWithIdentifier (identifier: string) {
  return (aggregation: KPIAggregationItem) => aggregation.kpi.identifier === identifier
}

export function sortByKPI (kpiContext: string) {
  return (itemA: ItemToSort, itemB: ItemToSort): number => {
    let dataA: KPIAggregationItem|KPIResult|undefined
    let unitA: string|undefined
    let valueA: number|undefined
    let dataB: KPIAggregationItem|KPIResult|undefined
    let unitB: string|undefined
    let valueB: number|undefined

    if (isComponentInProjectKPIAggregations(itemA) && isComponentInProjectKPIAggregations(itemB)) {
      dataA = itemA.kpi_aggregations.find(isAggregationWithIdentifier(kpiContext))
      unitA = dataA?.kpi.unit
      valueA = dataA?.kpi.value
      dataB = itemB.kpi_aggregations.find(isAggregationWithIdentifier(kpiContext))
      unitB = dataB?.kpi.unit
      valueB = dataB?.kpi.value
    } else if (isSavingsOrSavingsGroup(itemA) && isSavingsOrSavingsGroup(itemB)) {
      dataA = itemA.savings.find(isSavingWithContext(kpiContext))
      unitA = dataA?.unit
      valueA = dataA?.value
      dataB = itemB.savings.find(isSavingWithContext(kpiContext))
      unitB = dataB?.unit
      valueB = dataB?.value
    } else {
      reportError(new Error(`Unexpected type of item to sort. The type of itemA: ${JSON.stringify(itemA)} is not compatible with the type of itemB: ${JSON.stringify(itemB)}`))
      return 0
    }

    if (dataA === undefined) return 1
    if (dataB === undefined) return -1

    if (unitA !== unitB) {
      reportError(new Error(`unitA (${unitA}) of itemA: ${JSON.stringify(dataA)} and unitB (${unitB}) of itemB: ${JSON.stringify(dataB)} are not equal, and cannot be compared`))
      return 0
    }

    if (valueA !== valueB) {
      if (valueA === undefined) {
        return 1
      }
      if (valueB === undefined) {
        return -1
      }
      return valueB - valueA
    }

    return 0
  }
}
