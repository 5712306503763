<script setup lang="ts">
import { computed } from 'vue'
import MultipleDropDown from '@/components/MultipleDropDown.vue'
import { useAdministrationStore } from '@/stores/views/Administration'
import { useAdministrationUsersStore } from '@/stores/views/Administration/users'
import { useI18n } from 'vue-i18n'

const props = withDefaults(defineProps<{
  companyRolesIds: number[],
  disabled?: boolean,
  userId: number|undefined
}>(), {
  disabled: false,
})

const administrationStore = useAdministrationStore()
const administrationUsersStore = useAdministrationUsersStore()
const { t } = useI18n()

const options = computed(() => {
  return administrationStore.displayedCompanyRoles.filter((companyRole) => {
    return companyRole.name && administrationStore.companyRoles.some((companyRoleFromStore) => {
      return companyRoleFromStore.id === companyRole.id
    })
  }).map((companyRole) => {
    return {
      id: companyRole.id!,
      label: companyRole.name!,
    }
  })
})

const userCompanyRolesIds = computed(() => {
  return administrationStore.users.find((user) => {
    return user.id === props.userId
  })?.company_roles ?? []
})

const selectionText = computed(() => {
  if (userCompanyRolesIds.value.length > 0) {
    return options.value.filter((option) => {
      return userCompanyRolesIds.value.some((userCompanyRoleId) => {
        return option.id === userCompanyRoleId
      })
    }).map((option) => {
      return option.label
    }).join(', ')
  }
  return t('no_role')
})

function updateCompanyRoles (newRoleSet: number[]) {
  if (props.userId) {
    const companyRolesUpdatePayload = administrationUsersStore.computeRolesUpdatePayload(props.companyRolesIds, newRoleSet)
    administrationUsersStore.updateCompanyRoles(companyRolesUpdatePayload, props.userId)
  }
}
</script>

<template>
  <MultipleDropDown
    :disabled="props.disabled"
    :items="options"
    :override-selection-text="selectionText"
    :value="userCompanyRolesIds"
    @change="updateCompanyRoles"
  />
</template>

<i18n lang="json" locale="de">
  {
    "no_role": "Keine Rolle"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "no_role": "No role"
  }
</i18n>
