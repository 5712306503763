<script setup lang="ts">
import { BULLET_GRAPH_COLORS, VUETIFY_COLORS } from '@theme/colors'
import { computed, ref, watch } from 'vue'
import bullet from 'highcharts/modules/bullet'
import type { BulletGraphThreshold } from './types'
import { captureMessage } from '@sentry/vue'
import { Chart } from 'highcharts-vue'
import { formatSubAndSuper } from '@/filters/formatting'
import Highcharts from 'highcharts'
import { merge } from 'lodash'
import { TYPOGRAPHY } from '@/utils/designConstants'
import { useI18n } from 'vue-i18n'

interface Props {
  customChartOptions?: Highcharts.Options,
  isLabel?: string,
  isValue?: number,
  loading?: boolean,
  setLabel?: string,
  setValue?: number,
  thresholds: BulletGraphThreshold[],
  title?: string,
  unit?: string
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  customChartOptions: () => { return {} },
  loading: false,
  thresholds: () => [],
})

const { t } = useI18n()
const chart = ref<typeof Chart|null>()

const range = computed<[number, number]>(() => {
  let minValue, maxValue
  if (!props.isValue && props.isValue !== 0) {
    minValue = 0
    maxValue = 0
  } else if (!props.setValue && props.setValue !== 0) {
    minValue = Math.min(sortedBenchmarkZones.value[0].value, props.isValue)
    maxValue = Math.max(sortedBenchmarkZones.value[sortedBenchmarkZones.value.length - 1].value, props.isValue)
  } else {
    minValue = Math.min(sortedBenchmarkZones.value[0].value, props.isValue, props.setValue)
    maxValue = Math.max(sortedBenchmarkZones.value[sortedBenchmarkZones.value.length - 1].value, props.isValue, props.setValue)
  }

  const rangeMin = minValue - (minValue * 0.1)
  const rangeMax = maxValue + (maxValue * 0.1)
  if (props.unit === '%') {
    return [Math.max(0, rangeMin), Math.min(rangeMax, 100)]
  } else {
    return [rangeMin, rangeMax]
  }
})

const sortedBenchmarkZones = computed<BulletGraphThreshold[]>(() => {
  const unsortedThresholds = [...props.thresholds]
  return unsortedThresholds.sort((a: BulletGraphThreshold, b: BulletGraphThreshold) => a.value <= b.value ? -1 : 1)
})

// This is used to fix the issue with the arrow not being exactly aligned with chart when the value is 100%
// @ts-ignore
const leftIndentation = computed(() => {
  if (props.unit === '%') {
    if (props.isValue && props.isValue >= 99) {
      return '-5px'
    }
  }
  return '-3px'
})

function whitelabelColor (color: string): string {
  switch (color) {
    case 'blue': return BULLET_GRAPH_COLORS.blue
    case 'green': return BULLET_GRAPH_COLORS.green
    case 'yellow': return BULLET_GRAPH_COLORS.yellow
    case 'red': return BULLET_GRAPH_COLORS.red
    default:
      captureMessage(`No bulletgraph whitelabel color defined for '${color}'`, 'warning')
      return color
  }
}

watch(
  () => props.loading,
  (newVal: boolean) => {
    if (newVal) {
      chart.value?.chart.showLoading()
    } else {
      chart.value?.chart.hideLoading()
    }
  },
)

// --- execution ---

bullet(Highcharts)

/**
 * https://github.com/highcharts/highcharts-vue/issues/71#issuecomment-485784315
 * The 'lang' object can not be updated to force a re-draw. Languages only change on rendering (create/destroy hooks)
 */
Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: "'Inter', sans-serif",
    },
  },
  lang: {
    loading: t('highcharts.loading') as string,
    noData: t('highcharts.no_data') as string,
  },
})

const chartOptions = computed(() => {
  const result: Highcharts.Options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      height: '110px',
      inverted: true,
      style: {
        color: '#000',
      },
      type: 'bullet',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      align: 'left',
      floating: true,
      itemStyle: {
        color: VUETIFY_COLORS.neutral.darken3,
        cursor: 'auto',
        fontSize: TYPOGRAPHY.headings.legend.size,
        fontWeight: TYPOGRAPHY.headings.legend.weight,
        textOverflow: 'ellipsis',
      },
      labelFormat: props.unit && props.unit.length > 2 ? formatSubAndSuper(props.unit ?? '') : ' ', // with an empty string highcharts reverts to the default
      layout: 'vertical',
      useHTML: true,
      x: -30,
      y: 20,
    },
    // @ts-ignore
    series: [{
      borderWidth: 0,
      color: 'transparent',
      data: [{ y: props.isValue }],
      events: {
        legendItemClick: function () {
          return false // returning false will cancel the default action, making the legend non-clickable
        },
      },
      pointPadding: 0.25,
      targetOptions: {
        width: '200%',
      },
    }],
    title: {
      style: {
        visibility: 'hidden',
      },
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      categories: [''], // required to suppress the default category
      labels: {
        padding: '30',
        style: {
          color: VUETIFY_COLORS.neutral.darken3,
        },
        useHTML: true,
      },
    },
    yAxis: {
      endOnTick: false,
      gridLineWidth: 0,
      labels: {
        format: props.unit && props.unit.length <= 2 ? `{value}${props.unit}` : '{value}',
        style: {
          color: VUETIFY_COLORS.neutral.darken3,
          fontSize: TYPOGRAPHY.headings.legend.size,
          fontWeight: TYPOGRAPHY.headings.legend.weight,
        },
      },
      max: range.value[1],
      min: range.value[0],
      plotBands: [
        {
          color: {
            linearGradient: {
              x1: 0,
              x2: 1,
              y1: 1,
              y2: 1,
            },
            stops: sortedBenchmarkZones.value.map((zone: BulletGraphThreshold) => [
              (zone.value - range.value[0]) / (range.value[1] - range.value[0]),
              whitelabelColor(zone.name),
            ]),
          },
          from: range.value[0],
          to: range.value[1],
        },
      ],
      plotLines: [
        {
          color: 'transparent',
          label: {
            align: 'center',
            rotation: 0,
            style: {
              fontSize: TYPOGRAPHY.headings.legend.size,
            },
            text: `
                <div class="set-tooltip text-neutral-darken3 mt-5">
                  <div class="arrow-up"></div>
                  ${props.setLabel ? `<span class="target-label">${props.setLabel}</span>` : ''}
                </div>
              `,
            useHTML: true,
            y: 20,
          },
          value: props.setValue,
          width: 2,
          zIndex: 5,
        },
        {
          color: 'transparent',
          label: {
            align: 'center',
            rotation: 0,
            style: {
              fontSize: TYPOGRAPHY.headings.legend.size,
            },
            text: `
                <div class="set-tooltip text-neutral-darken3">
                  ${props.isLabel ? `<span class="target-label">${props.isLabel}</span>` : ''}
                  <div class="arrow-down"></div>
                </div>
              `,
            useHTML: true,
            y: props.isLabel ? -2 : 12,
          },
          value: props.isValue,
          width: 2,
          zIndex: 5,
        },
      ],
      startOnTick: false,
      title: {
        // @ts-ignore
        enabled: false,
      },
    },
  }
  merge(result, props.customChartOptions)
  return result
})
</script>

<template>
  <div class="d-flex flex-column fill-height">
    <span
      class="bullet-graph-title text-neutral-darken3 text-h6 px-0"
      v-html="title"
    />
    <Chart
      ref="chart"
      class="bullet-graph mt-10"
      :options="chartOptions"
      :update-args="[true, true, true]"
    />
  </div>
</template>

<style lang="sass">
.highcharts-container
  overflow: visible !important
.highcharts-plot-line-label
  height: auto
  width: auto
  padding-left: 6px !important
  transform: translateX(-3px) !important
  top: 12px !important
.bullet-graph-title
  margin-top: 1px
.bullet-graph
  overflow: visible !important
  .set-tooltip
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    left: v-bind(leftIndentation) !important
    position: relative !important

  .arrow-down
    width: 0
    height: 0
    border-left: 5px solid transparent !important
    border-right: 5px solid transparent !important
    border-top-width: 20px !important
    border-top-style: solid !important

  .arrow-up
    width: 0
    height: 0
    border-left: 5px solid transparent !important
    border-right: 5px solid transparent !important
    border-top-width: 20px !important
    border-top-style: solid !important
    transform: rotate(180deg)
</style>
