import { FilesState } from './types'

export default {
  elevateReports: null,
  loading: false,
} as FilesState

export function resetStoreState (state: FilesState): void {
  state.elevateReports = null
  state.loading = false
}
