import { Alert, STATUS } from '@/services/alerta/types'
import { Fault, Incident, IncidentConfiguration, StatusAndAlertsState } from './types'
import { getColorOfOperationalStateCode, getOperationalStateByCode } from '@/utils/helpers/faults'
import { OPERATIONAL_STATE_PIN, SYSTEM_ICON_ALPHANUMERIC_ID, SYSTEM_LINK_ALPHANUMERIC_ID } from '@/settings'
import { sampleDataForSystemComponents, sampleDataForSystemOperationalStatusCodes } from './sampleData'
import { ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { getComponentAttributeByAlphanumericId } from '@/utils/helpers/componentAttribute'
import { getHashIdOfPinByAlphanumericId } from '@/utils/helpers/components'
import { GetterTree } from 'vuex'
import i18n from '@/i18n'
import moment from 'moment'
import { RootState } from '../types'
import { textForLocale } from '@/utils/helpers/locale'
import { useAlertsStore } from '@/stores/views/Alerts/alerts'

function mergeIncidentConfigurationsWithIncidentAlerts (incidentConfigurations: IncidentConfiguration[], incidentAlerts: Alert[]) {
  const incidents: Incident[] = []
  for (const incidentConfiguration of incidentConfigurations) {
    const incident: Incident = {
      ...incidentConfiguration,
      alert: null,
      color: 'neutral',
    }
    if (incident.active) {
      const incidentAlert: Alert|undefined = incidentAlerts.find((alert: Alert) => {
        return alert.attributes.category === incidentConfiguration.category
      })
      if (incidentAlert) {
        incident.alert = incidentAlert
        incident.timePassed = moment(incidentAlert.createTime).locale(i18n.global.locale.value).fromNow()
        if (incidentAlert.status === STATUS.OPEN) {
          incident.color = 'error'
        } else if (incidentAlert.status === STATUS.ACK) {
          incident.color = 'warning'
        }
      } else {
        incident.color = 'success'
      }
    }
    incidents.push(incident)
  }

  return incidents
}

export default {
  faults: (state: StatusAndAlertsState, getters): Fault[] => {
    if (state.systemComponents === null) return []

    const systemComponents = getters.showCockpitNudge ? sampleDataForSystemComponents() : state.systemComponents
    const systemsOperationalStatusCodes = getters.showCockpitNudge ? sampleDataForSystemOperationalStatusCodes() : state.systemsOperationalStatusCodes
    const systemsOperationalStateMap = new Map(systemsOperationalStatusCodes)
    return systemComponents.map((system: ComponentInProjectWithContext) => {
      let operationalState: number|null = null
      const operationalStateHashId: string|null = getHashIdOfPinByAlphanumericId(system, OPERATIONAL_STATE_PIN)
      if (operationalStateHashId !== null && systemsOperationalStateMap.has(operationalStateHashId)) {
        operationalState = systemsOperationalStateMap.get(operationalStateHashId) as number|null
      }
      const icon: string|undefined = getComponentAttributeByAlphanumericId(system, SYSTEM_ICON_ALPHANUMERIC_ID)?.value
      const link: string|undefined = getComponentAttributeByAlphanumericId(system, SYSTEM_LINK_ALPHANUMERIC_ID)?.value
      const tooltip: string = i18n.global.t(getOperationalStateByCode(operationalState) ? `status_and_alerts.messages.${getOperationalStateByCode(operationalState)}` : 'messages.no_data') as string
      const fault: Fault = {
        color: getColorOfOperationalStateCode(operationalState),
        icon,
        link,
        title: textForLocale(system.nameDE!, system.nameEN!),
        tooltipText: tooltip,
      }
      return fault
    })
      .sort((a: Fault, b: Fault) => {
        if (a.title < b.title) {
          return -1
        } else if (a.title > b.title) {
          return 1
        } else {
          return 0
        }
      })
  },

  incidents: (state: StatusAndAlertsState, _getters, _rootState, rootGetters): Incident[]|null => {
    const alertsStore = useAlertsStore()

    const projectId = rootGetters['projects/currentProjectId'] as number|null
    if (projectId === null) return null

    const incidentConfigurations = rootGetters['building_analyses/incidentConfigurationsOfProject'](projectId) as IncidentConfiguration[]
    if (incidentConfigurations.length === 0) return []

    const alerts = alertsStore.incidentAlertsOfProject(projectId) as Alert[]|null ?? []

    return mergeIncidentConfigurationsWithIncidentAlerts(incidentConfigurations, alerts)
  },

  showCockpitNudge: (state: StatusAndAlertsState, getters, __, rootGetters): boolean => {
    const projectId = rootGetters['projects/currentProjectId'] as number|null
    if (state.systemComponents === null || projectId === null || !getters.incidents) {
      return false
    } else if (state.systemComponents.length === 0 && getters.incidents?.length === 0) {
      return true
    } else {
      return false
    }
  },
} as GetterTree<StatusAndAlertsState, RootState>
