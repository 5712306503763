import { LabelsState } from './types'

export default {
  definitions: null,
  labelDefinitionLookupMap: new Map(),
  loadingDefinitions: false,
  loadingSystems: false,
  systems: null,
} as LabelsState

export function resetStoreState (store: LabelsState): void {
  store.labelDefinitionLookupMap = new Map()
  store.definitions = null
  store.systems = null
  store.loadingDefinitions = false
  store.loadingSystems = false
}
