<!--
TODO: merge with <ColoredFigure />
-->

<script setup lang="ts">
import { computed } from 'vue'
import { formatValue } from '@/filters/formatting'

interface Props {
  unitSymbol?: string,
  value: number | string
}

const props = defineProps<Props>()
const displayValue = computed(() => typeof props.value === 'number' ? formatValue(props.value) : props.value)
</script>

<template>
  <div class="value-chip tw-rounded tw-px-1.5 tw-py-0.5 tw-bg-[rgb(var(--v-theme-neutral-lighten3))] tw-border-[rgb(var(--v-theme-neutral-lighten1))] tw-border-solid tw-border">
    <span class="tw-font-semibold">{{ displayValue }}</span> <span v-if="unitSymbol">{{ unitSymbol }}</span>
  </div>
</template>

<style scoped lang="sass">
.value-chip
  font-variant-numeric: lining-nums tabular-nums
</style>
