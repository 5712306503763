<i18n lang="json" locale="de">
  {
    "title": "Erfolgreich eingeloggt",
    "text": "Die Applikation wird geladen ..."
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "title": "Successfully logged in",
    "text": "The application is loading ..."
  }
</i18n>

<template>
  <div
    class="d-flex justify-center align-center aedifion__auth__callback__wrapper flex-column"
  >
    <v-progress-circular
      color="primary-darken2"
      indeterminate
      :size="100"
      :width="7"
    />
    <span class="mt-4 text-h6">
      {{ t('title') }}
    </span>
    <span class="subtitle mt-4">
      {{ t('text') }}
      <v-icon
        class="ml-2"
        color="primary"
      >
        fa:fas fa-sign-in-alt
      </v-icon>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { onInitialApiRequestsCompleted } from '@/utils/helpers/hooks'
import { RouteLocationRaw } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AuthCallback',

  setup () {
    const { t } = useI18n()
    return { t }
  },

  computed: {
    isAuthenticated (): boolean {
      return this.$store.getters['auth/oidcIsAuthenticated']
    },
  },

  async mounted () {
    let redirectPath: RouteLocationRaw
    try {
      redirectPath = await this.oidcSignInCallback()
    } catch (error) {
      if (this.isAuthenticated) {
        redirectPath = { name: 'home' }
      } else {
        redirectPath = { name: 'auth-failed' }
      }
    }
    onInitialApiRequestsCompleted(async () => {
      await this.$router.push(redirectPath)
    })
  },

  methods: {
    ...mapActions({
      oidcSignInCallback: 'auth/oidcSignInCallback',
    }),
  },
})
</script>

<style lang="sass">
  .aedifion__auth__callback__wrapper
    position: absolute
    left: 0px
    right: 0px
    top: 0px
    bottom: 0px
</style>
