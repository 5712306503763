<script setup lang="ts">
import CompanyCard from './CompanyCard.vue'
import ProjectsTable from './ProjectsTable.vue'
import { useAdministrationStore } from '@/stores/views/Administration/index'
import { useI18n } from 'vue-i18n'

// --- definition ---

const administrationStore = useAdministrationStore()
const { t } = useI18n()

// --- execution ---

administrationStore.fetchCompanyWithContext()
</script>

<template>
  <div class="fill-width">
    <h3 class="text-h3 text-neutral-darken4">
      {{ t('overview') }}
    </h3>
    <div class="d-flex">
      <CompanyCard />
      <ProjectsTable class="fill-width" />
    </div>
  </div>
</template>

<i18n lang="json" locale="de">
  {
    "overview": "Übersicht"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "overview": "Overview"
  }
</i18n>
