let alreadyDetectedValue: boolean|null = null

export async function isIDBAvailable (): Promise<boolean> {
  if (alreadyDetectedValue !== null) {
    return alreadyDetectedValue
  }

  alreadyDetectedValue = true

  if (
    import.meta.env.MODE.startsWith('test-e2e') ||
    !globalThis.indexedDB
  ) {
    alreadyDetectedValue = false
  }

  return alreadyDetectedValue
}

export function resetAlreadyDetectedValue () {
  alreadyDetectedValue = null
}
