import { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { isAssetOverviewSetUp } from '@/utils/helpers/viewStatus'
import { onInitialApiRequestsCompleted } from '@/utils/helpers/hooks'
import { RootState } from '@/vuex/types'
import { Store } from 'vuex'

export const homepageGuardMiddleware: (store: Store<RootState>) => NavigationGuard = (
  store: Store<RootState>,
) => (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (to.name === 'home') {
    onInitialApiRequestsCompleted(() => {
      if (store.state.projects.projects.length === 1) {
        const projectId = store.state.projects.projects[0].project!.id!
        if (isAssetOverviewSetUp(projectId)) {
          next({ name: 'asset-overview', params: { project: projectId.toString() } })
        } else {
          next({ name: 'optimization-optimizations', params: { project: projectId.toString() } })
        }
      } else {
        next()
      }
    })
  } else {
    next()
  }
}
