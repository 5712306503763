<script setup lang="ts">
import ColoredFigure from '@/components/ColoredFigure.vue'
import { computed } from 'vue'
import { formatValue } from '@/filters/formatting'
import { RouteLocationRaw } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { VUETIFY_COLORS } from '@theme/colors'

interface Props {
  analysisResult?: Record<string, unknown>,
  countHigh: number,
  countMedium: number,
  countOptimized: number,
  name: string,
  numberOfComponents: number,
  optimizationPeriod?: string,
  savings?: number;
  to: RouteLocationRaw
}

const props = defineProps<Props>()
const { t } = useI18n()

const colors = VUETIFY_COLORS

/**
 * ? The currency system and units system have to be hardcoded for now, until the backend supports posting different units and currencies
 */
const currency = computed<'€'|'$'>(() => {
  // if (!vuexStore.state.user?.user?.user?.currency_system) return '€'

  // return vuexStore.state.user.user?.user.currency_system === 'EUR' ? '€' : '$'
  return '€'
})

const hasSavingsPotential = computed(() => {
  if (props.savings) return true
  else return false
})

const hasHighPotentials = computed(() => {
  if (props.countHigh) return true
  else return false
})

const hasMediumPotentials = computed(() => {
  if (props.countMedium) return true
  else return false
})

const isFullyOptimized = computed(() => {
  if (props.countHigh === 0 && props.countMedium === 0 && props.countOptimized > 0) return true
  else return false
})

const savingsPotentialAmount = computed<string>(() => {
  if (props.savings) {
    return formatValue(props.savings, { integer: true, unit: currency.value })
  }
  return ''
})

const hasAnalyses = computed<boolean>(() => {
  return !!props.savings || props.countHigh > 0 || props.countMedium > 0 || props.countOptimized > 0
})

const totalAnalyses = computed<number>(() => props.countHigh + props.countMedium + props.countOptimized)
</script>

<template>
  <v-card
    data-cy="count-card"
    class="pa-6 count-card"
    :to="props.to"
  >
    <v-card-title class="card-title text-h5 text-neutral-darken4 d-inline-block text-truncate text-capitalize pa-0">
      {{ props.name }}
    </v-card-title>
    <v-card-text
      class="d-flex pa-0 count-card-subtitle__wrapper pb-6"
    >
      <v-tooltip
        color="white"
        location="top"
      >
        <template #activator="{ props: componentTooltipProps }">
          <div
            class="icon-div"
            v-bind="componentTooltipProps"
          >
            <v-icon
              color="neutral"
              class="mr-2 neutral-darken1"
              size="14"
            >
              fa:far fa-layer-group
            </v-icon>
            {{ props.numberOfComponents }}
          </div>
        </template>
        <span class="text-body-1 text-neutral-darken1">
          {{ t('components') }}
        </span>
      </v-tooltip>
      <v-tooltip
        color="white"
        location="top"
      >
        <template
          #activator="{ props: analysisTooltipProps }"
        >
          <div

            class=" icon-div"
            v-bind="analysisTooltipProps"
          >
            <v-icon
              color="neutral"
              class="mr-2 neutral-darken1"
              size="14"
            >
              fa:far fa-magnifying-glass-chart
            </v-icon>
            {{ totalAnalyses }}
          </div>
        </template>
        <span class="text-body-1 text-neutral-darken1">
          {{ t('analyses') }}
        </span>
      </v-tooltip>
    </v-card-text>
    <v-card-text
      v-if="hasSavingsPotential"
      data-testid="savings-potential"
      class="d-flex flex-column pa-0 savings-potential__container"
    >
      <div
        class="savings-amount__container"
      >
        <span class="savings-amount__text text-semantics font-weight-semibold px-2">{{ savingsPotentialAmount }}</span>
      </div>
      <div
        class="savings-text__container"
      >
        <span
          class="mb-3 text-neutral-darken1"
        >{{ t('cost_savings_potential') }}</span>
      </div>
    </v-card-text>
    <v-card-text
      v-else
      class="pa-0 optimization-period__container"
    >
      <div class="d-flex justify-start align-center flex-row">
        <ColoredFigure
          v-if="hasHighPotentials"
          :background-color="colors.error.lighten4"
          :figure-color="colors.error.darken2"
          :value="String(props.countHigh)"
          class="mr-6"
          :text="t('high')"
        />
        <ColoredFigure
          v-if="hasMediumPotentials"
          :background-color="colors.warning.lighten4"
          :figure-color="colors.warning.darken2"
          :value="String(props.countMedium)"
          :text="t('medium')"
        />
      </div>
      <ColoredFigure
        v-if="!hasAnalyses"
        :background-color="colors.neutral.lighten3"
        :figure-color="colors.neutral.darken2"
        value="fa:far fa-circle-xmark"
        icon
        :text="t('no_active_analyses')"
      />
      <ColoredFigure
        v-if="isFullyOptimized"
        :background-color="colors.success.lighten4"
        :figure-color="colors.success.darken2"
        icon
        value="fa:far fa-check"
      >
        <div
          class="text-body-1 mt-10px"
        >
          <span class="text-success-darken2">
            <span class="font-weight-semibold">
              {{ t('optimized') }}
            </span>
          </span>
        </div>
      </ColoredFigure>
      <div
        v-if="!hasSavingsPotential && hasAnalyses"
        data-testid="no-cost-savings-text"
        class="text-body-1 mt-10px"
      >
        <span class="text-neutral-darken1">
          <span>
            {{ t('no_cost_savings') }}
          </span>
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="sass" scoped>

  .count-card:hover
    .card-title
      color: rgb(var(--v-theme-primary-darken2)) !important

  .count-card
    .count-card-subtitle__wrapper
      gap: 24px

  .icon-div:hover
    .v-icon
      color: rgb(var(--v-theme-primary-darken2)) !important

  .card-title
    max-width: 100%

  .savings-text__container
    position: relative

  .savings-amount__container
    width: fit-content
    border: 1px solid transparent
    border-radius: 4px
    background-image: linear-gradient(white, white), linear-gradient(181.25deg, #E60050 1.28%, #E6007E 26.26%, #B03A8B 99.18%) !important
    box-shadow: 0px 3px 0px #B03A8B
    background-origin: border-box
    background-clip: content-box, border-box
    margin-bottom: 10px

  .savings-amount__text
    font-feature-settings: 'ss04' on, 'ss01' on, 'cv01' on
    background: linear-gradient(181.25deg, #E60050 1.28%, #E6007E 26.26%, #B03A8B 99.18%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    background-clip: text
    text-fill-color: transparent

  .v-tooltip__content
    opacity: 1 !important
    padding: 6px 8px
    border: 1px solid rgb(var(--v-theme-neutral-lighten1)) !important
    box-shadow: 0px 3px 0px rgba(23, 25, 38, 0.12)
    span
      color: rgb(var(--v-theme-neutral-darken3)) !important
      line-height: 20px

  .mt-10px
    margin-top: 10px
</style>

<i18n lang="json" locale="de">
  {
    "analyses": "Analysen",
    "cost_savings_potential": "Kosteneinsparungspotenzial",
    "components": "Komponenten",
    "optimized": "Optimiert",
    "high": "Hoch",
    "medium": "Mittel",
    "no_active_analyses": "Keine aktiven Analysen",
    "no_cost_savings": "Keine Berechnungen zu Kosteneinsparungen verfügbar"
  }
  </i18n>
  <i18n lang="json" locale="en">
  {
    "analyses": "Analyses",
    "components": "Components",
    "cost_savings_potential": "Cost savings potential",
    "optimized": "Optimized",
    "high": "High",
    "medium": "Medium",
    "no_active_analyses": "No active analyses",
    "no_cost_savings": "No cost savings calculations available"
  }
  </i18n>
