import { AnalyticsResultsForComponent, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { BuildingAnalysesState } from './types'
import { MutationTree } from 'vuex'
import { ProjectValue } from '@/utils/types'

function setFlagForProject (flagArray: number[], flagValue: ProjectValue<boolean>): void {
  if (flagValue.value) {
    flagArray.push(flagValue.projectId)
  } else {
    const index = flagArray.indexOf(flagValue.projectId)
    if (index > -1) {
      flagArray.splice(index, 1)
    }
  }
}

export default {
  ADD_PROJECT_WITH_SAMPLE_DATA: (state: BuildingAnalysesState, projectId: number) => {
    state.projectsWithSampleData.push(projectId)
  },

  SET_BUILDING_ANALYSES_RESULTS: (state: BuildingAnalysesState, results: ProjectValue<AnalyticsResultsForComponent>) => {
    state.buildingAnalysesResults[results.projectId] = results.value
  },

  SET_BUILDING_COMPONENT: (state: BuildingAnalysesState, component: ProjectValue<ComponentInProjectWithContext>) => {
    state.buildingComponents[component.projectId] = component.value
  },

  SET_LOADING_PROJECTS_BUILDING_ANALYSES: (state: BuildingAnalysesState, loading: ProjectValue<boolean>) => {
    setFlagForProject(state.loadingProjectBuildingAnalyses, loading)
  },

  SET_LOADING_PROJECTS_BUILDING_COMPONENT: (state: BuildingAnalysesState, loading: ProjectValue<boolean>) => {
    setFlagForProject(state.loadingProjectBuildingComponent, loading)
  },

} as MutationTree<BuildingAnalysesState>
