<template>
  <div>
    <template
      v-for="(item, index) in items"
      :key="`project_item_${index}`"
    >
      <v-divider
        v-if="item.divider"
        :key="`project_item_divider_${index}`"
        class="group__divider"
      />
      <NavigationItem
        :children="item.children"
        :data-cy="`navigation-item-${item.to.name}`"
        :icon="item.icon"
        :route-names="item.routeNames"
        :title="item.title"
        :to="item.to"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import NavigationItem from './NavigationItem.vue'
import { NavigationItemData } from './types'

export default defineComponent({
  name: 'NavigationItemGroup',

  components: {
    NavigationItem,
  },

  props: {
    items: {
      required: true,
      type: Array as PropType<Array<NavigationItemData>>,
    },
  },
})
</script>

<style lang="sass" scoped>
.group__divider
  border-color: rgb(var(--v-theme-neutral-darken2)) !important
  margin: 10px 12px
  opacity: 1
</style>
