<script setup lang="ts">
import { compareTags, createFullTagValueList } from '@/utils/helpers/tags'
import { computed, ref } from 'vue'
import { DatapointOverviewItem, TagItem } from '@/vuex/data_points_view/types'
import { Node, TagAssociation } from '@aedifion.io/aedifion-api'
import { DatapointWithContextAndScore } from '@/vuex/datapoints/types'
import { DIKebabItemDatapointPayload } from '@/utils/types'
import FilterSelect from '@/components/FilterSelect/FilterSelect.vue'
import { FilterSelectItem } from '@/components/FilterSelect/types'
import { InvalidValueError } from '@/utils/helpers/validate'
import { ProjectTagResponse } from '@/vuex/tags/types'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import TagSelector from './TagSelector.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/vuex'

type TableTag = TagAssociation & { tableKey: string }

interface Props {
  kebabMenuItem: DIKebabItemDatapointPayload
  value?: boolean
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  value: true,
})

const emits = defineEmits<{
  'tags:close': []
}>()

const { t } = useI18n()
const store = useStore()

const headers = ref([
  {
    align: 'start',
    sortable: false,
    title: t('key'),
    value: 'key',
  },
  { title: t('value'), value: 'value' },
  { title: t('source'), value: 'source' },
  { title: t('protected'), value: 'protected' },
  { value: 'delete' },
])

const datapoint = computed<DatapointWithContextAndScore|DatapointOverviewItem>(() => {
  return store.getters['datapoints/datapointWithDataPointId'](props.kebabMenuItem.id)
})

const loading = computed<boolean>(() => store.state.datapoints.pendingTagUpdate)

const tagsWithoutDescription = computed<TableTag[]>(() => {
  if (datapoint.value?.tags) {
    const result = datapoint.value.tags.reduce((previous: TableTag[], currentValue: TagAssociation) => {
      if (currentValue.key !== 'description') {
        const valueToAdd: TableTag = {
          ...currentValue,
          tableKey: String(currentValue.id) + currentValue.source,
        }
        previous.push(valueToAdd)
      }
      return previous
    }, [])
    result.sort(compareTags)
    return result
  } else {
    return []
  }
})

async function addNewTagToDataPoint (tag: TagItem): Promise<void> {
  const newTagObject = {
    dataPointID: datapoint.value.dataPointID,
    payload: tag,
  }
  try {
    const createTagResponse: ProjectTagResponse = await store.dispatch('tags/createTag', newTagObject)
    await store.dispatch('datapoints/addTag', createTagResponse, { root: true })
  } catch (error) {
    if (error instanceof InvalidValueError) {
      showErrorNotification(`${t('notifications.errors.no_project_selected_with_attempted_action', { action: t('add_tag') })}`)
    }
    reportError(error)
  }
}

function description (key: string, value?: string): string | null {
  const label: Node | null = store.getters['labels/label'](key, value)
  return label?.description ?? null
}

function displayName (key: string, value?: string): string {
  const label: Node | null = store.getters['labels/label'](key, value)
  return label?.display_name ?? value ?? key
}

async function removeTag (tagId: number) {
  const payload = {
    dataPointID: datapoint.value.dataPointID,
    tagId,
  }

  try {
    await store.dispatch('datapoints/removeTag', payload)
  } catch (error) {
    if (error instanceof InvalidValueError) {
      showErrorNotification(`${t('notifications.errors.no_project_selected_with_attempted_action', { action: t('remove_tag') })}`)
    }
    reportError(error)
  }
}

function tagValues (item: TagAssociation): Array<FilterSelectItem> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const labels: any[] | null = store.getters['labels/allLeafNodes'](item.key)
  const tagValues: string[] | null = store.getters['tags/getTagValues'](item.key)
  const valuesList = createFullTagValueList(labels, tagValues)
  const hasValue = valuesList.find((value: FilterSelectItem) => value.value === item.value)

  if (!hasValue) {
    return [{
      title: item.value!,
      value: item.value!,
    }]
  }

  return valuesList
}

async function updateTagValue (tagId: number, key: string, newValue: FilterSelectItem) {
  const payload = {
    dataPointID: datapoint.value.dataPointID,
    key,
    newValue: newValue.value,
    tagId,
  }

  try {
    await store.dispatch('datapoints/updateTag', payload)
  } catch (error) {
    if (error instanceof InvalidValueError) {
      showErrorNotification(`${t('notifications.errors.no_project_selected_with_attempted_action', { action: t('edit_tag') })}`)
    }
    reportError(error)
  }
}

const itemsPerPage = computed<number>(() => tagsWithoutDescription.value?.length || 10)
</script>

<template>
  <v-dialog
    data-cy="tag-editor-dialog"
    persistent
    scrollable
    width="70%"
    :model-value="props.value"
  >
    <v-card>
      <v-card-title class="py-4 text-h5">
        {{ datapoint.dataPointID }} - {{ t('title') }}
      </v-card-title>
      <v-card-text>
        <v-overlay
          absolute
          scrim="neutral-lighten5"
          opacity="0.9"
          class="d-flex justify-center align-center"
          contained
          :model-value="loading"
        >
          <v-progress-circular
            color="primary-darken2"
            indeterminate
            size="50"
            width="6"
          />
        </v-overlay>
        <v-data-table
          disable-pagination
          :items-per-page="itemsPerPage"
          disable-sort
          :headers="headers"
          hide-default-footer
          :items="tagsWithoutDescription"
          item-key="tableKey"
        >
          <template #item.key="{ item }">
            <v-tooltip
              location="bottom"
              :disabled="description(item.key) === null"
            >
              <template #activator="{ props: itemKeyTooltipProps }">
                <span
                  v-bind="itemKeyTooltipProps"
                  v-text="displayName(item.key)"
                />
              </template>
              <span v-text="description(item.key)" />
            </v-tooltip>
          </template>

          <template #item.value="{ item }">
            <v-tooltip
              v-if="item._protected || item.source !== 'user'"
              location="bottom"
              :disabled="description(item.key, item.value) === null"
            >
              <template #activator="{ props: itemValueTooltipProps }">
                <span
                  v-bind="itemValueTooltipProps"
                  v-text="displayName(item.key, item.value)"
                />
              </template>
              <span v-text="description(item.key, item.value)" />
            </v-tooltip>
            <span v-else>
              <FilterSelect
                dense
                :extensible="true"
                :items="tagValues(item)"
                :menu-props="{ maxWidth: 190, offsetY: true }"
                :placeholder="t('placeholder')"
                :model-value="item.value"
                @change="(selectedItem) => updateTagValue(item.id, item.key, selectedItem)"
              />
            </span>
          </template>

          <template #item.protected="{ item }">
            <v-tooltip location="right">
              <template #activator="{ props: protectedItemTooltipProps }">
                <v-icon
                  v-if="item._protected"
                  v-bind="protectedItemTooltipProps"
                  size="small"
                >
                  fa:far fa-lock
                </v-icon>
                <v-icon
                  v-else

                  size="small"
                  v-bind="props"
                >
                  fa:far fa-lock-open-alt
                </v-icon>
              </template>
              <span v-if="item._protected">
                {{ t('tooltip.protected') }}
              </span>
              <span v-else>
                {{ t('tooltip.free') }}
              </span>
            </v-tooltip>
          </template>

          <template #item.delete="{ item }">
            <v-row>
              <!--
                ---- Buttons confirm/unconfirm ----
                <v-col cols="2">
                  <v-btn
                    :disabled="item.confirmed"
                    icon
                  >

                    <v-icon
                      :small="true"
                    >
                      fa:far fa-thumbs-up
                    </v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="2">
                  <v-btn
                    :disabled="!item.confirmed"
                    icon
                  >

                    <v-icon
                      :small="true"
                    >
                      fa:far fa-thumbs-down
                    </v-icon>
                  </v-btn>
                </v-col>
              -->

              <v-col cols="">
                <v-btn
                  class="ml-1 tw-bg-transparent"
                  :disabled="item._protected || item.source !== 'user'"
                  icon
                  size="40"
                  variant="text"
                  color="bg-transparent"
                  @click="removeTag(item.id)"
                >
                  <v-icon
                    :color="item._protected || item.source !== 'user' ? 'grey' : 'red'"
                    size="small"
                  >
                    fa:fas fa-trash-alt
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template #bottom />
        </v-data-table>
        <div class="text-h6 pt-4 text-neutral-darken3">
          {{ t('create_tag') }}
        </div>
        <div class="d-flex flex-row pt-2">
          <TagSelector
            allow-custom-tags
            @change="addNewTagToDataPoint"
          />
        </div>
      </v-card-text>
      <v-card-actions class="pt-2 pb-4 px-4">
        <v-btn
          class="ml-auto"
          color="primary-darken2"
          variant="text"
          @click="emits('tags:close')"
        >
          {{ t('actions.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="sass" scoped>
  .v-card-title
    span
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
  :deep(.v-table__wrapper)
    overflow-y: hidden
</style>

<i18n locale="de">
  {
    "add_tag": "um einen neuen Tag zu erstellen",
    "edit_tag": "um einen Tag zu bearbeiten",
    "key": "Schlüssel",
    "placeholder": "Neuer Wert",
    "protected": "Geschützt",
    "remove_tag": "um einen Tag zu entfernen",
    "source": "Quelle",
    "title": "Tags bearbeiten",
    "tooltip": {
      "free": "Frei",
      "protected": "Geschützt"
    },
    "value": "Wert",
    "create_tag": "Neuen Tag erstellen"
  }
  </i18n>
<i18n locale="en">
  {
    "add_tag": "to add a new tag",
    "edit_tag": "to edit a tag",
    "key": "Key",
    "placeholder": "New Value",
    "protected": "Protected",
    "remove_tag": "to remove a tag",
    "source": "Source",
    "title": "Edit tags",
    "tooltip": {
      "free": "Free",
      "protected": "Protected"
    },
    "value": "Value",
    "create_tag": "Create new tag"
  }
  </i18n>
