import { ComponentItem, ComponentsState } from './types'
import { Component } from '@aedifion.io/aedifion-api'
import { GetterTree } from 'vuex'
import { RootState } from '../types'

export const OBSOLETE_COMPONENTS_ALPHANUMERIC_IDS = [
  'FAN',
  'AHU_HUM_CODI',
  'H_CM',
  'C_CM',
  'TH_CTRL_LOO',
  'HUM',
]

export default {
  componentsList: (state: ComponentsState): Component[]|null => {
    if (!state.components) return null
    return state.components!.filter((component: ComponentItem) => !component.private && !OBSOLETE_COMPONENTS_ALPHANUMERIC_IDS.includes(component.alphanumeric_id!))
  },

  isLoadingComponents: (state: ComponentsState): boolean => state.loadingComponents,
} as GetterTree<ComponentsState, RootState>
