<i18n locale="de">
{
  "nudge_title": "Immer die Betriebsmeldungen der Anlagen im Blick!"
}
</i18n>
<i18n locale="en">
{
  "nudge_title": "Always keep the operating messages of the systems in view!"
}
</i18n>

<template>
  <div
    class="layout-wrapper"
    :style="[showCockpitNudge ? {'overflow': 'hidden'} : {}]"
  >
    <div :class="{'cockpitNudgeBlur': showCockpitNudge}">
      <PageHeader
        title-key="links.meta.title.status_and_alerts"
      />
      <SystemFaults :loading="isLoadingRequiredData" />
      <Incidents
        :loading="isLoadingRequiredData"
        class="mt-2"
      />
    </div>
    <CockpitNudge
      :name="t('links.meta.title.status_and_alerts')"
      :title="t('nudge_title')"
      :value="showCockpitNudge"
    />
  </div>
</template>

<script lang="ts">
import { NavigationGuardNext, RouteLocation } from 'vue-router'
import CockpitNudge from '@/components/CockpitNudge.vue'
import { defineComponent } from 'vue'
import Incidents from './Incidents.vue'
import { mapGetters } from 'vuex'
import PageHeader from '@/components/PageHeader.vue'
import { reportError } from '@/utils/helpers/errors'
import SystemFaults from './SystemFaults.vue'
import { useI18n } from 'vue-i18n'
import { useAlertsStore } from '@/stores/views/Alerts/alerts'

export default defineComponent({
  name: 'StatusAndAlerts',

  components: {
    CockpitNudge,
    Incidents,
    PageHeader,
    SystemFaults,
  },

  async beforeRouteUpdate (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) {
    if (to.params.project && from.params.project && to.params.project !== from.params.project) {
      this.isLoadingRequiredData = true
      await this.fetchDigitalTwin()
      try {
        await this.$store.dispatch('status_and_alerts/fetchSystemComponents')
      } catch (error) {
        reportError(error)
      }
      this.fetchIncidentAlerts()
      this.isLoadingRequiredData = false
    }
    next()
  },

  setup () {
    const { t } = useI18n()
    const alertsStore = useAlertsStore()
    return { alertsStore, t }
  },

  data () {
    return {
      isLoadingRequiredData: false,
    }
  },

  computed: {
    ...mapGetters({
      projectId: 'projects/currentProjectId',
      showCockpitNudge: 'status_and_alerts/showCockpitNudge',
    }),
  },

  async created () {
    this.isLoadingRequiredData = true
    await this.fetchDigitalTwin()
    if (this.$store.state.status_and_alerts.systemComponents === null) {
      try {
        await this.$store.dispatch('status_and_alerts/fetchSystemComponents')
      } catch (error) {
        reportError(error)
      }
    }
    this.fetchIncidentAlerts()
    this.isLoadingRequiredData = false
  },

  methods: {
    async fetchDigitalTwin (): Promise<void> {
      if (!this.$store.getters['building_analyses/isBuildingComponentOfProjectLoaded'](this.projectId)) {
        try { // TODO: isn’t this try/catch useless?
          await this.$store.dispatch('building_analyses/fetchBuildingComponentForProject', this.projectId)
        } catch (error) {
          reportError(error)
        }
      }
    },

    fetchIncidentAlerts (): void {
      if (!this.alertsStore.areIncidentAlertsOfProjectLoaded(this.projectId)) {
        this.alertsStore.fetchIncidentAlertsForProject(this.projectId)
      }
    },
  },
})

</script>

<style lang="sass" scoped>
  .cockpitNudgeBlur
    -webkit-filter: blur(2px)
    -moz-filter: blur(2px)
    -o-filter: blur(2px)
    -ms-filter: blur(2px)
    filter: blur(2px)
  :deep(.v-skeleton-loader)
    outline: 1px solid #D9D9DA
  :deep(.v-skeleton-loader.no-outline)
    outline: none
</style>
