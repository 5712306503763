<template>
  <v-menu
    location="bottom"
    close-delay="200"
    :offset="[0, 10]"
    open-delay="500"
    open-on-hover
  >
    <template #activator="{ props: menuProps }">
      <slot
        name="activator"
        v-bind="menuProps"
      />
    </template>
    <v-card max-width="300">
      <v-card-title v-if="$slots.title">
        <slot name="title" />
      </v-card-title>
      <v-card-subtitle v-if="$slots.subtitle">
        <slot name="subtitle" />
      </v-card-subtitle>
      <v-card-text v-if="$slots.text">
        <slot name="text" />
      </v-card-text>
      <v-card-actions v-if="linkUrl">
        <v-btn
          :href="linkUrl"
          size="small"
          target="_blank"
          variant="text"
        >
          {{ linkText }}
          <v-icon
            end
            size="x-small"
          >
            fa:far fa-external-link
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'TooltipCard',

  props: {
    linkText: {
      default: null,
      required: false,
      type: String as PropType<string>,
    },

    linkUrl: {
      default: null,
      required: false,
      type: String as PropType<string>,
    },
  },
})
</script>
