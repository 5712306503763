<i18n locale=de>
{
  "alt": "Avatar",
  "change_image": "Bild ändern",
  "dialog_title": "Bild ändern",
  "drop_indicator_label": "Bild hier ablegen",
  "or": "oder",
  "delete_image": "Bild entfernen",
  "select_image": "Bild auswählen",
  "upload": "Hochladen"
}
</i18n>
<i18n locale=en>
{
  "alt": "Avatar",
  "change_image": "Change image",
  "dialog_title": "Change image",
  "drop_indicator_label": "Drag and drop your image here",
  "or": "or",
  "delete_image": "Delete image",
  "select_image": "Select image",
  "upload": "Upload"
}
</i18n>

<template>
  <v-row justify="center">
    <v-menu
      :disabled="loading"
      location="end"
    >
      <template #activator="{ props: menuProps }">
        <div
          :class="['avatar', {'hover_blur': !loading}, 'circular']"
          :v-ripple="loading"
          v-bind="menuProps"
        >
          <v-img
            :alt="t('alt')"
            class="circular tw-cursor-pointer"
            cover
            data-cy="avatar-editor"
            :src="!!avatar ? avatar : '/img/dummy-image.jpg'"
          >
            <v-row
              v-if="loading"
              align="center"
              class="fill-height ma-0"
              justify="center"
            >
              <v-progress-circular
                color="neutral-darken2"
                indeterminate
                size="80"
                width="6"
              />
            </v-row>
          </v-img>
          <v-icon
            theme="dark"
            size="x-large"
            color="white"
          >
            fa:fas fa-camera
          </v-icon>
        </div>
      </template>
      <v-list
        class="aedifion-box-shadow"
      >
        <v-list-item
          data-cy="avatar-editor-change-image-action"
          @click="openFileDialog"
        >
          <v-list-item-title>
            {{ t('change_image') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          data-cy="avatar-editor-delete-image-action"
          :disabled="!avatar"
          @click="deleteAvatar"
        >
          <v-list-item-title>
            {{ t('delete_image') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="dialog"
      width="350"
    >
      <v-card class="py-2">
        <v-card-title>
          {{ t('dialog_title') }}
        </v-card-title>
        <v-card-text class="text-center">
          <div v-if="!newAvatarUrl">
            <v-col cols="12">
              <div
                :class="['drop_area', 'd-flex', 'justify-center', 'align-center', 'circular', dropAreaDragover ? 'drag_over': '']"
                data-cy="avatar-editor-dropzone"
                @dragenter="dropAreaDragover = true"
                @dragleave="dropAreaDragover = false"
                @dragover.prevent
                @drop.prevent="onDrop"
              >
                <div class="drop_indicator">
                  <div>
                    <v-icon
                      color="neutral-darken2"
                      size="100"
                      class="tw-h-fit tw-w-fit"
                    >
                      fa:fas fa-cloud-upload
                    </v-icon>
                  </div>
                  <div class="text-body-1 text-neutral-darken2">
                    {{ t('drop_indicator_label') }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <span class="text-neutral-darken2">{{ t('or') }}</span>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="primary-darken2"
                @click="showFileSelector"
              >
                {{ t('select_image') }}
              </v-btn>
            </v-col>
          </div>
          <v-col
            v-if="newAvatarUrl"
            cols="12"
          >
            <v-img
              aspect-ratio="1"
              class="avatar_preview circular"
              :src="newAvatarUrl"
            />
          </v-col>
        </v-card-text>
        <v-card-actions class="pb-2">
          <v-spacer />
          <v-btn
            color="primary-darken2"
            variant="text"
            @click="dialog = false"
          >
            {{ t('actions.cancel') }}
          </v-btn>
          <v-btn
            color="primary-darken2"
            data-cy="avatar-editor-upload"
            :disabled="newAvatarUrl == null"
            @click="updateAvatar"
          >
            {{ t('upload') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <input
      ref="fileInput"
      data-cy="avatar-editor-file-input"
      enctype="image/*"
      style="display:none"
      type="file"
      @change="onFileSelected"
    >
  </v-row>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AvatarEditorDeprecated',

  props: {
    avatar: {
      required: false,
      type: String as PropType<string>,
    },

    loading: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },

  emits: ['delete', 'update'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      dialog: false,
      dropAreaDragover: false,
      hover: false,
      newAvatarFile: null as null|File,
      newAvatarUrl: null as null|string,
    }
  },

  methods: {
    deleteAvatar (): void {
      this.$emit('delete')
    },

    onDrop (event: DragEvent): void {
      this.dropAreaDragover = false
      if (event.dataTransfer) {
        const droppedFiles = event.dataTransfer.files
        this.setNewAvatar(droppedFiles[0])
      }
    },

    onFileSelected (): void {
      const selectedFiles = (this.$refs.fileInput as HTMLInputElement).files
      if (selectedFiles) {
        this.setNewAvatar(selectedFiles[0])
      }
    },

    openFileDialog (): void {
      this.newAvatarUrl = null
      this.newAvatarFile = null
      this.dialog = true
    },

    setNewAvatar (file: File): void {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.addEventListener('load', () => {
        this.newAvatarUrl = String(fileReader.result)
      })
      this.newAvatarFile = file
    },

    showFileSelector (): void {
      (this.$refs.fileInput as HTMLInputElement).click()
    },

    updateAvatar (): void {
      this.dialog = false
      this.$emit('update', this.newAvatarFile)
    },
  },
})
</script>

<style lang="sass" scoped>
  .avatar
    height: 180px
    position: relative
    width: 180px

    .v-image
      height: 100%
      object-fit: cover
      object-position: center
      transition: inherit
      width: 100%

  .avatar_preview
    left: 50%
    height: 180px
    position: relative
    transform: translateX(-50%)
    width: 180px

  .circular
    border-radius: 50%

  .drag_over
    background-color: rgb(var(--v-theme-primary-lighten3))

  .drop_area
    border: 2px dashed rgb(var(--v-theme-neutral-darken2))
    height: 220px
    left: 50%
    position: relative
    transform: translateX(-50%)
    width: 220px

    .drop_indicator
      display: flex
      flex-direction: column
      height: calc(80%)
      justify-content: center
      margin: 0
      pointer-events: none
      position: absolute
      width: calc(80%)

  .hover_blur
    transition: all 0.3s linear

    :deep(.v-icon)
      left: calc(50% - 20px)
      opacity: 0
      pointer-events : none
      position: absolute
      top: calc(50% - 20px)
      transition: inherit

    :deep(.v-responsive)
      &:hover
        filter: blur(2px)
      &:hover + .v-icon
        opacity: 1
</style>
