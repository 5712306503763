import { AnalysisResultsOverview, AnalyticsOverviewItem } from '@aedifion.io/aedifion-api'
import { AnalysisOverviewState } from './types'
import { MutationTree } from 'vuex'

export default {
  SET_LOADING_OVERVIEW: (state: AnalysisOverviewState, loading: boolean) => {
    state.loadingOverview = loading
  },

  SET_OVERVIEW_ITEMS: (state: AnalysisOverviewState, overviewItems: AnalyticsOverviewItem[]) => {
    state.overviewItems = overviewItems
  },

  SET_OVERVIEW_SUMMARY: (state: AnalysisOverviewState, overviewSummary: AnalysisResultsOverview) => {
    state.overviewSummary = overviewSummary
  },
} as MutationTree<AnalysisOverviewState>
