<i18n lang="json" locale="de">
 {
  "title": "Authentifizierung fehlgeschlagen",
  "text": "Während der Authentifizierung trat ein Fehler auf.",
  "login": "Einloggen"
  }
</i18n>
<i18n lang="json" locale="en">
{
  "title": "Authentication failed",
  "text": "An error occurred during the authentication process.",
  "login": "Log In"
}
</i18n>
<template>
  <div
    class="d-flex justify-center align-center aedifion__auth__callback__wrapper flex-column"
  >
    <v-icon
      color="error"
      size="x-large"
    >
      fa:fas fa-times-octagon
    </v-icon>
    <span class="mt-4 text-h6">
      {{ t('title') }}
    </span>
    <span class="mt-4 subtitle">
      {{ t('text') }}
    </span>
    <v-btn
      class="mt-4"
      color="primary-darken2"
      size="large"
      variant="outlined"
      @click="login"
    >
      {{ t('login') }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AuthFailed',
  setup () {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    isAuthenticated (): boolean {
      return this.$store.getters['auth/oidcIsAuthenticated']
    },
  },
  mounted () {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'home' })
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/authenticateOidc',
    }),
  },
})
</script>

<style lang="sass">
  .aedifion__auth__callback__wrapper
    position: absolute
    left: 0px
    right: 0px
    top: 0px
    bottom: 0px
</style>
