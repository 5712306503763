/* eslint-disable camelcase */
import { AnalysisInstancesState, ExtendedAnalysisResult } from './types'
import { AnalysisResultSummaries, InstanceConfig } from '@aedifion.io/aedifion-api'
import { MutationTree } from 'vuex'

export default {
  ADD_PENDING_INSTANCE_UPDATE_FOR_FUNCTION: (state: AnalysisInstancesState, functionId: number) => {
    if (!state.pendingInstanceUpdates.includes(functionId)) {
      state.pendingInstanceUpdates.push(functionId)
    }
  },

  REMOVE_PENDING_INSTANCE_UPDATE_FOR_FUNCTION: (state: AnalysisInstancesState, functionId: number) => {
    const foundIndex: number|undefined = state.pendingInstanceUpdates.findIndex((functionIdInState: number) => functionIdInState === functionId)
    if (foundIndex > -1) state.pendingInstanceUpdates.splice(foundIndex, 1)
  },

  SELECT_INSTANCE: (state: AnalysisInstancesState, instance: InstanceConfig) => (state.selectedInstance = instance),

  SET_ANALYSIS_RESULT: (state: AnalysisInstancesState, analysisResult: ExtendedAnalysisResult|null) => (state.analysisResult = analysisResult),

  SET_ANALYSIS_RESULTS: (state: AnalysisInstancesState, analysisResults: AnalysisResultSummaries[]|null) => (state.analysisResults = analysisResults),

  SET_INSTANCES: (state: AnalysisInstancesState, instances: InstanceConfig[]) => (state.instanceList = instances),

  SET_LOADING_ANALYSIS_RESULT: (state: AnalysisInstancesState, loading: boolean) => (state.loadingAnalysisResult = loading),

  SET_LOADING_ANALYSIS_RESULTS: (state: AnalysisInstancesState, loading: boolean) => (state.loadingAnalysisResults = loading),

  SET_LOADING_INSTANCES: (state: AnalysisInstancesState, loading: boolean) => (state.loading = loading),

  UPDATE_INSTANCE: (state: AnalysisInstancesState, instance: InstanceConfig) => {
    const foundInstanceIndex: number = state.instanceList.findIndex((instanceInState: InstanceConfig) => {
      return instanceInState.id === instance.id
    })

    if (foundInstanceIndex > -1) state.instanceList.splice(foundInstanceIndex, 1, instance)
    else state.instanceList.push(instance)
  },

} as MutationTree<AnalysisInstancesState>
