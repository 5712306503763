import { computed, onBeforeUnmount, type Ref, watch } from 'vue'
import { useAppStore } from '@/stores/app'

export default function useInfiniteScroll (
  interSectionTarget: Ref<HTMLElement | null>,
  onLoadMore: () => Promise<void>|void,
  interSectionObserversOptions?: Omit<IntersectionObserverInit, 'root'> & { root?: Ref<HTMLElement|null>|null }): void {
  const appStore = useAppStore()
  const currentProject = computed(() => appStore.projectId)
  let intersectionObserver: IntersectionObserver | null = null

  // Reset the intersection observer when the current project changes
  watch(currentProject, (newProject) => {
    if (newProject !== null) {
      intersectionObserver?.disconnect()
      intersectionObserver = null
    }
  })

  // Only initialize the intersection observer when the target element is available
  watch([() => interSectionTarget.value], async ([newTargetValue]) => {
    if (newTargetValue && intersectionObserver === null) {
      intersectionObserver = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            onLoadMore()
          }
        },
        {
          root: interSectionObserversOptions?.root?.value ?? null,
          rootMargin: interSectionObserversOptions?.rootMargin ?? '0px',
          threshold: interSectionObserversOptions?.threshold ?? 0,
        },
      )
      intersectionObserver.observe(interSectionTarget.value as HTMLElement)
      // We need to destroy the intersection observer when the target element is removed from the DOM
      // This happens when the observer is used inside dialogs or modals
    } else if (newTargetValue === null && intersectionObserver !== null) {
      intersectionObserver.disconnect()
      intersectionObserver = null
    }
  }, {
    immediate: true,
  })

  // Disconnect the intersection observer when the component is unmounted to prevent memory leaks
  onBeforeUnmount(() => {
    if (intersectionObserver) {
      intersectionObserver.disconnect()
    }
  })
}
