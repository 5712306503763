/**
 * /src/utils/helpers/faults.ts
 * A helper file for fault systems.
 * Provides helper methods to retrieve certain information
 * or calculating several fault-states.
 */

/**
 * Mapped numeric operational states to their operational state category.
 */
const OPERAIONAL_STATE_STATUS_CODES: { [operationalState: string]: number[] } = {
  equipment_error_action_required: [300],
  equipment_error_less_important: [301],
  equipment_switched_off: [200],
  in_operation: [100],
  no_data: [0],
  non_critical_notification: [103],
  ready_for_operation: [101, 102],
}

/**
 * Mapped colors to their operational state category.
 */
const OPERATIONAL_STATE_COLORS: { [operationalState: string]: string } = {
  equipment_error_action_required: 'error',
  equipment_error_less_important: 'warning',
  equipment_switched_off: 'success--bordered',
  in_operation: 'success',
  no_data: 'neutral',
  non_critical_notification: 'info',
  ready_for_operation: 'success',
}

/**
 * Returns the operational state the passed code represents.
 * @param code Representational number for an operational state.
 * @returns Operational state category or null if none was found for the passed code.
 */
export function getOperationalStateByCode (code: number|null): string|null {
  if (code === null) return null
  for (const [operationalState, codes] of Object.entries(OPERAIONAL_STATE_STATUS_CODES)) {
    if (codes.includes(code)) return operationalState as string
  }
  return null
}

/**
 * Returns a color string for the operational state, based on the passed operationalState value.
 * Returns null if no operational state was found for the color.
 * @param operationalState Name of the operational state.
 * @returns Color string of the matching operational state. Returns null if no match found.
 */
export function getColorOfOperationalStateName (operationalState: string|null): string {
  if (operationalState === null) return 'neutral'
  return OPERATIONAL_STATE_COLORS[operationalState] ?? 'neutral'
}

/**
 * Returns the operational state color by it's numerical value.
 * @param operationalStateValue The value that represents a certain operational state. If null passed, returns null.
 * @returns Color as string value if passed value matches an operational state. Otherwise returns null.
 */
export function getColorOfOperationalStateCode (operationalStateValue: number|null): string {
  return getColorOfOperationalStateName(
    getOperationalStateByCode(operationalStateValue),
  )
}
