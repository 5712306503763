<template>
  <v-row>
    <v-col
      v-for="component in componentsList"
      :key="`item ${component.id}`"
      cols="12"
      data-cy="componentsList"
      lg="3"
      md="4"
      sm="6"
    >
      <ComponentCard
        :component-item="getComponentData(component)"
        data-cy="componentCard"
        @click="$emit('select', getComponentData(component))"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component } from '@aedifion.io/aedifion-api'
import ComponentCard from '@/components/ComponentCard.vue'
import { defineComponent } from 'vue'
import { textForLocale } from '@/utils/helpers/locale'

export default defineComponent({
  name: 'SelectComponentPage',

  components: {
    ComponentCard,
  },

  emits: ['select'],

  computed: {
    componentsList (): Component[] {
      return this.$store.getters['components/componentsList']
    },
  },

  methods: {
    getComponentData (component: Component) {
      return {
        alphanumericID: component.alphanumeric_id,
        componentID: component.id,
        componentName: textForLocale(component.nameDE!, component.nameEN!),
      }
    },
  },
})
</script>
