<template>
  <ActionCard
    :color="color"
    :icon="icon"
    :loading="loading"
    style="height: 100%"
    :title="title"
  >
    <slot />
    <template #actions>
      <slot name="actions" />
    </template>
  </ActionCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ActionCard from './ActionCard.vue'

export default defineComponent({
  name: 'IncidentCard',
  components: {
    ActionCard,
  },

  props: {
    color: {
      type: String as PropType<string>,
      required: false,
      default: 'neutral',
    },

    icon: {
      type: String as PropType<string>,
      required: false,
      default: 'fa:fal fa-question-circle',
    },

    loading: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },

    title: {
      type: String as PropType<string>,
      required: true,
    },
  },
})
</script>
