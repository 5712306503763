<script setup lang="ts">
import { computed, ref } from 'vue'
import { Fault } from '@/vuex/status_and_alerts/types'
import FaultCard from '@/components/FaultCard.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/vuex'

// --- definition ---

interface Props {
  loading: boolean
}

defineProps<Props>()

const store = useStore()
const { t, tm, rt } = useI18n()

const infoOpen = ref(false)
const statusExplanationTableHeaders = ref([
  {
    align: 'start',
    sortable: false,
    title: t('current_statuses.head_table.color'),
    value: 'color',
  },
  {
    title: t('current_statuses.head_table.interpretation'),
    value: 'interpretation',
  },
])

const faults = computed<Fault[]>(() => store.getters['status_and_alerts/faults'])
</script>

<template>
  <div>
    <v-dialog
      max-width="700px"
      persistent
      :model-value="infoOpen"
    >
      <v-card
        data-cy="info-overlay-faults"
        class="pt-3"
      >
        <v-card-title class="mt-0 pt-0 text-h5 py-4">
          {{ t('title') }}
        </v-card-title>
        <v-card-text class="pb-0 text-body-2 text-neutral-darken1">
          <p>{{ t('current_statuses.tootltip_text') }}</p>
          <v-data-table
            class="mb-2 mt-4"
            disable-pagination
            disable-sort
            :headers="statusExplanationTableHeaders"
            :hide-default-footer="true"
            :items="tm('current_statuses.body_table')"
          >
            <template #item.color="{item}">
              {{ rt(item.color) }}
            </template>
            <template #item.interpretation="{item}">
              {{ rt(item.interpretation) }}
            </template>
            <template #bottom />
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ml-auto"
            color="primary-darken2"
            variant="elevated"
            data-cy="button-close-faults"
            @click="infoOpen = false"
          >
            {{ t('actions.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <span
      class="text-h6 mr-2"
      v-text="t('title')"
    />
    <v-icon
      data-cy="info-icon-faults"
      size="x-small"
      color="neutral-darken1"
      @click="infoOpen = true"
    >
      fa:far fa-info-circle
    </v-icon>
    <div
      v-if="faults.length > 0 && !loading"
      class="grid"
    >
      <FaultCard
        v-for="(item, index) of faults"
        :key="`fault_${index}`"
        class="my-2"
        :color="item.color"
        data-cy="facilityFault"
        :icon="item.icon"
        :link="item.link"
        :title="item.title"
        :tooltip-text="item.tooltipText"
      />
    </div>
    <v-row
      v-else-if="loading"
      class="mb-2 mt-0 mx-0 grid"
    >
      <v-col
        v-for="index in 8"
        :key="index"
        cols="12"
        lg="3"
        md="4"
        sm="12"
        xl="2"
        class="py-2 px-0"
      >
        <v-skeleton-loader
          class="neutral-lighten5"
          elevation="0"
          height="82"
          type="list-item-avatar-two-line"
          width="275"
        />
      </v-col>
    </v-row>
    <v-alert
      v-else
      border="start"
      class="text-semantics mt-2 pa-2"
      color="warning-darken2"
      type="warning"
    >
      {{ t('not_configured') }}
    </v-alert>
  </div>
</template>

<style lang="sass">
  .v-alert
    max-width: 600px

  .grid
    display: grid
    grid-template-columns: repeat(auto-fill, 300px)
    justify-content: space-between
</style>

<!-- Added this eslint ignore because the locales are actually being used,
  but the are dynamic and the eslint plugin cannot detect that -->
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n lang="json" locale="de">
  {
    "title": "Aktuelle Status",
    "no_datapoint_mapped": "Dieser Systemkomponente ist derzeit kein Datenpunkt zugeordnet.",
    "not_configured": "Es wurden noch keine Systemkomponenten konfiguriert.",
    "current_statuses": {
      "tootltip_text": "Dieser zusammengefasste Systemstatus wird für ausgewählte Anlagen im Status And Alerts angezeigt. Eine Anlage im Betrieb hat regulär den Status Grün. Treten weitere Meldungen auf, wird die Farbe des Systemstatus entsprechend dieser Tabelle angepasst.",
      "head_table": {
        "color": "Farbe",
        "interpretation": "Interpretation"
      },
      "body_table": [
        {
          "color": "Grün",
          "interpretation": "Anlage in Betrieb oder betriebsbereit"
        },
        {
          "color": "Grüne Umrandung ohne Füllung",
          "interpretation": "Anlage manuell ausgeschaltet (z.B. Kühlung im Winter ausgeschaltet)"
        },
        {
          "color": "Rot",
          "interpretation": "Fehlermeldung mit hoher Priorität"
        },
        {
          "color": "Gelb",
          "interpretation": "Fehlermeldung mit niedriger Priorität"
        }
      ]
    }
  }
  </i18n>
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n lang="json" locale="en">
  {
    "title": "Current statuses",
    "no_datapoint_mapped": "No datapoint is currently mapped to this system component.",
    "not_configured": "No system components have been configured yet.",
    "current_statuses": {
      "tootltip_text": "This summarized equipment status is displayed for selected plants in the Status And Alerts. A plant in operation has regularly the status Green. If further messages occur, the color of the system status is adjusted according to this table.",
      "head_table": {
        "color": "Color",
        "interpretation": "Interpretation"
      },
      "body_table": [
        {
          "color": "Green",
          "interpretation": "System in operation or ready for operation"
        },
        {
          "color": "Green border without filling",
          "interpretation": "System is manually switched off (e.g., cooling switched off in winter)"
        },
        {
          "color": "Yellow",
          "interpretation": "Error message with low priority"
        },
        {
          "color": "Red",
          "interpretation": "Error message with high priority"
        }
      ]
    }
  }
  </i18n>
