<script setup lang="ts">
import { onBeforeRouteUpdate, RouteLocationNormalized, useRoute, useRouter } from 'vue-router'
import { useAdministrationUsersStore, UserCreationPayload } from '@/stores/views/Administration/users'
import { getProjectRoleIds } from '@/utils/helpers/roles'
import { NewUser } from '@aedifion.io/aedifion-api'
import { useAdministrationStore } from '@/stores/views/Administration/index'
import { useEditUser } from '../composables/useEditUser'
import { useI18n } from 'vue-i18n'
import UserDeletionConfirmationDialog from './UserDeletionConfirmationDialog.vue'
import UserFormDialog from '@/components/UserFormDialog.vue'
import UsersFilters from './UsersFilters.vue'
import UsersTable from './UsersTable.vue'

type Dictionary<T> = {
  [key: string]: T;
}

// --- definition ---

const route = useRoute()
const router = useRouter()
const administrationStore = useAdministrationStore()
const administrationUsersStore = useAdministrationUsersStore()
const { t } = useI18n()

function hasFilterInQuery (routeToTest?: RouteLocationNormalized) {
  if (!routeToTest) {
    routeToTest = route
  }
  return !!routeToTest.query.search || !!routeToTest.query.roles || !!routeToTest.query.projects
}

function updateQueryUsingStoredFilters () {
  const query: Dictionary<string | (string | null)[]> = {}

  if (administrationUsersStore.searchFilter.length) {
    query.search = administrationUsersStore.searchFilter
  }
  if (administrationUsersStore.companyRolesFilter.length) {
    query.roles = administrationUsersStore.companyRolesFilter.join(',')
  }
  if (administrationUsersStore.projectsFilter.length) {
    query.projects = administrationUsersStore.projectsFilter.join(',')
  }

  if (!!query.search || !!query.roles || !!query.projects) {
    router.replace({ query })
  }
}

async function componentInitialization () {
  await administrationStore.fetchCompanyWithContext()
  if (hasFilterInQuery()) {
    administrationUsersStore.clearFilters()
    administrationUsersStore.updateFiltersUsingUrlQuery(route.query)
  } else {
    updateQueryUsingStoredFilters()
  }
}

function onAddUser (): void {
  clearEditedUser()
  toggleUserFormDialogState()
}

async function onCreateUser (payload: {
  companyRoles: number[],
  projectRoles: number[],
  user: NewUser
}): Promise<void> {
  const newUser: UserCreationPayload = {
    companyRolesIds: payload.companyRoles,
    projectRoles: getProjectRoleIds(administrationStore.projects, payload.projectRoles),
    user: payload.user,
  }

  const isUserCreated = await administrationUsersStore.createUser(newUser)
  if (isUserCreated) {
    toggleUserFormDialogState()
  }
}

// #region Edit User
const { handleEditUser, updateUser, userBeingEdited, isUserFormDialogOpen, clearEditedUser, toggleUserFormDialogState } = useEditUser()
// #endregion

// --- execution ---

onBeforeRouteUpdate((to, from, next) => {
  if (to.name && to.name === from.name && !hasFilterInQuery(to)) {
    administrationUsersStore.clearFilters()
  }
  next()
})

componentInitialization()
</script>

<template>
  <div class="fill-width">
    <h3 class="text-h3 text-neutral-darken4">
      {{ t('users') }}
    </h3>
    <div class="d-flex justify-space-between flex-wrap mt-8">
      <UsersFilters />
      <div>
        <UserFormDialog
          v-if="isUserFormDialogOpen"
          :value="isUserFormDialogOpen"
          :company-roles="administrationStore.companyRoles"
          :projects="administrationStore.projects"
          :loading="administrationStore.loading"
          :users="administrationStore.users"
          :user="userBeingEdited"
          @create-user="onCreateUser"
          @update-user="updateUser"
          @change="toggleUserFormDialogState"
        />
        <v-btn
          color="primary-darken2"
          data-testid="administration-users-create-user-button"
          @click="onAddUser"
        >
          <span>{{ t('add_user') }}</span>
          <v-icon
            size="small"
            class="ml-2"
          >
            fa:far fa-plus
          </v-icon>
        </v-btn>
      </div>
    </div>
    <UsersTable
      class="mt-4"
      @edit-user="handleEditUser"
    />
    <UserDeletionConfirmationDialog />
  </div>
</template>

<i18n lang="json" locale="de">
  {
    "users": "Benutzer",
    "add_user": "Benutzer hinzufügen"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "users": "Users",
    "add_user": "Add User"
  }
</i18n>
