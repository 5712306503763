<script setup lang="ts">
import { computed, ref } from 'vue'
import { docsLink, textForLocale } from '@/utils/helpers/locale'
import { formatDateTime } from '@/filters/formatting'
import type { Incident } from '@/vuex/status_and_alerts/types'
import IncidentCard from '@/components/IncidentCard.vue'
/* import CreateNewEventCard from './CreateNewEventCard.vue' */
import { useI18n } from 'vue-i18n'
import { useStore } from '@/vuex'

// --- definition ---

interface Props {
  loading: boolean
}

defineProps<Props>()

const store = useStore()
const { t } = useI18n()

const infoOpen = ref(false)

const incidents = computed<Incident[]|null>(() => store.getters['status_and_alerts/incidents'])

function incidentName (incident: Incident): string {
  return textForLocale(incident?.name?.de ?? '', incident?.name?.en ?? '')
}
</script>

<template>
  <div>
    <v-dialog
      max-width="700px"
      persistent
      :model-value="infoOpen"
    >
      <v-card data-cy="info-overlay-incidents">
        <v-card-title>
          {{ t('title') }}
        </v-card-title>
        <v-card-text class="pb-0">
          <!-- The locales for indents were temporarily added to the global locales, because they only worked this way and should be removed after the upgrade to vue 3.2 -->
          <i18n-t
            keypath="incidents.info"
            tag="p"
          >
            <template #alarms>
              <a
                :href="docsLink('incidents_alarms')"
                target="_blank"
              >
                {{ t('incidents.links.alarms') }}
              </a>
            </template>
            <template #contact_us>
              <a
                :href="docsLink('contact')"
                target="_blank"
              >
                {{ t('incidents.links.contact_us') }}
              </a>
            </template>
          </i18n-t>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ml-auto"
            color="primary"
            data-cy="button-close-incidents"
            @click="infoOpen = false"
          >
            {{ t('actions.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <span
      v-if="!loading && incidents !== null && incidents.length > 0"
      class="text-h6 mr-2"
      v-text="t('title')"
    />
    <v-icon
      v-if="!loading && incidents !== null && incidents.length > 0"
      data-cy="info-icon-incidents"
      size="x-small"
      color="neutral-darken1"
      @click="infoOpen = true"
    >
      fa:far fa-info-circle
    </v-icon>
    <v-row
      v-if="!loading && incidents !== null && incidents.length > 0"
      class="mt-1"
    >
      <v-col
        v-for="(incident, index) of incidents"
        :key="`incident_${index}`"
        class="pt-1"
        cols="12"
        lg="3"
        md="4"
        sm="12"
        style="height: 200px"
        xl="2"
      >
        <IncidentCard
          :color="incident.color"
          data-cy="incidentCard"
          :icon="incident.icon"
          :title="incidentName(incident)"
        >
          <div
            v-if="incident.active && incident.alert === null"
          >
            <span class="text-body-1">
              {{ t('no_messages') }}
            </span>
          </div>
          <div
            v-else-if="incident.active && incident.alert && incident.alert.status === 'open'"
          >
            <p class="text-body-1 font-weight-medium mb-1">
              {{ incident.timePassed }} - {{ t('currently_active') }}
            </p>
            <p class="text-subtitle-1">
              {{ t('currently_active_text') }}
            </p>
          </div>
          <div
            v-else-if="incident.active && incident.alert && incident.alert.status === 'ack'"
          >
            <p class="text-body-1 font-weight-medium mb-1">
              {{ incident.timePassed }}
            </p>
            <p class="text-subtitle-1">
              {{ formatDateTime(incident.alert.createTime) }}
            </p>
          </div>
          <div
            v-else
          >
            <p class="text-body-1 font-weight-medium mb-1">
              {{ t('incident_not_available') }}
            </p>
            <p class="text-subtitle-1">
              {{ t('incident_not_configured_text') }}
            </p>
          </div>
          <template #actions>
            <!-- Will be enabled as soon as the alarms cockpit is integrated -->
            <!-- <v-btn
              v-if="!!incident.alert"
              block
              :color="incident.color"
              elevation="0"
              :to="`/alerts/${incident.alert.id}`"
            >
              {{t('go_to_alarm')}}
            </v-btn> -->
            <!-- Will be anabled as soon as the creation of incident-settings is integrated -->
            <!-- <v-btn
              v-if="!incident.active"
              block
              color="primary"
              elevation="0"
              outlined
            >
              {{t('configure')}}
            </v-btn> -->
          </template>
        </IncidentCard>
      </v-col>
      <!-- <v-col
        cols="12"
        lg="3"
        md="4"
        sm="12"
        style="height: 200px"
        xl="2"
      >
        <CreateNewEventCard />
      </v-col> -->
    </v-row>
    <div
      v-if="loading"
      class="mt-3"
    >
      <v-skeleton-loader
        type="text"
        width="100"
        class="bg-transparent no-outline"
      />
      <v-row
        class="mt-1"
      >
        <v-col
          v-for="(index) in 4"
          :key="index"
          cols="12"
          lg="3"
          md="4"
          sm="12"
          xl="2"
        >
          <v-skeleton-loader
            class="bg-white rounded-sm"
            height="184"
            type="article"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<!-- Added this eslint ignore because the code that uses the locales is commented -->
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n lang="json" locale="de">
  {
    "title": "Ereignisse",
    "no_messages": "Keine Meldungen",
    "incident_not_available": "Ereignis nicht verfügbar",
    "incident_not_configured_text": "Dieses Ereignis wurde noch nicht eingerichtet.",
    "configure": "Jetzt einrichten",
    "currently_active": "noch aktiv",
    "currently_active_text": "Ein Alarm ist aktiv und wurde noch nicht berabeitet.",
    "go_to_alarm": "Zum Alarm"
  }
  </i18n>
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
  <i18n lang="json" locale="en">
  {
    "title": "Incidents",
    "no_messages": "No notifications",
    "incident_not_available": "Incident not available",
    "incident_not_configured_text": "This incident was not configured yet.",
    "configure": "Configure now",
    "currently_active": "still active",
    "currently_active_text": "An alarm is active and has not yet been processed.",
    "go_to_alarm": "View alarm"
  }
  </i18n>
