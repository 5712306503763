<script setup lang="ts">
import AsideList from '@/components/AsideList/AsideList.vue'
import { computed } from 'vue'
import DatapointListItem from './DatapointListItem.vue'
import { DatapointListItemData } from '@/vuex/datapoints/types'
import DatapointsFilter from './DatapointsFilter.vue'
import DatapointsFilterChips from '@/views/DataPointsView/DatapointsList/DatapointsFilterChips.vue'
import debounce from 'lodash.debounce'
import { reportError } from '@/utils/helpers/errors'
import SearchInput from '@/components/SearchInput.vue'
import { useI18n } from 'vue-i18n'
import useIsReadOnly from '@/composables/useIsReadOnly'
import { useStore } from '@/vuex'

interface Props {
  itemsDraggable?: boolean;
}

withDefaults(defineProps<Props>(), {
  itemsDraggable: false,
})

const { t } = useI18n()
const vuexStore = useStore()
const { isReadOnly } = useIsReadOnly()

const datapoints = computed<DatapointListItemData[]>(() => vuexStore.getters['datapoints/datapointListItems'])
const isLoadingDatapoints = computed<boolean>(() => vuexStore.getters['datapoints/isLoadingDatapoints'])
const page = computed<number|null>(() => vuexStore.getters['datapoints/getCurrentPage'])
const totalPages = computed<number|null>(() => vuexStore.getters['datapoints/getTotalPages'])

const search = computed<string>({
  get (): string {
    return vuexStore.getters['data_points_view/search']
  },
  set (value: string): void {
    applySearchDelayed(value)
  },
})

const applySearchDelayed = debounce(function (search: string) {
  vuexStore.commit('data_points_view/SET_SEARCH', search)
  fetchDatapoints(1)
}, 500)

async function clickItem (item: DatapointListItemData) {
  if (item.selected) {
    vuexStore.dispatch('data_points_view/deselectDatapoint', item.hash_id)
  } else {
    try {
      await vuexStore.dispatch('data_points_view/selectDatapoints', [item.hash_id])
    } catch (error) {
      reportError(error)
    }
  }
}

function fetchDatapoints (page: number) {
  vuexStore.dispatch('datapoints/fetchDatapoints', page)
}

function onFiltersChanged (): void {
  fetchDatapoints(1)
}

function pageChange (newPage: number) {
  fetchDatapoints(newPage)
}

</script>

<template>
  <AsideList
    class="aside-list"
    :items="datapoints"
    :items-draggable="itemsDraggable"
    :loading="isLoadingDatapoints"
    :page="page"
    :pages-count="totalPages"
    @asidelist:click-item="clickItem"
    @asidelist:page-change="pageChange"
  >
    <template #content-header>
      <div
        class="d-flex align-center px-4 pt-4"
      >
        <SearchInput
          class="pr-2"
          :placeholder="t('search')"
          :model-value="search"
          @update:model-value="applySearchDelayed"
        />
        <DatapointsFilter
          :disabled="isLoadingDatapoints"
          @datapointsfilter:change="onFiltersChanged"
        />
      </div>
      <DatapointsFilterChips
        class="px-4 py-2"
        @datapointsfilter:change="onFiltersChanged"
      />
    </template>
    <template #list-item="{ itemData }">
      <DatapointListItem
        :item-data="itemData"
        :read-only="isReadOnly"
        v-bind="$attrs"
      />
    </template>
  </AsideList>
</template>

<style lang="sass" scoped>
// Prevents the selectable class from being applied when a v-list-item is clicked
:deep(.v-list-item--active)
    opacity: 1 !important
    background-color: unset !important
</style>

<i18n lang="json" locale="de">
  {
    "search": "Suche"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "search": "Search"
  }
</i18n>
