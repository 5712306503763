<i18n locale="de">
{
  "title": "{componentInProject} - Datenpunkte bearbeiten",
  "replace_confirmation_text": "Soll der Datenpunkt wirklich ersetzt werden?",
  "replace_confirmation_title": "Datenpunkt ersetzen",
  "unmap_confirmation_text": "Soll die Zuordnung des Datenpunktes zu der Komponente wirklich aufgehoben werden?",
  "unmap_confirmation_title": "Zuordnung aufheben"
}
</i18n>
<i18n locale="en">
{
  "title": "{componentInProject} - Edit datapoints",
  "replace_confirmation_text": "Should the datapoint really be replaced?",
  "replace_confirmation_title": "Replace datapoint",
  "unmap_confirmation_text": "Should the assignment of the datapoint to the component really be removed?",
  "unmap_confirmation_title": "Remove assignment"
}
</i18n>

<template>
  <v-dialog
    content-class="dialog-predictable-height"
    persistent
    scrollable
    :model-value="true"
  >
    <v-card class="mapping-editor">
      <v-card-title class="text-h5 pa-4">
        {{ t('title', { componentInProject: componentInProject.title } ) }}
      </v-card-title>
      <v-card-text
        class="px-1"
        style="height: 90vh;"
      >
        <div class="relative__wrapper">
          <div class="absolute__wrapper">
            <DataPointsView
              component-pin-mode
            >
              <ComponentPins
                class="mb-6"
                :component-alphanumeric-id="componentAlphanumericId"
                :component-in-project-name="componentInProjectName"
                :component-name="componentName"
                :loading="loading"
                :pins="pins"
                :pins-with-pending-mapping-updates="pinsWithPendingMappingUpdates"
                @component-pin-card:click-datapoint="toggleDatapoint"
                @component-pin-card:map="mapDatapoint"
                @component-pin-card:replace="onReplaceDatapointRequested"
                @component-pin-card:unmap="onUnmapDatapointRequested"
                @component-pins:pin-selected="onPinSelected"
              />
            </DataPointsView>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary-darken2"
          variant="text"
          @click="close"
        >
          {{ t('actions.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmationDialog
      v-model="showReplaceConfirmationDialog"
      :title="t('replace_confirmation_title')"
      @confirmation-dialog:accept="replaceDatapoint"
      @confirmation-dialog:cancel="abortReplaceDatapoint"
    >
      {{ t('replace_confirmation_text') }}
    </ConfirmationDialog>
    <ConfirmationDialog
      v-model="showUnmapConfirmationDialog"
      :title="t('unmap_confirmation_title')"
      @confirmation-dialog:accept="unmapDatapoint"
      @confirmation-dialog:cancel="abortUnmapDatapoint"
    >
      {{ t('unmap_confirmation_text') }}
    </ConfirmationDialog>
  </v-dialog>
</template>

<script lang="ts">
import { DatapointListItemData, PinInComponentInProject } from '@/vuex/datapoints/types'
import { defineComponent, PropType } from 'vue'
import { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import ComponentPins from '@/components/ComponentPins.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import DataPointsView from '@/views/DataPointsView/index.vue'
import { mapGetters } from 'vuex'
import { reportError } from '@/utils/helpers/errors'
import { textForLocale } from '@/utils/helpers/locale'
import { UpdateMappingPayload } from '@/vuex/mapping_editor/types'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'MappingEditor',

  components: {
    ComponentPins,
    ConfirmationDialog,
    DataPointsView,
  },

  props: {
    componentInProject: {
      required: true,
      type: Object as PropType<ComponentInProjectListItemData>,
    },
  },

  emits: ['mapping-editor:close'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      datapointToReplace: null as null|string,
      datapointToUnmap: null as null|string,
      mappingChanged: false,
      pinToUnmap: null as null|number,
      pinWithDatapointToReplace: null as null|number,
      selectedPin: null as null|PinInComponentInProject,
      showReplaceConfirmationDialog: false,
      showUnmapConfirmationDialog: false,
    }
  },

  computed: {
    ...mapGetters({
      componentInProjectWithContext: 'mapping_editor/componentInProjectWithContext',
      loading: 'mapping_editor/isLoading',
      pins: 'mapping_editor/pinsCardData',
      pinsWithPendingMappingUpdates: 'mapping_editor/pinsWithPendingMappingUpdates',
    }),

    componentAlphanumericId (): string|null {
      return this.componentInProjectWithContext?.component?.alphanumeric_id ?? null
    },

    componentInProjectName (): string {
      return this.componentInProject.title
    },

    componentName (): string|null {
      if (this.componentInProjectWithContext) {
        return textForLocale(this.componentInProjectWithContext.component!.nameDE, this.componentInProjectWithContext.component!.nameEN)
      } else {
        return null
      }
    },
  },

  created () {
    this.$store.dispatch('mapping_editor/fetchData', this.componentInProject.id)
  },

  methods: {
    abortReplaceDatapoint (): void {
      this.datapointToReplace = null
      this.pinWithDatapointToReplace = null
      this.showReplaceConfirmationDialog = false
    },

    abortUnmapDatapoint (): void {
      this.pinToUnmap = null
      this.showUnmapConfirmationDialog = false
    },

    close (): void {
      if (this.$store.getters['datapoints/selectedPinForRecommendations']) {
        this.$store.dispatch('datapoints/clear')
        this.$store.dispatch('data_points_view/clearFilters')
      }
      const updatedComponentInProject = this.mappingChanged ? this.componentInProjectWithContext : undefined
      this.$store.dispatch('mapping_editor/clear')
      this.$emit('mapping-editor:close', updatedComponentInProject)
    },

    mapDatapoint (pinId: number, datapointId: string): void {
      this.$store.dispatch('mapping_editor/mapDatapointToPin', {
        componentInProjectId: this.componentInProject.id,
        datapointId,
        pinId,
      } as UpdateMappingPayload)
      this.mappingChanged = true
    },

    onPinSelected (pinId: number|undefined) {
      this.$store.dispatch('data_points_view/clearFilters')
      if (pinId) {
        this.$store.commit('datapoints/SET_PIN_FOR_RECOMMENDATIONS', {
          componentInProjectId: this.componentInProject.id,
          pinId,
        } as PinInComponentInProject)
      } else {
        this.$store.commit('datapoints/SET_PIN_FOR_RECOMMENDATIONS', null)
      }
      this.$store.dispatch('datapoints/fetchDatapoints', 1)
    },

    onReplaceDatapointRequested (pinId: number, datapointId: string): void {
      this.pinWithDatapointToReplace = pinId
      this.datapointToReplace = datapointId
      this.showReplaceConfirmationDialog = true
    },

    onUnmapDatapointRequested (pinId: number, datapointId: string): void {
      this.pinToUnmap = pinId
      this.datapointToUnmap = datapointId
      this.showUnmapConfirmationDialog = true
    },

    replaceDatapoint (): void {
      this.mapDatapoint(this.pinWithDatapointToReplace!, this.datapointToReplace!)
      this.datapointToReplace = null
      this.pinWithDatapointToReplace = null
      this.showReplaceConfirmationDialog = false
    },

    async toggleDatapoint (datapoint: DatapointListItemData) {
      if (datapoint.selected) {
        this.$store.dispatch('data_points_view/deselectDatapoint', datapoint.hash_id)
      } else {
        try {
          await this.$store.dispatch('data_points_view/selectDatapoints', [datapoint.hash_id])
        } catch (error) {
          reportError(error)
        }
      }
    },

    unmapDatapoint (): void {
      this.$store.dispatch('mapping_editor/unmapDatapointFromPin', {
        componentInProjectId: this.componentInProject.id,
        datapointId: this.datapointToUnmap,
        pinId: this.pinToUnmap,
      } as UpdateMappingPayload)
      this.datapointToUnmap = null
      this.pinToUnmap = null
      this.showUnmapConfirmationDialog = false
      this.mappingChanged = true
    },
  },
})
</script>

<style lang="sass" scoped>
  .relative__wrapper
    position: relative
    height: 100%
    width: 100%

    .absolute__wrapper
      position: absolute
      left: 0px
      right: 0px
      top: 0px
      bottom: 0px

  .mapping-editor
    :deep(.datapoints-list)
      max-height: calc(100vh - 190px) !important
    :deep(.aside-list)
      margin-top: 20px
</style>

<style lang="sass">
  // override default dialog height so that child can be sized accordingly
  .dialog-predictable-height
    max-height: calc(100vh - 70px) !important
</style>
