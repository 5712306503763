import { AlertsResponse, KeyValuePair, RequestParams, SEVERITY, STATUS } from './types'

export class AlertaApi {
  /**
   * @internal
   * The base URL being used for all requests outgoing to the alerta API.
   */
  private _baseUrl: string

  /**
   * @internal
   * The access token being used for Bearer authorized requests outgoing to the alerta API.
   */
  private _accessToken: string|null

  /**
   * Create a new AlertaApi instance
   * @param baseUrl Alertas API URL.
   */
  constructor (baseUrl: string) {
    this._baseUrl = baseUrl
  }

  get baseUrl (): string|undefined {
    return this._baseUrl
  }

  get accessToken (): string|null|undefined {
    return this._accessToken
  }

  /**
   * @internal
   * Prepares a request object by setting specific headers and formatting query-parameters.
   * @param resource The resource to be requested, e.g. 'alerts'.
   * @param params Query parameters that will be set into the request object.
   * @returns Request object.
   */
  private prepareRequest (resource: string, params?: RequestParams, method = 'GET'): Request {
    const url: URL = new URL(`${this.baseUrl}/${resource}`)

    if (params) {
      for (const [key, value] of Object.entries(params)) {
        if (typeof value === 'number') {
          url.searchParams.append(key, value.toString())
        } else if (typeof value === 'string') {
          url.searchParams.append(key, value)
        } else if (typeof value === 'object' && Array.isArray(value)) {
          value.forEach((valueData: string) => {
            url.searchParams.append(key, valueData)
          })
        }
      }
    }

    const request: Request = new Request(url.toString(), { method })
    request.headers.append('Authorization', `Bearer ${this.accessToken}`)

    return request
  }

  /**
   * Sets the access token for all subsequent requests against the alerta API.
   * @param token The access token to be set for Bearer authorized requests.
   */
  setAccessToken (token: string|null): void {
    this._accessToken = token
  }

  /**
   * Fetch alerts with optional filters being applied.
   * @param page The page to be displayed of total items available.
   * @param status Filter the requested alerts for specific status.
   * @param severities Filter the requested alerts for specific severities.
   * @param tags Filter the requested alerts for specific tags.
   * @param attribute Filter the requested alerts for a specific attribute.
   * @param query A query string used to filter alerts. For the syntax, see https://docs.alerta.io/api/query-syntax.html.
   * @returns AlertsResponse - A list of alerts with additional meta information.
   */
  async getAlerts (
    page?: number,
    severities?: SEVERITY[],
    status?: STATUS[],
    tags?: string[],
    attribute?: KeyValuePair,
    query?: string,
  ): Promise<AlertsResponse> {
    const params: RequestParams = {
      page: page ?? undefined,
      q: query ?? undefined,
      severity: severities ?? undefined,
      status: status ?? undefined,
      tags: tags ?? undefined,
    }

    if (attribute?.key && attribute?.value) {
      params[`attributes.${attribute.key}`] = attribute.value
    }

    const request = this.prepareRequest('alerts', params)
    request.headers.append('Accept', 'application/json')

    const result: Response = await fetch(request)

    if (!result.ok) {
      throw result
    }

    const resultJson: AlertsResponse = await result.json()

    return resultJson
  }
}

export const Alerts = new AlertaApi(window.configuration.ALERTA_API_URL)
