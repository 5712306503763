import { Project, ProjectWithContext } from '@aedifion.io/aedifion-api'
import { MutationTree } from 'vuex'
import { ProjectsState } from './types'

export default {
  ADD_OR_UPDATE_PROJECT: (state: ProjectsState, project: ProjectWithContext) => {
    const index = state.projects.findIndex((item: ProjectWithContext) => {
      return item.project?.id === project.project!.id
    })
    if (index >= 0) {
      state.projects[index] = project
    } else {
      state.projects.push(project)
    }
  },

  SELECT_PROJECT: (state: ProjectsState, projectId: number) => {
    state.currentProjectId = projectId
  },

  SET_LOADING: (state: ProjectsState, loading: boolean) => {
    state.loading = loading
  },

  SET_PROJECTS: (state: ProjectsState, projects: ProjectWithContext[]) => {
    state.projects = (!projects || projects === null) ? state.projects : projects
  },

  UPDATE_PROJECT: (state: ProjectsState, project: Project) => {
    const index = state.projects.findIndex((item: ProjectWithContext) => {
      return item.project?.id === project.id
    })
    if (index >= 0) {
      state.projects[index].project = project
    }
  },
} as MutationTree<ProjectsState>
