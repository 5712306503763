<script setup lang="ts">
interface Props {
  color?: string,
  disabled?: boolean,
  icon?: string,
  link?: string,
  loading?: boolean,
  selectable?: boolean,
  selected?: boolean,
  title: string
}

withDefaults(defineProps<Props>(), {
  loading: false,
  selectable: false,
  selected: false,
})

const emits = defineEmits(['action-card:click'])
</script>

<template>
  <v-card
    class="d-flex flex-column"
    :class="{'aedifion-active': selected, '_disabled-cursor': disabled}"
    v-on="selectable ? { click: () => emits('action-card:click') } : {}"
  >
    <v-card-title
      v-if="!loading"
      class="pa-4 text-h6"
    >
      <div
        class="d-flex flex-row align-center text-left"
      >
        <v-icon
          v-if="icon"
          :class="['mr-3', {'_disabled': disabled}]"
          :color="color"
          :size="20"
        >
          {{ icon }}
        </v-icon>
        <v-tooltip location="top">
          <template #activator="{ props }">
            <span
              :class="['text-truncate', {'_disabled': disabled}]"
              style="line-height: normal"
              v-bind="props"
              v-html="title"
            />
          </template>
          <span v-html="title" />
        </v-tooltip>
        <v-spacer v-if="link" />
        <v-btn
          v-if="link"
          :href="link"
          icon
          height="28"
          width="28"
          class="bg-transparent"
          rounded="rounded-circle"
          size="small"
          target="_blank"
          @click.stop
        >
          <v-icon
            color="neutral-darken1"
            size="small"
          >
            fa:far fa-info-circle
          </v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <v-card-text
      class="px-4 pb-0 flex-grow-1 flex-shrink-0 text-neutral-darken2"
    >
      <v-skeleton-loader
        v-if="loading"
        type="paragraph"
      />
      <div
        v-else
        class="fill-height"
      >
        <slot />
      </div>
    </v-card-text>

    <v-card-actions
      v-if="$slots.actions"
      class="px-4 py-0"
    >
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<style lang="sass" scoped>
  .v-card-title
    div.d-flex
      width: 100%

  ._disabled
    opacity: 0.5

  ._disabled-cursor
    cursor: auto
</style>
