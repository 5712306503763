<script setup lang="ts">
import { computed, ref } from 'vue'
import { DataRow, DataType, SelectOption } from '@/components/DataCard/types'
import { useI18n } from 'vue-i18n'

const { locale, t } = useI18n()

interface Props {
  editMode: boolean;
  row: DataRow;
}

const props = defineProps<Props>()

const hasFocus = ref(false)

const selectItems = computed<SelectOption[]>(() => {
  if (props.row.selectOptions) {
    const emptyItem: SelectOption = {
      title: t('none') as string,
      value: '',
    }
    return [emptyItem, ...props.row.selectOptions]
  } else {
    return []
  }
})

const selectValue = computed<string>(() => {
  return props.row.value ?? ''
})

const value = computed<string|undefined>(() => {
  let newValue: string
  if (props.editMode && hasFocus.value) {
    newValue = props.row.value ?? ''
  } else {
    if (props.row.value) {
      newValue = props.row.value
      if (props.row.type === DataType.Number) {
        newValue = Number(newValue).toLocaleString(locale.value)
      } else if (props.row.type === DataType.Year) {
        if (newValue.includes('.')) {
          newValue = newValue.substring(0, newValue.indexOf('.'))
        }
      }
      if (props.row.unit) {
        newValue = newValue.concat(' ', props.row.unit)
      }
    } else {
      newValue = ''
    }
  }
  return newValue
})

function onBlur (): void {
  hasFocus.value = false
}

/**
 * Handles the change event of the input field.
 * @param newValue Emits this value on change.
 */
function onChange (newValue: string): void {
  if (newValue !== props.row.value) {
    let cleanedValue = newValue
    if (props.row.type === DataType.Number) {
      cleanedValue = cleanedValue.replace(/\D/g, '')
    } else if (props.row.unit) {
      cleanedValue = cleanedValue.replace(props.row.unit, '')
    }
    cleanedValue = cleanedValue.trim()
    emits('change', props.row.key, cleanedValue)
  }
}

function onFocus (): void {
  hasFocus.value = true
}

/**
 * Prevents the default behaviour of the keydown event for a specified set of keys.
 * @param event The keydown event.
 */
function onKeydown (event: KeyboardEvent): void {
  if (props.row.type === DataType.Number || props.row.type === DataType.Year) {
    const allowedKeys = [
      '\\d', // digits
      '\\s', // whitespace characters
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowUp',
      'ArrowRight',
      'ArrowDown',
    ]
    if (!event.metaKey && !event.ctrlKey && !RegExp(`(${allowedKeys.join('|')})`).test(event.key)) {
      event.preventDefault()
    }
  }
}

const emits = defineEmits<{
  (e: 'change', key: string, newValue: string): void;
  (e: 'save'): void;
}>()
</script>

<template>
  <div class="data-card-input__wrapper">
    <v-select
      v-if="row.type === DataType.Selection"
      :model-value="selectValue"
      :menu-icon="editMode ? undefined : 'mdi-chevron-down'"
      :class="['font-weight-semibold', { 'normal-cursor': !editMode }]"
      data-test-id="data-card-select"
      density="compact"
      :error-messages="row.error"
      flat
      hide-details="auto"
      :items="selectItems"
      :variant="editMode ? 'outlined' : 'solo'"
      :readonly="!editMode"
      @update:model-value="onChange"
    />
    <v-text-field
      v-else-if="row.type === DataType.Number || row.type === DataType.Year"
      :class="['font-weight-semibold', { 'normal-cursor': !editMode }]"
      color="primary"
      data-test-id="data-card-input"
      density="compact"
      :error-messages="row.error"
      flat
      hide-details="auto"
      :variant="editMode ? 'outlined' : 'solo'"
      :readonly="!editMode"
      :suffix="editMode && hasFocus && row.unit ? row.unit : ''"
      :model-value="value"
      @blur="onBlur"
      @update:model-value="onChange"
      @focus="onFocus"
      @keydown="onKeydown"
      @keydown.enter.prevent="emits('save')"
    />
    <v-textarea
      v-else
      auto-grow
      :class="['font-weight-semibold', { 'normal-cursor': !editMode }]"
      color="primary"
      data-test-id="data-card-textarea"
      density="compact"
      :error-messages="row.error"
      flat
      hide-details="auto"
      no-resize
      :variant="editMode ? 'outlined' : 'solo'"
      :readonly="!editMode"
      rows="1"
      :suffix="editMode && hasFocus && row.unit ? row.unit : ''"
      :model-value="value"
      @blur="onBlur"
      @update:model-value="onChange"
      @focus="onFocus"
      @keydown.enter.exact.prevent="emits('save')"
    />
  </div>
</template>

<style lang="sass" scoped>
  :deep(.normal-cursor .v-select__slot)
    cursor: default !important

  :deep(.normal-cursor .v-input__slot)
    cursor: default !important

  :deep(.normal-cursor .v-input__slot textarea)
    cursor: default !important

  :deep(.normal-cursor .v-input__slot input)
    cursor: default !important

  :deep(.v-field__input)
    font-size: 14px !important
    line-height: 1.75rem
</style>

<i18n locale="de">
{
  "none": "Keines"
}
</i18n>
<i18n locale="en">
{
  "none": "None"
}
</i18n>
