<script setup lang="ts">
import { CompanyRoles, CompanyWithContextProjects } from '@aedifion.io/aedifion-api'
import { computed, ref } from 'vue'
import LabelDivider from '@/components/LabelDivider.vue'
import { ProjectRoleId } from '@/stores/views/Administration/users'
import { useI18n } from 'vue-i18n'

type ProjectRole = {
  projectId: number
  projectName: string
  roleId: number
  roleName: string
}

interface Props {
  companyRoles: number[]
  companyRolesFromStore: CompanyRoles[]
  loading: boolean
  projectRoles: ProjectRoleId[]
  projects: CompanyWithContextProjects[]
}

// --- definition ---

const { t } = useI18n()

const props = withDefaults(defineProps<Props>(), {
  companyRoles: () => [],
  companyRolesFromStore: () => [],
  loading: false,
  projectRoles: () => [],
  projects: () => [],
})

const emit = defineEmits<{
  (e: 'company-roles-changed', newCompanyRoles: number[]): void;
  (e: 'project-roles-changed', newProjectRoles: ProjectRoleId[]): void;
}>()

// project roles

const projects = computed<CompanyWithContextProjects[]>(() => {
  return props.projects
    .slice()
    .sort((left, right) => left.name!.localeCompare(right.name!))
})

const usersProjectRoles = ref<ProjectRoleId[]>(addRoleAndProjectName(props.projectRoles) ?? [])

const userProjectRolesForSelectedProject = computed<ProjectRole[]>(() => {
  return selectedProjectRoles.value.filter((projectRole) => projectRole.projectId === currentProjectId.value)
})

function addRoleAndProjectName (projectRoles: ProjectRoleId[]): ProjectRole[] {
  return projectRoles.map((projectRole) => {
    const project = projects.value.find((project) => project.id === projectRole.projectId)
    return {
      projectId: projectRole.projectId,
      projectName: project?.name ?? '',
      roleId: projectRole.roleId,
      roleName: project?.roles?.find((role) => role.id === projectRole.roleId)?.name ?? '',
    }
  })
}

const currentProjectId = ref<number|null>(null)
const currentProject = computed<CompanyWithContextProjects | null>(() => {
  if (currentProjectId.value === null) return null
  return projects.value.find((project) => project.id === currentProjectId.value) ?? null
})

const availableRolesForCurrentProject = computed<ProjectRole[]>(() => {
  return currentProject.value?.roles?.map((projectRole) => {
    return {
      projectId: currentProject.value!.id!,
      projectName: currentProject.value!.name,
      roleId: projectRole.id!,
      roleName: projectRole.name!,
    }
  }) ?? []
})

const selectedProjectRoles = computed<ProjectRole[]>(() => {
  const result: ProjectRole[] = []
  for (const project of projects.value) {
    for (const role of project.roles || []) {
      if (usersProjectRoles.value.some((projectRoleId) => projectRoleId.roleId === role.id)) {
        result.push({
          projectId: project.id!,
          projectName: project.name,
          roleId: role.id!,
          roleName: role.name!,
        })
      }
    }
  }
  return result
})

function updateProjectRoles (newProjectRoles: ProjectRole[]) {
  const updatedProjectRoles = [
    ...usersProjectRoles.value.filter((projectRole) => projectRole.projectId !== currentProjectId.value),
    ...newProjectRoles.map((projectRole) => {
      return {
        projectId: projectRole.projectId,
        roleId: projectRole.roleId,
      } as ProjectRoleId
    }),
  ]
  emit('project-roles-changed', updatedProjectRoles)
  usersProjectRoles.value = updatedProjectRoles
}

function removeProjectRole (roleId: number) {
  const filteredProjectRoles = usersProjectRoles.value.filter((projectRole) => projectRole.roleId !== roleId)
  emit('project-roles-changed', filteredProjectRoles)
  usersProjectRoles.value = filteredProjectRoles
}
</script>

<template>
  <div>
    <v-row>
      <v-col
        cols="12"
      >
        <LabelDivider :text="t('organization_level')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        data-testid="user-roles-editor-column-company"
      >
        <v-select
          chips
          color="primary-darken2"
          data-testid="user-roles-editor-company-roles"
          :disabled="props.loading || props.companyRolesFromStore.length === 0"
          hide-details="auto"
          item-title="name"
          item-value="id"
          :items="props.companyRolesFromStore"
          :label="t('company_roles')"
          :menu-props="{ contentClass: 'aedifion-border' }"
          multiple
          variant="filled"
          :model-value="props.companyRoles"
          @update:model-value="emit('company-roles-changed', $event)"
          @keydown.enter.prevent
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
      >
        <LabelDivider :text="t('project_level')" />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        data-testid="user-roles-editor-column-project"
        sm="6"
      >
        <v-select
          v-model="currentProjectId"
          color="primary-darken2"
          data-testid="user-roles-editor-project"
          :disabled="props.loading || projects.length === 0"
          hide-details="auto"
          item-title="name"
          item-value="id"
          :items="projects"
          :label="t('project')"
          :menu-props="{ contentClass: 'aedifion-border' }"
          variant="filled"
          @keydown.enter.prevent
        />
      </v-col>

      <v-col
        cols="12"
        data-testid="user-roles-editor-column-project-roles"
        sm="6"
      >
        <v-select
          data-testid="user-roles-editor-project-roles"
          color="primary-darken2"
          :disabled="props.loading || projects.length === 0 || availableRolesForCurrentProject.length === 0"
          hide-details="auto"
          item-title="roleName"
          item-value="roleId"
          :items="availableRolesForCurrentProject"
          :label="t('roles')"
          :menu-props="{ contentClass: 'project-roles-menu' }"
          multiple
          variant="filled"
          return-object
          :model-value="userProjectRolesForSelectedProject"
          @update:model-value="updateProjectRoles($event)"
          @keydown.enter.prevent
        />
      </v-col>
    </v-row>

    <v-data-table
      v-show="selectedProjectRoles.length"
      class="mt-2"
    >
      <thead>
        <tr>
          <th>{{ t('project') }}</th>
          <th>{{ t('role') }}</th>
          <th>{{ t('actions') }}</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(projectRole, index) in selectedProjectRoles"
          :key="projectRole.roleId"
          :data-testid="`user-roles-editor-project-roles-table.row.${index}`"
        >
          <td :data-testid="`user-roles-editor-project-roles-table.row.${index}.project`">
            {{ projectRole.projectName }}
          </td>
          <td :data-testid="`user-roles-editor-project-roles-table.row.${index}.role`">
            <v-chip size="small">
              {{ projectRole.roleName }}
            </v-chip>
          </td>
          <td>
            <v-tooltip location="left">
              <template #activator>
                <v-btn
                  data-cy="remove-project-role"
                  :disabled="props.loading"
                  icon
                  variant="text"
                  @click="removeProjectRole(projectRole.roleId)"
                >
                  <v-icon
                    size="x-small"
                    color="neutral-darken1"
                  >
                    fa:fas fa-trash
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ t('actions.remove') }}</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
      <template #bottom />
    </v-data-table>
  </div>
</template>

<i18n locale="de">
{
  "actions": "Aktionen",
  "company_roles": "Firmenrollen",
  "project": "Projekt",
  "role": "Rolle",
  "roles": "Rollen",
  "organization_level": "Organisatorische Ebene",
  "project_level": "Projekt Ebene"
}
</i18n>
<i18n locale="en">
{
  "actions": "Actions",
  "company_roles": "Company roles",
  "project": "Project",
  "role": "Role",
  "roles": "Roles",
  "organization_level": "Organization Level",
  "project_level": "Project Level"
}
</i18n>
