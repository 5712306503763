import { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { RootState } from '@/vuex/types'
import { Store } from 'vuex'
import { useAppStore } from '@/stores/app'

export const projectGuardMiddleware: (store: Store<RootState>) => NavigationGuard = (
  store: Store<RootState>,
) => async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!to?.meta?.requiresProject) {
    return next()
  }

  let projectId: number|null = null

  if (to?.params?.project) {
    projectId = parseInt(to.params.project as string)
  } else if (to?.meta?.project) {
    projectId = to.meta.project as number
  }

  if (projectId === null) {
    return next({ name: 'page-not-found' })
  }

  if (projectId !== store.getters['projects/currentProjectId']) {
    try {
      await store.dispatch('clearProjectDependentStores')
      const payload = {
        projectId,
        showNotification: false,
      }
      await store.dispatch('projects/selectProject', payload)
      useAppStore().setProjectId(projectId)
    } catch (error) {
      return next({ name: 'page-not-found' })
    }
  }
  next()
}
