<script setup lang="ts">
import { computed, ref } from 'vue'
import { FilterSelectItem } from './types'

interface Props {
  dense?: boolean,
  disabled?: boolean,
  extensible?: boolean,
  icon?: string,
  items: FilterSelectItem[],
  label?: string,
  menuProps?: Record<string, unknown>,
  modelValue?: string | number,
  placeholder?: string
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  dense: false,
  disabled: false,
  extensible: false,
  // defaults copied from https://vuetifyjs.com/en/api/v-select/#props-menu-props
  menuProps: () => ({
    closeOnContentClick: false,
    disableKeys: true,
    maxHeight: 304,
    openOnClick: false,
  }),
})

const emit = defineEmits<{
  (event: 'change', params: FilterSelectItem): void
  (event: 'update:modelValue', params: string | number): void
}>()

const customSelectedItem = ref<string | null>(null)
const filter = ref('')

const filteredItems = computed(() => {
  let result: FilterSelectItem[] = props.items

  if (filter.value.length > 0) {
    result = props.items.filter((item) => {
      return item.title.toLowerCase().includes(filter.value.toLocaleLowerCase())
    })
  }

  if (props.extensible) {
    if (filter.value.length > 0 && !result.some(tag => tag.title === filter.value)) {
      result.unshift({
        title: filter.value,
        value: filter.value,
      })
    }
    if (customSelectedItem.value !== null && !result.some(tag => tag.title === customSelectedItem.value)) {
      result.unshift({
        title: customSelectedItem.value,
        value: customSelectedItem.value,
      })
    }
  }

  return result
})

const inputVal = computed({
  get () {
    return props.modelValue
  },
  set (val: string | number | undefined) {
    if (val !== undefined) {
      emit('update:modelValue', val)
    }
  },
})

function onItemSelected (selectedItem: FilterSelectItem) {
  if (props.extensible && !props.items.some((item) => item.title === selectedItem.title)) {
    customSelectedItem.value = filter.value
  }

  filter.value = ''
  emit('change', selectedItem)
}
</script>

<template>
  <v-select
    v-model="inputVal"
    data-testid="filter-select"
    :density="props.dense ? 'compact' : undefined"
    :disabled="props.disabled"
    hide-details
    item-title="title"
    :items="filteredItems"
    :label="props.label"
    color="primary-darken2"
    :menu-props="{
      closeOnContentClick: props.menuProps?.closeOnContentClick ?? false,
      maxHeight: props.menuProps?.maxHeight,
      maxWidth: props.menuProps?.maxWidth,
      offset: [props.menuProps?.offsetX ?? 0, props.menuProps?.offsetY ?? 0],
    }"
    variant="outlined"
    return-object
    :style="`max-width: ${props.menuProps.maxWidth}px`"
    @update:model-value="onItemSelected"
  >
    <template
      v-if="props.icon"
      #prepend-inner
    >
      <v-icon
        class="mr-2"
      >
        {{ props.icon }}
      </v-icon>
    </template>
    <template #prepend-item>
      <v-text-field
        v-model="filter"
        data-testid="filter-select-input"
        autofocus
        class="pt-0 pb-4 px-4"
        hide-details
        :placeholder="props.placeholder"
      />
    </template>
    <template #item="{ item, props: itemProps }">
      <v-list-item
        v-bind="itemProps"
        :lines="item.raw.subtitle !== undefined ? 'two' : 'one'"
        :title="item.raw.title"
        :value="item.raw.value"
        :subtitle="item.raw?.subtitle"
        :disabled="item.raw.disabled"
      />
    </template>
  </v-select>
</template>
