import { OptimizationState } from './types'
import moment from 'moment'

export default {
  dateRange: [
    moment.utc().subtract(7, 'd').toISOString().split('T')[0],
    moment.utc().toISOString().split('T')[0],
  ],
  followAnalysisPeriod: false,
  loadingComponentInProject: false,
  loadingComponentsAnalysisResults: false,
  search: null,
  selectedComponentInProject: null,
  selectedComponentInProjectResults: null,
  selectedInstanceId: null,
  selectedResultId: null,
  samplerate: 'auto',
  hiddenPinsHashIds: [],
} as OptimizationState

export function resetStoreState (state: OptimizationState): void {
  state.dateRange = [
    moment.utc().subtract(7, 'd').toISOString().split('T')[0],
    moment.utc().toISOString().split('T')[0],
  ]
  state.followAnalysisPeriod = false
  state.loadingComponentsAnalysisResults = false
  state.loadingComponentInProject = false
  state.search = null
  state.selectedComponentInProject = null
  state.selectedComponentInProjectResults = null
  state.selectedInstanceId = null
  state.selectedResultId = null
  state.samplerate = 'auto'
  state.hiddenPinsHashIds = []
}
