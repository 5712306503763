import { AnalysisInstancesState } from './types'

export default {
  analysisResult: null,
  analysisResults: null,
  instanceList: [],
  loading: true,
  loadingAnalysisResult: false,
  loadingAnalysisResults: false,
  pendingInstanceUpdates: [],
  selectedInstance: null,
} as AnalysisInstancesState

export function resetStoreState (state: AnalysisInstancesState): void {
  state.analysisResult = null
  state.analysisResults = null
  state.instanceList = []
  state.loading = true
  state.loadingAnalysisResult = false
  state.loadingAnalysisResults = false
  state.pendingInstanceUpdates = []
  state.selectedInstance = null
}
