import { Endpoint } from '@aedifion.io/aedifion-api'
import { EndpointsState } from './types'
import { MutationTree } from 'vuex'

export default {
  SET_ENDPOINTS: (state: EndpointsState, endpoints: Endpoint[]) => {
    state.endpoints = endpoints
  },
  SET_LOADING: (state: EndpointsState, loading: boolean) => {
    state.loading = loading
  },
} as MutationTree<EndpointsState>
