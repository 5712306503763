import { AnalyticsResultsForComponentResultIds, ComponentInProjectAttribute, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { BuildingAnalysesState } from './types'
import { getComponentAttributeByAlphanumericId } from '@/utils/helpers/componentAttribute'
import { GetterTree } from 'vuex'
import { IncidentConfiguration } from '@/vuex/status_and_alerts/types'
import { RootState } from '../types'
import { validateNotNullish } from '@/utils/helpers/validate'

export default {
  allAnalysesOfProject: (state: BuildingAnalysesState) => (projectId: number): AnalyticsResultsForComponentResultIds[]|null => {
    return state.buildingAnalysesResults[projectId]?.result_ids ?? null
  },

  analysisOfProject: (state: BuildingAnalysesState) => (projectId: number, alphanumericId: string): AnalyticsResultsForComponentResultIds|null => {
    if (!(projectId in state.buildingAnalysesResults)) {
      return null
    }
    return state.buildingAnalysesResults[projectId].result_ids?.find(
      (item: AnalyticsResultsForComponentResultIds) => item.alphanumeric_id === alphanumericId,
    ) ?? null
  },

  areAnalysesOfProjectLoaded: (state: BuildingAnalysesState) => (projectId: number): boolean => {
    return projectId in state.buildingAnalysesResults
  },

  buildingComponentOfCurrentProject: (_state: BuildingAnalysesState, getters, _rootState, rootGetters): ComponentInProjectWithContext|null => {
    const projectId: number = validateNotNullish(rootGetters['projects/currentProjectId'])
    return getters.buildingComponentOfProject(projectId)
  },

  buildingComponentOfProject: (state: BuildingAnalysesState) => (projectId: number): ComponentInProjectWithContext|null => {
    return state.buildingComponents[projectId] ?? null
  },

  incidentConfigurationsOfProject: (state: BuildingAnalysesState) => (projectId: number): IncidentConfiguration[] => {
    if (!(projectId in state.buildingComponents)) {
      return []
    }
    const incidentConfigurationAttribute: ComponentInProjectAttribute|null = getComponentAttributeByAlphanumericId(state.buildingComponents[projectId], 'B_EVS')
    let result: IncidentConfiguration[] = []
    if (incidentConfigurationAttribute !== null) {
      result = JSON.parse(incidentConfigurationAttribute.value!)
    }
    return result
  },

  isBuildingComponentOfProjectLoaded: (state: BuildingAnalysesState) => (projectId: number): boolean => {
    return projectId in state.buildingComponents
  },

  isLoadingAnalysesOfProject: (state: BuildingAnalysesState) => (projectId: number): boolean => {
    return state.loadingProjectBuildingAnalyses.includes(projectId)
  },

  isLoadingBuildingComponentOfProject: (state: BuildingAnalysesState) => (projectId: number): boolean => {
    return state.loadingProjectBuildingComponent.includes(projectId)
  },

  projectUsesSampleData: (state: BuildingAnalysesState) => (projectId: number): boolean => {
    return state.projectsWithSampleData.includes(projectId)
  },
} as GetterTree<BuildingAnalysesState, RootState>
