<script setup lang="ts">
import DropDown, { DropDownItem } from '@/components/DropDown.vue'
import type { Attribute } from '@/vuex/component_attributes_editor/types'
import ComponentAttributeBaseRow from './ComponentAttributeBaseRow.vue'
import { computed } from 'vue'
import { DataRow } from '@/components/DataCard/types'
import vuexStore from '@/vuex'

interface Props {
  attribute: Attribute
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'update:attribute': [ value: Attribute ]
}>()

function handleUpdateCertificateType (newValue: string) {
  emits('update:attribute', { ...props.attribute, new_value: newValue })
}

const certificationsData = computed<DataRow[]>(() => (vuexStore.getters['asset_profile/certificationsData']))

const certsDgnbSelectItems = computed<DropDownItem[]>(() => {
  return certificationsData.value.find((cert: DataRow) => cert.key === 'B+CERTS_DGNB')?.selectOptions as DropDownItem[] || []
})
const certsLeedSelectItems = computed<DropDownItem[]>(() => {
  return certificationsData.value.find((cert: DataRow) => cert.key === 'B+CERTS_LEED')?.selectOptions as DropDownItem[] || []
})
const certsWScoreSelectItems = computed<DropDownItem[]>(() => {
  return certificationsData.value.find((cert: DataRow) => cert.key === 'B+CERTS_WSCORE')?.selectOptions as DropDownItem[] || []
})

function certificationSelectItems (certification: string): DropDownItem[] {
  switch (certification) {
    case 'B+CERTS_DGNB':
      return certsDgnbSelectItems.value
    case 'B+CERTS_LEED':
      return certsLeedSelectItems.value
    case 'B+CERTS_WSCORE':
      return certsWScoreSelectItems.value
    default: return []
  }
}
</script>

<template>
  <ComponentAttributeBaseRow
    :attribute="attribute"
  >
    <template #custom-input>
      <DropDown
        :items="certificationSelectItems(attribute.alphanumeric_id)"
        :select-first-item="false"
        color="neutral-darken2"
        :value="attribute.value"
        @update:value="handleUpdateCertificateType"
      />
    </template>
  </ComponentAttributeBaseRow>
</template>
