import { SemanticColor } from '@/filters/colors'

export function getCo2Color (value: number|null) {
  if (value === null) {
    return null
  }

  if (value <= 25) {
    return SemanticColor.Green
  } else if (value <= 50) {
    return SemanticColor.Yellow
  }
  return SemanticColor.Red
}

export function getEnergyColor (value: number|null) {
  if (value === null) {
    return null
  }

  if (value <= 50) {
    return SemanticColor.Green
  } else if (value <= 100) {
    return SemanticColor.Yellow
  }
  return SemanticColor.Red
}
