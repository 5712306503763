import { AnalysisOverviewState } from './types'

export default {
  loadingOverview: false,
  overviewItems: [],
  overviewSummary: null,
} as AnalysisOverviewState

export function resetStoreState (state: AnalysisOverviewState): void {
  state.loadingOverview = false
  state.overviewItems = []
  state.overviewSummary = null
}
