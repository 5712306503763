import { stringifyForLogging } from './errors'

export class InvalidValueError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'InvalidValueError'
  }
}

/**
 * Creates an error string with a default template for use with InvalidValueError.
 * @param reason a short explanation as to why the error was raised
 * @param value the value that caused the error to be triggered
 * @returns the error string
 */
export function createErrorString (reason: string, value?: unknown) {
  let type: string = typeof value
  if (value === null) {
    type = 'null'
  }
  if (type === 'object') {
    type = (value as object).constructor.name
  }
  value = stringifyForLogging(value, { explicitNullish: true }) ?? '<empty>'
  return `${reason}\nValue: ${value} (${type})`
}

export type CheckHelperOptions = {
  errorMessage?: string
}

/**
 * Checks that the two values passed are not equal, and if they are, raises an InvalidValueError.
 * @param valueToTest the variable that we want to check
 * @param prohibitedValue the value that we don’t want valueToTest to be equal to
 * @param options a set of options to tweak the behavior of the helper function
 * - `errorMessage` (string): a custom error message to use if the InvalidValueError is thrown
 * @returns the value that was tested
 */
export function validateNotEqual <T> (valueToTest: T, prohibitedValue: T, options?: CheckHelperOptions): T {
  if (valueToTest === prohibitedValue) {
    const errorMessage: string = options?.errorMessage ?? createErrorString('Check failed: the value that was checked is prohibited.', valueToTest)
    throw new InvalidValueError(errorMessage)
  }

  return valueToTest
}

/**
 * Checks that the value passed is not nullish (`null` or `undefined`), and if it is, raises an InvalidValueError.
 * @param value the variable that we want to check
 * @param options a set of options to tweak the behavior of the helper function
 * - `errorMessage` (string): a custom error message to use if the InvalidValueError is thrown
 * @returns the value that was tested
 */
export function validateNotNullish <T> (value: T, options?: CheckHelperOptions): Exclude<T, null | undefined> {
  if (value === null || value === undefined) {
    const errorMessage: string = options?.errorMessage ?? createErrorString('Check failed: the value that was checked must be defined.', value)
    throw new InvalidValueError(errorMessage)
  }

  return value as Exclude<T, null | undefined>
}

/**
 * Validates that an array is not empty.
 * Throws an `InvalidValueError` if the array is empty.
 * @param array
 * @throws InvalidValueError
 * - `errorMessage` (string): a custom error message to use if the InvalidValueError is thrown
 * @returns the value that was tested
 */
export function validateNonEmptyArray<T> (array: T[]) {
  if (!Array.isArray(array)) {
    const errorMessage = createErrorString('The argument must be an array', array)
    throw new InvalidValueError(errorMessage)
  }

  if (array.length === 0) {
    const errorMessage = createErrorString('The array must not be empty', array)
    throw new InvalidValueError(errorMessage)
  }

  return array
}
