import { LocationQuery, RouteLocation, RouteLocationNormalized } from 'vue-router'
import { createNextLocation } from './helpers'
import { resetStoreState } from '@/vuex/asset_overview/state'
import { RootState } from '@/vuex/types'
import { Store } from 'vuex'

/**
 * Clears all asset overview values that are displayed in the route query from the store.
 * @param store vuex store.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function clearAssetOverviewStore (store: Store<RootState>): RouteLocation|void {
  resetStoreState(store.state.asset_overview)
}

/**
 * Checks the store and the query for asset overview values that should be displayed in the query and adds all that are missing or different.
 * @param store vuex store.
 * @param to Target route with the new query.
 * @returns a new location with updated query, if the query was changed.
 */
export function updateAssetOverviewQuery (store: Store<RootState>, to: RouteLocationNormalized): RouteLocation|void {
  const newQuery: LocationQuery = {}

  const storeSelectedInstanceId: number|null = store.state.asset_overview.selectedInstanceId
  if (storeSelectedInstanceId && storeSelectedInstanceId.toString() !== to.query.instance) {
    newQuery.instance = storeSelectedInstanceId.toString()
  }

  const storeSelectedResultId: string|null = store.state.asset_overview.selectedResultId
  if (storeSelectedResultId && storeSelectedResultId !== to.query.result) {
    newQuery.result = storeSelectedResultId
  }

  if (Object.keys(newQuery).length > 0) {
    return createNextLocation(to, newQuery)
  }
}

/**
 * Checks the query of the target route and if it contains values that differ from the stored values it updates the store.
 * @param store vuex store.
 * @param to Target route with values to be stored.
 */
export function updateAssetOverviewStore (store: Store<RootState>, to: RouteLocationNormalized): RouteLocation|void {
  if (to.query.instance && store.state.asset_overview.selectedInstanceId !== parseInt(to.query.instance as string)) {
    store.commit('asset_overview/SET_SELECTED_INSTANCE_ID', parseInt(to.query.instance as string))
  }

  if (to.query.result && store.state.asset_overview.selectedResultId !== to.query.result) {
    store.commit('asset_overview/SET_SELECTED_RESULT_ID', to.query.result)
  }
}
