import { UserManagerSettings } from 'oidc-client-ts'
import { VuexOidcClientSettings } from 'vuex-oidc'

export const oidcSettings: VuexOidcClientSettings & UserManagerSettings = {
  authority: window.configuration.OAUTH_AUTHORITY,
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  client_id: 'frontend',
  loadUserInfo: false,
  monitorSession: false,
  postLogoutRedirectUri: window.location.origin,
  redirect_uri: `${window.location.origin}/auth`,
  response_mode: 'query',
  responseType: 'code',
  scope: 'openid',
  silentRedirectUri: `${window.location.origin}/auth-silent-renew`,
}
