<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { docsLink, textForLocale } from '@/utils/helpers/locale'
import { FetchAnalysisResultPayload, ToggleInstancePayload } from '@/vuex/analysis_instances/types'
import ActionCard from '@/components/ActionCard.vue'
import AnalysisResultDetails from './AnalysisResultDetails.vue'
import { ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { CondensedAnalysisFunctionWithResult } from '@/vuex/optimization/getters'
import { getIconbyAlphanumericId } from '@/utils/helpers/icons'
import i18n from '@/i18n'
import { InvalidValueError } from '@/utils/helpers/validate'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { sortAnalysisFunctions } from '@/utils/helpers/analysisFunctions'
import TabbedContainer from '@/components/TabbedContainer/TabbedContainer.vue'
import TabbedContainerElement from '@/components/TabbedContainer/TabbedContainerElement.vue'
import { useI18n } from 'vue-i18n'
import useIsReadOnly from '@/composables/useIsReadOnly'
import { useRoute } from 'vue-router'
import { useStore } from '@/vuex'
import vuetify from '@/plugins/vuetify'
import { whitelabelColor } from '@/filters/colors'

// --- definition ---
type ExtendedAnalysisFunctionWithResult = CondensedAnalysisFunctionWithResult & {
  icon: string|null;
  link: string|null;
}

const route = useRoute()
const store = useStore()
const { t } = useI18n()
const { isReadOnly } = useIsReadOnly()

const showAllAnalysisFunctions = ref(false)

const amountOfAnalysisCards = computed<number>(() => {
  return (store.getters['optimization/getAnalysisFunctionsWithResults'] ?? []).length
})

const analysisFunctionsWithResults = computed<Array<ExtendedAnalysisFunctionWithResult>>(() => {
  const sortedAnalysisFunctions = sortAnalysisFunctions((store.getters['optimization/getAnalysisFunctionsWithResults'] ?? []))
  const result: Array<ExtendedAnalysisFunctionWithResult> = []
  for (const analysisFunction of sortedAnalysisFunctions) {
    if (showAllAnalysisFunctions.value || analysisFunction.enabled) {
      result.push({
        ...analysisFunction,
        icon: getIconbyAlphanumericId(analysisFunction.function_alphanumeric_id),
        link: docsLink(`analysis.${analysisFunction.function_alphanumeric_id}`),
      })
    }
  }
  return result
})

const isLoadingResources = computed<boolean>(() => {
  return store.getters['components_in_project/isLoadingComponentInProjectWithContext'] ||
    store.getters['analysis_instances/isLoadingInstances'] ||
    store.getters['analysis_functions/isLoadingAnalysisFunctions'] ||
    store.getters['optimization/isLoadingComponentInProjectResults']
})

const pendingInstanceUpdates = computed<number[]>(() => {
  return store.getters['analysis_instances/pendingInstanceUpdates']
})

const selectedComponentInProject = computed<ComponentInProjectWithContext|null>(() => {
  return store.state.optimization.selectedComponentInProject
})

const selectedInstanceId = computed<number|null|string>(() => {
  return store.state.optimization.selectedInstanceId
})

const showButtonToShowAllAnalyses = computed<boolean>(() => {
  const allAnalysisFunctions: Array<CondensedAnalysisFunctionWithResult>|null = store.getters['optimization/getAnalysisFunctionsWithResults']
  if (allAnalysisFunctions === null) {
    return false
  } else {
    return allAnalysisFunctions.some(analysisFunction => !analysisFunction.enabled)
  }
})

const skeletonCardAmount = computed<number>(() => {
  const breakpointName = vuetify.display.name.value
  switch (breakpointName) {
    case 'xs': return 1
    case 'sm': return 1
    case 'md': return 2
    case 'lg': return 3
    case 'xl': return 4
    default: return 1
  }
})

function analysisFunctionNameForLocale (analysisFunction: ExtendedAnalysisFunctionWithResult): string {
  return textForLocale(analysisFunction.function_names.de, analysisFunction.function_names.en)
}

function analysisStateLabel (analysisFunction: ExtendedAnalysisFunctionWithResult): string {
  return analysisFunction.enabled ? t('activated') : t('disabled')
}

async function fetchAnalysisResult (analysisFunction: ExtendedAnalysisFunctionWithResult, isActive: boolean) {
  if (!isActive) {
    // toggleTabByIntanceId(analysisFunction.instance_id!.toString())
    const payload: FetchAnalysisResultPayload = {
      instanceId: analysisFunction.instance_id!,
      language: i18n.global.locale.value,
      resultId: analysisFunction.result_id!,
    }
    try {
      await fetchResultsData(payload)
    } catch (error) {
      if (error instanceof InvalidValueError) {
        showErrorNotification(`${t('notifications.errors.no_project_selected_with_attempted_action', { action: t('fetch_analysis_results') })}`)
      }
      reportError(error)
    }
  } else {
    store.commit('analysis_instances/SET_ANALYSIS_RESULT', null, { root: true })
    store.commit('optimization/SET_SELECTED_INSTANCE_ID', null, { root: true })
    store.commit('optimization/SET_SELECTED_RESULT_ID', null, { root: true })
  }
}

async function fetchResultsData (payload: FetchAnalysisResultPayload) {
  await Promise.all([
    store.dispatch('analysis_instances/fetchAnalysisResults', payload.instanceId),
    store.dispatch('analysis_instances/fetchAnalysisResult', payload),
  ])
}

function hasPendingInstanceUpdate (functionId: number): boolean {
  return pendingInstanceUpdates.value.includes(functionId)
}

function resultText (analysisFunction: ExtendedAnalysisFunctionWithResult): string {
  if (analysisFunction.instance_id !== null) {
    if (analysisFunction.interpretation !== null) {
      return analysisFunction.interpretation
    } else if (analysisFunction.enabled) {
      return t('no_analysis_results') as string
    }
  }
  return t('inactive') as string
}

function toggleAnalysisFunction (
  functionId: number,
  functionNames: ExtendedAnalysisFunctionWithResult['function_names'],
  instanceId: number|null,
): void {
  const functionName: string = textForLocale(functionNames.de, functionNames.en)

  const selectedComponent = (store.getters['optimization/getSelectedComponent'] as ComponentInProjectWithContext|null)!

  const componentInProjectId = selectedComponent.id as number
  const componentInProjectName = textForLocale(selectedComponent.nameDE!, selectedComponent.nameEN!)

  const payload: ToggleInstancePayload = {
    componentInProjectId,
    componentInProjectName,
    functionId,
    functionName,
    instanceId: instanceId ?? undefined,
  }

  store.dispatch('analysis_instances/toggleInstance', payload)
}

async function toggleTabElementsOnRouteQueryChange () {
  if (route.query.instance && route.query.result) {
    const payload: FetchAnalysisResultPayload = {
      instanceId: parseInt(route.query.instance as string),
      language: i18n.global.locale.value,
      resultId: route.query.result as string,
    }

    try {
      await fetchResultsData(payload)
    } catch (error) {
      reportError(error)
    }
  }
}

watch(
  selectedComponentInProject,
  () => {
    showAllAnalysisFunctions.value = false
  },
)

watch(
  isLoadingResources,
  async (newValue: boolean) => {
    if (
      !newValue &&
        store.state.optimization.selectedComponentInProjectResults &&
        store.state.optimization.selectedComponentInProjectResults.result_ids &&
        store.state.optimization.selectedComponentInProjectResults.result_ids.length > 0
    ) {
      await nextTick()
      toggleTabElementsOnRouteQueryChange()
    }
  },
)
// --- execution ---

const isComponentMounted = ref(false)

onMounted(() => {
  if (!isLoadingResources.value) {
    toggleTabElementsOnRouteQueryChange()
  }
  isComponentMounted.value = true
})
</script>

<template>
  <div data-cy="analysis-results">
    <TabbedContainer
      ref="analysisCardsContainerTab"
      :value="selectedInstanceId"
    >
      <v-row
        v-show="!isLoadingResources"
        class="px-2 pt-2 mb-1"
      >
        <v-col
          v-for="(analysisFunction, index) in analysisFunctionsWithResults"
          :key="`analysis_function_${index}`"
          class="px-1 py-1"
          lg="4"
          md="6"
          sm="12"
          xl="3"
        >
          <TabbedContainerElement
            :id="analysisFunction.instance_id"
            ref="analysisFunctionCardTab"
            :active-tab-id="selectedInstanceId"
            :disabled="analysisFunction.result_id === null"
          >
            <template #tab="{active, disabled}">
              <ActionCard
                class="fill-height hoverable-bordered"
                :color="whitelabelColor(analysisFunction.signal_color)"
                data-cy="analysis-result-card"
                :disabled="disabled"
                :icon="analysisFunction.icon"
                :link="analysisFunction.link"
                :selectable="analysisFunction.result_id !== null"
                :selected="active === true && analysisFunction.result_id !== null"
                :title="analysisFunctionNameForLocale(analysisFunction)"
                @action-card:click="fetchAnalysisResult(analysisFunction, active)"
              >
                <span
                  :class="disabled ? '_disabled' : ''"
                  v-text="resultText(analysisFunction)"
                />
                <template #actions>
                  <v-switch
                    v-if="!isReadOnly"
                    v-model="analysisFunction.enabled"
                    class="aedifion-switch pt-0"
                    data-testid="analysis-function-switch"
                    color="success"
                    density="compact"
                    :disabled="hasPendingInstanceUpdate(analysisFunction.function_id)"
                    hide-details
                    inset
                    :label="analysisStateLabel(analysisFunction)"
                    :loading="hasPendingInstanceUpdate(analysisFunction.function_id)"
                    @click.stop="toggleAnalysisFunction(analysisFunction.function_id, analysisFunction.function_names, analysisFunction.instance_id)"
                  />
                  <span
                    v-else
                    class="tw-mt-[50px] tw-leading-4 text-subtitle-1 text-neutral-darken1"
                  >{{ analysisStateLabel(analysisFunction) }}</span>
                </template>
              </ActionCard>
            </template>
            <template #content>
              <Teleport
                v-if="isComponentMounted"
                to="#analysis-results"
              >
                <AnalysisResultDetails
                  :color="analysisFunction.signal_color"
                  :function-name="analysisFunctionNameForLocale(analysisFunction)"
                  :interpretation="analysisFunction.interpretation"
                  :latest-result-id="analysisFunction.result_id"
                />
              </Teleport>
            </template>
          </TabbedContainerElement>
        </v-col>
      </v-row>
      <v-row
        v-show="isLoadingResources"
        class="mt-0 px-2"
      >
        <v-col
          v-for="index of skeletonCardAmount"
          :key="index"
          class="pa-1"
          lg="4"
          md="6"
          sm="12"
          xl="3"
        >
          <v-sheet
            height="132"
            width="316"
          >
            <v-skeleton-loader
              class="pa-4"
              type="heading,text, table-heading"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </TabbedContainer>
    <div
      v-if="showButtonToShowAllAnalyses"
      class="d-flex justify-center align-center pb-2"
      data-cy="show-more-button"
      style="width: 100%"
    >
      <v-btn
        color="primary-darken2"
        variant="outlined"
        @click="showAllAnalysisFunctions = !showAllAnalysisFunctions"
      >
        {{ showAllAnalysisFunctions ? t('show_less') : t('show_total_cards', {amount: amountOfAnalysisCards}) }}
        <v-icon
          :class="[showAllAnalysisFunctions ? 'rotated' : '', 'rotatable']"
          end
        >
          fa:fal fa-angle-up
        </v-icon>
      </v-btn>
    </div>
    <v-row>
      <v-col>
        <div id="analysis-results" />
      </v-col>
    </v-row>
  </div>
</template>

<style lang="sass" scoped>
  ._disabled
    opacity: 0.5
    cursor: auto

  .rotatable
    transition: transform 0.3s ease-in-out

  .rotated
    transform: rotate(180deg)

  :deep(.v-skeleton-loader__text)
    margin-top: 16px
    width: 70%

  :deep(.v-skeleton-loader__table-cell .v-skeleton-loader__text)
      margin-top: -6px !important

  :deep(.aedifion-switch .v-switch__track)
    height: 22px,
    width: 44px,
    min-width: 44px
  :deep(.aedifion-switch .v-switch__thumb)
    height: 18px,
    width: 18px,
    transform: none
</style>
<i18n locale="de">
{
  "activated": "Aktiv",
  "disabled": "Inaktiv",
  "fetch_analysis_results": "um die Analyseergebnisse zu laden",
  "no_analysis_results": "Erste Resultate am nächsten Montag.",
  "inactive": "Keine Resultate.",
  "show_total_cards": "Zeige alle ({amount} insgesamt)",
  "show_less": "Zeige weniger"
}
</i18n>
<i18n locale="en">
{
  "activated": "Active",
  "disabled": "Disabled",
  "fetch_analysis_results": "to fetch analysis results",
  "no_analysis_results": "Firsts results next Monday.",
  "inactive": "No results.",
  "show_total_cards": "Show all ({amount} in total)",
  "show_less": "Show less"
}
</i18n>
