/**
 * Checks if the two provided arrays are equal, not counting the order of elements. So for example, ['a','b'] and ['b','a'] are considered equal.
 * @param a The first array.
 * @param b The second array.
 * @returns true the two array contain the same elements in any order, otherwise false.
 */
export function areUnsortedEqual<T> (a: Array<T>, b: Array<T>): boolean {
  if (a.length === 0 && b.length === 0) {
    return true
  } else if (a.length !== b.length) {
    return false
  } else {
    return a.every((item: T) => b.includes(item))
  }
}
