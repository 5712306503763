import { TagGroups, TagsState } from './types'
import { MutationTree } from 'vuex'

export default {
  SET_LOADING: (state: TagsState, loading: boolean) => (state.loading = loading),

  SET_PENDING_TAG_UPDATE: (state: TagsState, status: boolean) => (state.pendingTagUpdate = status),

  SET_TAG_GROUPS: (state: TagsState, tagGroups: TagGroups) => {
    state.tagGroups = tagGroups
  },

  SET_TAG_KEYS: (state: TagsState, tagKeys: string[]) => {
    state.tagKeys = tagKeys
  },
} as MutationTree<TagsState>
