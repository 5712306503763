<script setup lang="ts">
import { ColumnHeader, RowData, SortDirection, Width } from './types'
import { computed, onBeforeUnmount, onMounted, ref, useAttrs, useSlots } from 'vue'
import { debounce, throttle } from 'lodash'
import ActionCell from './ActionCell.vue'
import Cell from './Cell.vue'
import HeaderCell from './HeaderCell.vue'
import useInfiniteScroll from '@/composables/useInfiniteScroll'

const slots = useSlots()
const attributes = useAttrs()

interface Props {
  headers: ColumnHeader[];
  loadMoreCallback?: () => Promise<void>|void,
  showActionColumn?: boolean;
  showHeaders?: boolean;
  sortByKey?: string|null;
  sortDirection?: SortDirection|null;
  stickyHeaders?: boolean;
  summary?: RowData;
}

const props = withDefaults(defineProps<Props>(), {
  showActionColumn: false,
  showHeaders: true,
  stickyHeaders: true,
})

const headerHeight = 32
const isScrolling = ref(false)
const tableElement = ref()
const initialTableElementBoundingRect = ref<DOMRect>()
const currentTableElementBoundingRect = ref<DOMRect>()

const columnCount = computed<number>(() => {
  return completedHeaders.value.length
})

const completedHeaders = computed<ColumnHeader[]>(() => {
  if (props.showActionColumn) {
    const result = [...props.headers]
    result.push({
      key: 'summarized-table-action',
      text: '',
      width: Width.MaxContent,
    })
    return result
  } else {
    return props.headers
  }
})

// @ts-ignore
const gridColumns = computed<string>(() => {
  const columnSettings: string[] = []
  for (const header of completedHeaders.value) {
    if (header.width === Width.MaxContent) {
      columnSettings.push('max-content')
    } else if (header.width === Width.MinContent) {
      columnSettings.push('min-content')
    } else {
      const widthGrowMinimum = header.widthGrowMinimum ?? '100px'
      columnSettings.push(`minmax(${widthGrowMinimum}, 1fr)`)
    }
  }
  return columnSettings.join(' ')
})

// @ts-ignore
const gridRows = computed<string>(() => {
  let result = `[header] ${headerHeight}px`
  if (props.summary) {
    result += ' [summary] 64px'
  }
  return result
})

// @ts-ignore
const bodyScreenHeight = computed<string>(() => {
  // 4 is the radius of the border. Extending it those 4 pixels means the curve of the border is covered
  return initialTableElementBoundingRect.value ? `${initialTableElementBoundingRect.value.top + headerHeight + 4}px` : '0'
})

// @ts-ignore
const bodyScreenLeft = computed<string>(() => {
  return currentTableElementBoundingRect.value ? `${currentTableElementBoundingRect.value.left}px` : '0'
})

// @ts-ignore
const bodyScreenWidth = computed<string>(() => {
  if (currentTableElementBoundingRect.value) {
    const result = window.innerWidth - currentTableElementBoundingRect.value.left
    return `${result}px`
  } else {
    return '0'
  }
})

// @ts-ignore
const headerTop = computed<string>(() => {
  if (props.stickyHeaders) {
    return initialTableElementBoundingRect.value ? `${initialTableElementBoundingRect.value.top}px` : '0'
  }
  return '0'
})

// @ts-ignore
const summaryRowTop = computed<string>(() => {
  return initialTableElementBoundingRect.value ? `${initialTableElementBoundingRect.value.top + headerHeight}px` : '0'
})

function classesForHeaderCell (column: number): string[] {
  const result: string[] = ['header-cell', 'position-top', 'position-bottom']
  if (column === 0) {
    result.push('position-left')
  }
  if (column === columnCount.value - 1) {
    result.push('position-right')
  }
  return result
}

// @ts-ignore
const headerCellPosition = computed(() => {
  return props.stickyHeaders ? 'sticky' : 'relative'
})

function classesForSummaryCell (column: number): string[] {
  const result: string[] = ['summary-cell', 'bordered', 'position-top', 'position-bottom']
  if (column === 0) {
    result.push('position-left')
  }
  if (column === columnCount.value - 1) {
    result.push('position-right')
  }
  if (isScrolling.value) {
    result.push('floating')
    result.push('aedifion-box-shadow-no-border')
  }
  return result
}

function onScroll (): void {
  isScrolling.value = window.scrollY !== 0
}

function summarySlotUsed (headerKey: string): boolean {
  return `summary.${headerKey}` in slots
}

function updateBodyMask (): void {
  currentTableElementBoundingRect.value = tableElement.value?.getBoundingClientRect()
  if (!initialTableElementBoundingRect.value) {
    initialTableElementBoundingRect.value = currentTableElementBoundingRect.value
  }
}

const infiniteScrollObserverTarget = ref<HTMLDivElement | null>(null)

if (props.loadMoreCallback) {
  useInfiniteScroll(
    infiniteScrollObserverTarget,
    props.loadMoreCallback,
    {
      rootMargin: '100px',
    },
  )
}

const onResizeDelayed = debounce(updateBodyMask, 500)
const onScrollDelayed = throttle(onScroll, 100)

onMounted (() => {
  window.addEventListener('resize', onResizeDelayed)
  window.addEventListener('scroll', onScrollDelayed)
  updateBodyMask()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResizeDelayed)
  window.removeEventListener('scroll', onScrollDelayed)
})
</script>

<template>
  <div
    ref="tableElement"
    class="summarized-content"
  >
    <div
      v-if="showHeaders"
      class="header-row"
    >
      <HeaderCell
        v-for="(header, index) in completedHeaders"
        :key="`header.${index}`"
        :class="classesForHeaderCell(index)"
        :header="header"
        :sort-by-key="sortByKey"
        :sort-direction="sortDirection"
        v-bind="{...attributes, 'data-testid': `header.${header.key}`}"
      />
    </div>

    <div
      v-if="summary"
      class="summary-row"
    >
      <div
        v-for="(header, index) in headers"
        :key="`summary.${index}`"
        :data-testid="`summary.${header.key}`"
        :class="classesForSummaryCell(index)"
      >
        <div
          v-if="summarySlotUsed(header.key)"
          class="summary-cell-slot-wrapper"
        >
          <slot
            :header="header"
            :name="`summary.${header.key}`"
          />
        </div>
        <Cell
          v-else
          :data="summary[header.key]"
          :header="header"
          summary-line-formatting
        />
      </div>
      <ActionCell
        v-if="showActionColumn"
        :class="classesForSummaryCell(columnCount - 1)"
        :data="summary"
      />
    </div>

    <slot name="body" />

    <div
      v-if="props.loadMoreCallback"
      ref="infiniteScrollObserverTarget"
    />

    <div
      v-if="showHeaders && stickyHeaders"
      class="body-mask"
    />
  </div>
</template>

<style lang="sass" scoped>
.position-left.bordered
    border-left: 1px solid rgb(var(--v-theme-neutral-lighten1))

.position-right.bordered
  border-right: 1px solid rgb(var(--v-theme-neutral-lighten1))

.position-top.bordered
  border-top: 1px solid rgb(var(--v-theme-neutral-lighten1))

.position-bottom.bordered
  border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1))

.position-top.position-left
  border-top-left-radius: 4px

.position-top.position-right
  border-top-right-radius: 4px

.position-bottom.position-left
  border-bottom-left-radius: 4px

.position-bottom.position-right
  border-bottom-right-radius: 4px

.summarized-content
  display: grid
  grid-template-columns: v-bind(gridColumns)
  grid-template-rows: v-bind(gridRows)
  position: relative
  z-index: 0

  /* This applies the hover effect to all items in the header if one item is hovered. display: contents is required, otherwise the grid will be broken */
  .header-row
    display: contents

    &:hover > .header-cell
      background-color: rgb(var(--v-theme-neutral-lighten2))
      color: rgb(var(--v-theme-neutral-darken2))

      & :deep(.sortIcon)
        color: rgb(var(--v-theme-neutral-darken3))

  .header-cell
    position: v-bind(headerCellPosition)
    top: v-bind(headerTop)
    z-index: 1

  /* This applies the hover effect to all items in the summary if one item is hovered. display: contents is required, otherwise the grid will be broken */
  .summary-row
    display: contents
    &:hover > .summary-cell :deep(.unit)
      color: rgb(var(--v-theme-neutral-darken1))

  .summary-cell
    background-color: rgb(var(--v-theme-neutral-lighten5))
    margin-bottom: 8px
    position: v-bind(headerCellPosition)
    top: v-bind(summaryRowTop)
    z-index: 1

    &.floating
      transition: box-shadow 0.3s ease-in-out

  .summary-cell-slot-wrapper
    height: 100%
    padding: 0 16px !important

  .body-mask
    position: fixed
    top: -4px
    height: v-bind(bodyScreenHeight)
    left: v-bind(bodyScreenLeft)
    width: v-bind(bodyScreenWidth)
    background-color: rgb(var(--v-theme-background))
</style>
