<template>
  <v-card
    class="pa-4"
    elevation="0"
    rounded
  >
    <v-card-title class="pa-0">
      <v-skeleton-loader
        v-if="loading"
        class="mt-2"
        height="22"
        :loading="loading"
        min-width="75%"
        type="text"
      />
      <div
        v-else
        v-html="formatSubAndSuper(title)"
      />
    </v-card-title>
    <v-card-text class="pa-0 pt-2">
      <AssetActionCardContent
        :loading="loading"
        :summary-kpi="summaryKpi"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import AssetActionCardContent from '@/components/AssetActionCardContent.vue'
import { formatSubAndSuper } from '@/filters/formatting'
import i18n from '@/i18n'
import { SummaryKPIResult } from '@aedifion.io/aedifion-api'

export default defineComponent({
  name: 'AssetAnalysisSummary',

  components: {
    AssetActionCardContent,
  },

  props: {
    kpiKey: {
      required: true,
      type: String as PropType<string>,
    },

    loading: {
      default: false,
      required: false,
      type: Boolean as PropType<boolean>,
    },

    summaryKpi: {
      required: false,
      type: Object as PropType<SummaryKPIResult>,
    },

    title: {
      required: true,
      type: String as PropType<string>,
    },
  },

  computed: {
    currentValue (): string | null {
      let value: string | null = null
      const unit: string | null = (this.summaryKpi?.current && this.summaryKpi.current.unit) || null
      if (this.summaryKpi?.current && typeof this.summaryKpi.current.value === 'number') {
        value = i18n.global.n(this.summaryKpi.current.value)
      } else if (this.summaryKpi?.current && typeof this.summaryKpi.current.value === 'string') {
        value = this.summaryKpi.current.value
      }
      if (!value && !unit) return null
      if (!unit) return value
      return `${value} ${unit}`
    },

    trendValue (): string | null {
      const trendValue: number | null = (this.summaryKpi?.development && this.summaryKpi.development.value) || null
      if (!trendValue && trendValue !== 0) return null
      const trendUnit: string = (this.summaryKpi?.development && this.summaryKpi.development.unit) || ''
      let sign = ''
      if (trendValue < 0) {
        sign = '-'
      } else if (trendValue > 0) {
        sign = '+'
      }
      return `${sign}${i18n.global.n(Math.abs(trendValue))}${trendUnit}`
    },
  },

  methods: {
    formatSubAndSuper,
  },
})
</script>
