import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { type GetKpiAggregationPayload, useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import type { KPIResultShort, TimeRangeInfo, User } from '@aedifion.io/aedifion-api'
import { formatValue } from '@/filters/formatting'
import { getColorForKPI } from '@/utils/helpers/kpiAggregations'
import i18n from '@/i18n'
import moment from 'moment'
import { perSquareUnitKPIs, energyKPIs, type PerSquareUnitKPIsKeys, type EnergyKPIsKeys } from '@/stores/views/Reporting'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import vuexStore from '@/vuex'
import { VUETIFY_COLORS } from '@theme/colors'
import type { SeriesLineOptions } from 'highcharts'

type KPIValue = {
  aggregationValue?: string,
  color: string,
  subtitle?: string,
  title: string,
  unit?: string
  monthUnit?: string
  series?: SeriesLineOptions[]
}

type HistoricalKPIValue = {
  yearAgoValue: number|null,
  twoYearsAgoValue: number|null,
}

type KPIValueMap = Map<number, HistoricalKPIValue>

export const useAssetOverviewStore = defineStore('assetOverview', () => {
  const analyticsApiStore = useAnalyticsApiStore()
  const appStore = useAppStore()
  const userStore = useUserStore()

  const loading = ref(false)
  const kpiAggregations = ref<Map<PerSquareUnitKPIsKeys, KPIValue>>(new Map())

  const user = computed<User>(() => userStore.userDetails!)

  async function _fetchKPIAggregation (kpi: PerSquareUnitKPIsKeys) {
    const payload: GetKpiAggregationPayload = {
      currencySystem: user.value?.currency_system,
      kpi,
      projectIds: [appStore.projectId],
      timeAggregation: getKPITimeAggregation(kpi),
      timeWindow: 'P1Y',
      unitsSystem: user.value?.units_system,
    }

    try {
      const sumResponse = await analyticsApiStore.getKpiAggregation(payload, { storeResult: false })
      const projectResult = sumResponse?.project_results?.[0]

      if (isEnergyKPI(kpi)) {
        let startDateForAvailablePast24Months, endDateForAvailablePast24Months
        const timeRange = projectResult?.time_range_info

        if (!timeRange?.start || !timeRange?.end) {
          startDateForAvailablePast24Months = moment().utc().subtract(2, 'year').subtract(1, 'month').startOf('month').toDate()
          endDateForAvailablePast24Months = moment(startDateForAvailablePast24Months).utc().add(1, 'year').toDate()
        } else {
          startDateForAvailablePast24Months = moment(timeRange?.start).utc().subtract(1, 'year').startOf('month').toDate()
          endDateForAvailablePast24Months = moment(timeRange?.end).utc().subtract(1, 'year').startOf('month').toDate()
        }

        const startMonth = moment(timeRange?.start ?? startDateForAvailablePast24Months).month()
        const monthMap = generateMonthsMap(startMonth)

        updateMonthsMap(projectResult?.kpis, 'yearAgoValue', monthMap)

        const { project_results: energyKpiPlotsForTwoYearsAgoResponse } = await analyticsApiStore.getKpiAggregation({
          ...payload,
          timeWindow: undefined,
          start: startDateForAvailablePast24Months,
          end: endDateForAvailablePast24Months,
        }, { storeResult: false })

        const projectResultsForTwoYearsAgo = energyKpiPlotsForTwoYearsAgoResponse?.[0].kpis

        updateMonthsMap(projectResultsForTwoYearsAgo, 'twoYearsAgoValue', monthMap)

        const seriesData = {
          series: [
            {
              data: [...monthMap.entries()].map(([month, { yearAgoValue }]) => {
                return {
                  custom: {
                    month: getMonthAbbreviation(month),
                  },
                  y: yearAgoValue,
                }
              }),
              type: 'line',
              custom: {
                textColor: VUETIFY_COLORS.neutral.darken3,
                tooltipBgColor: VUETIFY_COLORS.neutral.darken3,
              },
              name: getDateRangeForKPI(timeRange, false),
              color: VUETIFY_COLORS.neutral.darken3,
              zIndex: 2,
            },
            {
              data:
                [...monthMap.entries()].map(([month, { twoYearsAgoValue }]) => {
                  return {
                    custom: {
                      month: getMonthAbbreviation(month),
                    },
                    y: twoYearsAgoValue,
                  }
                }),
              type: 'line',
              custom: {
                textColor: VUETIFY_COLORS.neutral.darken2,
                tooltipBgColor: VUETIFY_COLORS.neutral.base,
              },
              name: getDateRangeForKPI(energyKpiPlotsForTwoYearsAgoResponse?.[0].time_range_info, false),
              color: VUETIFY_COLORS.neutral.lighten1,
              zIndex: 1,
            },
          ] as SeriesLineOptions[],
        }

        kpiAggregations.value.set(kpi, {
          ...kpiAggregations.value.get(kpi) as KPIValue,
          series: seriesData.series,
        })
      }

      if (shouldStoreResults(Boolean(projectResult?.time_range_info?.filled), kpi)) {
        const unit = replaceMonthlyWithYearly(vuexStore.getters['labels/label']('units', sumResponse.units)?.symbol) ?? undefined
        const monthUnit = vuexStore.getters['labels/label']('units', sumResponse.units)?.symbol

        kpiAggregations.value.set(kpi, {
          ...kpiAggregations.value.get(kpi),
          aggregationValue: formatValue(projectResult?.aggregation_value, { displayUnit: false, unit }),
          color: getColorForKPI(projectResult?.aggregation_value, kpi),
          subtitle: getDateRangeForKPI(projectResult?.time_range_info),
          title: i18n.global.t(`kpis.${kpi}`),
          unit,
          monthUnit,
        })
      } else {
        payload.timeAggregation = 'mean'
        const meanResponse = await analyticsApiStore.getKpiAggregation(payload, { storeResult: false })
        const projectResult = meanResponse?.project_results?.[0]

        if (projectResult) {
          const unit = replaceMonthlyWithYearly(vuexStore.getters['labels/label']('units', meanResponse.units)?.symbol) ?? undefined
          const monthUnit = vuexStore.getters['labels/label']('units', meanResponse.units)?.symbol

          const aggregatedValuePerYear = projectResult.aggregation_value! * 12

          kpiAggregations.value.set(kpi, {
            ...kpiAggregations.value.get(kpi),
            aggregationValue: formatValue(aggregatedValuePerYear, { displayUnit: false, unit }),
            color: getColorForKPI(aggregatedValuePerYear, kpi),
            subtitle: aggregatedValuePerYear ? i18n.global.t('asset_overview.extrapolated_data') : undefined,
            title: i18n.global.t(`kpis.${kpi}`),
            unit,
            monthUnit,
          })
        }
      }
    } catch (error) {
      reportError(error)
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.analysis_results') })}`)
    }
  }

  async function fetchAllKPIAggregations () {
    loading.value = true

    const energyKPIRequests = perSquareUnitKPIs.map((kpi) => _fetchKPIAggregation(kpi))
    await Promise.all(energyKPIRequests)

    loading.value = false
  }

  function clearStore () {
    kpiAggregations.value.clear()
  }

  return {
    clearStore,
    fetchAllKPIAggregations,
    kpiAggregations,
    loading,
  }
})

export function getDateRangeForKPI (timeRange: TimeRangeInfo|undefined, showYearly = true) {
  // We subtract one month from the end date, because the analytics API returns the end date as the first day of the next month
  if (showYearly) {
    return (timeRange?.start && timeRange?.end) ? `${i18n.global.t('dates.yearly')} - ${moment(timeRange.start).format('MMM YYYY')} - ${moment(timeRange.end).subtract(1, 'month').format('MMM YYYY')}` : ''
  }

  return (timeRange?.start && timeRange?.end) ? `${moment(timeRange.start).format('MMM YYYY')} - ${moment(timeRange.end).subtract(1, 'month').format('MMM YYYY')}` : ''
}

function getKPITimeAggregation (kpiType: PerSquareUnitKPIsKeys) {
  if (kpiType === 'productivity' || kpiType === 'technical_availability') {
    return 'mean'
  }

  return 'sum'
}

function shouldStoreResults (isFilled: boolean, kpiType: PerSquareUnitKPIsKeys) {
  // We store the results for the productivity and technical availability KPIs, as their time aggregation is 'mean' and not 'sum' in every case
  return isFilled || kpiType === 'productivity' || kpiType === 'technical_availability'
}

export function replaceMonthlyWithYearly (unit: string|undefined) {
  if (!unit) return

  const currentLocale = i18n.global.locale.value

  if (currentLocale === 'en') {
    return unit.replace('mon', 'y')
  } else {
    return unit.replace('mon', 'a')
  }
}

export function isEnergyKPI (kpi: PerSquareUnitKPIsKeys | EnergyKPIsKeys): kpi is EnergyKPIsKeys {
  return energyKPIs.includes(kpi as EnergyKPIsKeys)
}

/**
 * Generates a map of KPI values for each month starting from the specified start month.
 * Each month is initialized with null values for the yearAgoValue and twoYearsAgoValue and should be updated using the updateMonthsMap function.
 * @param startMonth - The starting month for generating the map.
 * @returns A map of KPI values for each month.
 */
function generateMonthsMap (startMonth: number): KPIValueMap {
  const monthMap: KPIValueMap = new Map()

  for (let i = 0; i < 12; i++) {
    const month = (i + startMonth) % 12

    monthMap.set(month, {
      yearAgoValue: null,
      twoYearsAgoValue: null,
    })
  }
  return monthMap
}

/**
 * Sets the provided KPI value to the corresponding month in the month map.
 * @param kpis - An array of KPIResultShort objects representing the KPIs.
 * @param valueKey - The key indicating which value to update in the month map ('yearAgoValue' or 'twoYearsAgoValue').
 * @param monthMap - The map containing the monthly KPI values.
 */
function updateMonthsMap (kpis: KPIResultShort[]|undefined, valueKey: 'yearAgoValue' | 'twoYearsAgoValue', monthMap: KPIValueMap) {
  for (const kpiValue of kpis ?? []) {
    const month = moment(kpiValue.start).month()
    const value = kpiValue.value ?? null
    monthMap.set(month, {
      ...monthMap.get(month) as HistoricalKPIValue,
      [valueKey]: value,
    })
  }
}

function getMonthAbbreviation (monthNumber: number): string {
  const normalizedMonth = (monthNumber) % 12
  const momentObj = moment().month(normalizedMonth).startOf('month')

  return momentObj.format('MMM')
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAssetOverviewStore, import.meta.hot))
}
