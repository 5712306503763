/* eslint-disable @typescript-eslint/no-explicit-any */
import { aedifionApi, ComponentInProjectAttribute, ComponentInProjectWithContext, Project, UpdateProject } from '@aedifion.io/aedifion-api'
import { ActionTree } from 'vuex'
import { AssetProfileState } from './types'
import { DataRow } from '@/components/DataCard/types'
import i18n from '@/i18n'
import { resetStoreState } from './state'
import { RootState } from '../types'
import { validateNotNullish } from '@/utils/helpers/validate'

function currentProject (rootGetters: any): Project {
  return validateNotNullish(
    rootGetters['projects/currentProject'] as Project|null,
    { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
  )
}

function currentProjectId (rootGetters: any): number {
  return validateNotNullish(
    rootGetters['projects/currentProjectId'] as number|null,
    { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
  )
}

function buildingComponentOfProject (rootGetters: any, projectId: number): ComponentInProjectWithContext {
  return validateNotNullish(
    rootGetters['building_analyses/buildingComponentOfProject'](projectId) as ComponentInProjectWithContext|null,
    { errorMessage: i18n.global.t('notifications.errors.no_component_in_project') as string },
  )
}

async function updateBuildingAttributes (projectId: number, buildingComponent: ComponentInProjectWithContext, changeset: DataRow[]): Promise<void> {
  const requests: Array<Promise<void>> = []
  for (const row of changeset) {
    const storedAttribute = buildingComponent.attributes!.find((attribute: ComponentInProjectAttribute) => {
      return attribute.key === row.key
    })
    if (storedAttribute !== undefined) {
      if (row.value !== undefined && row.value !== '') {
        requests.push(updateAttribute(projectId, row, buildingComponent, storedAttribute))
      } else {
        requests.push(deleteAttribute(projectId, row, buildingComponent, storedAttribute))
      }
    } else if (row.value !== undefined && row.value !== '') {
      requests.push(createAttribute(projectId, row, buildingComponent))
    }
  }
  await Promise.allSettled(requests)
}

async function updateAttribute (projectId: number, row: DataRow, buildingComponent: ComponentInProjectWithContext, storedAttribute: ComponentInProjectAttribute): Promise<void> {
  try {
    await aedifionApi.Project.putProjectComponentAttribute(projectId, buildingComponent.id!, row.key, row.value!)
    storedAttribute.value = row.value
  } catch (error) {
    handleAttributeError(row, buildingComponent)
  }
}

async function deleteAttribute (projectId: number, row: DataRow, buildingComponent: ComponentInProjectWithContext, storedAttribute: ComponentInProjectAttribute): Promise<void> {
  try {
    await aedifionApi.Project.deleteProjectComponentAttribute(projectId, buildingComponent.id!, row.key)
    const index: number|undefined = buildingComponent.attributes?.indexOf(storedAttribute)
    if (index !== undefined) {
      buildingComponent.attributes!.splice(index, 1)
    }
  } catch (error) {
    handleAttributeError(row, buildingComponent)
  }
}

async function createAttribute (projectId: number, row: DataRow, buildingComponent: ComponentInProjectWithContext): Promise<void> {
  try {
    const postResult = await aedifionApi.Project.postProjectComponentAttribute({ key: row.key, value: row.value! }, projectId, buildingComponent.id!)
    buildingComponent.attributes!.push(postResult.resource)
  } catch (error) {
    handleAttributeError(row, buildingComponent)
  }
}

function handleAttributeError (row: DataRow, buildingComponent: ComponentInProjectWithContext) {
  row.error = i18n.global.t('attributes.update_error') as string
  const storedAttribute = buildingComponent.attributes!.find((attribute: ComponentInProjectAttribute) => {
    return attribute.key === row.key
  })
  if (storedAttribute) {
    row.value = storedAttribute.value
  } else if (row.value) {
    delete row.value
  }
}

function createProjectChangesetFromRows (rows: DataRow[]): UpdateProject {
  const result: UpdateProject = {}
  for (const updatedAttribute of rows) {
    if (updatedAttribute.key.startsWith('B++')) {
      switch (updatedAttribute.key) {
        case 'B++NAME':
          result.name = updatedAttribute.value
          break
        case 'B++DESCRIPTION':
          result.description = updatedAttribute.value
          break
        case 'B++ADDRESS':
          result.address = updatedAttribute.value
          break
      }
    }
  }
  return result
}

function resetProjectRowValues (rows: DataRow[], project: Project): void {
  rows.forEach((row: DataRow) => {
    switch (row.key) {
      case 'B++NAME':
        row.value = project.name
        break
      case 'B++DESCRIPTION':
        row.value = project.description
        break
      case 'B++ADDRESS':
        row.value = project.address
        break
    }
  })
}

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  updateCertificationsData: async ({ commit, rootGetters }, changeset: DataRow[]) => {
    commit('SET_UPDATING_CERTIFICATIONS_DATA', true)
    try {
      const projectId: number = currentProjectId(rootGetters)
      const buildingComponent: ComponentInProjectWithContext = buildingComponentOfProject(rootGetters, projectId)
      await updateBuildingAttributes(projectId, buildingComponent, changeset)
    } finally {
      commit('SET_UPDATING_CERTIFICATIONS_DATA', false)
    }
  },

  updateEquipmentData: async ({ commit, rootGetters }, changeset: DataRow[]) => {
    commit('SET_UPDATING_EQUIPMENT_DATA', true)
    try {
      const projectId: number = currentProjectId(rootGetters)
      const buildingComponent: ComponentInProjectWithContext = buildingComponentOfProject(rootGetters, projectId)
      await updateBuildingAttributes(projectId, buildingComponent, changeset)
    } finally {
      commit('SET_UPDATING_EQUIPMENT_DATA', false)
    }
  },

  updateGeneralData: async ({ commit, dispatch, rootGetters }, changeset: DataRow[]) => {
    commit('SET_UPDATING_GENERAL_DATA', true)
    try {
      const project: Project = currentProject(rootGetters)
      const buildingComponent: ComponentInProjectWithContext = buildingComponentOfProject(rootGetters, project.id!)

      const componentUpdateRows: DataRow[] = changeset.filter((row: DataRow) => !row.key.startsWith('B++'))
      await updateBuildingAttributes(project.id!, buildingComponent, componentUpdateRows)

      const projectUpdateRows: DataRow[] = changeset.filter((row: DataRow) => row.key.startsWith('B++'))
      if (projectUpdateRows.length > 0) {
        try {
          const projectUpdatePayload: UpdateProject = createProjectChangesetFromRows(projectUpdateRows)
          await dispatch('projects/putProject', projectUpdatePayload, { root: true })
        } catch (error) {
          projectUpdateRows.forEach((row: DataRow) => { row.error = i18n.global.t('attributes.update_error') as string })
          resetProjectRowValues(projectUpdateRows, project)
        }
      }
    } finally {
      commit('SET_UPDATING_GENERAL_DATA', false)
    }
  },

  updateStakeholdersData: async ({ commit, rootGetters }, changeset: DataRow[]) => {
    commit('SET_UPDATING_STAKEHOLDERS_DATA', true)
    try {
      const projectId: number = currentProjectId(rootGetters)
      const buildingComponent: ComponentInProjectWithContext = buildingComponentOfProject(rootGetters, projectId)
      await updateBuildingAttributes(projectId, buildingComponent, changeset)
    } finally {
      commit('SET_UPDATING_STAKEHOLDERS_DATA', false)
    }
  },
} as ActionTree<AssetProfileState, RootState>
