const IconSet: { [alphanumericId: string]: string; } = {
  alarm_state_analysis: 'fa:fal fa-siren-on',
  control_loop_oscillation_analysis: 'fa:fal fa-wave-sine',
  dew_point_alert_analysis: 'fa:fal fa-dewpoint',
  energy_conversion_analysis: 'fa:fal fa-draw-circle',
  fan_speed_analysis: 'fa:fal fa-fan',
  filter_servicing_analysis: 'fa:fal fa-wrench',
  humidity_conditioning_analysis: 'fa:fal fa-humidity',
  operating_cycle_analysis: 'fa:fal fa-recycle',
  reduced_load_analysis: 'fa:fal fa-house-night',
  room_air_quality_analysis: 'fa:fal fa-air-conditioner',
  schedule_analysis: 'fa:fal fa-calendar',
  sensor_outage_analysis: 'fa:fal fa-sensor-alert',
  sequence_analysis: 'fa:fal fa-abacus',
  setpoint_deviation_analysis: 'fa:fal fa-balance-scale-right',
  setpoint_plausibility_analysis: 'fa:fal fa-question-circle',
  synchronized_operation_analysis: 'fa:fal fa-sync',
  temperature_spread_analysis: 'fa:fal fa-temperature-up',
  thermal_comfort_analysis: 'fa:fal fa-sort-circle',
  virtual_heat_meter_analysis: 'fa:fal fa-tachometer-alt-slow',
  weather_station_analysis: 'fa:fal fa-cloud-sun',
}

/**
 * Returns an icon in fontawesome notation that is assigned to a specific alphanumeric ID. If no icon found for the passed ID, return a default icon.
 * @param alphanumericId An alphanumeric ID for any resource.
 * @returns string Returns a string in the fontawesome notation.
 */
export const getIconbyAlphanumericId = (alphanumericId: string): string => {
  return IconSet[alphanumericId] ?? 'fa:fal fa-analytics'
}
