<i18n locale="de">
{
  "tag_key": "Tag Schlüssel",
  "tag_value": "Tag Wert"
}
</i18n>
<i18n locale="en">
{
  "tag_key": "Tag key",
  "tag_value": "Tag value"
}
</i18n>

<template>
  <div class="d-flex align-center">
    <v-row dense>
      <v-col
        :cols="column ? 12 : 6"
      >
        <FilterSelect
          dense
          :disabled="disabled"
          :extensible="allowCustomTags"
          :items="tagKeys"
          :label="t('tag_key')"
          :menu-props="{ maxWidth: 600, maxHeight: 300}"
          style="min-width: 150px; max-width: 300px"
          :model-value="currentTagKey"
          @change="setTagKey"
        />
      </v-col>
      <v-col
        :cols="column ? 12 : 6"
      >
        <FilterSelect
          dense
          :disabled="disabled"
          :extensible="allowCustomTags"
          :model-value="currentTagValue"
          :items="tagValues"
          :label="t('tag_value')"
          :menu-props="{ maxWidth: 600 }"
          style="min-width: 150px; max-width: 300px"
          @change="setTagValue"
        />
      </v-col>
    </v-row>
    <v-btn
      class="ml-2"
      color="primary"
      :disabled="currentTagKey === '' || currentTagValue === '' || disabled"
      icon
      variant="outlined"
      size="x-small"
      @click="onAddClicked"
    >
      <v-icon>fa:far fa-plus</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { createFullTagValueList } from '@/utils/helpers/tags'
import FilterSelect from '@/components/FilterSelect/FilterSelect.vue'
import { FilterSelectItem } from '@/components/FilterSelect/types'
import { Node } from '@aedifion.io/aedifion-api'
import { TagItem } from '@/vuex/data_points_view/types'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'TagSelector',

  components: {
    FilterSelect,
  },

  props: {
    allowCustomTags: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },

    column: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },

    disabled: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },

  emits: ['change'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      currentTagKey: '' as string,
      currentTagValue: '' as string,
    }
  },

  computed: {
    tagKeys (): FilterSelectItem[] {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const labels: any[]|null = this.$store.getters['labels/allRootNodes']
      const tagKeys: string[]|null = this.$store.getters['tags/getTagKeys']
      return createFullTagValueList(labels, tagKeys)
    },

    tagValues (): Array<FilterSelectItem> {
      if (!this.currentTagKey) {
        return []
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const labels: any[]|null = this.$store.getters['labels/allLeafNodes'](this.currentTagKey)
      const tagValues: string[]|null = this.$store.getters['tags/getTagValues'](this.currentTagKey)
      return createFullTagValueList(labels, tagValues)
    },
  },

  methods: {
    displayName (key: string, value?: string): string {
      const label: Node|null = this.$store.getters['labels/label'](key, value)
      return label?.display_name ?? value ?? key
    },

    onAddClicked (): void {
      this.$emit('change', { key: this.currentTagKey, value: this.currentTagValue } as TagItem)
    },

    setTagKey (key: FilterSelectItem) {
      this.currentTagKey = key.value as string
    },

    setTagValue (value: FilterSelectItem) {
      this.currentTagValue = value.value as string
    },
  },
})
</script>
