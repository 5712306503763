<script setup lang="ts">
import { computed, ref } from 'vue'
import AvatarEditor from '@/components/AvatarEditor.vue'
import CompanyEditBtn from './CompanyEditBtn.vue'
import CompanyEditDialog from './CompanyEditDialog.vue'
import HorizontalCardProperty from '@/components/HorizontalCardProperty.vue'
import { useAdministrationStore } from '@/stores/views/Administration/index'
import { useI18n } from 'vue-i18n'

// --- definition ---

const administrationStore = useAdministrationStore()
const { t } = useI18n()

const isCompanyEditDialogOpen = ref(false)

const loading = computed<boolean>(() => {
  return administrationStore.loading
})

const avatar = computed<string | null>(() => {
  return administrationStore.company?.avatar_url ?? null
})

const isAvatarLoading = computed<boolean>(() => {
  return administrationStore.loading || administrationStore.updatingAvatar
})

const companyName = computed<string | null>(() => {
  return administrationStore.company?.name ?? null
})

const companyLocation = computed<string | null>(() => {
  return administrationStore.company?.address ?? null
})

const companyPhoneNumber = computed<string | null>(() => {
  return administrationStore.company?.phone ?? null
})

const companyDescription = computed<string | null>(() => {
  return administrationStore.company?.description ?? null
})

const companyNumberOfUsers = computed<number>(() => {
  return administrationStore.users.length
})

const companyNumberOfProjects = computed<number>(() => {
  return administrationStore.projects.length
})

function openEditCompanyDialog (): void {
  isCompanyEditDialogOpen.value = true
}

function closeEditCompanyDialog (): void {
  isCompanyEditDialogOpen.value = false
}
</script>

<template>
  <div class="mt-8 mr-6">
    <CompanyEditDialog
      :is-open="isCompanyEditDialogOpen"
      @close="closeEditCompanyDialog()"
    />
    <v-card class="company-card pb-6">
      <AvatarEditor
        :avatar="avatar"
        class="ma-6"
        :loading="isAvatarLoading"
        @remove-avatar="administrationStore.deleteAvatar"
        @update-avatar="administrationStore.updateAvatar"
      />
      <div class="px-4">
        <div v-if="!loading">
          <div class="d-flex justify-space-between align-center">
            <HorizontalCardProperty
              icon="fa:far fa-building"
              :value="companyName"
            />
            <CompanyEditBtn @edit-option-click="openEditCompanyDialog()" />
          </div>
          <HorizontalCardProperty
            v-if="companyLocation"
            icon="fa:far fa-location-dot"
            :value="companyLocation"
          />
          <HorizontalCardProperty
            v-if="companyPhoneNumber"
            icon="fa:far fa-phone-rotary"
            :value="companyPhoneNumber"
          />
          <HorizontalCardProperty
            v-if="companyDescription"
            icon="fa:far fa-memo"
            :value="companyDescription"
          />
        </div>
        <div v-if="loading">
          <v-skeleton-loader
            class="py-3"
            type="text"
          />
          <v-skeleton-loader
            class="py-3"
            type="text"
          />
          <v-skeleton-loader
            class="pt-3"
            type="text"
          />
        </div>
        <v-divider class="my-4 mx-5" />
        <HorizontalCardProperty
          icon="fa:far fa-user"
          :label="t('users')"
          :loading="loading"
          :to="{ name: 'administration-users' }"
          :value="companyNumberOfUsers.toString()"
        />
        <HorizontalCardProperty
          class="pb-0"
          icon="fa:far fa-buildings"
          :label="t('projects')"
          :loading="loading"
          :value="companyNumberOfProjects.toString()"
        />
      </div>
    </v-card>
  </div>
</template>

<style lang="sass" scoped>
.company-card
  width: 300px
</style>

<i18n lang="json" locale="de">
  {
    "projects": "Projekte",
    "users": "Benutzer"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "projects": "Projects",
    "users": "Users"
  }
</i18n>
