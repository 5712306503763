/* eslint-disable @typescript-eslint/no-explicit-any */
import { CompanyRole, Role } from '@aedifion.io/aedifion-api'
import { NavigationGuardNext, RouteLocationNormalized, RouteMeta } from 'vue-router'
import { hasOneRoleAmong } from '@/utils/helpers/roles'
import { useUserStore } from '@/stores/user'
import { waitForInitialApiRequestsToComplete } from '@/utils/helpers/hooks'

interface CustomRouteMeta extends RouteMeta {
  requiresRole?: {
    companyRole: string[],
    projectRole: string[]
  }
}

type CustomToRoute = Omit<RouteLocationNormalized, 'meta'> & {meta?: CustomRouteMeta}

interface RoleNavigationGuard {
  (to: CustomToRoute, from: RouteLocationNormalized, next: NavigationGuardNext): void
}

export const roleGuardMiddleware: () => RoleNavigationGuard = () => async (
  to: CustomToRoute,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const userStore = useUserStore()
  let passedRoleCheck = false

  if (!to.meta?.requiresRole?.companyRole?.length && !to.meta?.requiresRole?.projectRole?.length) {
    passedRoleCheck = true
    return next()
  }

  await waitForInitialApiRequestsToComplete()

  const companyRoles: CompanyRole[] = userStore.companyRoles
  const projectRoles: Role[] = userStore.projectRoles

  const projectId = to.params.projectId === undefined ? undefined : parseInt(to.params.projectId as string)
  if (!passedRoleCheck && hasOneRoleAmong({
    companyRoles: to.meta?.requiresRole?.companyRole,
    projectId,
    projectRoles: (to.meta?.requiresRole?.projectRole),
  }, companyRoles, projectRoles)) {
    passedRoleCheck = true
    return next()
  }

  if (!passedRoleCheck) {
    return next({ name: 'forbidden' })
  }
}
