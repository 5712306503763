<template>
  <div
    class="tabbed-container__tab order-0 fill-height"
    :class="{
      'tabbed-container__tab_active': active,
      'tabbed-container__tab_hover': hover,
      'tabbed-container__tab_grayscale-disabled': grayscaleDisabled }"
    @click="setActive"
    @mouseenter.self="mouseEnter"
    @mouseleave.self="mouseLeave"
  >
    <slot
      :active="active"
      :disabled="disabled"
      :hover="hover"
      name="tab"
    />
  </div>
  <div
    class="tabbed-container__content order-1 px-2"
    :class="{'d-none': !active}"
  >
    <slot
      v-if="active"
      name="content"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

interface TabbedContainerElementData {
  hover: boolean
}

export default defineComponent({
  name: 'TabbedContainerElement',

  props: {
    activeTabId: {
      required: false,
      default: null,
      type: [Number, String, null] as PropType<number | string | null>,
    },
    disabled: {
      default: false,
      required: false,
      type: Boolean as PropType<boolean>,
    },
    grayscaleDisabled: {
      default: false,
      required: false,
      type: Boolean as PropType<boolean>,
    },
    id: {
      required: false,
      default: null,
      validator: (prop: unknown) => typeof prop === 'number' || prop === null || typeof prop === 'string',
      type: [Number, String, null] as PropType<number | string | null>,
    },
  },

  emits: ['setActiveTab'],

  data (): TabbedContainerElementData {
    return {
      hover: false,
    }
  },

  computed: {
    active (): boolean {
      if (this.id === null) return false
      return this.activeTabId === this.id
    },
  },

  methods: {
    mouseEnter (): void {
      this.hover = true
    },
    mouseLeave (): void {
      this.hover = false
    },
    setActive (): void {
      if (!this.disabled) {
        this.$emit('setActiveTab', this.active ? null : this.id)
      }
    },
  },
})
</script>

<style lang="sass" scoped>
.tabbed-container__tab
  flex: 1 1 0
  cursor: pointer

  &_disabled.tabbed-container__tab_grayscale-disabled
    -webkit-filter: grayscale(100%)
    -moz-filter: grayscale(100%)
    -ms-filter: grayscale(100%)
    -o-filter: grayscale(100%)
    filter: grayscale(100%)

.tabbed-container__content
  flex: 0 0 100%

:deep(.custom-hoverable-bordered):hover
  outline: 1px solid rgb(var(--v-theme-neutral))
  background-color: rgb(var(--v-theme-neutral-lighten5)) // background-color on hover does not work
:deep(.custom-active)
  outline: 1px solid rgb(var(--v-theme-neutral-darken2)) !important
  background-color: rgb(var(--v-theme-neutral-lighten5)) !important
  box-shadow: 0px 0px 0px 3px rgba(23, 25, 38, 0.12) !important
</style>
