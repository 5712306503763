<template>
  <div />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { oidcSettings } from '@/utils/oidc'
import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc'

export default defineComponent({
  name: 'AuthSilentRenew',

  mounted () {
    vuexOidcProcessSilentSignInCallback(oidcSettings)
  },
})
</script>
