<!-- eslint-disable vue/no-setup-props-destructure -->
<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { type Keys, type KPIS, type RowDataWithMetaData, type SavingsPotentialMetaData } from '@/stores/views/Optimization/savingsPotential'
import { type AnalysisFunction } from '@aedifion.io/aedifion-api'
import { type CellData } from '@/components/SummarizedContent/types'
import { getUnit } from '@/stores/views/Reporting/helpers'
import isEqual from 'lodash/isEqual'
import { useI18n } from 'vue-i18n'
import { VUETIFY_COLORS } from '@theme/colors'
import vuexStore from '@/vuex'

interface Props {
  data: RowDataWithMetaData<Keys>;
  modelValue: boolean;
}

// --- definition ---

const props = defineProps<Props>()

const emit = defineEmits<{
  (event: 'update:modelValue'): void;
  (event: 'update:savings-potential', payload: { metaData: SavingsPotentialMetaData, row: Record<KPIS, Partial<CellData>>, updatedSavings: KPIS[] }): void;
}>()

const { t } = useI18n()

const dialogState = computed({
  get: () => props.modelValue,
  set: (value: boolean) => {
    if (!value) {
      emit('update:modelValue')
    }
  },
})

const loading = ref(false)
const analysisFunctionAlphanumericId = ref('')

const savingsPotential: Record<KPIS, Partial<CellData>> = reactive({
  cost: {
    ...(props.data.cost as CellData),
    text: formatNumber(props.data.cost?.text as number|undefined),
    unit: 'euro',
  },
  // eslint-disable-next-line vue/sort-keys
  co2: {
    ...(props.data.co2 as CellData),
    text: formatNumber(props.data.co2?.text as number|undefined),
    unit: 'kilograms',
  },
  energy: {
    ...(props.data.energy as CellData),
    // eslint-disable-next-line vue/no-setup-props-destructure
    text: formatNumber(props.data.energy?.text as number|undefined),
    unit: 'kilowatt-hours',
  },
})

const updatedSavings = computed<KPIS[]>(() => {
  const updatedSavings: KPIS[] = []

  if (savingsPotential.cost.text !== formatNumber(props.data.cost?.text as number|undefined)) {
    updatedSavings.push('cost')
  }
  if (savingsPotential.co2.text !== formatNumber(props.data.co2?.text as number|undefined)) {
    updatedSavings.push('co2')
  }
  if (savingsPotential.energy.text !== formatNumber(props.data.energy?.text as number|undefined)) {
    updatedSavings.push('energy')
  }

  return updatedSavings
})

const originalAnalyses = {
  cost: String(formatNumber(props.data.cost?.text as number|undefined) ?? ''),
  // eslint-disable-next-line vue/sort-keys
  co2: String(formatNumber(props.data.co2?.text as number|undefined) ?? ''),
  energy: String(formatNumber(props.data.energy?.text as number|undefined) ?? ''),
}

const isSaveButtonDisabled = computed<boolean>(() => {
  return loading.value || isEqual(originalAnalyses, {
    // This type coercion is needed, because v-model returns a string once the value is changed, and since we do not know the type of the value, we have to convert it to a string
    cost: String(savingsPotential.cost.text ?? ''),
    // eslint-disable-next-line vue/sort-keys
    co2: String(savingsPotential.co2.text ?? ''),
    energy: String(savingsPotential.energy.text ?? ''),
  })
})

const textAndIconStyles = {
  error: {
    icon: 'fa:fas fa-diamond-exclamation',
    iconColor: VUETIFY_COLORS.error.base,
    textColor: VUETIFY_COLORS.error.darken2,
  },
  success: {
    icon: 'fa:fas fa-check-circle',
    iconColor: VUETIFY_COLORS.success.base,
    textColor: VUETIFY_COLORS.success.darken2,
  },
  warning: {
    icon: 'fa:fas fa-octagon-exclamation',
    iconColor: VUETIFY_COLORS.warning.base,
    textColor: VUETIFY_COLORS.warning.darken2,
  },
} as const

const getSeverityStyles = computed(() => {
  return textAndIconStyles[
    props.data.result?.severity as keyof typeof textAndIconStyles
  ]
})

function handleSave () {
  emit('update:savings-potential', {
    metaData: {
      ...props.data.metaData,
      analysisFunctionAlphanumericId: analysisFunctionAlphanumericId.value,
    },
    row: savingsPotential,
    updatedSavings: updatedSavings.value,
  })
  emit('update:modelValue')
}

function hasSavingsText (savingsText: string | undefined) {
  return savingsText !== undefined && savingsText !== ''
}

function formatNumber (number: number|undefined) {
  if (number === undefined) return undefined

  const formattedNumber = number.toFixed(2)
  return +formattedNumber % 1 === 0 ? parseInt(formattedNumber, 10) : parseFloat(formattedNumber)
}

async function getAnalysisFunctionAlphanumericId () {
  loading.value = true
  await vuexStore.dispatch('analysis_functions/fetchAnalysisFunctions', props.data.metaData.componentId)
  analysisFunctionAlphanumericId.value = (vuexStore.getters['analysis_functions/getAnalysisFunctionById'](props.data.metaData.analysisFunctionId) as AnalysisFunction|null)?.alphanumeric_id ?? ''
  loading.value = false
}

// --- execution ---

getAnalysisFunctionAlphanumericId()
</script>

<template>
  <v-dialog
    v-model="dialogState"
    width="496px"
    persistent
    data-testid="edit-savings-dialog"
  >
    <v-card
      width="480px"
      class="ma-2 pa-6 aedifion-box-shadow"
    >
      <v-card-title class="pa-0 mb-4 text-h6">
        {{ t("edit_savings_potential") }}
      </v-card-title>

      <v-card-text class="px-4 py-2 mb-4 rounded aedifion-border">
        <div>
          <v-icon
            size="20"
            class="severity-icon"
            :color="getSeverityStyles.iconColor"
            data-testid="severity-icon"
          >
            {{ getSeverityStyles.icon }}
          </v-icon>
          <span
            class="font-weight-semibold"
            data-testid="severity-text"
            :style="{ color: getSeverityStyles.textColor }"
          >{{ props.data.result?.text }}</span>
        </div>
        <div class="mt-2">
          <span
            data-testid="component-text"
            class="d-block text-neutral-darken3"
          >{{ props.data.component?.text }}</span>
          <span
            data-testid="component-subtitle"
            class="text-subtitle-1 text-neutral-darken1"
          >{{ props.data.component?.subtitle }}</span>
        </div>
      </v-card-text>

      <div class="d-flex gap-16 mb-4">
        <v-text-field
          v-for="(saving, savingKey) in savingsPotential"
          :key="savingKey"
          v-model="saving.text"
          :data-testid="`savings-potential-input-${savingKey}`"
          :label="t(`analyses.${savingKey}`)"
          variant="filled"
          type="number"
          :class="{ 'input-filled': hasSavingsText(saving.text)}"
          :suffix="getUnit(saving.unit ?? '')"
        />
      </div>

      <v-card-actions class="pa-0">
        <v-btn
          color="primary-lighten3"
          class="flex-grow-1 text-primary"
          data-testid="cancel-button"
          height="40px"
          variant="elevated"
          @click="emit('update:modelValue')"
        >
          {{ t('actions.cancel') }}
          <v-icon
            class="btn-icon"
            size="14"
          >
            fa:far fa-xmark
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          class="flex-grow-1 text-white"
          :disabled="isSaveButtonDisabled"
          variant="elevated"
          data-testid="save-button"
          height="40px"
          @click="handleSave"
        >
          {{ t('actions.save') }}
          <v-icon
            class="btn-icon"
            size="14"
          >
            fa:far fa-check
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="sass">
.gap-16
  gap: 16px

:deep(.v-text-field__details .v-input__slot)
  display: none
  margin: 0

.severity-icon
  margin-right: 16px
  line-height: 28px !important

:deep(.v-text-field--filled)
  .v-text-field__suffix
    align-self: flex-start
    transition: transform 0.3s ease

  &:focus-within
    .v-text-field__suffix
      transform: translateY(8px)

:deep(.input-filled .v-text-field__slot)
    .v-text-field__suffix
      transform: translateY(8px) !important

.btn-icon
  line-height: 20px !important
  margin-left: 12px
  margin-top: 2px
</style>

<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n lang="json" locale="de">
{
  "edit_savings_potential": "Einsparpotenzial bearbeiten",
  "analyses": {
    "cost": "Kosten",
    "co2": "CO₂",
    "energy": "Energie"
  }
}
</i18n>

<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n lang="json" locale="en">
{
  "edit_savings_potential": "Edit Savings Potential",
  "analyses": {
    "cost": "Cost",
    "co2": "CO₂",
    "energy": "Energy"
  }
}
</i18n>
