<script setup lang="ts">
import { computed, PropType } from 'vue'

export interface Button {
  activeIcon?: string
  id: string
  label: string
}

const props = defineProps({
  buttons: {
    required: true,
    type: Array as PropType<Button[]>,
    validator: (buttons: Button[]) => {
      if (buttons.length < 2) {
        // eslint-disable-next-line no-console
        console.error('ButtonGroup.vue: The buttons prop must have at least 2 buttons')
        return false
      }
      return true
    },
  },
  modelValue: {
    required: true,
    type: String as PropType<string>,
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const activeButton = computed({
  get () {
    return props.modelValue
  },
  set (value: string) {
    emit('update:modelValue', value)
  },
})

const isActive = computed(() => (buttonId: string) => {
  return activeButton.value === buttonId
})
</script>

<template>
  <div class="d-flex">
    <v-btn
      v-for="button of props.buttons"
      :key="button.id"
      :data-testid="`ButtonGroup-${button.id}`"
      :class="[
        'button text-primary-darken3',
        isActive(button.id) ? 'text-primary-darken2 active' : 'text-neutral-darken3'
      ]"
      color="white"
      @click="activeButton = button.id"
    >
      <v-icon
        v-if="button.activeIcon && isActive(button.id)"
        start
        size="14"
      >
        {{ button.activeIcon }}
      </v-icon>
      {{ button.label }}
    </v-btn>
  </div>
</template>

<style scoped>
.button {
  border-color: rgb(var(--v-theme-neutral-lighten1)) !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;

  height: 40px !important;

  transition: border-color 0.05s ease-in-out;
}

.button:not(:first-child) {
  margin-left: -1px;
}

.active {
  border-color: rgb(var(--v-theme-primary)) !important;
  z-index: 2;
}

.button:not(.active):hover, .button:not(.active):focus-visible {
  background-color: rgb(var(--v-theme-primary-lighten4)) !important;
  border-color: rgb(var(--v-theme-primary-lighten2)) !important;
  color: rgb(var(--v-theme-primary-darken2)) !important;
  z-index: 1;
}

.button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Removes the hover default dark tint effect on the button */
.theme--light.button:hover::before,
.theme--light.button:focus-visible::before {
  opacity: 0;
}
</style>
