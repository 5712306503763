import { ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { getComponentAttribute } from '@/utils/helpers/componentAttribute'
import store from '@/vuex/index'

export function isAssetOverviewSetUp (projectId: Readonly<number>): boolean {
  const usesSampleData: boolean = store.getters['building_analyses/projectUsesSampleData'](projectId)
  if (usesSampleData) {
    return false
  } else {
    const buildingComponent: ComponentInProjectWithContext|null = store.getters['building_analyses/buildingComponentOfProject'](projectId)
    const netFloorAreaAttribute = getComponentAttribute(buildingComponent, 'B+NFA')
    return !!netFloorAreaAttribute
  }
}
