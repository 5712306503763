import { BulletGraphColors, TimeseriesColors, VuetifyColors } from '../types.js'

export const VUETIFY_COLORS = {
  accent: { // warning, this color might be called primary - pink on marketing side
    base: '#E6007E'
  },
  anchor: '#3D4FC2',
  error: {
    base: '#F64C5C',
    darken2: '#C43647',
    lighten2: '#FF808C',
    lighten3: '#FFB3BA',
    lighten4: '#FEE7E9'
  },
  info: {
    base: '#0D9AF2',
    darken2: '#006AB1',
    lighten2: '#5FB3ED',
    lighten3: '#B4DDF9',
    lighten4: '#E4F3FD'
  },
  neutral: {
    base: '#A6A6A9',
    darken1: '#767679',
    darken2: '#646466',
    darken3: '#3C3C3F',
    darken4: '#171926',
    lighten1: '#D9D9DA',
    lighten2: '#E6E6E8',
    lighten3: '#F2F2F4',
    lighten5: '#FFFFFF'
  },
  primary: { // warning, this color might be called secondary on marketing side
    base: '#667BFF',
    darken2: '#3D4FC2',
    darken4: '#272F62',
    lighten2: '#ADB8FF',
    lighten3: '#E0E4FF',
    lighten4: '#F0F2FF'
  },
  secondary: { // warning, this color might be called primary - purple on marketing side
    base: '#B03A8B'
  },
  success: {
    base: '#00B899',
    darken2: '#067966',
    lighten2: '#75D7C6',
    lighten3: '#A5E4D9',
    lighten4: '#DDF4F0'
  },
  warning: {
    base: '#F7A930',
    darken2: '#A86602',
    lighten2: '#FDC05D',
    lighten3: '#FFDA9E',
    lighten4: '#FFEFD6'
  }
} as VuetifyColors

export const BULLET_GRAPH_COLORS = {
  blue: '#0D9AF2',
  green: '#00B899',
  red: '#F64C5C',
  yellow: '#F7A930'
} as BulletGraphColors

export const TIMESERIES_COLORS = [
  '#272F62',
  '#009BF8',
  '#972972',
  '#FFA600',
  '#47CFCB',
  '#E6007E',
  '#5131B6',
  '#FFACB9'
] as TimeseriesColors

export const COCKPIT_NUDGE_GRADIENT = [
  '#E60050',
  '#E6007E',
  '#B03A8B'
] as string[]
