import { isNavigationFailure, LocationQuery, NavigationFailureType, RouteLocation } from 'vue-router'
import { Plugin, Store } from 'vuex'
import { updateDataPointsViewQuery, updateDataPointsViewQueryForDatapointsStore } from './dataPointsView'
import { updateOptimizationQuery, updateOptimizationQueryForComponentsInProjectStore } from './optimization'
import { RootState } from '../../types'
import router from '@/router'
import { updateAssetOverviewQuery } from './assetOverview'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryUpdaterFunction = (mutationName: string, payload: any, currentRoute: RouteLocation) => LocationQuery|undefined

type FunctionRegistry = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [module: string]: QueryUpdaterFunction|Array<QueryUpdaterFunction>
}

const storeUpdaters: FunctionRegistry = {
  analysis_instances: [updateOptimizationQuery, updateAssetOverviewQuery],
  asset_overview: updateAssetOverviewQuery,
  components_in_project: updateOptimizationQueryForComponentsInProjectStore,
  data_points_view: updateDataPointsViewQuery,
  datapoints: updateDataPointsViewQueryForDatapointsStore,
  optimization: updateOptimizationQuery,
}

let queryUpdaterEnabled = true

/**
 * Enables or disables this plugin. This should be used whenever a query guard changes store values that cause actions here.
 * @param enabled If the plugin should be enabled or not.
 */
export function setQueryUpdaterPluginEnabled (enabled: boolean): void {
  queryUpdaterEnabled = enabled
}

/**
 * This plugin, if enabled, checks if a function is registered for the store a mutation is called in, and if so, executes that function.
 * @param store vuex store
 */
const VuexQueryUpdaterPlugin: Plugin<RootState> = (store: Store<RootState>): void => {
  store.subscribe(async (mutation) => {
    if (!queryUpdaterEnabled) {
      return
    }

    const mutationPath = mutation.type.split('/')
    if (mutationPath[0] in storeUpdaters) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let newQuery: any
      if (Array.isArray(storeUpdaters[mutationPath[0]])) {
        (storeUpdaters[mutationPath[0]] as Array<QueryUpdaterFunction>).forEach((updaterFunction: QueryUpdaterFunction) => {
          const updatedQueryResult = updaterFunction(mutationPath[1], mutation.payload, router.currentRoute.value)
          if (updatedQueryResult !== undefined) {
            if (newQuery === undefined) newQuery = {}
            Object.assign(newQuery, updatedQueryResult)
          }
        })
      } else {
        newQuery = (storeUpdaters[mutationPath[0]] as QueryUpdaterFunction)(mutationPath[1], mutation.payload, router.currentRoute.value)
      }
      if (newQuery) {
        if (Object.keys(newQuery).length === 0) newQuery.cleared = undefined
        try {
          await router.replace({
            query: newQuery,
            replace: true,
          })
        } catch (error) {
          if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
            throw new Error(`QueryUpdater for mutation '${mutation.type}' returned an unchanged query. It should have returned 'undefined'.`)
            // Ignores the error when we inject the page query parameter
          } else if (isNavigationFailure(error, NavigationFailureType.cancelled)) {
            // ignore
          } else {
            throw error
          }
        }
      }
    }
  })
}

export default VuexQueryUpdaterPlugin
