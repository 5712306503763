import { aedifionApi, AnalyticsOverview } from '@aedifion.io/aedifion-api'
import { ActionTree } from 'vuex'
import { AnalysisOverviewState } from './types'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import { validateNotNullish } from '@/utils/helpers/validate'

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  fetchOverview: async ({ commit, rootGetters }) => {
    commit('SET_LOADING_OVERVIEW', true)
    try {
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )
      const result: AnalyticsOverview = await aedifionApi.Analytics.getAnalyticsOverview(projectId)
      commit('SET_OVERVIEW_ITEMS', result.items)
      commit('SET_OVERVIEW_SUMMARY', result.overview)
    } catch (error) {
      reportError(error)
    } finally {
      commit('SET_LOADING_OVERVIEW', false)
    }
  },
} as ActionTree<AnalysisOverviewState, RootState>
