import { LocationQuery, RouteLocation, RouteLocationNormalized } from 'vue-router'
import { FunctionRegistry } from './queryGuard'
import { RootState } from '@/vuex/types'
import { Store } from 'vuex'

/**
 * Creates a `Location` with an altered query, used to replace the next target of the router.
 * @param to The original target of the router.
 * @param newQuery The query that should be placed into the target.
 * @returns A location as the next target of the router.
 */
export function createNextLocation (to: RouteLocationNormalized, newQuery: LocationQuery): RouteLocation {
  return {
    hash: to.hash,
    name: to.name || undefined,
    params: to.params,
    path: to.path,
    query: newQuery,
    // @ts-expect-error Not sure why this is needed, but kept it for now. //Todo: Check if this is still needed.
    replace: true,
  }
}

/**
 * Compares two routes and returns true if the routes have the same target route, but different projects. Ie., returns true if only the project was changed.
 * @param to Target route
 * @param from Previous route
 * @returns true if only the project changed
 */
export function projectChanged (to: RouteLocationNormalized, from: RouteLocationNormalized): boolean {
  return to.name === from.name && to.params.project !== from.params.project
}

/**
 * Checks if registry contains a function for the name of `to` and if so executes it.
 * @param registry Registry of functions with the route names they are assigned to.
 * @param store vuex store, passed on to the function.
 * @param to Target route. Passed on to the function and source for the name the registry is checked for.
 * @returns the result of the function, if any, or void.
 */
export function execRegisteredFunction (registry: FunctionRegistry, store: Store<RootState>, to: RouteLocationNormalized): RouteLocation|void {
  if (to.name && to.name in registry) {
    return registry[to.name as string](store, to)
  }
}
