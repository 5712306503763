import { calculateAverage, getMinAndMaxValuesOfTimeSeries } from '@/utils/helpers/timeseries'
import { DatapointIDAndTimeseries, DatapointOverviewItem, DataPointsViewState, DateRangeObject, TagItem, TimeseriesShort } from './types'
import { getDescriptionTag } from '@/utils/helpers/tags'
import { getStartAndEndDate } from '@/utils/helpers/timerange'
import { GetterTree } from 'vuex'
import { RootState } from '../types'
import { TagAssociation } from '@aedifion.io/aedifion-api'

export default {
  getDatapointsOverviewItems: (state: DataPointsViewState, getters, rootState, rootGetters): DatapointOverviewItem[] => {
    const overviewItems = [...state.datapointsOverview]
    // binary timeseries are displayed higher in the list than analog timeseries
    // so that it matches the visual position of the charts
    return overviewItems.sort((itemA, itemB) => {
      const timeseriesA: DatapointIDAndTimeseries|undefined = state.datapointTimeseries.find((timeseries: DatapointIDAndTimeseries) => {
        return timeseries.dataPointID === itemA.dataPointID
      })
      if (!timeseriesA) {
        return 0
      }
      const timeseriesB: DatapointIDAndTimeseries|undefined = state.datapointTimeseries.find((timeseries: DatapointIDAndTimeseries) => {
        return timeseries.dataPointID === itemB.dataPointID
      })
      if (!timeseriesB) {
        return 0
      }
      const valueA = timeseriesA.isBinary ? 1 : 0
      const valueB = timeseriesB.isBinary ? 1 : 0
      return valueB - valueA
    }).map((datapointOverviewItem: DatapointOverviewItem) => {
      const descriptionTag: TagAssociation|null = getDescriptionTag(datapointOverviewItem?.tags || [])
      const timeseries: DatapointIDAndTimeseries|undefined = state.datapointTimeseries.find((timeseries: DatapointIDAndTimeseries) => {
        return timeseries.dataPointID === datapointOverviewItem.dataPointID
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let unitLabel: any|undefined
      if (timeseries) {
        unitLabel = timeseries.unitLabelId ? rootGetters['labels/label']('units', timeseries.unitLabelId) : null
      }

      const minMaxValues = getMinAndMaxValuesOfTimeSeries(timeseries?.timeseries as number[][])
      const meanValue = calculateAverage(timeseries?.timeseries as TimeseriesShort<number> ?? [])

      return {
        ...datapointOverviewItem,
        description: descriptionTag?.value,
        max: minMaxValues.max,
        mean: meanValue,
        min: minMaxValues.min,
        seriesColor: state.seriesColorMap.get(datapointOverviewItem.hashID) as string,
        tags: datapointOverviewItem.tags || [],
        unit: unitLabel?.display_name,
        writable: datapointOverviewItem?.writable ?? false,
      }
    })
  },

  getDatapointTimeseries: (state: DataPointsViewState): DatapointIDAndTimeseries[] => state.datapointTimeseries,

  getDateRange: (state: DataPointsViewState): [string, string?] => state.dateRange,

  getEffectiveRange: (state: DataPointsViewState): DateRangeObject => {
    return getStartAndEndDate(state.dateRange, state.zoom || undefined, state.liveViewRange)
  },

  getSelectedDatapointsHashIds: (state: DataPointsViewState): string[] => state.selectedDatapointsHashIds,

  getSeriesColorForDatapoint: (state: DataPointsViewState) => (hashID: string) => (state.seriesColorMap.get(hashID)),

  getTagFilters: (state: DataPointsViewState): TagItem[] => state.tagFilters,

  hasFilters: (state: DataPointsViewState): boolean => {
    return state.tagFilters.length > 0 || state.favoritesFilterSet || state.writableFilterSet
  },

  isFavoritesFilterSet: (state: DataPointsViewState): boolean => state.favoritesFilterSet,

  isLoadingDatapointTimeseries: (state: DataPointsViewState): boolean => state.loadingDatapointsTimeseries,

  isShowingSetpointEditor: (state: DataPointsViewState): boolean => state.setpointId !== null,

  isWritableFilterSet: (state: DataPointsViewState): boolean => state.writableFilterSet,

  search: (state: DataPointsViewState): string => state.search ?? '',

  tagFiltersAsFilterString: (state: DataPointsViewState): string|undefined => {
    if (state.tagFilters.length > 0) {
      return state.tagFilters.reduce((accumulator: string, currentValue: TagItem) => {
        return `${accumulator}${currentValue.key}=${currentValue.value};`
      }, '').slice(0, -1)
    } else {
      return undefined
    }
  },

  zoom: (state: DataPointsViewState): DateRangeObject|null => state.zoom,
} as GetterTree<DataPointsViewState, RootState>
