<script setup lang="ts">
interface Props {
  show: boolean;
}

defineProps<Props>()

const logoUrl = new URL('@theme/img/logo.png', import.meta.url).href
</script>

<template>
  <v-overlay
    scrim="neutral-lighten5"
    :model-value="show"
    opacity="1"
  >
    <v-img
      class="center fadein"
      max-width="600"
      :src="logoUrl"
    />
  </v-overlay>
</template>

<style lang="sass" scoped>
  .center
    position: absolute
    top: 50%
    width: 100%
    height: 100%
    left: 50%
    transform: translate(-50%, -50%)
    animation: fadein 1s forwards
  @keyframes fadein
    from
      opacity: 0
    to
      opacity: 1

  :deep(.v-overlay__content)
    width: 100%
    height: 100%
</style>
