import { DBSchema, type IDBPDatabase, openDB } from 'idb'
import { isIDBAvailable } from '@/utils/helpers/idb'

// we previously had a system that incremented the version number automatically
// whenever a new labels definition was available. as a result a user’s version
// is unpredictable and count is restarted at 100.
const CURRENT_SCHEMA_VERSION = 100
const LABELS_DATABASE = 'labels'
const CHECKSUMS_STORE = 'checksums'
const DEFINITIONS_STORE = 'definitions'

interface LabelsDb extends DBSchema {
  [CHECKSUMS_STORE]: {
    key: string,
    value: string
  },
  [DEFINITIONS_STORE]: {
    key: string,
    value: unknown
  }
}

let database: IDBPDatabase<LabelsDb> | undefined

/**
 * Use this function to open or retrieve (if it’s already open) the labels
 * IndexedDb database using a singleton.
 * @returns The current labels IndexedDb database.
 */
async function getDatabase (): Promise<IDBPDatabase<LabelsDb> | undefined> {
  try {
    const idbAvailable: boolean = await isIDBAvailable()
    if (!database && idbAvailable) {
      database = await openDB(LABELS_DATABASE, CURRENT_SCHEMA_VERSION, {
        upgrade (db, oldSchemaVersion) {
          if (oldSchemaVersion < 100 && oldSchemaVersion > 0) {
          // we previously had one store per labels definition version
          // we want to clean this up
            for (const oldObjectStore of db.objectStoreNames) {
              db.deleteObjectStore(oldObjectStore)
            }
            // the checksum was stored in localStorage, that’s not needed anymore
            localStorage.removeItem('labels_latest_checksum')
          }
          if (oldSchemaVersion < 100) {
            db.createObjectStore(CHECKSUMS_STORE)
            db.createObjectStore(DEFINITIONS_STORE)
          }
          if (oldSchemaVersion < 101) {
          // put your code to migrate from v100 to v101 here
          }
        },
      })
    }
  } catch (error) {}

  return database
}

/**
 * Gets a labels definition from the database.
 * @param checksum The current checksum to check against. If that corresponds to
 * the checksum that is stored, the labels definition will be returned from the
 * database. Otherwise, there is either no labels definition stored or it is
 * outdated, and the function returns `null`.
 * @param locale The locale of the labels definition to get.
 * @returns The labels definition from the database or `null`.
 */
export async function readLabels (checksum: string, locale: string): Promise<unknown | null> {
  try {
    const db = await getDatabase()

    const storedChecksum = await db?.get(CHECKSUMS_STORE, locale)
    if (checksum === storedChecksum) {
      return await db?.get(DEFINITIONS_STORE, locale)
    }
  } catch (error) {}
  return null
}

/**
 * Save a labels definition to the database.
 * @param checksum The checksum for the labels definition we’re storing. Used to
 * check whether we’re reaching for an outdated version or not later on.
 * @param locale The locale of the labels definition to set.
 * @param definition The labels definition itself.
 */
export async function storeLabels (checksum: string, locale: string, definition: unknown): Promise<void> {
  try {
    const db = await getDatabase()

    await db?.put(DEFINITIONS_STORE, definition, locale)
    await db?.put(CHECKSUMS_STORE, checksum, locale)
  } catch (error) {}
}
