<script setup lang="ts">
import { ref } from 'vue'
import type { Samplerate } from '@/vuex/data_points_view/types'
import type { SelectItem } from '@/utils/types'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface Props {
  disabled: boolean;
  value: string;
  showLabel: boolean;
}

withDefaults(defineProps<Props>(), {
  disabled: false,
  value: 'auto',
  showLabel: true,
})

const emit = defineEmits<{
  (e: 'update:value', value: SelectItem['value']): void;
}>()

const items = ref<SelectItem<Samplerate>[]>([
  {
    title: t('samplerate_auto') as string,
    value: 'auto',
  },
  {
    title: t('samplerate_1h') as string,
    value: '1h',
  },
  {
    title: t('samplerate_15m') as string,
    value: '15m',
  },
])

function onChangeHandler (value: SelectItem['value']) {
  emit('update:value', value)
}
</script>

<template>
  <v-select
    :model-value="value"
    data-testid="samplerate-select"
    menu-icon="fa:far fa-chevron-down"
    density="compact"
    :disabled="disabled"
    :class="{ altStyle: !showLabel }"
    hide-details
    :items="items"
    :label="showLabel ? t('samplerate') : undefined"
    variant="outlined"
    @update:model-value="onChangeHandler"
  >
    <template
      v-if="!showLabel"
      #prepend-inner
    >
      <span class="text-neutral-darken1 text-body-1">{{ t('samplerate') }}</span>
    </template>
    <template
      v-if="!showLabel"
      #selection="{ item }"
    >
      <span class="text-body-1 tw-font-medium">{{ item.title }}</span>
    </template>
  </v-select>
</template>

<style lang="sass" scoped>
:deep(.v-icon)
  font-size: 14px !important

.altStyle
 &:deep(.v-field--variant-outlined .v-field__outline__start),
 &:deep(.v-field--variant-outlined .v-field__outline__end),
 &:deep(.v-field--variant-outlined .v-field__outline__notch)
  border-color: rgb(var(--v-theme-neutral-lighten1))
  opacity: 1
</style>

<i18n locale="de">
{
  "samplerate": "Auflösung"
}
</i18n>
<i18n locale="en">
{
  "samplerate": "Samplerate"
}
</i18n>
