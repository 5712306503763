<script setup lang="ts">
import { Alignment, ColumnHeader, RowData, SortDirection, Width } from '@/components/SummarizedContent/types'
import { computed, ref } from 'vue'
import ProjectOptionsBtn from '@/views/Administration/ProjectOptionsBtn.vue'
import SummarizedTable from '@/components/SummarizedTable.vue'
import { useAdministrationStore } from '@/stores/views/Administration/index'
import { useI18n } from 'vue-i18n'

type Keys = 'name' | 'usersCount' | 'options'

const administrationStore = useAdministrationStore()
const { t } = useI18n()

const loading = computed(() => {
  return administrationStore.loading
})

const headers = ref<ColumnHeader<Keys>[]>([{
  key: 'name',
  text: t('projects'),
  width: Width.Grow,
}, {
  align: Alignment.Right,
  key: 'usersCount',
  text: t('users'),
  width: Width.MaxContent,
}, {
  key: 'options',
  text: '',
  width: Width.MaxContent,
}])

const rows = computed<RowData<Keys>[]>(() => {
  return administrationStore.projectsWithUsersCount.map((project) => {
    return {
      name: {
        custom: {
          avatar: project.avatar_url,
          description: project.description,
          id: project.id,
        },
        text: project.name,
      },
      options: {
        custom: {
          id: project.id,
        },
        text: '',
      },
      usersCount: {
        text: project.usersCount.toString(),
      },
    }
  })
})
</script>

<template>
  <div>
    <SummarizedTable
      v-if="!loading && rows.length"
      :default-sorting="{
        direction: SortDirection.ASC,
        key: 'name'
      }"
      :headers="headers"
      :rows="rows"
      :sticky-headers="false"
    >
      <template #cell.name="{ data }">
        <div class="d-flex align-center overflow-hidden">
          <v-img
            :alt="t('avatar_alt')"
            aspect-ratio="1"
            cover
            class="aedifion-border neutral-lighten1 rounded mr-2"
            :src="data.custom.avatar ?? '/img/dummy-image.jpg'"
            width="42px"
          />
          <div class="d-flex flex-column align-start overflow-hidden">
            <router-link
              class="asset-profile-link text-body-1 font-weight-semibold text-decoration-none text-truncate"
              :to="{
                name: 'asset-profile',
                params: {
                  project: data.custom.id
                }
              }"
            >
              {{ data.text }}
            </router-link>
            <span
              v-if="data.custom.description"
              class="asset-description text-subtitle-1 text-neutral-darken1 text-truncate"
            >
              {{ data.custom.description }}
            </span>
          </div>
        </div>
      </template>
      <template #cell.options="{ data }">
        <div class="d-flex align-center mx-n2">
          <ProjectOptionsBtn :project-id="data.custom.id" />
        </div>
      </template>
    </SummarizedTable>
    <v-row
      v-if="loading"
      align="center"
      class="fill-height ma-0"
      justify="center"
    >
      <v-progress-circular
        indeterminate
        size="80"
      />
    </v-row>
  </div>
</template>

<style scoped lang="sass">
.asset-profile-link, .asset-description
  max-width: 100%

.asset-profile-link
  color: rgb(var(--v-theme-neutral-darken4))

.asset-profile-link:hover
  color: rgb(var(--v-theme-primary-darken2))
</style>

<i18n lang="json" locale="de">
  {
    "avatar_alt": "Projekt-Avatar",
    "projects": "Projekte",
    "users": "Benutzer"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "avatar_alt": "Project avatar",
    "projects": "Projects",
    "users": "Users"
  }
</i18n>
