<script setup lang="ts">
import { type ComponentPublicInstance, computed, nextTick, ref } from 'vue'
import { ListItemData } from './types'
import { TYPOGRAPHY } from '@/utils/designConstants'
import { useI18n } from 'vue-i18n'

export interface Props {
  items?: ListItemData[]
  itemsDraggable?: boolean
  loading?: boolean
  page?: number
  pagesCount?: number
  paginationOff?: boolean
  title?: string
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  itemsDraggable: false,
  loading: false,
  page: 1,
  pagesCount: 1,
  paginationOff: false,
})

const emits = defineEmits<{
  'asidelist:click-item': [item: ListItemData]
  'asidelist:page-change': [newPage: number, oldPage: number]
}>()

const { t } = useI18n()

const invalidPage = computed(() => {
  return !props.paginationOff && (props.page === null || props.page < 1 || props.pagesCount === null || props.page > props.pagesCount)
})

const paginationOptions = computed(() => {
  const pages: number[] = []
  if ((props.pagesCount === 0 || props.pagesCount === null) && props.page === null) return [1]
  else if (props.pagesCount === null && props.page !== null) return [props.page]
  for (let i = 1; i <= props.pagesCount; i++) {
    pages.push(i)
  }
  return pages
})

function onDragEnd () {
  const ghost = document.getElementById('drag-ghost')
  if (ghost) {
    ghost.remove()
  }
}

function onDragStart (event: DragEvent, item: ListItemData) {
  event.dataTransfer!.setData('asidelist-item', String(item.id))
  const dragImage: HTMLElement = document.createElement('div')
  dragImage.id = 'drag-ghost'
  dragImage.style.fontFamily = 'Inter'
  dragImage.style.fontSize = TYPOGRAPHY.headings.body1.size
  dragImage.style.fontWeight = TYPOGRAPHY.fontWeights.medium
  dragImage.style.color = 'rgb(var(--v-theme-neutral-darken3))'
  dragImage.style.backgroundColor = 'rgb(var(--v-theme-neutral-lighten5))'
  dragImage.style.padding = '6px'
  dragImage.style.display = 'inline-block'
  const text = document.createTextNode(item.title)
  dragImage.appendChild(text)
  document.body.appendChild(dragImage)
  event.dataTransfer!.setDragImage(dragImage, 0, 60)
}

// #region NAVIGATE TO THE TOP OF THE TABLE AFTER CHANGING THE PAGE
const itemList = ref<ComponentPublicInstance | null>(null)

async function scrollToTopOfList () {
  await nextTick()
  itemList.value?.$el.scrollTo({ top: 0, behavior: 'smooth' })
}
// #region

defineExpose({
  invalidPage,
  paginationOptions,
})
</script>

<template>
  <div class="asidelist__wrapper pr-3">
    <v-card class="d-flex flex-column">
      <div
        v-if="title || $slots.toolbar"
        class="d-flex flex-row align-center pa-2 pl-4 rounded-b-0"
        style="border-bottom-left-radius: 0 !important; border-bottom-right-radius: 0 !important; margin: -0.5px"
      >
        <span
          v-if="title"
          class="text-h6"
          v-text="title"
        />
        <v-spacer />
        <slot name="toolbar" />
      </div>
      <v-divider
        v-if="title || $slots.toolbar"
      />
      <div v-if="$slots['content-header']">
        <slot name="content-header" />
        <v-divider />
      </div>
      <div
        class="asidelist-list__content d-flex flex-column"
        style="height: 100%"
      >
        <div
          v-if="loading"
          class="asidelist-list__content--loading d-flex justify-center align-center"
        >
          <v-progress-circular
            color="neutral"
            indeterminate
          />
        </div>
        <v-list
          ref="itemList"
          :class="loading ? 'scroll-y-hidden blur-background' : ''"
        >
          <v-list-item
            v-for="(item, index) in items"
            :key="`${title}_list_item_${index}`"
            class="pr-1 hoverable"
            density="compact"
            :draggable="itemsDraggable"
            @click="emits('asidelist:click-item', item)"
            @dragend="onDragEnd"
            @dragstart="onDragStart($event, item)"
          >
            <slot
              :item-data="item"
              name="list-item"
            >
              <div>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.description">
                  {{ item.description }}
                </v-list-item-subtitle>
              </div>
            </slot>
          </v-list-item>
        </v-list>
      </div>
      <v-divider
        v-if="!paginationOff"
      />
      <v-card-actions
        v-if="!paginationOff"
        class="pa-0 justify-center"
      >
        <div
          v-if="!$slots['custom-pagination']"
          class="d-flex w-100 flex-row justify-space-around align-center pa-1"
        >
          <v-btn
            :disabled="page <= 1 || loading || invalidPage"
            icon
            @click="$emit('asidelist:page-change', page - 1, page),
                    scrollToTopOfList()"
          >
            <v-icon size="x-small">
              fa fa-chevron-left
            </v-icon>
          </v-btn>
          <v-spacer />
          <div class="d-flex flex-row justify-center align-center pagination">
            <span class="mr-2">{{ t('pagination.page') }}:</span>
            <v-autocomplete
              class="pagination__select"
              data-cy="aside-list-page"
              density="compact"
              bg-color="transparent"
              :disabled="pagesCount === 0 || pagesCount === null || loading"
              :error="invalidPage"
              hide-details
              :items="paginationOptions"
              :model-value="page || 1"
              @update:model-value="(newPage) => {
                $emit('asidelist:page-change', newPage, page)
                scrollToTopOfList()}"
            />
            <span class="ml-2 tw-whitespace-nowrap">{{ t('pagination.of') }} {{ pagesCount || 1 }}</span>
          </div>
          <v-spacer />
          <v-btn
            :disabled="page >= pagesCount || loading || invalidPage"
            icon
            @click="emits('asidelist:page-change', page + 1, page),
                    scrollToTopOfList()"
          >
            <v-icon size="x-small">
              fa fa-chevron-right
            </v-icon>
          </v-btn>
        </div>
        <slot name="custom-pagination" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<style lang="sass" scoped>
@use '@/scss/settings' as vuetifySettings

.scroll-y-hidden
  overflow-y: hidden
.blur-background
  filter: blur(1px)
.asidelist__wrapper
  height: 100%
  max-height: 100% !important
  .v-card
    height: 100%
    max-height: 100% !important
    .asidelist-list__content
      max-width: 100%
      overflow-y: auto
      overflow-x: hidden
      position: relative
      .asidelist-list__content--loading
        position: absolute
        background-color: rgba(255, 255, 255, 0.5)
        z-index: 1
        height: 100%
        width: 100%
    .v-card__actions div.d-flex
      width: 100%
  .pagination
    span
      font-size: vuetifySettings.$data-table-footer-font-size
    .pagination__select
      margin-top: 0px
      flex-basis: 100px
      flex-grow: 0
      flex-shrink: 1
      :deep(input)
        font-size: vuetifySettings.$data-table-footer-font-size
      :deep(.v-field__input)
        font-size: vuetifySettings.$data-table-footer-font-size
        white-space: nowrap
      :deep(.v-autocomplete__selection)
        max-width: 100%
</style>
