<script setup lang="ts">
import { ref, watch } from 'vue'
import texts from '@theme/texts'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface Props {
  acceptButtonColor?: string
  acceptButtonTextKey?: string
  dialogWidth?: number
  requiredInput?: string
  showContactInfo?: boolean
  showMainContent?: boolean
  title: string
  value?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  acceptButtonColor: 'error-darken2',
  acceptButtonTextKey: 'actions.confirm',
  dialogWidth: 600,
  showContactInfo: false,
  showMainContent: true,
  value: true,
})

const emits = defineEmits(['confirmation-dialog:accept', 'confirmation-dialog:cancel'])

const emailSupport = texts.emailSupport
const phoneSupport = texts.phoneSupport
const input = ref('')

// Reset input when dialog is closed
watch(() => props.value, (newValue) => {
  if (newValue === false) {
    input.value = ''
  }
})

defineExpose({
  input,
})
</script>

<template>
  <v-dialog
    data-cy="confirmation-dialog"
    :max-width="dialogWidth"
    persistent
    content-class="aedifion-box-shadow-no-border"
    :model-value="value"
    @keydown.escape="emits('confirmation-dialog:cancel')"
  >
    <form
      autocomplete="on"
      :class="$slots['alternativeContent'] && 'pa-0'"
      data-cy="confirmation-dialog-content"
      @submit.prevent="emits('confirmation-dialog:accept')"
    >
      <v-card class="pa-2">
        <div v-if="props.showMainContent">
          <v-card-title
            class="text-neutral-darken4 error--outline pb-0 d-flex pa-4"
            data-cy="confirmation-dialog-title"
          >
            {{ title }}
            <v-btn
              v-if="$slots['alternativeContent']"
              class="ml-auto remove-hover__btn text-neutral-darken4"
              data-cy="confirmation-dialog-cancel-button"
              icon
              height="24"
              width="24"
              @click="emits('confirmation-dialog:cancel')"
            >
              <template #default>
                <v-icon>
                  fa:fal fa-circle-xmark
                </v-icon>
              </template>
            </v-btn>
          </v-card-title>
          <div :class="$slots['alternativeContent'] && 'd-flex flex-row'">
            <div>
              <v-card-text
                data-cy="confirmation-dialog-default-slot"
                class="pb-0"
              >
                <slot />
                <v-sheet
                  v-if="!!$slots['warning']"
                  class="warning-slot text-error-darken2 px-4 tw-py-[10px]"
                  rounded
                  :width="$slots['alternativeContent'] && '446'"
                >
                  <h2
                    class="mb-2"
                  >
                    <v-icon
                      color="error"
                      size="20"
                      class="tw-leading-5"
                    >
                      fa:fas fa-octagon-exclamation
                    </v-icon>
                    <span class="ml-2 text-error-darken2 font-weight-semibold tw-leading-5">{{ t('warning') }}</span>
                  </h2>
                  <slot name="warning" />
                </v-sheet>
                <v-card-actions
                  v-if="$slots['alternativeContent']"
                  class="px-0 pb-3 mt-11"
                >
                  <v-btn
                    class="ml-auto"
                    :color="props.acceptButtonColor"
                    :disabled="!!props.requiredInput && input !== props.requiredInput"
                    type="submit"
                    data-testid="confirmation-dialog-accept-button"
                    variant="elevated"
                  >
                    {{ t(props.acceptButtonTextKey) }}
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </div>
            <v-divider
              v-if="$slots['alternativeContent']"
              class="mt-8 d-block mb-16 ml-7 mr-5 border-opacity-100 tw-h-auto"
              vertical
            />
            <v-card-text class="pb-0">
              <div
                v-if="props.showContactInfo"
                class="mt-3 text-subtitle2"
                :style="$slots['alternativeContent'] && 'width:227px'"
              >
                <span>{{ t("happy_to_support") }}</span>&nbsp;<span>{{ t("contact_us") }}</span>
                <div
                  v-if="emailSupport"
                  class="mb-1 mt-3"
                >
                  <v-icon
                    class="mr-2"
                    size="small"
                  >
                    fa:far fa-envelope
                  </v-icon>
                  <a
                    class="text-decoration-none text-primary-darken2"
                    :href="`mailto:${emailSupport}`"
                    v-text="emailSupport"
                  />
                </div>
                <div v-if="phoneSupport">
                  <v-icon
                    class="mr-2"
                    size="small"
                  >
                    fa:far fa-phone-rotary
                  </v-icon>
                  <a
                    class="text-decoration-none text-neutral-darken3"
                    :href="`tel:${phoneSupport}`"
                    v-text="phoneSupport"
                  />
                </div>
              </div>
              <div
                v-if="!!props.requiredInput"
                id="requiredInput"
              >
                <span
                  data-cy="request-input-span"
                  v-text="t('request_input')"
                />
                <br>
                <span
                  class="text-cta text-error-darken2 d-block mt-1"
                  data-cy="required-input-span"
                >
                  {{ props.requiredInput }}
                </span>
                <v-text-field
                  v-model="input"
                  data-testid="required-input"
                  class="my-4"
                  variant="filled"
                  hide-details
                />
              </div>
            </v-card-text>
            <v-card-actions
              v-if="!$slots['alternativeContent']"
              class="pt-0 align-center justify-center align-center"
            >
              <v-btn
                class="flex-grow-1 text-primary"
                color="primary-lighten3"
                variant="elevated"
                data-cy="confirmation-dialog-cancel-button"
                @click="$emit('confirmation-dialog:cancel')"
              >
                <span class="tw-leading-5">{{ t('actions.cancel') }}</span>
                <v-icon
                  end
                  size="14"
                >
                  fa:far fa-xmark
                </v-icon>
              </v-btn>
              <v-btn
                class="flex-grow-1 text-white"
                :color="props.acceptButtonColor ?? 'primary-darken2'"
                data-cy="confirmation-dialog-accept-button"
                :disabled="!!props.requiredInput && input !== props.requiredInput"
                type="submit"
                variant="elevated"
              >
                <span class="tw-leading-5">{{ t(props.acceptButtonTextKey) }}</span>
                <v-icon
                  end
                  size="14"
                >
                  fa:far fa-trash
                </v-icon>
              </v-btn>
            </v-card-actions>
          </div>
        </div>
        <slot
          v-else
          :name="'alternativeContent'"
        />
      </v-card>
    </form>
  </v-dialog>
</template>

<style lang="sass" scoped>
.warning-slot
  border: 1px solid rgb(var(--v-theme-error-lighten2)) !important
  background-color: rgb(var(--v-theme-error-lighten4)) !important
</style>

<i18n lang="json" locale="de">
  {
    "request_input": "Bitte gib folgendes ein, um die Aktion zu bestätigen:",
    "happy_to_support": "Unser Team unterstützt und berät dich gerne!",
    "contact_us": "Kontaktiere uns einfach.",
    "warning": "Achtung"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "request_input": "Please type the following to confirm the action:",
    "happy_to_support": "Our team will be happy to support and advise you!",
    "contact_us": "Just contact us.",
    "warning": "Warning"
  }
</i18n>
