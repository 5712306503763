<i18n lang="json" locale="de">
{
  "title": "Kennzahlen im Überblick",
  "table_footer": {
    "rowsPerPage": "Zeilen pro Seite"
  }
}
</i18n>
<i18n lang="json" locale="en">
{
  "title": "Key figures at a glance",
  "table_footer": {
    "rowsPerPage": "Rows per page"
  }
}
</i18n>

<template>
  <v-expansion-panels flat>
    <v-expansion-panel
      data-cy="kpiPanel"
      :disabled="!hasKpis"
    >
      <v-expansion-panel-title class="pl-0">
        <span class="text-h6">{{ t('title') }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-data-iterator
          :items-per-page="itemsPerPage"
          no-filter
          :page="currentPage"
          :items="items"
          :loading="loading"
          :sort-by="[{
            order: 'asc',
            key: 'name'
          }]"
        >
          <template #default="iteratorProps">
            <v-list
              class="pr-2 pl-0"
              density="compact"
            >
              <template
                v-for="(item, index) in itemsToDisplay"
                :key="`kpi__${index}`"
              >
                <v-list-item
                  data-cy="kpiEntry"
                >
                  <div
                    class="text-body-1"
                    style="width: 100%"
                  >
                    <div class="d-flex flex-row justify-space-between align-center">
                      <span
                        class="text-neutral-darken2"
                        v-text="item.name"
                      />
                      <span>
                        {{ item.value }}
                        <span
                          class="text-neutral-darken2"
                          v-text="item.unit"
                        />
                      </span>
                    </div>
                  </div>
                </v-list-item>
                <v-divider
                  v-if="index < iteratorProps.items.length - 1"
                  :key="index"
                  class=" border-opacity-100"
                />
              </template>
            </v-list>
          </template>
          <template #footer>
            <div class="d-flex align-center justify-end">
              <div class="d-flex align-center justify-center">
                <span class="text-legend mr-2 mt-2">{{ t('table_footer.rowsPerPage') }}</span>
                <v-select
                  v-model="itemsPerPage"
                  density="compact"
                  class="mr-2"
                  variant="plain"
                  :items="[10, 25, 50, 100]"
                  hide-details
                  style="width: 50px;"
                />
              </div>
              <v-pagination
                class="mt-2"
                :length="paginationPages"
                density="compact"
                :total-visible="itemsPerPage"
                @update:model-value="currentPage = $event"
              />
            </div>
          </template>
        </v-data-iterator>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { KPIResultWithStringValue } from './AnalysisResultDetails.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'KPITable',

  props: {
    items: {
      type: Array as PropType<KPIResultWithStringValue[]>,
      required: true,
      default: () => [],
    },

    loading: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: true,
    },
  },

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      currentPage: 1,
      itemsPerPage: 10,
    }
  },

  computed: {
    hasKpis (): boolean {
      return this.items.length > 0
    },
    itemsToDisplay (): KPIResultWithStringValue[] {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = this.currentPage * this.itemsPerPage
      return this.items.slice(start, end)
    },
    paginationPages (): number {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
  },
})
</script>

<style lang="sass" scoped>
:deep(.v-expansion-panel-content > .v-expansion-panel-content__wrap)
  padding-left: 0
  padding-right: 0

:deep(.v-select)
  .v-field__input
    font-size: 12px
</style>
