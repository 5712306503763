import { LocationQuery, RouteLocation } from 'vue-router'

/**
 * Checks the current router query and creates a query with updated filters, if necessary.
 * If the filter is supposed to be enabled and the query already contains it, or if the filter is supposed to be disabled
 * and the query does not contain it, returns early without a result.
 * If the filter is enabled it adds the key to the query, otherwise it removes it.
 * @param newDatapoint The data points view search that was set in the store
 * @param currentRoute The current route, used to check if the query needs to be updated.
 * @returns a new query with the required updates or undefined if nothing changed.
 */
export function updateCheckboxFlag (filterKey: string, enabled: boolean, currentRoute: RouteLocation): LocationQuery|undefined {
  if ((enabled && currentRoute.query[filterKey]) || (!enabled && !currentRoute.query[filterKey])) {
    return undefined
  }
  const newQuery: LocationQuery = JSON.parse(JSON.stringify(currentRoute.query))
  if (enabled) {
    newQuery[filterKey] = 'true'
  } else {
    delete newQuery[filterKey]
  }
  return newQuery
}
