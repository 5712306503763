/**
 * A helper library for all methods related to components or components in a project.
 */
import { AssignedPin, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'

/**
 * Returns assigned pin of a component in project if it was found by the passed alhpanumeric id.
 * @param pins The assinged pins of a component in a project.
 * @param alphanumericId used to find a pin with the same alhpanumeric id.
 * @returns Assinged pin of a component in project or null, if pin was not found.
 */
export function findPinOfComponentInProjectByAlphanumericId (pins: AssignedPin[], alphanumericId: string): AssignedPin|null {
  return pins.find((pin: AssignedPin) => pin.alphanumeric_id === alphanumericId) ?? null
}

/**
 * Returns a hash ID of a component in a project which belongs to a pin that will be looked up by the alphanumeric id.
 * If the pin was not found by the given alphanumeric id, null gets returned
 * @param componentInProject The component in a project that will be used for the look up of the pin and the hash ID.
 * @param alphanumericId used to find a pin with the same alhpanumeric id.
 * @returns Returns a hash ID string if the datapoint was found for a pin by its alhpanumeric id. Otherwise returns null.
 */
export function getHashIdOfPinByAlphanumericId (componentInProject: ComponentInProjectWithContext, alphanumericId: string): string|null {
  const foundPin: AssignedPin|null = findPinOfComponentInProjectByAlphanumericId(componentInProject.pins ?? [], alphanumericId)
  return foundPin?.datapoint_hash_id ?? null
}
