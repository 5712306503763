<script setup lang="ts">
import { ref, useAttrs, watch } from 'vue'

// make attributes accessable in setup script
const attrs = useAttrs()

// use a ref copy of the items to not manipulate the original array
const items = ref(attrs.items)

// watch for changes in the items prop
watch(() => attrs.items, (newItems) => {
  items.value = newItems as unknown[]
})

// masonry wall handles resize events but changes of items heights are not detected
// a redraw is forced on resize to recalculate the layout
onresize = () => {
  // assigning to a ref is a workaround to trigger a re-render
  // https://jan-mueller.at/blog/vue-masonry-wall/
  // While every prop of the component is reactive, mutations to the items array will not update the layout. To force updates, assign a new array to the items prop.
  items.value = (attrs.items as unknown[]).slice()
}
</script>

<template>
  <!-- Check for a valid items array. The div wrapper is needed! It behaves differently when the condition is placed at MasonryWall component. -->
  <div v-if="$attrs.items?.length">
    <!-- pass all other atrributes (including listeners) and replace items with the local items copy -->
    <MasonryWall v-bind="{...$attrs, items}">
      <!-- pass all used slots to the MasonryWall component -->
      <template
        v-for="(_, slot) in $slots"
        #[slot]="scope"
      >
        <!-- provide all usesd slots -->
        <slot
          :name="slot"
          v-bind="scope || {}"
        />
      </template>
    </MasonryWall>
  </div>
</template>
