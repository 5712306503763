import i18n from '@/i18n'
import { Task } from '@aedifion.io/aedifion-api'

/**
 * Get the color of the priority
 * @param priority The tasks priority
 * @returns The color of the priority
 */
export function getPriorityColor (priority: Task.PriorityEnum): 'warning' | 'error' | 'info' {
  switch (priority) {
    case Task.PriorityEnum.Low:
      return 'info'
    case Task.PriorityEnum.Medium:
      return 'warning'
    case Task.PriorityEnum.High:
      return 'error'
  }
}

/**
 * Get the icon of the priority
 * @param priority The tasks priority
 * @returns The icon of the priority
 */
export function getPriorityIcon (priority: Task.PriorityEnum): string {
  switch (priority) {
    case Task.PriorityEnum.Low:
      return 'fa:fas fa-dash'
    case Task.PriorityEnum.Medium:
      return 'fa:fas fa-chevron-up'
    case Task.PriorityEnum.High:
      return 'fa:fas fa-chevrons-up'
  }
}

/**
 * Get the text of the priority
 * @param priority The tasks priority
 * @returns The text of the priority
 */
export function getPriorityText (priority: Task.PriorityEnum): string {
  switch (priority) {
    case Task.PriorityEnum.Low:
      return i18n.global.t('checklist.priority.low')
    case Task.PriorityEnum.Medium:
      return i18n.global.t('checklist.priority.medium')
    case Task.PriorityEnum.High:
      return i18n.global.t('checklist.priority.high')
  }
}

/**
 * Get the color of the status
 * @param priority The tasks status
 * @returns The color of the status
 */
export function getPriorityClass (priority: Task.PriorityEnum): 'warning' | 'error' | 'info' {
  switch (priority) {
    case Task.PriorityEnum.Low:
      return 'info'
    case Task.PriorityEnum.Medium:
      return 'warning'
    case Task.PriorityEnum.High:
      return 'error'
  }
}

/**
 * Get the icon of the status
 * @param status The tasks status
 * @returns The icon of the status
 */
export function getStatusColor (status: Task.StatusEnum): string {
  switch (status) {
    case Task.StatusEnum.Open:
      return 'neutral lighten3'
    case Task.StatusEnum.Doing:
      return 'info lighten4'
    case Task.StatusEnum.Review:
      return 'info lighten4'
    case Task.StatusEnum.Done:
      return 'success lighten4'
    case Task.StatusEnum.Rejected:
      return 'warning lighten4'
    case Task.StatusEnum.Failed:
      return 'error lighten4'
  }
}

/**
 * Get the border color for the status
 * @param status The tasks status
 * @returns The border color variable for the status
 */
export function getStatusBorderColor (status: Task.StatusEnum): string {
  switch (status) {
    case Task.StatusEnum.Open:
      return 'neutral-lighten1'
    case Task.StatusEnum.Doing:
      return 'info-lighten3'
    case Task.StatusEnum.Review:
      return 'info-lighten3'
    case Task.StatusEnum.Done:
      return 'success-lighten3'
    case Task.StatusEnum.Rejected:
      return 'error-lighten3'
    case Task.StatusEnum.Failed:
      return 'error-lighten3'
  }
}

/**
 * Get the text color for the status
 * @param status The tasks status
 * @returns The text color class for the status
 */
export function getStatusTextColor (status: Task.StatusEnum): string {
  switch (status) {
    case Task.StatusEnum.Open:
      return 'text-neutral-darken2'
    case Task.StatusEnum.Doing:
      return 'text-info-darken2'
    case Task.StatusEnum.Review:
      return 'text-info-darken2'
    case Task.StatusEnum.Done:
      return 'text-success-darken2'
    case Task.StatusEnum.Rejected:
      return 'text-warning-darken2'
    case Task.StatusEnum.Failed:
      return 'text-error-darken2'
  }
}
