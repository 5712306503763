<i18n locale="de">
{
  "title": "Fehlende Berechtigung",
  "text": "Dir fehlen die erforderlichen Berechtigungen um diese Seite aufzurufen. Bitte wende dich an deinen Administrator um die Berechtigungen zu erhalten.",
  "btn": "Home"
}
</i18n>
<i18n locale="en">
{
  "title": "Missing authorization",
  "text": "You lack the necessary permissions to access this page. Please contact your administrator to get the missing permissions.",
  "btn": "Home"
}
</i18n>
<template>
  <div class="aedifion__auth__callback__wrapper d-flex justify-center align-center">
    <div class="forbidden__wrapper d-flex justify-center align-center ma-4 pa-4 flex-column">
      <v-icon
        class="mb-4"
        color="neutral-darken3"
        size="x-large"
      >
        fa:fas fa-user-shield
      </v-icon>
      <span class="text-h6 mb-2">{{ t('title') }}</span>
      <span class="body text-center mb-4">{{ t('text') }}</span>
      <v-btn
        color="primary"
        variant="outlined"
        to="/"
      >
        {{ t('btn') }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Forbidden',

  setup () {
    const { t } = useI18n()
    return { t }
  },

})
</script>

<style lang="sass" scoped>
  .forbidden__wrapper
    max-width: 800px
</style>
