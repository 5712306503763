<script setup lang="ts">
import { type AvailableLocales, setLocale } from '@/i18n'
import { computed, reactive, ref, watch } from 'vue'
import type { UpdateUser, User } from '@aedifion.io/aedifion-api'
import { captureMessage } from '@sentry/vue'
import isEqual from 'lodash/isEqual'
import { required } from '@vuelidate/validators'
import { SelectItem } from '@/utils/types'
import texts from '@theme/texts'
import { useI18n } from 'vue-i18n'
import { useReportingStore } from '@/stores/views/Reporting'
import { useUserProfileStore } from '@/stores/views/User/profile'
import { useUserStore } from '@/stores/user'
import { useVuelidate } from '@vuelidate/core'
import { useLocale as useVuetifyLocale } from 'vuetify'
import { useStore as useVuexStore } from '@/vuex'

const emits = defineEmits<{
  'cookie-manager:open': []
}>()

const vuexStore = useVuexStore()

const reportingStore = useReportingStore()
const userStore = useUserStore()
const userProfileStore = useUserProfileStore()

const { t, te, availableLocales } = useI18n()
const { current: currentVuetifyLocale } = useVuetifyLocale()

const companyName: string = texts.companyName

interface TUserProfileData {
  address: string;
  currencySystems: string;
  email: string;
  firstName: string;
  lastName: string;
  locale: string;
  phone: string;
  unitSystems: string;
}

const originalProfileData: TUserProfileData = reactive({
  address: '',
  currencySystems: '',
  email: '',
  firstName: '',
  lastName: '',
  locale: '',
  phone: '',
  unitSystems: '',
})

const profileData: TUserProfileData = reactive({
  address: '',
  currencySystems: '',
  email: '',
  firstName: '',
  lastName: '',
  locale: '',
  phone: '',
  unitSystems: '',
})

const rules = computed(() => ({
  profileData: {
    firstName: { required },
    lastName: { required },
  },
}))

const v$ = useVuelidate(rules, { profileData })

const submittable = ref<boolean>(false)

const availableCurrencies = computed<string[]>(() => {
  const currencies = vuexStore.getters['labels/availableCurrencies']
  if (currencies) {
    return currencies
  }

  return []
})

const availableLanguages = computed<SelectItem[]>(() => {
  return (availableLocales).map((locale) => {
    return {
      title: t(`locale_names.${locale}`),
      value: locale,
    }
  })
})

const availableUnitSystems = computed<SelectItem[]>(() => {
  return (vuexStore.getters['labels/availableUnitSystems'] ?? []).map((unitSystem: string) => {
    let title = unitSystem
    if (te(`unit_systems.${unitSystem}`)) {
      title = t(`unit_systems.${unitSystem}`)
    } else {
      captureMessage(`Missing translation for unit_system.${unitSystem}`, 'warning')
    }
    return {
      title,
      value: unitSystem,
    }
  })
})

const firstNameErrors = computed<string>(() => {
  if (v$.value.profileData.firstName.$dirty && v$.value.profileData.firstName.required && v$.value.profileData.firstName.$error) {
    return t('errors.is_required', { field: t('form_fields.first_name') })
  }
  return ''
})

const lastNameErrors = computed<string>(() => {
  if (v$.value.profileData.lastName.$dirty && v$.value.profileData.lastName.required && v$.value.profileData.lastName.$error) {
    return t('errors.is_required', { field: t('form_fields.last_name') })
  }
  return ''
})

const unitsSystemLocked = computed<boolean>(() => {
  return window.configuration.REALM !== 'demo'
})

function checkFormSubmittable (): void {
  submittable.value = !v$.value.$errors.length && !isEqual(originalProfileData, profileData)
}

function resetForm () {
  for (const key in profileData) {
    profileData[key as keyof TUserProfileData] = originalProfileData[key as keyof TUserProfileData]
  }

  submittable.value = false
}

function setUserData (user: User): void {
  originalProfileData.address = user.address ?? ''
  originalProfileData.currencySystems = user.currency_system ?? ''
  originalProfileData.email = user.email ?? ''
  originalProfileData.firstName = user.firstName ?? ''
  originalProfileData.lastName = user.lastName ?? ''
  originalProfileData.phone = user.phone ?? ''
  originalProfileData.unitSystems = user.units_system ?? ''
  originalProfileData.locale = user.locale ?? ''
  resetProfileData()
}

function resetProfileData (): void {
  for (const key in profileData) {
    profileData[key as keyof TUserProfileData] = originalProfileData[key as keyof TUserProfileData]
  }
}

async function submit (): Promise<void> {
  if (!isEqual(originalProfileData, profileData)) {
    v$.value.$touch()
    if (!v$.value.$errors.length) {
      const payload: UpdateUser = {
        address: profileData.address,
        currency_system: profileData.currencySystems === '' ? undefined : profileData.currencySystems,
        email: profileData.email,
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        locale: profileData.locale,
        phone: profileData.phone,
        units_system: profileData.unitSystems === '' ? undefined : profileData.unitSystems,
      }

      await userProfileStore.updateDetails(payload)

      reportingStore.clearState()

      setLocale(profileData.locale as AvailableLocales)
      currentVuetifyLocale.value = profileData.locale

      for (const key in profileData) {
        originalProfileData[key as keyof TUserProfileData] = profileData[key as keyof TUserProfileData]
      }
    }
  }

  submittable.value = false
}

watch(() => userStore.userDetails, (newValue: User|null) => {
  if (newValue) {
    setUserData(newValue)
  }
}, {
  immediate: true,
})
</script>

<template>
  <form
    autocomplete="on"
    @keydown.esc="resetForm"
    @submit.prevent="submit"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="profileData.firstName"
          autocomplete="given-name"
          data-hj-suppress
          :error-messages="firstNameErrors"
          variant="filled"
          color="primary-darken2"
          hide-details="auto"
          :label="t('form_fields.first_name')"
          @update:model-value="
            v$.profileData.firstName.$touch();
            checkFormSubmittable();
          "
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="profileData.lastName"
          autocomplete="family-name"
          data-hj-suppress
          :error-messages="lastNameErrors"
          variant="filled"
          hide-details="auto"
          :label="t('form_fields.last_name')"
          color="primary-darken2"
          @update:model-value="
            v$.profileData.lastName.$touch();
            checkFormSubmittable();
          "
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="profileData.address"
          append-inner-icon="fa:fas fa-map-marker"
          autocomplete="street-address"
          data-hj-suppress
          variant="filled"
          color="primary-darken2"
          hide-details="auto"
          :label="t('form_fields.address')"
          @update:model-value="checkFormSubmittable()"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
      >
        <v-text-field
          v-model="profileData.phone"
          append-inner-icon="fa:fas fa-phone"
          autocomplete="tel"
          color="primary-darken2"
          data-hj-suppress
          variant="filled"
          hide-details="auto"
          :label="t('form_fields.phone')"
          @update:model-value="checkFormSubmittable()"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="profileData.email"
          append-inner-icon="fa:fas fa-envelope"
          data-hj-suppress
          color="primary-darken2"
          disabled
          variant="filled"
          :hint="t('email_support_hint')"
          :label="t('form_fields.email')"
          persistent-hint
        />
      </v-col>
      <v-col
        cols="12"
        data-cy="user-profile-language"
      >
        <v-select
          v-model="profileData.locale"
          data-hj-suppress
          variant="filled"
          color="primary-darken2"
          hide-details
          :items="availableLanguages"
          :label="t('form_fields.language')"
          @update:model-value="checkFormSubmittable()"
          @keydown.enter.prevent
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-tooltip
          :disabled="!unitsSystemLocked"
          location="top"
        >
          <template #activator="{ props: unitSystemLockedTooltipProps }">
            <div
              v-bind="unitSystemLockedTooltipProps"
            >
              <v-select
                v-model="profileData.unitSystems"
                data-cy="user-profile-unit-system"
                :disabled="unitsSystemLocked"
                variant="filled"
                hide-details
                color="primary-darken2"
                :items="availableUnitSystems"
                :label="t('form_fields.unit_systems')"
                @update:model-value="checkFormSubmittable()"
                @keydown.enter.prevent
              />
            </div>
          </template>
          <span v-text="t('unit_system_locked_tooltip', { companyName })" />
        </v-tooltip>
      </v-col>
      <v-col
        cols="12"
        data-cy="user-profile-currency-systems"
        sm="6"
      >
        <v-select
          v-model="profileData.currencySystems"
          variant="filled"
          hide-details
          :items="availableCurrencies"
          color="primary-darken2"
          :label="t('form_fields.currency_systems')"
          @update:model-value="checkFormSubmittable()"
          @keydown.enter.prevent
        />
      </v-col>
      <v-col
        class="d-flex flex-row justify-space-between"
        cols="12"
      >
        <v-btn
          class="text-primary-darken2"
          color="primary-lighten3"
          data-cy="cookie-manager-button"
          @click="emits('cookie-manager:open')"
        >
          <span>{{ t('open_cookie_manager') }}</span>
          <v-icon
            end
            size="small"
          >
            fa:far fa-cookie
          </v-icon>
        </v-btn>
        <v-btn
          :block="$vuetify.display.name === 'xs'"
          color="primary-darken2"
          data-cy="save-button"
          :disabled="!submittable"
          :loading="userProfileStore.isDetailsUpdatePending"
          location="right"
          type="submit"
        >
          {{ t("actions.save") }}
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<i18n lang="json" locale=de>
  {
    "email_support_hint": "Es ist nicht möglich die E-Mail Adresse manuell zu ändern. Bitte kontaktiere den Support wenn eine Änderung notwendig sein sollte.",
    "open_cookie_manager": "Cookie Einstellungen",
    "unit_system_locked_tooltip": "Bitte kontaktiere das {companyName} Team um ein anderes Einheitensystem auszuwählen."
  }
  </i18n>
<i18n lang="json" locale=en>
  {
    "email_support_hint": "It is not possible to change the email address manually. Please contact the support if it needs to be changed.",
    "open_cookie_manager": "Cookie Settings",
    "unit_system_locked_tooltip": "Please contact the {companyName} team to select another unit system."
  }
</i18n>
