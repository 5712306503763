import { DBSchema, IDBPDatabase, openDB } from 'idb'
import { isIDBAvailable } from '@/utils/helpers/idb'

const CURRENT_SCHEMA_VERSION = 1
const SEARCH_DATABASE = 'search'

const MAX_ENTRIES_PER_STORE = 100

export enum SearchStore {
  SavingsPotential = 'savings-potential'
}

interface StoredSearch {
  date: Date,
  search: string
}

interface SearchDb extends DBSchema {
  [SearchStore.SavingsPotential]: {
    indexes: { 'by-date': Date, 'by-search': string },
    key: string,
    value: StoredSearch
  }
}

function initiateObjectStore (db: IDBPDatabase<SearchDb>, storeName: SearchStore) {
  const store = db.createObjectStore(storeName, { autoIncrement: true })
  store.createIndex('by-date', 'date', { unique: false })
  store.createIndex('by-search', 'search', { unique: true })
}

let database: IDBPDatabase<SearchDb> | undefined

/**
 * Use this function to open or retrieve (if it’s already open) the search
 * history IndexedDb database using a singleton.
 * @returns The current search history IndexedDb database.
 */
async function getDatabase (): Promise<IDBPDatabase<SearchDb> | undefined> {
  const idbAvailable: boolean = await isIDBAvailable()
  if (!database && idbAvailable) {
    database = await openDB(SEARCH_DATABASE, CURRENT_SCHEMA_VERSION, {
      upgrade (db, oldSchemaVersion) {
        if (oldSchemaVersion < 1) {
          // this database has never been open before, we create the first store
          initiateObjectStore(db, SearchStore.SavingsPotential)
        }
        if (oldSchemaVersion < 2) {
          // put your code to migrate from v1 to v2 here
        }
      },
    })
  }

  return database
}

/**
 * Retrieves the most recent searches that correspond to the typed string.
 * @param store A "silo" of searches. Each search is assigned a store, and
 * retrieving a list of searches is only ever done from a specific store.
 * @returns The ordered array of searches (most recent first).
 */
export async function getSearches (store: SearchStore): Promise<string[]> {
  try {
    const db = await getDatabase()
    const results: string[] = (await db?.getAllFromIndex(store, 'by-date'))?.map((storedSearch: StoredSearch) => storedSearch.search) ?? []

    // the index is stored from oldest to newest so we need to reverse it
    return results.reverse()
  } catch (error) {
    return []
  }
}

/**
 * Define a search that should appear as a suggestion.
 * @param store A "silo" of searches. Each search is assigned a store, and
 * retrieving a list of searches is only ever done from a specific store.
 * @param search The search we wish to store.
 */
export async function storeSearch (store: SearchStore, search: string): Promise<void> {
  const db = await getDatabase()

  // if there’s no identical search that’s already stored,
  // existingEntry is undefined and .put() creates a new entry
  const existingEntry = await db?.getKeyFromIndex(
    store,
    'by-search',
    search,
  )

  await db?.put(store, {
    date: new Date(),
    search,
  }, existingEntry)

  let cursor = await db?.transaction(store, 'readwrite').store.index('by-date').openCursor()

  while (cursor && ((await cursor.source.count()) ?? 0) > MAX_ENTRIES_PER_STORE) {
    await cursor.delete()
    cursor = await cursor.continue()
  }
}
