<script setup lang="ts">
import { type ActionMenuItem } from '@/components/ActionMenu.vue'
import { docsLink } from '@/utils/helpers/locale'
import KebabMenu from '@/components/KebabMenu.vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  projectId: number
}>()

const emit = defineEmits<{
  (e: 'edit-option-click'): void
}>()

const { t } = useI18n()

const editUsersLink = {
  name: 'administration-users',
  query: {
    projects: props.projectId.toString(),
  },
}
const apiLink = `${window.configuration.AEDIFION_API_URL}/ui`
const documentationLink = docsLink('home')!

const items = ref<ActionMenuItem[]>([{
  icon: 'fa:far fa-pen',
  id: 'edit',
  label: t('manage_users'),
  to: editUsersLink,
}, {
  divider: true,
  href: apiLink,
  icon: 'fa:far fa-arrow-up-right',
  id: 'open-api',
  label: t('api'),
  target: '_blank',
}, {
  href: documentationLink,
  icon: 'fa:far fa-arrow-up-right',
  id: 'open-api-documentation',
  label: t('documentation'),
  target: '_blank',
}])
</script>

<template>
  <KebabMenu
    :items="items"
    small
    offset-direction="y"
    @edit-option-click="emit('edit-option-click')"
  />
</template>

<i18n lang="json" locale="de">
{
  "manage_users": "Benutzer verwalten",
  "api": "API",
  "documentation": "Dokumentation"
}
</i18n>
<i18n lang="json" locale="en">
{
  "manage_users": "Manage users",
  "api": "API",
  "documentation": "Documentation"
}
</i18n>
