<script setup lang="ts">
import { type Asset, type FetchKpiAggregationPayload, type PortfolioAsset } from '@/stores/views/Reporting/types'
import ButtonGroup, { type Button } from '@/components/ButtonGroup.vue'
import { computed, ref, watch } from 'vue'
import { formatBuildingAttributes, formatNetFloorArea } from '@/filters/buildingAttributes'
import moment from 'moment'
import { onInitialApiRequestsCompleted } from '@/utils/helpers/hooks'
import ReportingCard from './ReportingCard.vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useReportingStore } from '@/stores/views/Reporting'
import vuexStore from '@/vuex'

const { t, te } = useI18n()

const reportingStore = useReportingStore()

const { isPerSquareUnit } = storeToRefs(reportingStore)

const dateRange = computed(() => reportingStore.dateRange)

const areInitialApiRequestsCompleted = ref<boolean>(false)

const kpiType = ref<'perSquareUnit'|'absolute'>('perSquareUnit')

const aggregationType = computed<'sum'|'avg'>({
  get () {
    if (kpiType.value === 'perSquareUnit') {
      return 'avg'
    }
    return reportingStore.aggregationType
  },
  set (newAggregationType: 'sum' | 'avg') {
    reportingStore.aggregationType = newAggregationType
  },
})

const assets = computed<Asset[]|null>(() => reportingStore.assets(kpiType.value))

const isLoadingKpiAggregation = computed<boolean>(() => reportingStore.isLoadingKpiAggregation)

const portfolioKpis = computed<PortfolioAsset|undefined>(() => {
  const kpis = reportingStore.getPortfolioKpis(kpiType.value)
  if (aggregationType.value === 'sum') {
    return kpis?.sum
  } else {
    return kpis?.avg
  }
})

const portfolioDescription = computed<string>(() => {
  const floorArea = portfolioKpis?.value?.floorArea ?? ''
  const totalProjects = portfolioKpis.value?.totalProjectsWithTranslation

  return `${formatNetFloorArea(floorArea)} ${totalProjects ? '· ' + totalProjects : ''}`
})

const kpiHeaders = computed<string[]>(() => {
  if (!portfolioKpis.value) throw new Error('portfolioKpis.value is undefined')

  return Object.keys(portfolioKpis.value.kpis)
})

const buttons: Button[] = [{
  activeIcon: 'fa:far fa-check',
  id: 'sum',
  label: 'Sum',
}, {
  activeIcon: 'fa:far fa-check',
  id: 'avg',
  label: 'Avg',
}]

const lengthOfProjects = computed<number>(() => {
  return vuexStore.getters['projects/getProjectsIds'].length || 10
})

onInitialApiRequestsCompleted(() => {
  areInitialApiRequestsCompleted.value = true
  watch([() => dateRange.value, kpiType],
    async ([newDateRange, newKPIValue]) => {
      const newStartDate = moment.utc(moment(newDateRange[0]).format('YYYY-MM'), 'YYYY-MM-DD').startOf('month').toDate()
      const newEndDate = newDateRange[1] ? moment.utc(moment(newDateRange[1]).format('YYYY-MM'), 'YYYY-MM-DD').add(1, 'month').startOf('month').toDate() : moment.utc(moment(newDateRange[0]).format('YYYY-MM'), 'YYYY-MM-DD').add(1, 'month').startOf('month').toDate()
      await reportingStore.fetchKpiAggregationData({
        end: newEndDate,
        kpiType: newKPIValue,
        start: newStartDate,
      } as FetchKpiAggregationPayload)
    }, {
      deep: true,
      immediate: true,
    })
})

watch(isPerSquareUnit,
  (isPerSquareUnit) => {
    const newKPIType = isPerSquareUnit ? 'perSquareUnit' : 'absolute'
    if (kpiType.value !== newKPIType) {
      kpiType.value = newKPIType
    }
  }, {
    immediate: false,
  })

/**
   * This var is exposed, because it is being used in the tests
   */
defineExpose({
  areInitialApiRequestsCompleted,
})
</script>

<template>
  <div
    v-if="!isLoadingKpiAggregation && areInitialApiRequestsCompleted"
    class="reporting-list"
    data-testid="reporting-list"
  >
    <ReportingCard
      class="mb-2 first-card"
      :description="portfolioDescription"
      title="Portfolio"
      :values="portfolioKpis?.kpis ?? {}"
      :value-text-class="'font-weight-semibold'"
      :is-per-square-unit="isPerSquareUnit"
    >
      <template #default>
        <ButtonGroup
          v-if="kpiType === 'absolute'"
          v-model="aggregationType"
          :buttons="buttons"
          class="button-group mr-4"
        />
      </template>
      <template #headers="{ index }">
        <div
          class="reporting-card__header"
          data-testid="reporting-card-header"
        >
          <v-tooltip location="top">
            <template #activator="{ props }">
              <span

                v-bind="props"
              >{{ t(`${kpiHeaders[index]}`) }}</span>
            </template>
            <span v-if="te(`${kpiHeaders[index]}_full`)">{{ t(`${kpiHeaders[index]}_full`) }}</span>
            <span v-else>{{ t(`${kpiHeaders[index]}`) }}</span>
          </v-tooltip>
        </div>
      </template>
    </ReportingCard>
    <ReportingCard
      v-for="asset in assets"
      :id="asset.id"
      :key="`asset_key_${asset.id}`"
      :title="asset.name"
      :description="formatBuildingAttributes(asset.floorArea, asset.type, asset.city, 'm²', {capitalize : true})"
      :values="asset?.kpis ?? {}"
      :is-per-square-unit="isPerSquareUnit"
    />
  </div>
  <div
    v-else
    data-testid="reporting-list-skeleton"
    class="mt-n4"
  >
    <v-row
      class="d-flex flex-row justify-end tw-content-end mr-2"
    >
      <v-col
        v-for="(index) in 5"
        :key="index"
        cols="2"
      >
        <v-skeleton-loader
          type="text"
          class="ml-auto bg-transparent"
          width="80%"
        />
      </v-col>
    </v-row>
    <v-skeleton-loader
      type="table-row"
      class="v-sheet px-4 mb-2 reporting-card__skeleton-loader"
    />
    <v-sheet
      v-for="i in lengthOfProjects"
      :key="i"
      class=""
    >
      <v-skeleton-loader
        type="table-row"
        class="px-4 reporting-card__skeleton-loader"
      />
    </v-sheet>
  </div>
</template>

<style lang="sass" scoped>
  /* This specific number was chosen, because at 1320px viewport width, we have the following:
     SideNav-width: 200px;
     margin on the left and right: 80px;
     The left over width is 1320 - 200 - 80 = 1040px which exactly equals the min-width of the card. */
  @media screen and (max-width: 1319px)
    .reporting-list
      overflow-x: scroll
      overflow-y: scroll
      height: calc(100vh - 120px)
    .first-card
      :deep(.sticky::before)
        content: ''
        position: absolute
        top: -43px
        left: -2px
        background: rgb(var(--v-background))
        width: 240px
        height: 42px

  :deep(.reporting-card__wrapper)
    &:nth-child(1)
      .reporting-card
        border: 0
        border: 1px solid rgb(var(--v-theme-neutral-lighten1))
        border-radius: 4px
        border-left: 0 !important
      .sticky
        border-top-left-radius: 4px
        border-bottom-left-radius: 4px
      .reporting-card
        border: 1px solid rgb(var(--v-theme-neutral-lighten1))
        border-radius: 4px
        border-left: 0 !important
    &:nth-child(2)
      .reporting-card
        border-radius: 4px
        border-bottom-right-radius: 0
        border-bottom-left-radius: 0
        border-top-right-radius: 4px
      .sticky
        border-top-left-radius: 4px
    &:nth-child(n+2)
      .reporting-card
        border-bottom: 0
    &:last-child
      .reporting-card
        border-radius: 4px
        border-top-right-radius: 0
        border-top-left-radius: 0
        border-bottom-right-radius: 4px
        border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1)) !important
      .sticky
        border-bottom-left-radius: 4px

  .reporting-card__skeleton-loader
    padding-top: 3px
    padding-bottom: 3px

  [data-testid="reporting-list-skeleton"] > .v-sheet
    &:nth-child(n+2)
      border-radius: 0 !important
      border-bottom: 0
    &:nth-child(2)
      border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1)) !important
      border-radius: 4px !important
    &:nth-child(3)
      border-top-right-radius: 4px !important
      border-top-left-radius: 4px !important
    &:last-child
      border-radius: 4px
      border-top-right-radius: 0
      border-top-left-radius: 0
      border-bottom-right-radius: 4px !important
      border-bottom-left-radius: 4px !important
      border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1)) !important
  .reporting-card__header
    position: absolute
    top: -50px
    right: 0
    width: 100%
    white-space: nowrap
    text-align: right
    color: rgb(var(--v-theme-neutral-darken3))
    font-size: 12px
    font-weight: 500
    padding: 6px 0px
    &:hover
      background-color: rgb(var(--v-theme-neutral-lighten2))
</style>

<!-- Added this eslint ignore because the locales are actually being used, but the eslint plugin cannot detect dynamic locales -->
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n lang="json" locale="de">
  {
    "technical_availability": "TGA",
    "technical_availability_full": "Technische Verfügbarkeit",
    "technical_availability_absolute": "TGA",
    "technical_availability_absolute_full": "Technische Verfügbarkeit",
    "energy_cost": "Energiekosten",
    "energy_cost_absolute": "Energiekosten",
    "energy_consumption": "Energieverbrauch",
    "energy_consumption_absolute": "Energieverbrauch",
    "co2_emissions": "CO₂-Emissionen",
    "co2_emissions_absolute": "CO₂-Emissionen",
    "productivity": "Wohlbefinden",
    "productivity_absolute": "Wohlbefinden"
  }
  </i18n>
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
  <i18n lang="json" locale="en">
  {
    "technical_availability": "TA",
    "technical_availability_full": "Technical availability",
    "technical_availability_absolute": "TA",
    "technical_availability_absolute_full": "Technical availability",
    "energy_cost": "Energy cost",
    "energy_cost_absolute": "Energy cost",
    "energy_consumption": "Energy consumption",
    "energy_consumption_absolute": "Energy consumption",
    "co2_emissions": "CO₂ emissions",
    "co2_emissions_absolute": "CO₂ emissions",
    "productivity": "Wellbeing",
    "productivity_absolute": "Wellbeing"
  }
  </i18n>
