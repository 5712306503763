<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { TIMESERIES_COLORS, VUETIFY_COLORS } from '@theme/colors'
import { Chart } from 'highcharts-vue'
import { ChartData } from './types'
import { formatSubAndSuper } from '@/filters/formatting'
import Highcharts from 'highcharts'
import { merge } from 'lodash'
import { TYPOGRAPHY } from '@/utils/designConstants'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface Props {
  customChartOptions?: Highcharts.ChartOptions
  data: Array<ChartData>
  loading?: boolean
  title?: string|null
  unit?: string|null
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  title: null,
  unit: null,
})

const chart = ref<typeof Chart|null>()

watch(
  () => props.loading,
  (newVal: boolean) => {
    if (newVal === true) {
      chart.value?.chart.showLoading()
    } else {
      chart.value?.chart.hideLoading()
    }
  },
)

const chartOptions = computed(() => {
  const seriesData = props.data.map((item: ChartData) => {
    return {
      name: item.name,
      y: item.values[0],
    }
  })
  const result: Highcharts.Options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      plotBackgroundColor: undefined,
      plotBorderWidth: 0,
      plotShadow: false,
      spacingTop: 20,
      type: 'pie',
    },
    colors: TIMESERIES_COLORS,
    credits: {
      enabled: false,
    },
    legend: {
      align: 'left',
      floating: true,
      itemStyle: {
        color: VUETIFY_COLORS.neutral.darken3,
        cursor: 'auto',
        fontSize: TYPOGRAPHY.headings.legend.size,
        fontWeight: TYPOGRAPHY.headings.legend.weight,
        textOverflow: 'ellipsis',
      },
      labelFormat: props.unit ? `{name} (${formatSubAndSuper(props.unit)})` : '{name}',
      layout: 'vertical',
      useHTML: true,
      verticalAlign: 'middle',
      x: -16,
    },
    loading: {
      style: {
        opacity: 0.8,
      },
      // @ts-ignore
      useHTML: true,
    },
    noData: {
      position: {
        x: 50,
      },
      useHTML: true,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    series: [
      {
        center: ['75%', '50%'],
        data: seriesData,
        innerSize: '65%',
        type: 'pie',
      },
    ],
    title: {
      align: 'left',
      style: {
        color: VUETIFY_COLORS.neutral.darken3,
        fontSize: TYPOGRAPHY.headings.h6.size,
        fontWeight: TYPOGRAPHY.headings.h6.weight,
      },
      text: props.title ?? '',
      useHTML: true,
      verticalAlign: 'top',
      widthAdjust: 0,
      x: -10,
      y: 0,
    },
    tooltip: {
      pointFormat: `<b>{point.y:.1f} ${props.unit ? formatSubAndSuper(props.unit) : ''}</b>`,
      useHTML: true,
    },
  }
  merge(result, props.customChartOptions)
  return result
})

// --- execution ---

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: "'Inter', sans-serif",
    },
  },
  lang: {
    loading: t('highcharts.loading') as string,
  },
})
</script>

<template>
  <Chart
    ref="chart"
    class="pie-chart fill-height"
    :options="chartOptions"
    :update-args="[true, true, true]"
  />
</template>

<style lang="sass" scoped>
@use '@/scss/settings' as vuetifySettings

:deep(.pie-chart)
  .highcharts
    &-title
      min-height: 28px !important
    &-loading
      top: 0px !important
      left: 0px !important
      width: 100% !important
:deep(.highcharts-tooltip)
  &, & span
    font-size: map-get(vuetifySettings.$typography, 'legend', 'size') !important
:deep(.highcharts-tooltip b)
  font-weight: 500 !important
</style>
