<i18n lang="json" locale="de">
{
  "title": "Komponente Umbenennen",
  "update_component_name": "um eine Komponente umzubenennen",
  "validation": "Komponentenname ist erforderlich",
  "name_en": "Komponentenname (Englisch)",
  "name_de": "Komponentenname (Deutsch)"
}
</i18n>
<i18n lang="json" locale="en">
{
  "title": "Rename Component",
  "update_component_name": "to rename a component",
  "validation": "Component Name is Required",
  "name_en": "Component name (English)",
  "name_de": "Component name (German)"
}
</i18n>

<template>
  <v-dialog
    max-width="416px"
    persistent
    :model-value="modelValue"
    data-testid="rename-component-dialog"
    @keydown.escape="cancel"
  >
    <form
      autocomplete="on"
      @submit.prevent="saveNewComponentName()"
    >
      <v-card class="ma-2 pa-6 aedifion-box-shadow">
        <v-card-title class="pa-0 mb-4">
          <span>{{ t('title') }}</span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-text-field
            v-model.trim="newComponentNameEN"
            :autofocus="$i18n.locale === 'en'"
            variant="filled"
            :label="t('name_en')"
            data-cy="rename-component-input-field-en"
            hide-details
          />
          <span
            v-if="newComponentNameEN === ''"
            style="color: red"
          >
            {{ t('validation') }}
          </span>
        </v-card-text>

        <v-card-text class="pa-0 mt-4">
          <v-text-field
            v-model.trim="newComponentNameDE"
            :autofocus="$i18n.locale === 'de'"
            :label="t('name_de')"
            data-cy="rename-component-input-field-de"
            hide-details
            variant="filled"
          />
          <span
            v-if="newComponentNameDE === ''"
            style="color: red"
          >
            {{ t('validation') }}
          </span>
        </v-card-text>

        <v-card-actions class="pa-0 mt-4">
          <v-btn
            color="primary-lighten3"
            data-cy="rename-component-cancel"
            variant="elevated"
            class="flex-grow-1 text-primary-darken2"
            @click="cancel"
          >
            {{ t('actions.cancel') }}
            <v-icon
              end
              size="14"
            >
              fa:far fa-xmark
            </v-icon>
          </v-btn>
          <v-btn
            color="primary-darken2"
            variant="elevated"
            class="flex-grow-1 text-white"
            data-cy="rename-component-save"
            :disabled="isSaveButtonDisabled"
            type="submit"
          >
            {{ t('actions.save') }}
            <v-icon
              end
              size="14"
            >
              fa:far fa-check
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import { InvalidValueError } from '@/utils/helpers/validate'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'RenameComponentDialog',

  props: {
    itemData: {
      required: true,
      type: Object as PropType<ComponentInProjectListItemData>,
    },
    modelValue: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },

  emits: ['update:modelValue'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      newComponentNameDE: this.itemData.nameDE ?? '' as string,
      newComponentNameEN: this.itemData.nameEN ?? '' as string,
    }
  },

  computed: {
    componentNamesToUpdate (): ('en' | 'de')[] {
      const results: ('en'|'de')[] = []
      if (this.newComponentNameEN !== '' && this.newComponentNameEN !== this.itemData.nameEN) {
        results.push('en')
      }

      if (this.newComponentNameDE !== '' && this.newComponentNameDE !== this.itemData.nameDE) {
        results.push('de')
      }

      return results
    },
    isSaveButtonDisabled (): boolean {
      return this.componentNamesToUpdate.length === 0
    },
  },

  methods: {
    cancel (): void {
      this.$emit('update:modelValue', false)
      this.newComponentNameEN = this.itemData.nameEN ?? ''
      this.newComponentNameDE = this.itemData.nameDE ?? ''
    },

    async saveNewComponentName () {
      const shouldUpdateNameEN = this.componentNamesToUpdate.includes('en')
      const shouldUpdateNameDE = this.componentNamesToUpdate.includes('de')

      const payload = {
        componentInProjectID: this.itemData.id,
        name: {
          de: shouldUpdateNameDE ? this.newComponentNameDE : undefined,
          en: shouldUpdateNameEN ? this.newComponentNameEN : undefined,
        },
      }

      try {
        this.$emit('update:modelValue', false)
        await this.$store.dispatch('components_in_project/updateComponentName', payload)
      } catch (error) {
        if (error instanceof InvalidValueError) {
          showErrorNotification(`${this.t('notifications.errors.no_project_selected_with_attempted_action', { action: this.t('update_component_name') })}`)
        }
        reportError(error)
      }
    },
  },
})
</script>

<style lang="sass" scoped>
:deep(.v-label )
  color: rgb(var(--v-theme-neutral-darken1)) !important
</style>
