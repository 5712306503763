import { ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { SystemComponentsTimeseries } from './types'

export function sampleDataForSystemComponents (): ComponentInProjectWithContext[] {
  return [
    {
      attributes: [
        {
          alphanumeric_id: 'S+ICON',
          id: 36,
          key: 'S+ICON',
          value: 'fa:fal fa-temperature-frigid',
        },
      ],
      component: {
        alphanumeric_id: 'S',
        id: 28,
        nameDE: 'System',
        nameEN: 'System',
      },
      display_name: 'Cooling system | System',
      id: 198,
      nameDE: 'Kältesystem',
      nameEN: 'Cooling system',
      pins: [
        {
          alphanumeric_id: 'S+STAT_OPR',
          dataPointID: 'Status-Cooling',
          id: 288,
          location: 'bottom',
          nameDE: 'Betriebsstatus',
          nameEN: 'Operational state',
          pin_attributes: [
            {
              id: 8090,
              key: 'L1_analog_digital',
              probability: 1,
              value: '/multi-state',
            },
            {
              id: 2,
              key: 'L2_virtual',
              probability: 1,
              value: '/physical',
            },
            {
              id: 3,
              key: 'L3_direction',
              probability: 1,
              value: '/input',
            },
            {
              id: 8091,
              key: 'L4_type',
              probability: 1,
              value: '/message/modus',
            },
            {
              id: 43,
              key: 'L5_unit',
              probability: 1,
              value: '/scalar/generic',
            },
          ],
        },
      ],
      project_id: 15,
    },
    {
      attributes: [
        {
          alphanumeric_id: 'S+ICON',
          id: 39,
          key: 'S+ICON',
          value: 'fa:fal fa-sort',
        },
      ],
      component: {
        alphanumeric_id: 'S',
        id: 28,
        nameDE: 'System',
        nameEN: 'System',
      },
      display_name: 'Elevators | System',
      id: 201,
      nameDE: 'Aufzüge',
      nameEN: 'Elevators',
      pins: [
        {
          alphanumeric_id: 'S+STAT_OPR',
          dataPointID: 'Status-Elevator-3',
          id: 288,
          location: 'bottom',
          nameDE: 'Betriebsstatus',
          nameEN: 'Operational state',
          pin_attributes: [
            {
              id: 8090,
              key: 'L1_analog_digital',
              probability: 1,
              value: '/multi-state',
            },
            {
              id: 2,
              key: 'L2_virtual',
              probability: 1,
              value: '/physical',
            },
            {
              id: 3,
              key: 'L3_direction',
              probability: 1,
              value: '/input',
            },
            {
              id: 8091,
              key: 'L4_type',
              probability: 1,
              value: '/message/modus',
            },
            {
              id: 43,
              key: 'L5_unit',
              probability: 1,
              value: '/scalar/generic',
            },
          ],
        },
      ],
      project_id: 15,
    },
    {
      attributes: [
        {
          alphanumeric_id: 'S+ICON',
          id: 40,
          key: 'S+ICON',
          value: 'fa:fal fa-fire',
        },
      ],
      component: {
        alphanumeric_id: 'S',
        id: 28,
        nameDE: 'System',
        nameEN: 'System',
      },
      display_name: 'Fire alarm system | System',
      id: 202,
      nameDE: 'Brandmeldeanlage',
      nameEN: 'Fire alarm system',
      pins: [
        {
          alphanumeric_id: 'S+STAT_OPR',
          dataPointID: 'Status-FireAlert',
          id: 288,
          location: 'bottom',
          nameDE: 'Betriebsstatus',
          nameEN: 'Operational state',
          pin_attributes: [
            {
              id: 8090,
              key: 'L1_analog_digital',
              probability: 1,
              value: '/multi-state',
            },
            {
              id: 2,
              key: 'L2_virtual',
              probability: 1,
              value: '/physical',
            },
            {
              id: 3,
              key: 'L3_direction',
              probability: 1,
              value: '/input',
            },
            {
              id: 8091,
              key: 'L4_type',
              probability: 1,
              value: '/message/modus',
            },
            {
              id: 43,
              key: 'L5_unit',
              probability: 1,
              value: '/scalar/generic',
            },
          ],
        },
      ],
      project_id: 15,
    },
    {
      attributes: [
        {
          alphanumeric_id: 'S+ICON',
          id: 34,
          key: 'S+ICON',
          value: 'fa:fal fa-heat',
        },
      ],
      component: {
        alphanumeric_id: 'S',
        id: 28,
        nameDE: 'System',
        nameEN: 'System',
      },
      display_name: 'Heating system | System',
      id: 196,
      nameDE: 'Heizsystem',
      nameEN: 'Heating system',
      pins: [
        {
          alphanumeric_id: 'S+STAT_OPR',
          dataPointID: 'Status-Heating',
          id: 288,
          location: 'bottom',
          nameDE: 'Betriebsstatus',
          nameEN: 'Operational state',
          pin_attributes: [
            {
              id: 8090,
              key: 'L1_analog_digital',
              probability: 1,
              value: '/multi-state',
            },
            {
              id: 2,
              key: 'L2_virtual',
              probability: 1,
              value: '/physical',
            },
            {
              id: 3,
              key: 'L3_direction',
              probability: 1,
              value: '/input',
            },
            {
              id: 8091,
              key: 'L4_type',
              probability: 1,
              value: '/message/modus',
            },
            {
              id: 43,
              key: 'L5_unit',
              probability: 1,
              value: '/scalar/generic',
            },
          ],
        },
      ],
      project_id: 15,
    },
    {
      attributes: [
        {
          alphanumeric_id: 'S+ICON',
          id: 43,
          key: 'S+ICON',
          value: 'fa:far fa-mask',
        },
      ],
      component: {
        alphanumeric_id: 'S',
        id: 28,
        nameDE: 'System',
        nameEN: 'System',
      },
      display_name: 'Intrusion Detection System | System',
      id: 209,
      nameDE: 'Einbruchmeldeanlage',
      nameEN: 'Intrusion Detection System',
      pins: [
        {
          alphanumeric_id: 'S+STAT_OPR',
          dataPointID: 'Intrusion_detection_status',
          id: 288,
          location: 'bottom',
          nameDE: 'Betriebsstatus',
          nameEN: 'Operational state',
          pin_attributes: [
            {
              id: 8090,
              key: 'L1_analog_digital',
              probability: 1,
              value: '/multi-state',
            },
            {
              id: 2,
              key: 'L2_virtual',
              probability: 1,
              value: '/physical',
            },
            {
              id: 3,
              key: 'L3_direction',
              probability: 1,
              value: '/input',
            },
            {
              id: 8091,
              key: 'L4_type',
              probability: 1,
              value: '/message/modus',
            },
            {
              id: 43,
              key: 'L5_unit',
              probability: 1,
              value: '/scalar/generic',
            },
          ],
        },
      ],
      project_id: 15,
    },
    {
      attributes: [
        {
          alphanumeric_id: 'S+ICON',
          id: 35,
          key: 'S+ICON',
          value: 'fa:fal fa-plug',
        },
      ],
      component: {
        alphanumeric_id: 'S',
        id: 28,
        nameDE: 'System',
        nameEN: 'System',
      },
      display_name: 'Power supply | System',
      id: 197,
      nameDE: 'Stromversorgung',
      nameEN: 'Power supply',
      pins: [
        {
          alphanumeric_id: 'S+STAT_OPR',
          dataPointID: 'Status-Power',
          id: 288,
          location: 'bottom',
          nameDE: 'Betriebsstatus',
          nameEN: 'Operational state',
          pin_attributes: [
            {
              id: 8090,
              key: 'L1_analog_digital',
              probability: 1,
              value: '/multi-state',
            },
            {
              id: 2,
              key: 'L2_virtual',
              probability: 1,
              value: '/physical',
            },
            {
              id: 3,
              key: 'L3_direction',
              probability: 1,
              value: '/input',
            },
            {
              id: 8091,
              key: 'L4_type',
              probability: 1,
              value: '/message/modus',
            },
            {
              id: 43,
              key: 'L5_unit',
              probability: 1,
              value: '/scalar/generic',
            },
          ],
        },
      ],
      project_id: 15,
    },
    {
      attributes: [
        {
          alphanumeric_id: 'S+ICON',
          id: 38,
          key: 'S+ICON',
          value: 'fa:fal fa-tachometer',
        },
      ],
      component: {
        alphanumeric_id: 'S',
        id: 28,
        nameDE: 'System',
        nameEN: 'System',
      },
      display_name: 'Pressure boosting system | System',
      id: 200,
      nameDE: 'Druckerhöhungsanlage',
      nameEN: 'Pressure boosting system',
      pins: [
        {
          alphanumeric_id: 'S+STAT_OPR',
          dataPointID: 'Status-Pressure',
          id: 288,
          location: 'bottom',
          nameDE: 'Betriebsstatus',
          nameEN: 'Operational state',
          pin_attributes: [
            {
              id: 8090,
              key: 'L1_analog_digital',
              probability: 1,
              value: '/multi-state',
            },
            {
              id: 2,
              key: 'L2_virtual',
              probability: 1,
              value: '/physical',
            },
            {
              id: 3,
              key: 'L3_direction',
              probability: 1,
              value: '/input',
            },
            {
              id: 8091,
              key: 'L4_type',
              probability: 1,
              value: '/message/modus',
            },
            {
              id: 43,
              key: 'L5_unit',
              probability: 1,
              value: '/scalar/generic',
            },
          ],
        },
      ],
      project_id: 15,
    },
    {
      attributes: [
        {
          alphanumeric_id: 'S+ICON',
          id: 37,
          key: 'S+ICON',
          value: 'fa:fal fa-fan',
        },
      ],
      component: {
        alphanumeric_id: 'S',
        id: 28,
        nameDE: 'System',
        nameEN: 'System',
      },
      display_name: 'Ventilation systems | System',
      id: 199,
      nameDE: 'RLT-Systeme',
      nameEN: 'Ventilation systems',
      pins: [
        {
          alphanumeric_id: 'S+STAT_OPR',
          dataPointID: 'Status-AHU',
          id: 288,
          location: 'bottom',
          nameDE: 'Betriebsstatus',
          nameEN: 'Operational state',
          pin_attributes: [
            {
              id: 8090,
              key: 'L1_analog_digital',
              probability: 1,
              value: '/multi-state',
            },
            {
              id: 2,
              key: 'L2_virtual',
              probability: 1,
              value: '/physical',
            },
            {
              id: 3,
              key: 'L3_direction',
              probability: 1,
              value: '/input',
            },
            {
              id: 8091,
              key: 'L4_type',
              probability: 1,
              value: '/message/modus',
            },
            {
              id: 43,
              key: 'L5_unit',
              probability: 1,
              value: '/scalar/generic',
            },
          ],
        },
      ],
      project_id: 15,
    },
  ]
}

export function sampleDataForSystemOperationalStatusCodes (): SystemComponentsTimeseries {
  return [
    [
      'Intrusion_detection_status',
      300,
    ],
    [
      'Status-AHU',
      301,
    ],
    [
      'Status-Cooling',
      300,
    ],
    [
      'Status-Elevator-3',
      101,
    ],
    [
      'Status-FireAlert',
      200,
    ],
    [
      'Status-Heating',
      100,
    ],
    [
      'Status-Power',
      300,
    ],
    [
      'Status-Pressure',
      103,
    ],
  ]
}
