<i18n locale="de">
{
  "more": "Mehr"
}
</i18n>
<i18n locale="en">
{
  "more": " More"
}
</i18n>

<template>
  <v-menu
    :close-on-content-click="false"
    max-width="400"
  >
    <template
      #activator="{ props: menuProps }"
    >
      <v-btn
        class="text-body-1 font-weight-medium px-2"
        color="primary-darken2"
        data-testid="add-datapoint-button"
        variant="text"
        v-bind="menuProps"
      >
        {{ t('more') }}
        <v-icon
          end
          size="small"
        >
          fa:fal fa-plus
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-row
          dense
        >
          <v-col>
            <v-checkbox
              v-model="isFavoritesFilterSet"
              class="mt-0"
              color="primary-darken2"
              density="compact"
              :disabled="disabled || !isFavoritesFilterEnabled"
              hide-details
              :label="t('data_points_view.quick_filters.favorites')"
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="isWritableFilterSet"
              class="mt-0"
              color="primary-darken2"
              density="compact"
              :disabled="disabled || !isWritableFilterEnabled"
              hide-details
              :label="t('data_points_view.quick_filters.writable')"
            />
          </v-col>
          <v-spacer />
        </v-row>
        <TagSelector
          class="pt-6"
          column
          :disabled="disabled"
          @change="addTagFilter"
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import { TagItem } from '@/vuex/data_points_view/types'
import TagSelector from '@/views/DataPointsView/TagSelector.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'DatapointsFilter',

  components: {
    TagSelector,
  },

  props: {
    disabled: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },

  emits: ['datapointsfilter:change'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      expanded: true as boolean,
    }
  },

  computed: {
    ...mapGetters({
      hasFilters: 'data_points_view/hasFilters',
    }),

    isFavoritesFilterEnabled (): boolean {
      return this.$store.getters['datapoints/selectedPinForRecommendations'] === null
    },

    isFavoritesFilterSet: {
      get (): boolean {
        return this.$store.getters['data_points_view/isFavoritesFilterSet']
      },
      set (value: boolean): void {
        this.$store.commit('data_points_view/SET_FAVORITES_FILTER', value)
        this.$emit('datapointsfilter:change')
      },
    },

    isWritableFilterEnabled (): boolean {
      return this.$store.getters['datapoints/selectedPinForRecommendations'] === null
    },

    isWritableFilterSet: {
      get (): boolean {
        return this.$store.getters['data_points_view/isWritableFilterSet']
      },
      set (value: boolean): void {
        this.$store.commit('data_points_view/SET_WRITABLE_FILTER', value)
        this.$emit('datapointsfilter:change')
      },
    },
  },

  methods: {
    addTagFilter (tag: TagItem): void {
      this.$store.dispatch('data_points_view/addTagFilter', tag)
      this.$emit('datapointsfilter:change')
    },
  },
})
</script>
